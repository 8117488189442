export class SelectionUtil {

  public static getSelectedText(): Selection | null {
    if (window.getSelection) {
      return window.getSelection();
    } else if (document.getSelection) {
      return document.getSelection();
    }
    return null;
  };

  public static getText(): string | null {
    return SelectionUtil.getSelectedText()?.toString() || null;
  }

  public static removeWindowSelection() {
    // @ts-ignore
    let sel = window.getSelection ? window.getSelection() : document.selection;
    if (sel) {
      if (sel.removeAllRanges) {
        sel.removeAllRanges();
      } else if (sel.empty) {
        sel.empty();
      }
    }
  }
}