import { TextUtils } from '../../../../../common/utils/text-utils';

export interface IPhraseDetailsPromptPrepareInput {
  prompt: string;
  phrase: string;
  context: string;
  phraseCssClass: string;
  contextCssClass: string;
}
export class PhraseDetailsPhrasePrepareHelper {

  public static format(input: IPhraseDetailsPromptPrepareInput) {
    const phrase = TextUtils.replaceNewLines(input.phrase);
    let context = TextUtils.replaceNewLines(input.context);
    let result = TextUtils.replaceNewLines( input.prompt);
    result = PhraseDetailsPhrasePrepareHelper.formatPromptInputByParam(result, context, input.contextCssClass);
    result = PhraseDetailsPhrasePrepareHelper.formatPromptInputByParam(result, phrase, input.phraseCssClass);
    return result;
  }

  private static formatPromptInputByParam(prompt: string, param: string, className: string) {
    if (!param) return prompt;
    const value = `<span class="${className}">` + param + '</span>';
    return prompt.replaceAll(param, value);
  }
}