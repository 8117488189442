import React from 'react';
import { useParams } from 'react-router-dom';
import { ICopyGroupProps } from './types';

export const CopyGroup: React.FC<ICopyGroupProps> = ({
  onLoad
}) => {

  let { hash } = useParams();

  React.useEffect(() => {
    onLoad(hash);
  }, [hash]);

  return null;

};
