import React from 'react';
import { IOneTimeTooltipSelectProps } from './types';
import { ONETIME_TOOLTIP_SELECT } from '../../wordings';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { IconButton, Menu, Stack } from '@mui/material';
import { CloseIconLight } from '../../../../../../../icons/CloseIconLight';

export function OneTimeTooltipSelect ({
	anchorEl,
	onClose,
}: IOneTimeTooltipSelectProps) {

	const src = require('../../../../../../../img/select.mp4').default;

	return <Menu
		className={'animate__animated animate__zoomIn'}
		anchorEl={anchorEl}
		open={true}
		onClose={onClose}
		sx={{
			'& > .MuiPaper-root': {
				p: `${theme.spacing(1)} ${theme.spacing(1)} 0`,
				background: theme.palette.hint.light,
			},
		}}
	>
		<IconButton
			onClick={onClose}
			sx={{
				position: 'absolute',
				zIndex: 100,
				top: 0,
				right: 0,
				color: theme.palette.grey[400],

				'.MuiSvgIcon-root': { width: theme.spacing(2.5), height: theme.spacing(2.5) }
			}}
		>
			<CloseIconLight />
		</IconButton>

		<Stack
			gap={theme.spacing(1)}
		>
			<Text
				sx={{
					fontSize: theme.typography.h6,
					fontWeight: 600,
				}}
			>
				{ONETIME_TOOLTIP_SELECT}
			</Text>
			<video
				height="100"
				autoPlay
				loop
			>
				<source src={src} type="video/mp4"/>
			</video>
		</Stack>
	</Menu>
}
