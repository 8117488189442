import { EPlayerControllerMode, PlayerController } from '../playerController';
import { PlayerManager } from '../playerManager';
import { IPhraseSelectResult } from '../../../phrase/utils/phrase-select-preparator';
import { CaptionsSelectionPopupActions } from '../../../../store/captions-selection-popup/actions';
import { getDispatch } from '../../../../store';

export class PlaySelectTextHandler {

  public async play(selectResult: IPhraseSelectResult) {
    if (!selectResult || !selectResult.startTime || !selectResult.endTime) return;

    getDispatch()(CaptionsSelectionPopupActions.setPlayingSelectText(true));
    const playerController = PlayerController.getInstance();
    const playerManager = PlayerManager.getInstance()
    playerController.reset();

    await playerManager.pausePlay();

    playerController.setMode(EPlayerControllerMode.PLAY_SELECT_TEXT);
    await playerManager.startPlay(selectResult.startTime);
    playerManager.setTimer(selectResult.endTime);
  }

  public async onTimerDone() {
    await PlayerManager.getInstance().pausePlay();
    PlayerController.getInstance().setMode(EPlayerControllerMode.PLAY_COMMON);
    setTimeout(() => {
      getDispatch()(CaptionsSelectionPopupActions.setPlayingSelectText(false));
    }, 300)
  }
}