import { AppThunk } from '../../../../store/types';
import { MainLayoutEffects } from '../../../../effects/mainLayoutEffects';

export class PhrasesViewEvents {

  public static onShowLeftPanel(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      MainLayoutEffects.showLeftPanel(true);
    }
  }

  public static onShowHelp (): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(MainLayoutEffects.showHelp());
    }
  }

}