import { IState } from '../../../../../store/types';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { connect } from 'react-redux';
import { PhraseDetailsTabsEditorRouter } from '../components/PhraseDetailsTabsEditorRouter';
import { IPhraseDetailsTabsEditorRouterEvents, IPhraseDetailsTabsEditorRouterFields } from '../components/types';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { EMainViewMode } from '../../../../../store/general/types';
import { PhraseDetailsTabsEditorRouterEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabsEditorRouterFields => {
    return {
      show: getMainViewMode(state) === EMainViewMode.PHRASE_DETAILS_TABS_EDITOR,
      viewMode: PhraseDetailsTabsEditorPopupSelectors.getViewMode(state)
    }
  };
}


const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabsEditorRouterEvents => ({
    onChange: () => dispatch(PhraseDetailsTabsEditorRouterEvents.onChange()),
    onLoad: () => dispatch(PhraseDetailsTabsEditorRouterEvents.onLoad()),
  }
);


export const PhraseDetailsTabsEditorRouterHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabsEditorRouter);

PhraseDetailsTabsEditorRouterHOC.displayName = 'PhraseDetailsTabsEditorRouterHOC';