import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { InstallExtensionPopup } from '../components/InstallExtensionPopup';
import { IInstallExtensionPopupEvents, IInstallExtensionPopupFields } from '../components/types';
import { InstallExtensionPopupEvents } from './events';
import { isShowInstallExtensionWarningPopup } from '../../../../store/general/selectors';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IInstallExtensionPopupFields => {
    return {
      isOpen: isShowInstallExtensionWarningPopup(state)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IInstallExtensionPopupEvents => ({
    onClose: () => dispatch(InstallExtensionPopupEvents.onClose())
  }
);

export const InstallExtensionPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallExtensionPopup);

InstallExtensionPopupHOC.displayName = 'InstallExtensionPopupHOC';
