import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { getRequest, getRequestWithoutToken, postRequest } from '../../utils/requestService';
import { TPublicProfile } from '../../../spa/store/general/types';

export interface IUserProfileRest {
  id: number;
  userId: number;
  bio?: string;
  html?: string;
  name?: string;
  userName?: string;
  avatar?: string;
  showAvatar?: boolean;
}


export enum EUserProfileSaveErrorType {
  USER_NAME_EXIST = 1
}

export type TUserProfileRestResp = {
  result: {
    success: boolean,
    errorType?: EUserProfileSaveErrorType
  }
  profile?: TPublicProfile
}


export type TUserProfileGroupLang = {
  name: string,
  code: string
}

export type TUserProfileGroupVideo = {
  groupId?: number;
  videoId?: number,
  videoKey: string,
  title: string;
  thumb: string;
  thumbMedium: string;
  thumbStandard: string;
  author: string;
  notes?: TTeacherPhraseNoteInfo[];
  targetLangCode?: string,
  nativeLangCode?: string,
}

export type TUserProfileGroup = {
  id: number,
  name: string,
  targetLang: TUserProfileGroupLang,
  nativeLang: TUserProfileGroupLang,
  videos: TUserProfileGroupVideo[]
}


export type TTeacherPhraseNoteGroupInfo = {
  groupId: number,
  groupName: string,
  targetLangCode: string,
  nativeLangCode: string,
  videos: TTeacherPhraseNoteVideoInfo[]
}


export type TTeacherPhraseNoteVideoInfo = {
  groupId?: number;
  videoId: number,
  videoKey: string,
  thumbUrl: string,
  thumbMediumUrl: string,
  thumbStandardUrl: string,
  videoTitle: string,
  videoAuthor: string,
  targetLangCode: string,
  nativeLangCode?: string,
  notes: TTeacherPhraseNoteInfo[]
}

export type TTeacherPhraseNoteInfo = {
  phraseNoteId: number,
  phraseText: string,
  noteText: string,
}

export class UserProfileRest {

  private static PROFILE_URL = API_URL + '/userProfile/nick';
  private static PROFILE_SAVE_URL = API_URL + '/users/profile';
  private static PROFILE_GROUP_URL = API_URL + '/userProfile/groups/';
  private static PROFILE_NOTES_URL = API_URL + '/userProfile/notes/';
  private static PROFILE_LIST_URL = API_URL + '/users/profile/list';


  public static async getByNick(profileNickName: string): Promise<IUserProfileRest> {
    const url = `${this.PROFILE_URL}/${profileNickName}`;
    return await getRequestWithoutToken(url);
  }

  public static async findGroups(profileNickName: string): Promise<TUserProfileGroup[]> {
    const url = this.PROFILE_GROUP_URL + profileNickName;
    return getRequestWithoutToken(url);
  }

  public static async save(data: TPublicProfile): Promise<TUserProfileRestResp> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(this.PROFILE_SAVE_URL, data));
  }

  public static async findNotes(profileNickName: string): Promise<TTeacherPhraseNoteGroupInfo[]> {
    const url = this.PROFILE_NOTES_URL + profileNickName;
    return getRequestWithoutToken(url);
  }

  public static async getList(): Promise<IUserProfileRest[]> {
    const dispatch = getDispatch();
    const url = this.PROFILE_LIST_URL;
    return dispatch(getRequest(url));
  }
}