import * as React from 'react';
import { IVideoLibraryFlagsProps } from './types';
import { LangFlag } from '../../../LangFlag/LangFlag';
import { theme } from '../../../../../../common/styles/theme';
import { Button, Stack } from '@mui/material';
import { Text } from "../../../../../../common/components/Text/Text";
import { CopyGroupEffects } from '../../../../../effects/copyGroupEffects';
import { getActiveGroupId, getActiveUserGroup } from '../../../../../store/models/selectors';
import { getDispatch, getState } from '../../../../../store';
import { setSnackbarPanelAction } from '../../../../../store/general/actions';


export const VideoLibraryFlags: React.FC<IVideoLibraryFlagsProps> = ({
  show,
  nativeLangCode,
  targetLangCode
}) => {

  if (!show) return null;

  return (
    <Stack
      direction='row'
      spacing={theme.spacing()}
      alignItems={'center'}
    >
      <LangFlag code={targetLangCode} showCode={true}/>
      {nativeLangCode && targetLangCode &&
        <Text fontWeight={600} sx={{ color: theme.palette.grey[400] }}>/</Text>
      }
      <LangFlag code={nativeLangCode} showCode={true}/>
    </Stack>
  );
};
