import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IProfileGroupInfoEvents, IProfileGroupInfoFields } from '../components/types';
import { IProfileGroupInfoHOCProps } from './types';
import { ProfileGroupInfoEvents } from './events';
import { ProfileGroupInfo } from '../components/ProfileGroupInfo';
import { PublicProfileSelectors } from '../../../../../store/public-profile/selector';

const mapStateToProps = (
  dispatch: any,
  props: IProfileGroupInfoHOCProps
) => {
  return (
    state: IState
  ): IProfileGroupInfoFields => {
    const { id = 0 } = props;
    const group = PublicProfileSelectors.getGroupById(state, id);

    return {
      id,
      title: group.name,
      nativeLang: group.nativeLang.code,
      targetLang: group.targetLang.code,
      description: '',
      videos: group.videos
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  { id }: IProfileGroupInfoHOCProps
): IProfileGroupInfoEvents => ({
    onCopyGroup: () => dispatch(ProfileGroupInfoEvents.onCopyGroup(id)),
  }
);

export const ProfileGroupInfoHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileGroupInfo);

ProfileGroupInfoHOC.displayName = 'ProfileGroupInfoHOC';
