import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { Box, MenuItem, Stack } from '@mui/material';
import { IContentLibVideoPopupProps } from './types';
import { Text } from '../../../../../../common/components/Text/Text';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { getUserGroupTitle } from '../../../../../store/models/selectors';
import { getState } from '../../../../../store';
import LangFlag from '../../../LangFlag/LangFlag';
import { PlusIcon } from '../../../../../../../icons/PlusIcon';

export const ContentLibVideoPopup: React.FC<IContentLibVideoPopupProps> = ({
  isOpen,
  title,
  listTitle,
  channel,
  thumbUrl,
  groups,
  targetLang,
  nativeLang,
  url,
  canSave,
  onSave,
  onClose
}) => {
  if (!isOpen) return null;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
//  const [selectGroupId, setSelectGroupId] = React.useState<number>(-1);

  const handleSaveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectGroup = (groupId: number) => {
    setAnchorEl(null);
    onSave(groupId);
  }

  const player = React.useRef(null);	
	React.useEffect(() => {
    let idI = url.indexOf('v=');
    let videoId = idI > 0 ? url.slice(idI + 2) : '';
		requestAnimationFrame(() => {

			function init() {
				player.current = new window.YT.Player('ytplayer', {
					videoId,
					playerVars: {
						autoplay: 1,
						showinfo: 0,
						autohide: 1,
						modestbranding: 1,
					},
					events: {
						'onStateChange': (event: any) => {},
					}
				});
			}
			if (!document.getElementById('ytplayer')) {
				let div = document.createElement('div');
				div.id = 'ytplayer';
				document.getElementById('ytplayer-container-lib-popup')?.append(div);
			}

			if (window.YT && window.YT.Player) {
				init();
			} else if (document.getElementById('yt_iframe_api')) {
				let callback = window.onYouTubeIframeAPIReady;
				window.onYouTubeIframeAPIReady = () => {
					if (callback) callback();
					init();
				}
			} else {
				let tag = document.createElement('script');
				tag.src = 'https://www.youtube.com/iframe_api';
				tag.id = 'yt_iframe_api';
				let firstScriptTag = document.getElementsByTagName('script')[0];
				firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
				window.onYouTubeIframeAPIReady = init;
			}
		});

		return () => {
		}
	}, [url]);

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      PaperProps={{
        sx: {
          width: `95vw !important`,
          height: `95vh !important`,

          '& > .MuiStack-root': {
            height: 1,  
            maxHeight: 1,
          },
        }
      }}
    >
      <Stack
        gap={theme.spacing(2)}
        sx={{
          width: 1,
          height: 1,
          mt: -3,
        }}
      >
        <Stack
          gap={theme.spacing(.5)}
        >
          <Box
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '-webkit-line-clamp': '2',
              '-webkit-box-orient': 'vertical',
              maxHeight: '2.6em',
              lineHeight: `1.3 !important`,
              
              maxWidth: '80%',
              fontSize: theme.typography.h5,
              fontWeight: 600,
            }}
          >
            {title}
          </Box>

          <Text
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: theme.typography.subtitle2,
              color: theme.palette.grey[500],
              whiteSpace: 'nowrap',
            }}
          >
            {channel}
          </Text>
        </Stack>
        
        <Box
          id={'ytplayer-container-lib-popup'}
          sx={{
            position: 'relative',
            flex: `1 1 100%`,
            minHeight: 0,

            'iframe': {
              width: `100% !important`,
              height: `100% !important`,
              aspectRatio: 16/9,
              borderRadius: theme.spacing(1),
              border: 'none',
            }
          }}
        />

        <Stack
					direction={'row'}
					gap={2}
          justifyContent={'space-between'}
          sx={{
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
				>
          <Stack
            gap={.5}
            sx={{
              minWidth: 0,
            }}
          >
            <Stack
              direction={'row'}
              gap={3}
            >
              {/* <User
                name={'testAuthor'}
                photo={'https://i.ytimg.com/vi/9gidul7vqOw/default.jpg'}
              /> */}
              <Stack
                direction={'row'}
                gap={1}
                sx={{
                  '.lang-flag__code': {
                    color: theme.palette.grey[500],
                  },
                }}
              >
                <LangFlag code={targetLang} showCode={true} />
                <LangFlag code={nativeLang} showCode={true} />
              </Stack>
            </Stack>

            <Text
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: theme.typography.subtitle2,
                color: theme.palette.grey[500],
                whiteSpace: 'nowrap',
              }}
            >
              {listTitle}
            </Text>
          </Stack>

          {canSave &&
          <Button
            variant={'contained'}
            startIcon={
              <PlusIcon sx={{ width: theme.spacing(2.5), height: theme.spacing(2.5) }} />
            }
            onClick={handleSaveClick}
          >
            Add to my playlist
          </Button>}
        </Stack>
  
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!anchorEl}
          onClose={handleClose}
          sx={{
            '.MuiPaper-root': {
              width: theme.spacing(33),
              mt: theme.spacing(),
              boxShadow: theme.shadows[16],
            },

            '.MuiMenuItem-root': {
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        >
          {groups.map(group => {
            return <MenuItem
              onClick={() => onSelectGroup(group.id)}
            >
              {getUserGroupTitle(getState(), group)}
            </MenuItem>
          })}

          <Button
            variant={'contained'}
            sx={{
              ml: 2,
              mt: 1,
            }}
            onClick={() => onSelectGroup(0)}
          >
            Create new playlist
          </Button>

        </Menu>
      </Stack>
    </Modal>
  );
}