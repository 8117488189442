import React from 'react';
import { Box, Stack } from "@mui/material";
import { theme } from "../../../../common/styles/theme";
import { Text } from "../../../../common/components/Text/Text";

export type TShortcutProps = {
	label: string;
	shortcutKey: any;
	alternativeKey?: any;
}

export const Shortcut: React.FC<TShortcutProps> = ({
	label,
	shortcutKey,
	alternativeKey
}) => {
	const keyStyle = {
		height: theme.spacing(4),
		minWidth: theme.spacing(4),
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
		borderRadius: theme.spacing(0.5),
		bgcolor: theme.palette.secondary.main,
	};

	const lineStyle = {
		justifyContent: 'space-between',
		alignItems: 'center',
		mb: 0.5
	};

	return (
		<Stack direction='row' sx={lineStyle}>
			<Text>
				{label}
			</Text>
			<Stack direction='row' spacing={theme.spacing()} sx={{ alignItems: 'center' }}>
				<Box sx={keyStyle}>
					<Text
						fontWeight={600}
						sx={{
							p: theme.spacing(),
							color: theme.palette.grey[500]
						}}
					>
						{shortcutKey}
					</Text>
				</Box>
				{alternativeKey &&
					<>
						<Text>
							or
						</Text>
						<Box sx={keyStyle}>
							<Text
								fontWeight={600}
								sx={{
									py: theme.spacing(),
									color: theme.palette.grey[500]
								}}
							>
								{alternativeKey}
							</Text>
						</Box>
					</>
				}
			</Stack>
		</Stack>
	);
}
