export class HtmlUtils {

  public static findParentByClassName(element: HTMLElement | null | undefined, className: string): HTMLElement | null {
    let elem = element;
    while(elem) {
      if (elem.className &&
        typeof elem.className === 'string' &&
        elem.className.indexOf(className) >= 0) {
        return elem;
      }
      elem = elem.parentElement;
    }
    return null;
  }

  public static getParentsClasses (
    element: HTMLElement,
    names: string[] = null
  ): string[] {
    let target = element;
    let classes: string[] = [];
    let found = false;
    while (
        !found &&
        target &&
        target.parentNode
    ) {
        if (
            target.classList && // svg path doesnt have classList
            target.classList.length
        ) {
            classes = [
                ...classes,
                ...target.classList
            ];
            if (names) {
                for (
                    let i = 0;
                    i < names.length;
                    i++
                ) {
                    if (classes.includes(names[i])) {
                        found = true;
                        break;
                    }
                }
            }
        }
        target = target.parentNode as HTMLElement;
    }
    return classes;
  }

  public static getElementMaginsHeight(elem: HTMLDivElement): number {
    //@ts-ignore
    const style = elem.currentStyle || window.getComputedStyle(elem);
    let result = 0;
    [style.marginTop, style.marginBottom].forEach((s: string) => {
      const ps = s.indexOf('px');
      if (ps > 0) {
        const val = parseInt(s.substring(0, ps));
        if (val) {
          result += val;
        }
      }
    })
    return result;
  }

  public static removeHtmlTags(s: string, replace: string = ''): string {
    return s.replaceAll(/<\/?[^>]+(>|$)/g, replace);
  }
}
