import { alpha, Box, IconButton, Snackbar as SnackbarComponent, SnackbarProps, styled } from "@mui/material";
import React from 'react';
import { theme } from "../../styles/theme";
import { CloseIconLight } from "../../../../icons/CloseIconLight";

const MUISnackbar = styled(SnackbarComponent)<SnackbarProps>(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.spacing(),
    background: alpha(theme.palette.grey[900], 0.9),
    boxShadow: theme.shadows,
    backdropFilter: 'blur(16px)',
}));

export interface ISnackbarProps extends SnackbarProps {
    hideClose?: boolean;
    onClose: () => void;
}

export const Snackbar = ({
    hideClose,
    onClose,
    children,
    ...props
}: ISnackbarProps) => (
    <MUISnackbar
        {...props}
    >
        <Box sx={{ width: 1, position: 'relative' }}>
            {!hideClose &&
            <IconButton
              onClick={onClose}
              color={'primary'}
              sx={{
                  position: 'absolute',
                  top: theme.spacing(-2),
                  right: theme.spacing(-2),
                  color: theme.palette.grey[400],
                  '&:hover, &:active, &:focus': {
                      color: theme.palette.grey[300],
                  },
              }}
            >
                <CloseIconLight />
            </IconButton>
            }
            {children}
        </Box>
    </MUISnackbar>
);
