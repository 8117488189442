export class DateUtil {

  public static trimToDay(date: Date): Date {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  }

  public static trimToHour(date: Date): Date {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours()));
  }

  public static incDays(date: Date, days: number): Date {
    return new Date(date.getTime() + days * 1000 * 60 * 60 *24);
  }

}