import { AppThunk } from '../../../../store/types';
import { MainLayoutEffects } from '../../../../effects/mainLayoutEffects';
import { setOnboardingAction } from '../../../../store/general/actions';

export class DashboardEvents {

  public static onShowLeftPanel(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      MainLayoutEffects.showLeftPanel(true);
    }
  }

  public static onShowHelp (): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(MainLayoutEffects.showHelp());
    }
  }

  public static onShowOnboarding(
    step?: number,
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setOnboardingAction({
        isActive: true,
        step: step || 0,
      }));
    }
  }

}