import React from 'react';
import { theme } from '../../../../../../../common/styles/theme';
import { Box, Stack } from '@mui/material';
import { IPhraseTranslationSettingsProps } from './types';
import { PhraseDetailsTabTranslationLangHOC } from '../../PhraseDetailsTabTranslationLang/hocs/PhraseDetailsTabTranslationLangHOC';
import { PhraseDetailsTabTranslationLangAddHOC } from '../../PhraseDetailsTabTranslationLangAdd/hocs/PhraseDetailsTabTranslationLangAddHOC';
import { DragDropContext, DragStart, Droppable, DropResult } from 'react-beautiful-dnd';
import { Text } from '../../../../../../../common/components/Text/Text';
import { UserLangsTranslationColors } from '../../helpers/userLangsTranslationColors';

export function PhraseTranslationSettings ({
	translateLangs,
	onSort
}: IPhraseTranslationSettingsProps) {

	const [dragLangCode, setDragLangCode] = React.useState<string>('');

	const handleDrop = (droppedItem: DropResult) => {
		if (!droppedItem.destination) {
			setDragLangCode('');
			return;
		}
		const srcIndex = droppedItem.source.index;
		const sortedLangs = [...translateLangs];
		const srcLang = translateLangs[srcIndex];
		const destLang = translateLangs[droppedItem.destination.index];
		sortedLangs.splice(srcIndex, 1);
		let destIndex = sortedLangs.findIndex(l => l.code === destLang.code);

		if (droppedItem.destination.index > droppedItem.source.index)
			destIndex++;
		sortedLangs.splice(destIndex, 0, srcLang);
		setDragLangCode('');
		onSort(sortedLangs.map(l => l.code));
	};

	const handleStartDrag = (event: DragStart) => {
		setDragLangCode(event.draggableId);
	}

	return (
		<Stack sx={{
			h: 1,
			p: theme.spacing(2), 
		}}>
			<Text
				variant={'subtitle2'}
				sx={{
					mb: theme.spacing(1),
					fontWeight: 600,
				}}
			>
				Translation settings
			</Text>

			<Box sx={{
				flex: `1 1 100%`,
				overflow: 'auto',
			}}>


				<Stack
					gap={theme.spacing(2)}
					sx={{
						mt: theme.spacing(1),
					}}
				>
					<PhraseDetailsTabTranslationLangAddHOC />

					{translateLangs && !!translateLangs.length &&
						<DragDropContext onDragEnd={handleDrop} onDragStart={handleStartDrag} >
							<Droppable droppableId="list-container">
								{(provided) => (
									<Stack
										gap={theme.spacing(.5)}
										{...provided.droppableProps}
										ref={provided.innerRef}
									>
										{translateLangs.map((lang, index) => {
											return <PhraseDetailsTabTranslationLangHOC
												key={lang.code}
												index={index}
												langCode={lang.code}
												color={UserLangsTranslationColors.getColor(lang.code)}
												dragable={dragLangCode === lang.code}
											/>
										})}
										{provided.placeholder}
									</Stack>
								)}
							</Droppable>
						</DragDropContext>
					}
				</Stack>
			</Box>
		</Stack>
	);

}

