import { TAsyncThunk } from '../../../spa/store/types';
import { postRequest } from '../../utils/requestService';
import { API_URL } from '../../constants';
import { TNewVocabularyPhrase } from '../../../spa/types/common';
import { getAudioIdByKey, isAudioMovieKey } from '../../../spa/store/current-video/selectors';

export const PHRASE_CREATION_URL = API_URL + '/phrases/add';

export const createPhraseRest = (
  phrase: TNewVocabularyPhrase,
  teacherMode: boolean
): TAsyncThunk => async(
  dispatch
) => {
  const audioId = isAudioMovieKey(phrase.videoKey) ? getAudioIdByKey(phrase.videoKey) : 0;
  const phraseBody = { phrase, audioId, teacherMode};
  return await dispatch(postRequest(PHRASE_CREATION_URL, phraseBody));
};
