import { AppThunk } from '../../../../../../store/types';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../../store/phrase-details-tabs-editor-popup/actions';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsTabEffects } from '../../../../../../effects/phraseDetailsTabEffects';
import {
  IPhraseDetailsPromptValidatorResult,
  PhraseDetailsPromptValidator
} from '../../../../../../effects/phrase-details/phraseDetailsPromptValidator';
import { PhraseDetailsSelectors } from '../../../../../../store/phrase-details/selectors';
import { PhraseDetailsCustomPromptTestService } from '../../../../../../effects/phrase-details/phraseDetailsService/phrase-details-custom-prompt-test-service';
import { EventsRouter } from '../../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../../common/events/types';
import { PhraseContextEditorSelectors } from '../../../../../../store/phrase-context-editor/selectors';

export class PhraseDetailsTabCustomPromptFormEvents {

  public static onShow(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const state = getState();
      const tabId = PhraseDetailsTabsEditorPopupSelectors.getEditTabId(state);
      const lastSaveTabId = PhraseDetailsTabsEditorPopupSelectors.getLastSaveTabId(state);
      if (tabId !== lastSaveTabId) {
        dispatch(PhraseDetailsTabsEditorPopupActions.setLastSaveTabId(0));
      }
    }
  }

  public static onSave(title: string, prompt: string, description: string): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const state = getState();
      EventsRouter.trackEvent(Events.SAVE_TO_MY_PROMPTS_BUTTON_CLICKED);
      dispatch(PhraseDetailsTabsEditorPopupActions.setProcessing(true));
      const tabId = PhraseDetailsTabsEditorPopupSelectors.getEditTabId(state);
      const savedTab = await PhraseDetailsTabEffects.saveCustomPrompt(tabId, title, prompt, description);
      dispatch(PhraseDetailsTabsEditorPopupActions.setProcessing(false));
      dispatch(PhraseDetailsTabsEditorPopupActions.setEditTabId(savedTab.id));
      dispatch(PhraseDetailsTabsEditorPopupActions.setLastSaveTabId(savedTab.id));
    }
  }

  public static onValidate(prompt: string): AppThunk {
    return (
      dispatch,
      getState
    ): IPhraseDetailsPromptValidatorResult => {
      return PhraseDetailsPromptValidator.validate(prompt);
    }
  }

  public static onPromptChange(prompt: string): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      let phrasePreview = PhraseContextEditorSelectors.getPhraseText(state);
      let text = phrasePreview && phrasePreview.id < 0 && phrasePreview?.highlighted || '';
      let context = PhraseContextEditorSelectors.getPhraseContext(state)?.fullPhrase || PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state) || '';
      let fromLang = PhraseDetailsSelectors.getFromLang(state);
      let toLang = PhraseDetailsSelectors.getToLang(state);

      if (!text) { // no phrase selected
        const firstCaptionAsPhrase = PhraseDetailsTabsEditorPopupSelectors.getFirstCaptionAsPhrase(state);
        if (firstCaptionAsPhrase && firstCaptionAsPhrase.text) {
          text = firstCaptionAsPhrase.text;
          context = firstCaptionAsPhrase.context || '';
          fromLang = firstCaptionAsPhrase.fromLang;
          toLang = firstCaptionAsPhrase.toLang;
        }
      }

      const service = new PhraseDetailsCustomPromptTestService(text, context, fromLang?.code || '', toLang?.code || '', prompt);
      dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestPrompt(await service.getPreparedPrompt()));
    }
  }

  // public static onShowPhraseSelectPopup(): AppThunk {
  //   return (
  //     dispatch,
  //     getState
  //   ) => {
  //     return dispatch(PhraseSelectPopupActions.setShow(true));
  //   }
  // }


}