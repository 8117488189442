import React from 'react';
import { IPhraseDetailsTabsEditorRouterProps, PHRASE_DETAILS_TAB_EDITOR_CLASS_NAME } from './types';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { PhraseDetailsTabsEditorContainerHOC } from '../../PhraseDetailsTabsEditorContainer/hocs/PhraseDetailsTabsEditorContainerHOC';
import { PhraseDetailsTabsLibContainerHOC } from '../../PhraseDetailsTabsLibContainer/hocs/PhraseDetailsTabsLibContainerHOC';
import { PhraseDetailsTabsCreateContainerHOC } from '../../PhraseDetailsTabsCreateContainer/hocs/PhraseDetailsTabsCreateContainerHOC';
import { Stack } from "@mui/material";
import { theme } from "../../../../../../common/styles/theme";
import { PhraseDetailsTabTranslationContainerHOC } from '../../PhraseDetailsTabTranslation/PhraseDetailsTabTranslationContainer/hocs/PhraseDetailsTabTranslationContainerHOC';

export const PhraseDetailsTabsEditorRouter: React.FC<IPhraseDetailsTabsEditorRouterProps> = ({
  show,
  viewMode,
  onChange,
  onLoad,
}) => {



  if (!show) return null;

  React.useEffect(() => {
    onChange();
  }, [viewMode]);

  React.useEffect(() => {
    onLoad();
  }, []);

  const containerStyle = {
    gap: theme.spacing(2),
    width: 1,
    minHeight: 1,
    overflowY: 'auto',
    background: theme.palette.grey[100],
    p: theme.spacing(2),
  };





  return (<>

    <Stack
      direction={'row'}
      sx={containerStyle}
      className={PHRASE_DETAILS_TAB_EDITOR_CLASS_NAME}
    >
      {viewMode === EPhraseDetailsTabsEditorViewMode.MAIN &&
        <PhraseDetailsTabsEditorContainerHOC />
      }
      {viewMode === EPhraseDetailsTabsEditorViewMode.CREATE &&
        <PhraseDetailsTabsCreateContainerHOC />
      }
      {viewMode === EPhraseDetailsTabsEditorViewMode.LIBS &&
        <PhraseDetailsTabsLibContainerHOC />
      }
      {viewMode === EPhraseDetailsTabsEditorViewMode.TRANSLATIONS &&
        <PhraseDetailsTabTranslationContainerHOC />
      }
    </Stack>
  </>);
}
