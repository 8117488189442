import { AppThunk } from '../../../../../../store/types';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../../store/phrase-details-tabs-editor-popup/actions';
import { getGeneralStorage } from '../../../../../../../common/utils/local-storage/local-storage-helpers';


export class VideoTutorialTooltipEvents {

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(PhraseDetailsTabsEditorPopupActions.setShowTutorial(false));
      getGeneralStorage().setShowVideoPromptsTutorial(false);
    }
  }

}