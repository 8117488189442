import { IState } from '../../../../../store/types';
import { isFeedbackModalOpen } from '../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { FeedbackEvents } from './events';
import { IFeedbackEvents, IFeedbackFields } from '../components/types';
import { Feedback } from '../components/Feedback';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState,
	): IFeedbackFields => {
		const isShow = isFeedbackModalOpen(state);

		return {
			isShow,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IFeedbackEvents => ({
	onClose: () => dispatch(FeedbackEvents.onClose()),
	onSend: (form) => dispatch(FeedbackEvents.onSend(form)),
});

export const FeedbackHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(Feedback);

FeedbackHOC.displayName = 'FeedbackHOC';
