import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { theme } from '../../../../../../common/styles/theme';
import { IconButton, Stack } from '@mui/material';
import { SettingsIcon } from '../../../../../../../icons/SettingsIcon';
import { IVideoLibraryHeaderProps } from './types';
import { VideoLibraryHeaderSelect } from './VideoLibraryHeaderSelect';
import { VideoLibraryHeaderEmptyList } from './VideoLibraryHeaderEmptyList';
import { CLASS_ONBOARDING_NOEVENT } from '../../../Onboarding/constants';
import { SquareAppIcon } from '../../../../../../../icons/SquareAppIcon';
import { LinkIcon } from '../../../../../../../icons/LinkIcon';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';

export const VideoLibraryHeader: React.FC<IVideoLibraryHeaderProps> = ({
  group,
  groups,
  libraryGroupId,
  onGroupChange,
  onShowEditPlayLists,
  onCopyLink,
}) => {
  const {isMobile} = useContext(DeviceContext);

  const { groupId } = useParams();

  React.useEffect(() => {
    onGroupChange(parseInt(groupId));
  }, [groupId]);

  React.useEffect(() => {
    if (groups.length === 0) return;
    const gId = parseInt(groupId);
    if (gId && !libraryGroupId) {
      onGroupChange(gId);
    }
  }, [groupId, groups, libraryGroupId]);

  return (
    <Stack
      className={CLASS_ONBOARDING_NOEVENT}
      direction='row'
      sx={{ justifyContent: 'space-between' }}
      spacing={theme.spacing()}
    >
      {groups.length > 0 && (
        <VideoLibraryHeaderSelect
          groupId={group ? group.id : 0}
          groups={groups}
          onGroupChange={onGroupChange}
          sx={{
            width: '100%',
            maxWidth: isMobile
              ? '100%'
              : `calc(100% - ${theme.spacing(12)})`, // 2 buttons
          }}
        />
      )}

      {groups.length <= 0 && <VideoLibraryHeaderEmptyList />}

      {!isMobile &&
        <IconButton color={'secondary'} onClick={onCopyLink}>
          <LinkIcon />
        </IconButton>
      }

      <IconButton color={'secondary'} onClick={onShowEditPlayLists}>
        <SettingsIcon />
      </IconButton>
    </Stack>
  );
};
