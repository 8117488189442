import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabItem } from '../components/PhraseDetailsTabItem';
import { IPhraseDetailsTabItemEvents, IPhraseDetailsTabItemFields } from '../components/types';
import { getPhraseDetailsTabById } from '../../../../../store/models/selectors';
import { IPhraseDetailsTabItemHOCOwnProps } from './types';
import { PhraseDetailsTabItemEvents } from './events';
import { PhraseDetailsTabEffects } from '../../../../../effects/phraseDetailsTabEffects';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    { id, firstTabItem, index }: IPhraseDetailsTabItemHOCOwnProps
  ): IPhraseDetailsTabItemFields => {
    const prompt = getPhraseDetailsTabById(state, id);
    const promptDetails = prompt && PhraseDetailsTabEffects.getCustomPromptByTab(prompt);
    const canEdit = !!(prompt && PhraseDetailsTabEffects.isEditableTabType(prompt.type));
    const canDelete = !!(prompt && PhraseDetailsTabEffects.isCanDeleteTabType(prompt.type));
    return {
      title: prompt ? prompt.title : '',
      text: promptDetails ? promptDetails.description : '',
      favorite: prompt?.favorite || false,
      canDelete,
      canEdit,
      showFavoriteTooltip: firstTabItem && PhraseDetailsTabsEditorPopupSelectors.isShowFavoritePromptTooltip(state),
      index,
      tabId: id,
      isSelected: PhraseDetailsTabsEditorPopupSelectors.getEditTabId(state) === id,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  { id }: IPhraseDetailsTabItemHOCOwnProps
): IPhraseDetailsTabItemEvents => ({
    onEdit: () => dispatch(PhraseDetailsTabItemEvents.onEdit(id)),
    onDelete: () => dispatch(PhraseDetailsTabItemEvents.onDelete(id)),
    onFavoriteToggle: () => dispatch(PhraseDetailsTabItemEvents.onFavoriteToggle(id))
  }
);

export const PhraseDetailsTabItemHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabItem);

PhraseDetailsTabItemHOC.displayName = 'PhraseDetailsTabItemHOC';