import { AppThunk } from '../../../../store/types';
import { setSnackbarPanelAction } from '../../../../store/general/actions';

export class SnackbarPanelEvents {

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setSnackbarPanelAction(false));
    }
  }

}
