import React from 'react';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { Button, CircularProgress, FormControl, InputLabel, Stack, TextField } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { ICreatePlaylistProps } from './types';
import { LangUtil } from '../../../../../../common/utils/lang-util';
import { ConfirmPopup } from '../../../ConfirmPopup/ConfirmPopup';
import { LangSelectHOC } from '../../../LangSelect/hocs/LangSelectHOC';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { CreatePublicProfileModalHOC } from '../../../PublicProfile/CreatePublicProfileModal/hocs/CreatePublicProfileModalHOC';
import { PlayListLevelSelect } from '../../PlayListLevelSelect/PlayListLevelSelect';
import { EUserLevel } from '../../../../../store/general/types';

export const CreatePlaylist: React.FC<ICreatePlaylistProps> = ({
	isOpen,
	isPublicProfile,
	isSave,
	nativeLangCode: nativeCode,
	onCreate,
	onCheckConfirmCreateDefVideos,
	onClose,
}) => {
	const { isMobile } = React.useContext(DeviceContext);

	const [nativeLangCode, setNativeLangCode] = React.useState<string>(nativeCode);
	const [targetLangCode, setTargetLangCode] = React.useState<string>('');
	const [error, setError] = React.useState<string>();
	const [createProgress, setCreateProgress] = React.useState<boolean>(false);
	const [title, setTitle] = React.useState<string>('');
	const [titleChanged, setTitleChanged] = React.useState<boolean>(false);
	const [confirmAnchor, setConfirmAnchor] = React.useState<HTMLButtonElement | null>(null);
	const [isPublic, setPublic] = React.useState(false);
	const [isPublicCreate, setPublicCreate] = React.useState(false);
	const [isCreateDefVideos, setIsCreateDefVideos] = React.useState(false);
	const [level, setLevel] = React.useState<EUserLevel>(EUserLevel.UNKNOWN);

	React.useEffect(() => {
		if (!isOpen) return;

		setNativeLangCode(nativeCode);
		setTargetLangCode('');
		setCreateProgress(false);
		setError('');
		setPublic(false);
		setPublicCreate(false);
		setIsCreateDefVideos(false);
		setLevel(EUserLevel.UNKNOWN);
	}, [isOpen]);

	React.useEffect(() => {
		langValidation(nativeLangCode, targetLangCode);
		updateTitle(nativeLangCode, targetLangCode);
	}, [nativeLangCode, targetLangCode]);

  const updateTitle = (nativeLangCode: string, targetLangCode: string) => {
    if ((!nativeLangCode && !targetLangCode) || titleChanged) return;
    const list: string[] = [];
    [
      LangUtil.getLangNameByCode(targetLangCode),
      LangUtil.getLangNameByCode(nativeLangCode),
    ].forEach((title) => {
      if (title) {
        list.push(title);
      }
    });
    setTitle(list.join(' / '));
  };

	const langValidation = (nativeLangCode: string, targetLangCode: string) => {
		if (!nativeLangCode || !targetLangCode) return;
		if (nativeLangCode === targetLangCode) {
			setError('Choose different languages');
		} else {
			setError('');
		}
	};

	const handleSubmit = async (elem: HTMLButtonElement) => {
		if (!nativeLangCode || !targetLangCode || error) return;

		if (!isSave && onCheckConfirmCreateDefVideos(targetLangCode)) {
			setConfirmAnchor(elem);
		} else {
			onConfirmCreateDefaultVideos(false);
		}
	};

	const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
		setTitleChanged(true);
	};

	const onLevelChange = (level: EUserLevel) => {
		setLevel(level);
	}

	const handleCreate = async (createDefVideos: boolean) => {
		setCreateProgress(true);
		await onCreate(nativeLangCode, targetLangCode, title, createDefVideos, isPublic, level);
		onClose();
		setPublicCreate(false);
	};

	const onConfirmCreateDefaultVideos = async (createDefVideos: boolean) => {
		setConfirmAnchor(null);
		if (!isPublicProfile && isPublic) {
			setPublicCreate(true);
			setIsCreateDefVideos(createDefVideos);
		} else {
			handleCreate(createDefVideos);
		}
	};

	return (
		<>
			<Modal
				isMobile={isMobile}
				title={'Create Playlist'}
				open={isOpen && !isPublicCreate}
				onClose={onClose}
			>
				<Stack
					gap={theme.spacing(2)}
					sx={{
						width: theme.spacing(64),
						maxWidth: '100%',
					}}>

					<Stack
						flexDirection={'row'}
						gap={theme.spacing(2)}
						justifyContent={'space-between'}
						sx={{
							'.MuiFormControl-root': {
								m: 0,
							},

							[theme.breakpoints.down('sm')]: {
								flexWrap: 'wrap',
							},
						}}
					>
						<LangSelectHOC
							label={'Target language'}
							onChange={setTargetLangCode}
							langCode={targetLangCode}
						/>
						<LangSelectHOC
							label={'Native language'}
							onChange={setNativeLangCode}
							langCode={nativeLangCode}
						/>
					</Stack>

					{!!error &&
						<Text sx={{ color: theme.palette.error.main }}>{error}</Text>
					}

					<TextField
						label={'Title'}
						variant={'filled'}
						fullWidth
						value={title}
						onChange={onTitleChange}
						multiline={false}
					/>

					<FormControl>
						<InputLabel>Level</InputLabel>
						<PlayListLevelSelect
							value={level}
							onChange={onLevelChange}
						/>
					</FormControl>

					{/*<Stack
						sx={{ bgcolor: theme.palette.primary[50], p: theme.spacing(1.5), borderRadius: theme.spacing() }}
						gap={theme.spacing()}
					>
						<ControlSwitch
							title='Add to LangMagic library'
							checked={isPublic}
							onChange={() => setPublic(!isPublic)}
							labelPlacement={'end'}
							sx={{ mt: 0, gap: theme.spacing(1.5), width: 'fit-content', '.MuiSwitch-root': { m: 0 }, }}
						/>
						<Text sx={{ color: theme.palette.grey[500] }}>
							{PUBLIC_PLAYLIST_TEXT}
							{!isPublicProfile && (' ' + PUBLIC_PROFILE_TEXT)}
						</Text>
					</Stack>*/}

					<Stack
						gap={theme.spacing(2)}
						direction={'row'}
						alignItems={'center'}
						sx={{
							mt: theme.spacing(1),
						}}
					>
						<Button
							disabled={!nativeLangCode || !targetLangCode || !!error || createProgress}
							onClick={(e) => handleSubmit(e.currentTarget)}
							variant={'contained'}
							sx={{
								alignSelf: 'flex-start',
								mt: 1,
							}}
						>
							{isSave ? 'Create playlist and save video' : 'Create playlist'}
						</Button>
						{createProgress && <CircularProgress size={theme.spacing(3)} />}
					</Stack>

					<ConfirmPopup
						anchor={confirmAnchor}
						text={'Do you want to load some sample videos for this playlist?'}
						closeTitle={'No, start form blank list'}
						confirmTitle={'Yes, load sample videos'}
						onCancel={() => onConfirmCreateDefaultVideos(false)}
						onConfirm={() => onConfirmCreateDefaultVideos(true)}
						onClose={() => setConfirmAnchor(null)}
						popoverProps={{
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'left',
							},
							transformOrigin: {
								vertical: 'top',
								horizontal: 'left',
							},
						}}
					/>
				</Stack>
			</Modal>

			<CreatePublicProfileModalHOC
				onClose={(isApply) => {
					setPublicCreate(false);
					if (isApply) {
						handleCreate(!!isCreateDefVideos);
					} else {
						onClose();
					}
				}}
				isShow={isPublicCreate}
			/>
		</>);
};
