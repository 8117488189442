import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';

export class PhraseDetailsTabHeaderEvents {

  public static onBack(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(MainLayoutEffects.backToDashboard())
    }
  }


  public static onChangeTab(value: any): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(PhraseDetailsTabsEditorPopupActions.setOpenPromptLibId(0));
      dispatch(PhraseDetailsTabsEditorPopupActions.setEditTabId(0));
      if (value) {
        dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.LIBS));
        dispatch(PhraseDetailsTabsEditorPopupActions.setShowFavoritePromptTooltip(false));
      } else {
        dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.MAIN));
      }
    }
  }

  public static onCreateNew(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(PhraseDetailsTabsEditorPopupActions.setEditTabId(0));
      dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.CREATE));
    }
  }


}