
export enum ESystemHotKeys {
  SPACE = 'Space',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  ArrowDown = 'ArrowDown',
  R = 'KeyR',
  Q = 'KeyQ',
  S = 'KeyS',
  D = 'KeyD',
  A = 'KeyA'
}

export enum EKeyEventContainer {
  CAPTIONS, PHRASES
}
/*
export enum EStackKeys {
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  ArrowDown = 'ArrowDown',
  AutoPause = 'AutoPause'
}

export type TStackEventAutoPauseOption = {
  startPauseTime: number
}*/

export interface IShortcutHandler {

  handle(keyCode: ESystemHotKeys): void;
}