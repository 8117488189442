import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { ProfileVideoPopup } from '../components/ProfileVideoPopup';
import { IProfileVideoPopupEvents, IProfileVideoPopupFields } from '../components/types';
import { ProfileVideoPopupEvents } from './events';
import { IProfileVideoPopupHOCProps } from './types';

const mapStateToProps = (
) => {
  return (
    state: IState,
    { video }: IProfileVideoPopupHOCProps,
  ): IProfileVideoPopupFields => {
    return {
      video,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {groupId, video, onClose }: IProfileVideoPopupHOCProps,
): IProfileVideoPopupEvents => ({
    onClose,
    onSave: () => dispatch(ProfileVideoPopupEvents.onSave(video.videoKey, groupId, onClose)),
  }
);

export const ProfileVideoPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileVideoPopup);

ProfileVideoPopupHOC.displayName = 'ProfileVideoPopupHOC';
