import { TLanguageCode, TUserRole } from '../../../../store/models/types';
import { EUserLevel } from '../../../../store/general/types';

export interface IInstallSelectLangsFields {
  nativeCode: string,
  targetCode: string,
  userRoles: TUserRole[],
  userLevelItems: IUserLevelItem[]
}

export interface IInstallSelectLangsEvents {
  onLoad: () => void;
  onSave: (nativeLangCode: TLanguageCode, targetLangCode: TLanguageCode, roles: number[], level: EUserLevel) => Promise<any>,
}

export interface IInstallSelectLangsProps extends IInstallSelectLangsFields, IInstallSelectLangsEvents
{}


export interface IInstallUserRoleCheckboxFields {
  id: number,
  title: string,
  checked: boolean
}

export interface IInstallUserRoleCheckboxEvents {
  onChange: () => void;
}

export interface IInstallUserRoleCheckboxProps extends IInstallUserRoleCheckboxFields, IInstallUserRoleCheckboxEvents {

}

export interface IUserLevelItem {
  id: EUserLevel,
  title: string
}

export const userLevelItems: IUserLevelItem[] = [
  {id: EUserLevel.BEGINNER, title: 'Beginner'},
  {id: EUserLevel.INTERMEDIATE, title: 'Intermediate'},
  {id: EUserLevel.ADVANCED, title: 'Advanced'},
]
