import React from 'react';
import { useParams } from 'react-router-dom';
import { ICopyVideoProps } from './types';


export const CopyVideo: React.FC<ICopyVideoProps> = ({
  onLoad
}) => {

  let { hash } = useParams();
 
  React.useEffect(() => {
    onLoad(hash);
  }, [hash]);

  return null;

};
