import { AppThunk } from '../../../../../store/types';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { ContentLibEvents } from '../../effects';
import { isAuthorised } from '../../../../../store/general/selectors';
import { setCopyGroupOnLoginAction } from '../../../../../store/general/actions';
import { Navigator } from '../../../../../effects/navigator';

export class ContentLibGroupInfoEvents {

  public static onCopyGroup(contentGroupId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (isAuthorised(getState())) {
        const group = await ContentLibEffects.copyGroup(contentGroupId);
        dispatch(ContentLibActions.setCopyGroupConfirm({show: true, groupId: group.id}));
      } else {
        dispatch(setCopyGroupOnLoginAction({contentGroupId}));
        Navigator.openDefault();
      }
    }
  }

  public static onSelectGroup(groupId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibEvents.openGroup(groupId));
    }
  }


}