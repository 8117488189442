import { UserRest } from '../../common/rest/user/userRest';
import { onHide } from '../components/dashboard/Onboarding/constants';
import { getDispatch } from '../store';
import { setOnboardingAction } from '../store/general/actions';

export class OnboardingEffects {

  public static async setShow() {
    const value = new Date().toISOString();
    await UserRest.saveSettings({showOnboarding: value});
    const dispatch = getDispatch();
    dispatch(setOnboardingAction({ isActive: false, show: value }));
    onHide();
  }
}