import { IState } from '../../../../../store/types';
import { getCurrentMovieKey, getSelectedNativeCaption } from '../../../../../store/current-video/selectors';
import { getNativeCaptions } from '../../../../../store/videos/selectors';
import { connect } from 'react-redux';
import { NativeCaptions } from '../components/NativeCaptions';
import { INativeCaptionsEvents, INativeCaptionsFields } from '../components/types';
import { NativeCaptionsEvents } from './events';
import { INativeCaptionsHOCOwnProps } from './types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    {elementRef}: INativeCaptionsHOCOwnProps
  ): INativeCaptionsFields => {
    const langCode = getSelectedNativeCaption(state)?.code || '';
    return {
      elementRef,
      captions: getNativeCaptions(state),
      videoId: getCurrentMovieKey(state),
      langCode
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {onChangeActiveIndex}: INativeCaptionsHOCOwnProps
): INativeCaptionsEvents => ({
    //onCaptionClick: caption => dispatch(NativeCaptionsEvents.onCaptionClick(caption)),
    onCaptionClick: (startSelIndex, endSelIndex, isMobile) => dispatch(NativeCaptionsEvents.onCaptionClick(startSelIndex, endSelIndex, isMobile)),
    onChangeActiveIndex: index => onChangeActiveIndex(index)
  }
);

export const NativeCaptionsHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(NativeCaptions);

NativeCaptionsHOC.displayName = 'NativeCaptionsHOC';
