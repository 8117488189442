import { EPhraseDetailsCustomPromptType, PhraseDetailsCustomPromptVars } from './phraseDetailsCustomPromptVars';

export interface IPhraseDetailsPromptValidatorResult {
  success: boolean,
  errors?: string[]
}

export class PhraseDetailsPromptValidator {

  public static validate(prompt: string): IPhraseDetailsPromptValidatorResult {
    if (!prompt || !prompt.trim().length) {
      return {
        success: false, errors: ['Prompt is required']
      }
    }

    const phraseVar = PhraseDetailsCustomPromptVars.getVarByType(EPhraseDetailsCustomPromptType.PHRASE);
    const contextVar = PhraseDetailsCustomPromptVars.getVarByType(EPhraseDetailsCustomPromptType.CONTEXT);
    const entireVar = PhraseDetailsCustomPromptVars.getVarByType(EPhraseDetailsCustomPromptType.ENTIRE_SCRIPT);
    if (phraseVar && contextVar && entireVar) {
      if (prompt.indexOf(phraseVar.name) < 0 && prompt.indexOf(contextVar.name) < 0 && prompt.indexOf(entireVar.name) < 0) {
        return {
          success: false, errors: [`To save the prompt please use ${phraseVar.name} or ${contextVar.name} variables, or both.`]
        }
      }
    }

    return {success: true}
  }
}
