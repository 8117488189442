import React from 'react';
import { INoVideoMobileProps } from './types';

export const NoVideoMobile: React.FC<INoVideoMobileProps> = ({
  onShow,
}) => {
  
  React.useEffect(() => {
    onShow();
  }, []);

  return <></>;
};
