import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { AudioLibraryGroupItem } from '../components/AudioLibraryGroupItem';
import { IAudioLibraryGroupItemEvents, IAudioLibraryGroupItemFields } from '../components/types';
import { IAudioLibraryGroupItemHOCOwnProps } from './types';
import { AudioLibraryGroupItemEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    {isOpened}: IAudioLibraryGroupItemHOCOwnProps
  ): IAudioLibraryGroupItemFields | null => {
    return {
      isOpened
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {audioId, groupId}: IAudioLibraryGroupItemHOCOwnProps
): IAudioLibraryGroupItemEvents => ({
    onClick: () => dispatch(AudioLibraryGroupItemEvents.onClick(audioId, groupId)),
    onDelete: () => dispatch(AudioLibraryGroupItemEvents.onDelete(audioId, groupId)),
  }
);

export const AudioLibraryGroupItemHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioLibraryGroupItem);

AudioLibraryGroupItemHOC.displayName = 'AudioLibraryGroupItemHOC';