import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IContentLibVideoListEvents, IContentLibVideoListFields } from '../components/types';
import { ContentLibVideoListEvents } from './events';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { ContentLibVideoList } from '../components/ContentLibVideoList';

const mapStateToProps = (
) => {
  return (
    state: IState
  ): IContentLibVideoListFields => {
    const {text: searchText, targetLangCode, nativeLangCode} = ContentLibSelectors.getSearchFilter(state);
    return {
      groupId: ContentLibSelectors.getSelectedGroupId(state),
      searchText,
      targetLangCode,
      nativeLangCode
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IContentLibVideoListEvents => ({
    onLoadVideos: (startIndex, count) => dispatch(ContentLibVideoListEvents.onLoadVideos(startIndex, count)),
    onVideoClick: videoId => dispatch(ContentLibVideoListEvents.onVideoClick(videoId))
  }
);

export const ContentLibVideoListHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibVideoList);

ContentLibVideoListHOC.displayName = 'ContentLibVideoListHOC';