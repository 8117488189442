import { AppThunk } from '../../../../../store/types';
import { PlayerFactory } from '../../../../../effects/player/player-factory';
import { YtUtils } from '../../../../../effects/player/yt-utils';
import { IPlayerApiProps } from '../../../../../effects/player/player-api-context';
import { loadVideoEffect } from '../../../../../effects/video_effects';
import { getUserStateStorage } from '../../../../../../common/utils/local-storage/user-state';
import { PhraseListSelectors } from '../../../../../store/phrase-list/selectors';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { getCurrentMovieKey } from '../../../../../store/current-video/selectors';
import { getActiveGroupId } from '../../../../../store/models/selectors';

export class VideoEvents {

  public static onInit(
  ): AppThunk {
    return async (
      dispatch,
      getState
    ): Promise<IPlayerApiProps>  => {
      const playerApi = await YtUtils.configure('');

      const videoId = getCurrentMovieKey(getState());
      const groupId = getActiveGroupId(getState()) || 0;
      dispatch(this.onLoad(videoId, groupId, 0, true));
      return playerApi;
    }
  }

  public static onLoad(
    videoId: string,
    groupId: number,
    phraseId: number,
    pause: boolean,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const playerApi = await PlayerFactory.getPlayerApi();
      const state = getState();
      /*if (!isValidUserGroupVideo(state, groupId, videoId)) {
        return;
      }*/

      if (phraseId) {
        let phrase = PhraseListSelectors.findPhraseById(state, phraseId);
        const startPosition = phrase ? phrase.startTime : 0;
        const onVideoLoaded = () => {
          if (phrase) {
            PlayerController.getInstance().playPhrase(phrase);
          }
        }
        dispatch(loadVideoEffect(videoId, playerApi, groupId, pause, startPosition, onVideoLoaded));
      } else {
        dispatch(loadVideoEffect(videoId, playerApi, groupId, pause));
      }
      getUserStateStorage().setVideoInfo(videoId, groupId, phraseId);
    }
  }

}