import { API_URL } from '../../constants';
import { TAudio } from '../../../spa/store/models/types';
import { IPhraseNote, IVocabularyPhrase } from '../../../spa/types/common';
import { getRequest } from '../../utils/requestService';
import { getDispatch } from '../../../spa/store';

export interface IRestAudioGroup extends TAudio {
  phrases: IVocabularyPhrase[];
  phraseNotes: IPhraseNote[];
  position: number;
  audio: TAudio
}

interface IRestAudioCaptionWord {
  text: string,
  time: number
}

export interface IRestAudioCaption {
  startTime: number,
  endTime: number,
  words: IRestAudioCaptionWord[]
}


export class AudioRest {

  private static FETCH_URL = API_URL + '/audio/info';
  private static GET_CAPTIONS_URL = API_URL + '/audio/captions';

  public static async fetch(audioId: number, groupId: number): Promise<IRestAudioGroup | null> {
    const dispatch = getDispatch();
    const url = `${AudioRest.FETCH_URL}/${groupId}/${audioId}`;
    return await dispatch(getRequest(url));
  }

  public static async getCaptions(audioId: number): Promise<IRestAudioCaption[] | null> {
    const dispatch = getDispatch();
    const url = `${AudioRest.GET_CAPTIONS_URL}/${audioId}`;
    return await dispatch(getRequest(url));
  }

}
