import React from 'react';
import { IContentLibraryProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Box, Stack } from '@mui/material';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { ContentLibraryHeaderHOC } from '../../ContentLibraryHeader/hocs/ContentLibraryHeaderHOC';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { ContentLibVideoPopupHOC } from '../../ContentLibVideoPopup/hocs/ContentLibVideoPopupHOC';
import { ContentLibCopyGroupConfirmHOC } from '../../ContentLibCopyGroupConfirm/hocs/ContentLibCopyGroupConfirmHOC';
import { ContentLibCopyVideoConfirmHOC } from '../../ContentLibCopyVideoConfirm/hocs/ContentLibCopyVideoConfirmHOC';
import { ContentLibVideoListHOC } from '../../ContentLibVideoList/hocs/ContentLibVideoListHOC';
import { ContentLibGroupListHOC } from '../../ContentLibGroupList/hocs/ContentLibGroupListHOC';

export const ContentLibraryDashboard: React.FC<IContentLibraryProps> = ({
	viewMode,
	onLoad,
	onUnLoad
}) => {

	const className = 'content-library';

	React.useEffect(() => {
		onLoad();
		return () => {
			onUnLoad();
		}
	}, [])

	return <Stack
		className={`
			${className}
		`}
		sx={{
			width: 1,
			height: 1,
			background: theme.palette.secondary.main,
		}}
	>
		<ContentLibraryHeaderHOC />

		<Box
			sx={{
				flex: `1 1 100%`,
				minHeight: 0,
				width: 1,
				p: 2,
	
				overflow: 'auto',
			}}
		>
			<Paper
				sx={{
					width: 1,
					px: 3,
				}}
			>
				{viewMode === EContentLibViewMode.GROUP_LIST
					? <ContentLibGroupListHOC/>
					: <ContentLibVideoListHOC/>
				}
			</Paper>
		</Box>
		<ContentLibVideoPopupHOC/>
		<ContentLibCopyVideoConfirmHOC/>
		<ContentLibCopyGroupConfirmHOC/>
	</Stack>
};
