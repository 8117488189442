import {
  EPhraseDetailsTabsEditorNavigationMode,
  EPhraseDetailsTabsEditorViewMode, IPhraseDetailsTabsEditorLastSaveTabId,
  IPhraseDetailsTabsEditorOpenPromptLibId,
  IPhraseDetailsTabsEditorPopupEditTabId,
  IPhraseDetailsTabsEditorPopupNavigationMode,
  IPhraseDetailsTabsEditorPopupProcessing,
  IPhraseDetailsTabsEditorPopupViewMode,
  IPhraseDetailsTabsEditorPromptLibs,
  IPhraseDetailsTabsEditorPromptTestProcessing,
  IPhraseDetailsTabsEditorPromptTestPrompt,
  IPhraseDetailsTabsEditorPromptTestResult,
  IPhraseDetailsTabsEditorShowFavoritePromptTooltip,
  IPhraseDetailsTabsEditorShowTutorial,
  SET_PHRASE_DETAILS_TABS_EDITOR_EDIT_TAB_ID, SET_PHRASE_DETAILS_TABS_EDITOR_LAST_SAVE_TAB_ID,
  SET_PHRASE_DETAILS_TABS_EDITOR_NAVIGATION_MODE,
  SET_PHRASE_DETAILS_TABS_EDITOR_OPEN_PROMPT_LIB_ID,
  SET_PHRASE_DETAILS_TABS_EDITOR_POPUP_PROCESSING,
  SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_LIBS,
  SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROCESSING,
  SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROMPT,
  SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_RESULT,
  SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_FAVOR_TOOLTIP,
  SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_TUTORIAL,
  SET_PHRASE_DETAILS_TABS_EDITOR_VIEW_MODE,
  TLibGptPrompt
} from './types';

export class PhraseDetailsTabsEditorPopupActions {

  public static setViewMode(viewMode: EPhraseDetailsTabsEditorViewMode): IPhraseDetailsTabsEditorPopupViewMode {
    return {
      type: SET_PHRASE_DETAILS_TABS_EDITOR_VIEW_MODE,
      viewMode
    }
  }

  public static setNavigationMode(navigationMode: EPhraseDetailsTabsEditorNavigationMode): IPhraseDetailsTabsEditorPopupNavigationMode {
    return {
      type: SET_PHRASE_DETAILS_TABS_EDITOR_NAVIGATION_MODE,
      navigationMode
    }
  }

  public static setEditTabId(editTabId: number): IPhraseDetailsTabsEditorPopupEditTabId {
    return {
      type: SET_PHRASE_DETAILS_TABS_EDITOR_EDIT_TAB_ID,
      editTabId
    }
  }

  public static setProcessing(processing: boolean): IPhraseDetailsTabsEditorPopupProcessing {
    return {
      type: SET_PHRASE_DETAILS_TABS_EDITOR_POPUP_PROCESSING,
      processing
    }
  }


  public static setPromptTestPrompt(prompt: string): IPhraseDetailsTabsEditorPromptTestPrompt {
    return {
      type: SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROMPT,
      prompt
    }
  }

  public static setPromptTestResult(result: string): IPhraseDetailsTabsEditorPromptTestResult {
    return {
      type: SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_RESULT,
      result
    }
  }

  public static setPromptTestProcessing(processing: boolean): IPhraseDetailsTabsEditorPromptTestProcessing {
    return {
      type: SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROCESSING,
      processing
    }
  }

  public static setPromptLibs(promptLibs: TLibGptPrompt[]): IPhraseDetailsTabsEditorPromptLibs {
    return {
      type: SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_LIBS,
      promptLibs
    }
  }

  public static setOpenPromptLibId(openPromptLibId: number): IPhraseDetailsTabsEditorOpenPromptLibId {
    return {
      type: SET_PHRASE_DETAILS_TABS_EDITOR_OPEN_PROMPT_LIB_ID,
      openPromptLibId
    }
  }

  public static setShowTutorial(showTutorial: boolean): IPhraseDetailsTabsEditorShowTutorial {
    return {
      type: SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_TUTORIAL,
      showTutorial
    }
  }

  public static setShowFavoritePromptTooltip(show: boolean): IPhraseDetailsTabsEditorShowFavoritePromptTooltip {
    return {
      type: SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_FAVOR_TOOLTIP,
      show
    }
  }

  public static setLastSaveTabId(lastSaveTabId: number): IPhraseDetailsTabsEditorLastSaveTabId {
    return {
      type: SET_PHRASE_DETAILS_TABS_EDITOR_LAST_SAVE_TAB_ID,
      lastSaveTabId
    }
  }




}