import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { getRequest } from '../../utils/requestService';
import { TDefaultGptPrompt } from '../../../spa/store/models/types';

export class GptPromptRest {

  private static GPT_PROMPT_LIST_API_URL = API_URL + '/gptPrompt/list';

  public static async getLists(): Promise<TDefaultGptPrompt[]> {
    const dispatch = getDispatch();
    return await dispatch(getRequest(GptPromptRest.GPT_PROMPT_LIST_API_URL));
  }

}