import { API_URL } from '../../constants';
import { TUserTranslation } from '../../../spa/store/models/types';
import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';

export class UserTranslationRest {

  private static SAVE_URL = API_URL + '/userTranslation/save';

  public static async save(userTranslation: TUserTranslation): Promise<any>  {
    const dispatch = getDispatch();
    return dispatch(postRequest(UserTranslationRest.SAVE_URL, userTranslation));
  }

}