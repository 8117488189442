import { IState } from '../../../../../../store/types';
import { getUserOwnGroups } from '../../../../../../store/models/selectors';
import { connect } from 'react-redux';
import { EditPlaylist } from '../../components/EditPlaylist/EditPlaylist';
import { IEditPlaylistEvents, IEditPlaylistFields } from '../../components/EditPlaylist/types';
import { getIsEditPlaylistModalOpen } from '../../../../../../store/general/selectors';
import { EditPlaylistEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IEditPlaylistFields => {
    const isOpen = getIsEditPlaylistModalOpen(state);
    const groups = isOpen ? getUserOwnGroups(state) : [];
    return {
      isOpen,
      groups
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IEditPlaylistEvents => ({
    onClose: () => dispatch(EditPlaylistEvents.onClose()),
    onShowAddPlaylist: () => dispatch(EditPlaylistEvents.onShowAddPlayList()),
  }
);

export const EditPlaylistHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPlaylist);

EditPlaylistHOC.displayName = 'EditPlaylistHOC';