import { PhraseDetailsActions } from '../../../store/phrase-details/actions';
import { getDispatch, getState } from '../../../store';
import { PhraseDetailsSelectors } from '../../../store/phrase-details/selectors';
import { EDefaultGptPromptType, EPhraseDetailsTabType } from '../../../store/models/types';
import { PhraseDetailsGptchatService } from './phrase-details-gptchat-service';
import { getDefaultGptPrompt, getPhraseDetailsTabByType, getUserGptPromptById } from '../../../store/models/selectors';


export class PhraseDetailsExplainService extends PhraseDetailsGptchatService {

  constructor(text: string, context: string, targetLangCode: string, nativeLangCode: string) {
    super(text, context, targetLangCode, nativeLangCode);
  }

  protected saveHash(hash: string): void {
    getDispatch()(PhraseDetailsActions.setExplainHash(hash));
  }

  protected getHash(): string {
    return PhraseDetailsSelectors.getExplainHash(getState());
  }

  protected async getPromptText(): Promise<string> {
    const state = getState();
    const tab = getPhraseDetailsTabByType(state, EPhraseDetailsTabType.EXPLAIN);
    let prompt;
    if (tab && tab.relationId) {
      prompt = getUserGptPromptById(state, tab.relationId);
    }
    if (!prompt) {
      prompt = getDefaultGptPrompt(state, EDefaultGptPromptType.EXPLAIN);
    }
    return prompt?.prompt || '';
  }

}
