import React from 'react';
import { theme } from '../../../../../common/styles/theme';
import { IconButton } from '@mui/material';
import { ArrowIcon } from '../../../../../../icons/ArrowIcon';

export const ButtononShowLeftPanel = ({
	onClick
}) => {

	return (
		<IconButton
			sx={{
				position: 'relative',
				alignItems: 'flex-start',
				p: `${theme.spacing(3.25)} 0 0`,
				color: theme.palette.grey[400],
				transform: 'translateX(4px)',

				'.MuiSvgIcon-root': {
					position: 'relative',
					zIndex: 100,
					width: theme.spacing(2),
					height: theme.spacing(1.5),
				},

				'&:before': {
					content: '""',
					position: 'absolute',
					left: theme.spacing(.5),
					width: theme.spacing(1),
					top: 0,
					bottom: 0,
					background: theme.palette.common.white,
					borderRadius: theme.spacing(.5),
				},
				'&:after': {
					content: '""',
					position: 'absolute',
					left: theme.spacing(-1),
					width: theme.spacing(4),
					height: theme.spacing(4),
					top: theme.spacing(4),
					background: theme.palette.common.white,
					borderRadius: '50%',
					transform: 'translateY(-50%)',
					boxShadow: theme.shadows[8],
				},
			
				'&:hover, &:focus': {
					'&:before': {
					},
				},
			}}
			onClick={onClick}
		>
			<ArrowIcon />
		</IconButton>
	);
};