import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabListContainer } from '../components/PhraseDetailsTabListContainer';
import { IPhraseDetailsTabListContainerEvents, IPhraseDetailsTabListContainerFields } from '../components/types';
import { getPhraseDetailsTabs } from '../../../../../store/models/selectors';
import { PhraseDetailsTabListContainerEvents } from './events';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabListContainerFields => {
    const tabs = getPhraseDetailsTabs(state)
      .filter(t => {
        return t.type === EPhraseDetailsTabType.EXPLAIN ||
        t.type === EPhraseDetailsTabType.USAGE ||
        t.type === EPhraseDetailsTabType.CUSTOM_PROMPT && !t.isTemp
      })
      .sort((a, b) => {
        if (b.type===EPhraseDetailsTabType.CUSTOM_PROMPT && a.type===EPhraseDetailsTabType.CUSTOM_PROMPT) {
          return b.orderNum - a.orderNum;
        }
        return +(b.type===EPhraseDetailsTabType.CUSTOM_PROMPT) - +(a.type===EPhraseDetailsTabType.CUSTOM_PROMPT) ||
          +b.favorite - +a.favorite;
      });
    return {
      tabs
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabListContainerEvents => ({
    onSort: tabs => dispatch(PhraseDetailsTabListContainerEvents.onSort(tabs))
  }
);

export const PhraseDetailsTabListContainerHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabListContainer);

PhraseDetailsTabListContainerHOC.displayName = 'PhraseDetailsTabListContainerHOC';
