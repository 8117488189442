import { TAsyncThunk } from '../../../spa/store/types';
import { postRequest } from '../../utils/requestService';
import { API_URL } from '../../constants';

export const PHRASE_DELETE_URL = API_URL + '/phrases/remove';

export const removePhrase = (
  phraseId: number,
  teacherMode: boolean
): TAsyncThunk => async(
  dispatch
) => {
  const phraseBody = { phraseId, teacherMode };
  return await dispatch(postRequest(PHRASE_DELETE_URL, phraseBody));
};
