import React from 'react';
import { Box, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { IVideoLeftColumnProps } from './types';
import { ytElementId } from '../../../../../types/common';
import { CaptionsHOC } from '../../../CaptionsContainer/Captions/hocs/CaptionsHOC';
import { TopBarPanelHOC } from '../../../ToolbarPanel/Toolbar/hocs/TopBarPanelHOC';

export function VideoLeftColumn({
	onInit,
}: IVideoLeftColumnProps) {

	React.useEffect(() => {
		onInit();
	}, []);

	return (
		<Paper
			className={'video-left-column'}
			sx={{
				width: 1,
				height: 1,

				minWidth: theme.spacing(42),
				maxWidth: theme.spacing(42),

				[theme.breakpoints.up('xl')]: {
					minWidth: theme.spacing(49),
					maxWidth: theme.spacing(49),
				}
			}}
		>
			<Stack
				sx={{
					height: '100%',
				}}
			>
				<Stack gap={theme.spacing(2)}
					sx={{
						p: `${theme.spacing(2)} ${theme.spacing(2)} 0`,
					}}
				>
					<TopBarPanelHOC />

					<Box
						id={ytElementId}
						sx={{
							aspectRatio: 16/9,
							width: 1,
							height: 1,
							zIndex: 0,
							borderRadius: theme.spacing(.75),
						}}
					/>
				</Stack>

				<CaptionsHOC />
			</Stack>
		</Paper>
	);
};
