import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { ContentLibraryHeader } from '../components/ContentLibraryHeader';
import { IContentLibraryHeaderEvents, IContentLibraryHeaderFields } from '../components/types';
import { ContentLibraryHeaderEvents } from './events';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IContentLibraryHeaderFields => {
    const {targetLangCode, nativeLangCode, text} = ContentLibSelectors.getSearchFilter(state);

    let selectedGroupTitle;
    const isSelected = ContentLibSelectors.getViewMode(state) === EContentLibViewMode.GROUP_VIDEO_LIST;
    if (isSelected) {
      const groupId = ContentLibSelectors.getSelectedGroupId(state);
      const group = ContentLibSelectors.findGroupById(state, groupId);
      selectedGroupTitle = group?.title;
    }

    return {
      viewMode: ContentLibSelectors.getViewMode(state),
      text,
      targetLangCode,
      nativeLangCode,
      selectedGroupTitle,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IContentLibraryHeaderEvents => ({
    onChangeText: text => dispatch(ContentLibraryHeaderEvents.onTextChange(text)),
    onChangeTargetLangCode: code => dispatch(ContentLibraryHeaderEvents.onChangeTargetLangCode(code)),
    onChangeNativeLangCode: code => dispatch(ContentLibraryHeaderEvents.onChangeNativeLangCode(code)),
    onChangeViewMode: viewMode => dispatch(ContentLibraryHeaderEvents.onChangeViewMode(viewMode)),
    onBack: () => dispatch(ContentLibraryHeaderEvents.onBack()),
    onClickHelp: () => dispatch(ContentLibraryHeaderEvents.onClickHelp()),
  }
);

export const ContentLibraryHeaderHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibraryHeader);

ContentLibraryHeaderHOC.displayName = 'ContentLibraryHeaderHOC';
