import { LibGptPromptRest } from '../../../common/rest/libGptPrompt/libGptPromptRest';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../store/phrase-details-tabs-editor-popup/selectors';
import { getDispatch, getState } from '../../store';
import { PhraseDetailsTabsEditorPopupActions } from '../../store/phrase-details-tabs-editor-popup/actions';

export class PhraseDetailsPromptLibEffects {

  public static async loadPromptLibs() {
    const state = getState();
    const dispatch = getDispatch();
    let libs = PhraseDetailsTabsEditorPopupSelectors.getPromptLibs(state);
    if (!libs) {
      libs = await LibGptPromptRest.getLists();
      dispatch(PhraseDetailsTabsEditorPopupActions.setPromptLibs(libs));
    }
  }
}