import React from 'react';
import { IVideoTutorialPopupContainerProps} from './types';
import { VideoTutorialPopupHOC } from '../../hocs/VideoTutorialPopupHOC/VideoTutorialPopupHOC';

export const VideoTutorialPopupContainer: React.FC<IVideoTutorialPopupContainerProps> = ({
  isShow,
}) => {
  return isShow
    ? <VideoTutorialPopupHOC/>
    : null;
}
