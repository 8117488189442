import { getDispatch, getState } from '../store';
import { userGptPromptDeleteAction, userGptPromptSaveAction } from '../store/models/actions';
import { EUserGpPromptType, TUserGptPrompt } from '../store/models/types';
import { UserGptPromptRest } from '../../common/rest/userGptPrompt/userGptPromptRest';
import { getUserGptPromptById } from '../store/models/selectors';

export class UserGptPromptEffects {

  public static async save(id: number, title: string, prompt: string, description: string): Promise<TUserGptPrompt> {
    const dispatch = getDispatch();
    let userPrompt = id ? getUserGptPromptById(getState(), id) : null;
    if (userPrompt) {
      userPrompt = {...userPrompt, ...{title, prompt, description}};
    } else {
      userPrompt = {
        id: 0, title, prompt, description, type: EUserGpPromptType.CUSTOM
      }
    }
    userPrompt = await UserGptPromptRest.save(userPrompt);
    dispatch(userGptPromptSaveAction(userPrompt));
    return userPrompt;
  }

  public static async delete(id: number) {
    const dispatch = getDispatch();
    await UserGptPromptRest.remove(id);
    dispatch(userGptPromptDeleteAction(id));
  }
}