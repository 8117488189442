import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { CopyVideo } from '../components/CopyVideo';
import { ICopyVideoEvents, ICopyVideoFields } from '../components/types';
import { CopyVideoEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): ICopyVideoFields => {
    return {}
  };
}

const mapDispatchToProps = (
  dispatch: any
): ICopyVideoEvents => ({
    onLoad: hash => dispatch(CopyVideoEvents.onLoad(hash))
  }
);

export const CopyVideoHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyVideo);

CopyVideoHOC.displayName = 'CopyVideoHOC';
