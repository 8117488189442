import { connect } from 'react-redux';
import { NoVideoMobile } from '../components/NoVideoMobile';
import { INoVideoMobileEvents, INoVideoMobileFields } from '../components/types';
import { NoVideoMobileEvents } from './events';

const mapDispatchToProps = (
  dispatch: any
): INoVideoMobileEvents => ({
    onShow: () => dispatch(NoVideoMobileEvents.onShow()),
  }
);

export const NoVideoMobileHOC = connect(
  null,
  mapDispatchToProps
)(NoVideoMobile);

NoVideoMobileHOC.displayName = 'NoVideoMobileHOC';
