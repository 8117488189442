import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { PhraseSelectPopup } from '../components/PhraseSelectPopup';
import { IPhraseSelectPopupEvents, IPhraseSelectPopupFields } from '../components/types';

import { PhraseSelectPopupEvents } from './events';
import { PhraseDetailsSelectors } from '../../../../store/phrase-details/selectors';
import { PhraseSelectPopupSelectors } from '../../../../store/phrase-select-popup/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseSelectPopupFields => {
    return {
      show: PhraseSelectPopupSelectors.getShow(state),
      phrase: PhraseDetailsSelectors.getText(state) || '',
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseSelectPopupEvents => ({
    onClose: () => dispatch(PhraseSelectPopupEvents.onClose()),
    onSave: () => dispatch(PhraseSelectPopupEvents.onSave()),
  }
);

export const PhraseSelectPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseSelectPopup);

PhraseSelectPopupHOC.displayName = 'PhraseSelectPopupHOC';
