import { DateUtil } from '../../../../../../common/utils/dateUtil';
import { EStatDateRangeId, IDateRange } from './types';

export class StaticsFilterUtils {

  public static getDateRangeByFilters(): IDateRange[] {
    const todayDate = DateUtil.trimToDay(new Date());
    return [
      {
        id: EStatDateRangeId.YESTERDAY,
        from: DateUtil.incDays(todayDate, -1),
        to: todayDate
      },
      {
        id: EStatDateRangeId.LAST_10_DAYS,
        from: DateUtil.incDays(todayDate, -10),
        to: todayDate
      },
      {
        id: EStatDateRangeId.LAST_MONTH,
        from: DateUtil.incDays(todayDate, -30),
        to: todayDate
      }
    ];
  }
}