import { AppThunk } from '../../../../../store/types';
import { setSavedVideoConfirmAction } from '../../../../../store/general/actions';
import { ESavedVideoConfirmShowMode } from '../../../../../store/general/types';
import { getSavedVideoConfirm } from '../../../../../store/general/selectors';
import { videoMoveToGroup } from '../../../../../../common/rest/video/videoMoveToGroup';
import { moveVideoToGroupAction } from '../../../../../store/models/actions';
import { UserGroupEffects } from '../../../../../effects/userGroupEffects';
import { createGroupNameIndex, getGroupNativeLanguage, getGroupTargetLanguage, getUserGroupById } from '../../../../../store/models/selectors';
import { Navigator } from '../../../../../effects/navigator';

export class SavedVideoConfirmNotifyEvents {

  public static onSelectGroup(
    groupId: number,
		newListName?: string,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      const saveConfirm = getSavedVideoConfirm(state);
      if (saveConfirm.videoId && saveConfirm.groupId) {

        if (!groupId) { // create group          
          const group = getUserGroupById(state, saveConfirm.groupId) as any;
          const srcGroupTargetLang = getGroupTargetLanguage(state, group);
          const srcGroupNativeLang = getGroupNativeLanguage(state, group);
          
          let groupName = createGroupNameIndex(state, newListName || `${srcGroupTargetLang?.name} / ${srcGroupNativeLang?.name}`);
          
          groupId = await UserGroupEffects.createGroup(groupName, srcGroupTargetLang?.code || '', srcGroupNativeLang?.code || '');

          if (groupId) {
            await UserGroupEffects.loadGroup(groupId);
          }
        }

        await dispatch(videoMoveToGroup(saveConfirm.videoId, saveConfirm.groupId, groupId));
        dispatch(moveVideoToGroupAction(saveConfirm.videoId, saveConfirm.groupId, groupId));
        UserGroupEffects.setLibraryGroupId(groupId);
        Navigator.openVideo(groupId, saveConfirm.videoId, 0, true);

        setTimeout(() => { // show success
          dispatch(setSavedVideoConfirmAction({showMode: ESavedVideoConfirmShowMode.HIDE}));
        }, saveConfirm.groupId !== groupId ? 2000 : 0);
      }
    }
  }

}