import { alpha, styled, Switch as SwitchComponent, SwitchProps } from "@mui/material";

export const Switch = styled(SwitchComponent)<SwitchProps>(({ theme }) => ({
    width: theme.spacing(5),
    height: theme.spacing(2.5),
    padding: 0,
    margin: `0 ${theme.spacing(1)}`,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: `translateX(${theme.spacing(2.5)})`,
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: theme.spacing(0.25),
        '&.Mui-checked': {
            transform: `translateX(${theme.spacing(2.5)})`,
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: `0 ${theme.spacing(0.25)} ${theme.spacing(0.5)} 0 rgb(0 35 11 / 20%)`,
        width: theme.spacing(2),
        height: theme.spacing(2),
        borderRadius: '50%',
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: theme.spacing(2),
        opacity: 1,
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
        boxSizing: 'border-box',
    }
}));
