import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';

type TDetectLangExecResponse = {
  code: string
}

type TDetectLangBatchExecResponse = {
  codes: string[]
}


export class DetectLangRest {

  private static DETECT_LANG_API_URL = API_URL + '/detectLang/exec';
  private static DETECT_LANG_API_BATCH_URL = API_URL + '/detectLang/execBatch';

  public static async exec(text: string): Promise<string> {
    const dispatch = getDispatch();
    const resp: TDetectLangExecResponse = await dispatch(postRequest(DetectLangRest.DETECT_LANG_API_URL, {text}));
    return resp?.code || '';
  }

  public static async execBatch(texts: string[], priorityLang?: string): Promise<string[]> {
    const dispatch = getDispatch();
    const resp: TDetectLangBatchExecResponse = await dispatch(postRequest(DetectLangRest.DETECT_LANG_API_BATCH_URL, {texts, priorityLang}));
    return resp?.codes || [];
  }
}