import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Box } from '@mui/material';
import { OneTimeTooltipNativeLanguageHOC } from '../../../OneTimeTooltip/hocs/OneTimeTooltipNativeLanguageHOC/OneTimeTooltipNativeLanguageHOC';


export const NativeCaptionsStub: React.FC = () => {
  return (
    <Box
      sx={{
        flex: 1,
        p: theme.spacing(2)
      }}
    >
      <OneTimeTooltipNativeLanguageHOC />
    </Box>
  )
};
