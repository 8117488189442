
export enum EShortcutsListenerContainer {
	CAPTIONS = 'captions',
	PHRASES = 'phrases',
}

export const setShortcutsListenerContainer = (
	container: EShortcutsListenerContainer,
) => {
	let w = window as any;
	w.shortcutsListenerContainer = container;
};

export const getShortcutsListenerContainer = (
) => {
	let w = window as any;
	return w.shortcutsListenerContainer || EShortcutsListenerContainer.CAPTIONS;
};

export const showShortcutsListenerContainer = (
) => {
	let container = document.querySelector(`.shortcuts-listener--${getShortcutsListenerContainer()}`) as HTMLElement;
	if (container) {
		container.classList.add('shortcuts-listener--active');
		setTimeout(() => {
			container.classList.remove('shortcuts-listener--active');
		}, 1000);
	}
};
