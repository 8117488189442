import { PlayPhraseBaseHandler } from './playPhraseBaseHandler';

export class PlayPhraseCommandHandler extends PlayPhraseBaseHandler {

  public async playNextPhrase() {
    this.playNext();
  }

  public async playPrevPhrase() {
    this.playPrev();
  }

  public async repeatPhrase() {
    this.repeat();
  }


}