import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Alert, Box, Button, IconButton } from '@mui/material';
import { IOneTimeTooltipSimpleProps } from './types';
import { QuestionIcon } from '../../../../../../../icons/QuestionIcon';
import { COMMON_DONT_SHOW } from '../../wordings';

export function OneTimeTooltipSimple ({
	buttonCommand,
	canClose = true,
	className: classNameOwn = '',
	header,
	isPopup,
	show,
	text,
	onClickHelp,
	onClose,
	onMouseEnter,
	onMouseLeave,
}: IOneTimeTooltipSimpleProps) {
	if (!show) return null;

	const className = 'one-time-tooltip';

	const containerStyle = {
		position: 'relative',
		padding: theme.spacing(1.5),
		borderRadius: theme.spacing(.75),
		backgroundColor: theme.palette.hint.light,

		'&.popup': {
			position: 'absolute',
			zIndex: theme.zIndex.modal,
			left: theme.spacing(1),
			bottom: theme.spacing(1),
			maxWidth: theme.spacing(45),
			boxShadow: theme.shadows[8],
		},

		'.MuiAlert-message': {
			padding: 0,
			fontWeight: 400,
			fontSize: '14px',
			lineHeight: 1.5,
			overflow: 'visible',
		},

		'.MuiButton-text': {
			fontWeight: 400,
			lineHeight: 1.5,
		},

		'ul': {
			margin: '8px 0 0',
			paddingLeft: theme.spacing(2),
		},

		[`.${className}__text`]: {
			'& > :last-child': {
				mb: 0,
			}
		},

		'.MuiButtonBase-root': {
			fontSize: theme.typography.subtitle2,
		},
	};

	const buttonsStyle = {
		pt: theme.spacing(1),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	};

	const helpStyle = {
		height: theme.spacing(2),
		ml: theme.spacing(-1),
		mt: theme.spacing(-.5),
		color: theme.palette.primary.main,

		'svg': {
			width: theme.spacing(2),
		},
	};

	const buttonClose = canClose && (
		<Button
			sx={buttonCommand ? {} : {
				mt: '-2px',
			}}
			onClick={onClose}
		>
			{COMMON_DONT_SHOW}
		</Button>
	);

	const elRef = React.useRef<any>(null);
	const videoRef = React.useRef<HTMLMediaElement | null>(null);
	const onMouseEnterHandler = () => {
		if (videoRef.current) {
		  	videoRef.current.play();
		}
	}
	const onMouseLeaveHandler = () => {
		if (videoRef.current) {
			videoRef.current.pause();
		}
	}

	React.useEffect(() => {
		videoRef.current = elRef.current.querySelector('video');
	}, []);

	return (
		<Alert
			className={`${className} ${isPopup ? 'popup' : ''} ${classNameOwn}`}
			variant={'filled'}
			severity={'warning'}
			icon={false}
			ref={elRef}
			sx={containerStyle}
			onMouseEnter={onMouseEnterHandler}
			onMouseLeave={onMouseLeaveHandler}
		>
			{header}
			{/* <IconButton //пока нет видео туториалов
				sx={helpStyle}
				onClick={onClickHelp}
			>
				<QuestionIcon />
			</IconButton> */}
			<span
				className={`${className}__text`}
				dangerouslySetInnerHTML={{ __html: text }}
			/>{' '}
			{buttonCommand
				? <Box sx={buttonsStyle}>
					{buttonCommand}
					{buttonClose}
				</Box>
				: buttonClose
			}
		</Alert>
	);
}
