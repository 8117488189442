import {
  IPublicProfileClearAction,
  IPublicProfileSetAction,
  IPublicProfilesState,
  PUBLIC_PROFILE_CLEAR_ACTION,
  PUBLIC_PROFILE_SET_ACTION
} from './types';

export class PublicProfileActions {

  public static set(
    data: Partial<IPublicProfilesState>
  ): IPublicProfileSetAction {
    return {
      type: PUBLIC_PROFILE_SET_ACTION,
      data
    }
  }

  public static clear(
  ): IPublicProfileClearAction {
    return {
      type: PUBLIC_PROFILE_CLEAR_ACTION
    }
  }
}