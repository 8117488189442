import { AppThunk } from '../../../../store/types';
import { setShowNewFeaturesPopupAction } from '../../../../store/general/actions';
import { getNewFeatures } from '../../../../../common/rest/newFeatures/getNewFeatures';
import { VERSION } from '../../../../../common/constants';

export class NewFeaturesPopupEvents {

  public static onLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      return dispatch(getNewFeatures(VERSION));
    }
  }

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setShowNewFeaturesPopupAction(false));
    }
  }

}