import { API_URL } from '../../constants';
import { TAsyncThunk } from '../../../spa/store/types';
import { postRequest } from '../../utils/requestService';
import { IVocabularyPhrase } from '../../../spa/types/common';

const URL = API_URL + '/phrases/list';

export enum EFindPhrasesOrder {
  CREATE_DATE = 1,
  START_TIME = 2
}

export type TFindPhraseBody = {
  groupId?: number,
  targetLangCode?: string,
  videoId?: string,
  audioId?: number,
  videoGroupId?: number,
  offset: number,
  count: number,
  order: EFindPhrasesOrder,
  teacherMode: boolean
}

export const fetchAllVideoPhrases = (body: TFindPhraseBody): TAsyncThunk => async(
  dispatch
): Promise<IVocabularyPhrase[]> => {
  return await dispatch(postRequest(URL, body));
};
