import React from 'react';
import { SvgIcon, SvgIconProps } from "@mui/material";

export const SquareAppIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox='0 0 32 32'>
        <rect width="32" height="32" rx="4" fill="#29BA98"/>
        <g clipPath="url(#clip0_1773_16116)">
            <path d="M24.5 15H23V11C23 9.9 22.1 9 21 9H17V7.5C17 6.12 15.88 5 14.5 5C13.12 5 12 6.12 12 7.5V9H8C6.9 9 6.01 9.9 6.01 11V14.8H7.5C8.99 14.8 10.2 16.01 10.2 17.5C10.2 18.99 8.99 20.2 7.5 20.2H6V24C6 25.1 6.9 26 8 26H11.8V24.5C11.8 23.01 13.01 21.8 14.5 21.8C15.99 21.8 17.2 23.01 17.2 24.5V26H21C22.1 26 23 25.1 23 24V20H24.5C25.88 20 27 18.88 27 17.5C27 16.12 25.88 15 24.5 15Z" fill="#E1F4EF"/>
        </g>
        <defs>
            <clipPath id="clip0_1773_16116">
                <rect width="24" height="24" fill="white" transform="translate(4 4)"/>
            </clipPath>
        </defs>
    </SvgIcon>
);
