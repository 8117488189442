import { AppThunk } from '../../../../../store/types';
import { setShowAddNewVideoPopupAction } from '../../../../../store/general/actions';

export class AddVideoButtonEvents {

  public static onClick(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setShowAddNewVideoPopupAction(true))
    }
  }

}
