import { AppThunk } from '../../../../../store/types';
import { isAuthorised } from '../../../../../store/general/selectors';
import { setCopyVideoOnLoginAction } from '../../../../../store/general/actions';
import { Navigator } from '../../../../../effects/navigator';
import { CopyVideoEffects } from '../../../../../effects/copyVideoEffects';

export class CopyVideoEvents {

  public static onLoad(hash: string): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      if (!isAuthorised(state)) {
        dispatch(setCopyVideoOnLoginAction({hash}));
        Navigator.openDefault();
      } else {
        await CopyVideoEffects.startCopy(hash, 0);
      }
    }
  }

}