import * as React from 'react';
import { IStatisitcsItemProps } from './types';
import { Box, Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';

export const StatisitcsItem: React.FC<IStatisitcsItemProps> = ({
  title,
  dataKey,
  resultList
}) => {

  return (
    <Stack direction={'row'}>
      <Box
        style={{minWidth: '250px'}}
      >
        <Text variant='h6'>{title}:</Text>
      </Box>
      {resultList.map(item => {
        return (
          <Box
            style={{minWidth: '200px'}}
            key={item.dateRangeId}
          >
            <Text variant='h6'>{item[dataKey]}</Text>
          </Box>
        )
      })}
    </Stack>
  )
};