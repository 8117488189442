import { AppThunk, IState } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PhraseNoteTimeScaleEffects } from '../../../../../effects/phrase/phraseNoteTimeScaleEffects';
import { getPhraseDetailsTabByType } from '../../../../../store/models/selectors';
import { EPhraseDetailsTabType, TPhraseDetailsTab } from '../../../../../store/models/types';
import { getMainViewMode, isPauseOnNote } from '../../../../../store/general/selectors';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';
import construct = Reflect.construct;
import { getDispatch } from '../../../../../store';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { EMainViewMode } from '../../../../../store/general/types';

export class PhraseDetailsContainerEvents {

  public static onLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const mainViewMode = getMainViewMode(getState());
      if (mainViewMode === EMainViewMode.PHRASE_DETAILS) return; // mobile

      dispatch(PhraseDetailsActions.disActivate());
    }
  }

  public static onSelection(selection: Selection): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const anchorElement = selection?.anchorNode?.parentElement;
      if (anchorElement) {
        const activeTab = PhraseDetailsSelectors.getActiveTab(getState());
        if (activeTab?.type === EPhraseDetailsTabType.NOTES) {
          return;
        }
        dispatch(PhraseDetailsActions.setPopupMenu({
          show: true,
          text: selection.toString(),
          anchorElement
        }))
      }
    }
  }

  public static onPlayerPositionChange(position: number): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PhraseDetailsEffects.updateOnChangePlayerPosition(position);
    }
  }

}