import { PhraseDetailsGptchatService } from './phrase-details-gptchat-service';
import { getDispatch, getState } from '../../../store';
import { PhraseDetailsActions } from '../../../store/phrase-details/actions';
import { PhraseDetailsSelectors } from '../../../store/phrase-details/selectors';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsPromptLibEffects } from '../phraseDetailsPromptLibEffects';

export class PhraseDetailsLibPromptService extends PhraseDetailsGptchatService {

  private id: number;

  constructor(id: number, text: string, context: string, targetLangCode: string, nativeLangCode: string) {
    super(text, context, targetLangCode, nativeLangCode);
    this.id = id;
  }

  protected saveHash(hash: string): void {
    getDispatch()(PhraseDetailsActions.setLibPromptHash(this.id, hash));
  }

  protected getHash(): string {
    const libPrompt = PhraseDetailsSelectors.getLibPrompt(getState(), this.id);
    return libPrompt && libPrompt.hash || '';
  }

  protected async getPromptText(): Promise<string> {
    await PhraseDetailsPromptLibEffects.loadPromptLibs();
    const libPrompt = PhraseDetailsTabsEditorPopupSelectors.getPromptLibById(getState(), this.id);
    return libPrompt?.prompt || '';
  }

}