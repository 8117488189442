import { AppThunk } from '../../../../store/types';
import { PhraseContextEditorActions } from '../../../../store/phrase-context-editor/actions';
import { findVideoPhraseByWordId } from '../../../../store/videos/selectors';
import { PhraseDetailsSelectors } from '../../../../store/phrase-details/selectors';

export class PhraseContextEditorEffects {

	public static open(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const phraseId = PhraseDetailsSelectors.getPhraseId(getState());
			let phrase;
			if (phraseId) {
				phrase = findVideoPhraseByWordId(getState(), phraseId);
			}
			if (phrase?.id) {
				dispatch(PhraseContextEditorActions.activate(phrase.id));
			} else {
				dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
					show: true,
				}));
			}
		}
	}


}