import React from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import { IPhraseDetailsTabLibProps } from './types';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from "../../../../../../common/styles/theme";

export const PhraseDetailsTabLib: React.FC<IPhraseDetailsTabLibProps> = ({
  openPromptLibId,
  loading,
  promptLibs ,
  onLoad,
  onSelect
}) => {

  React.useEffect(() => {
    onLoad();
  }, [])

  const handleClick = (id: number) => {
    onSelect(id);
    return false;
  }

  const itemStyle = {
    maxWidth: theme.spacing(46),
    borderRadius: theme.spacing(0.75),
    p: theme.spacing(2),
    border: `${theme.spacing(0.25)} solid ${theme.palette.grey[200]}`,
    cursor: 'pointer',
    outline: 'none !important',
    '&:hover, &:focus': {
      borderColor: theme.palette.grey[300]
    }
  };

  const selectedStyle = {
    borderColor: `${theme.palette.primary.main} !important`,
    background: theme.palette.primary[100],
  };

  const textStyle = {
    fontSize: theme.typography.subtitle2,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }

  const descStyle = {
    display: '-webkit-box',
  	overflow: 'hidden',
   	textOverflow: 'ellipsis',
   	'-webkit-line-clamp': '2',
   	'-webkit-box-orient': 'vertical',
    maxHeight: '2.8em',
    lineHeight: 1.4,
    opacity: .5,
  }

  return (
      <Stack sx={{
        maxHeight: 'calc(100% - 154px)', // header height
        mr: theme.spacing(-2),
      }}>

        {loading &&
            <Stack alignItems={'center'} justifyContent={'center'}>
              <CircularProgress sx={{ margin: 'auto' }} />
            </Stack>
        }
        <Stack gap={theme.spacing()} sx={{
          pb: theme.spacing(2),
          overflow: 'auto',
        }}>
          {!loading && promptLibs && promptLibs.map((lib) => (
              <Box
                  key={lib.id}
                  tabIndex={0}
                  sx={{
                    ...itemStyle,
                    ...(openPromptLibId === lib.id ? selectedStyle : null)
                  }}
                  onClick={()=>{return handleClick(lib.id)}}
              >
                <Text sx={textStyle}>{lib.title}</Text>
                <Text sx={descStyle}>{lib.description}</Text>
              </Box>
          ))}
        </Stack>
      </Stack>
  );


}
