import React from 'react';
import { IPhraseChatItemProps } from './types';
import { Box, CircularProgress, IconButton, Stack, SxProps, Tooltip } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { TextWithSpeakerHOC } from '../../../../../../common/components/TextWithSpeaker/hocs/TextWithSpeakerHOC';
import { Divider } from '../../../../../../common/components/Divider/Divider';
import { RedoIcon } from '../../../../../../../icons/RedoIcon';
import { SettingsIcon } from '../../../../../../../icons/SettingsIcon';
import { NoPhraseHint } from '../../../OneTimeTooltip/components/NoPhraseHint/NoPhraseHint';

export const PhraseChatItem: React.FC<IPhraseChatItemProps> = ({
	active,
	prompt,
	processing,
	result,
	text,

	id, type,
	onClickSettings,
	onLoad,
}) => {
	const className = 'phrase-chat-item';

	const ref = React.useRef(null);
	const [isFlash, setFlash] = React.useState(false);
	const [isOpen, setOpen] = React.useState(false);

	React.useEffect(() => {
		if (active && result) {
			setFlash(true);
			setTimeout(() => {
				setFlash(false);
			}, 1000);
			if (ref.current) ref.current.scrollIntoViewIfNeeded();
		} else {
			setFlash(false);
		}
	}, [active, result]);

	React.useEffect(() => {
		onLoad(true);
	}, [prompt]);

	const buttonStyle: SxProps = {
		height: theme.spacing(4),
		width: theme.spacing(3),
		p: theme.spacing(1),
		fontSize: theme.typography.fontSize,
		color: theme.palette.grey[400],
	};
	
	return (active && !text
		? <NoPhraseHint />
		: <Stack
			sx={{
				position: 'relative',
				pr: theme.spacing(1),
				pb: theme.spacing(2),
			}}
		>
			{processing
				? <Stack
					sx={{
						width: 1,
						my: theme.spacing(3),
						alignItems: 'center',
					}}
				>
					<CircularProgress size={theme.spacing(3)} />
				</Stack>
				: <>
					{result &&
						<Box
							className={`
								${className}__out key${id}type${type}
								${isFlash ? 'flash' : ''}
							`}
							ref={ref}
							sx={{
								position: 'relative',
								pr: theme.spacing(.75),

								'&.flash:before': {
									content: '""',
									position: 'absolute',
									left: theme.spacing(-.75),
									right: 0,
									top: theme.spacing(-.75),
									bottom: theme.spacing(-.75),
									borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} 0`,
									animation: 'animation_chat_item_flash .5s 3 ease-in-out',
								},

								'@keyframes animation_chat_item_flash': {
									'100%': { background: theme.palette.primary[100] },
								},
							}}
						>
							<TextWithSpeakerHOC
								text={result}
							/>
						</Box>
					}

					<Stack
						flexDirection={'row'}
						justifyContent={'flex-end'}
						gap={theme.spacing(.5)}
					>
						<Tooltip
							title={'Edit prompt'}
						>
							<IconButton
								onClick={onClickSettings}
								sx={buttonStyle}
							>
								<SettingsIcon sx={{ width: theme.spacing(2), height: theme.spacing(2) }}/>
							</IconButton>
						</Tooltip>
						<Divider sx={{
							height: theme.spacing(2),
							width: '2px',
							m: `${theme.spacing(1)} 0 0`,
						}} />
						<Tooltip
							title={'Refresh prompt'}
						>
							<IconButton
								onClick={() => onLoad(false)}
								sx={buttonStyle}
							>
								<RedoIcon sx={{ width: theme.spacing(2.5), height: theme.spacing(2.5) }}/>
							</IconButton>
						</Tooltip>
					</Stack>
				</>
			}

			<Box
				className={`${className}__prompt ${isOpen ? 'open' : 'close'}`}
				sx={{
					alignSelf: 'flex-end',
					maxWidth: '90%',
					p: theme.spacing(1),
					borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
					background: theme.palette.grey[50],
					cursor: 'pointer',
				}}
				onClick={() => setOpen(!isOpen)}
			>
				<Box
					sx={{
						lineHeight: `1.3`,

						[`.${className}__prompt.close &`]: {
							display: '-webkit-box',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							'-webkit-line-clamp': '2',
							'-webkit-box-orient': 'vertical',
							maxHeight: '2.6em',
						}
					}}
				>
					{prompt}
				</Box>
			</Box>
			
		</Stack>

	);

}

