import { ELanguageStatus, EUserLevel, TAuthUser } from '../store/general/types';
import { setAuthUserAction, setLibraryGroupIdAction } from '../store/general/actions';
import { updateUserLanguages } from './updateUserLanguages';
import { updateUserGroups } from './updateUserGroups';
import { EventsRouter } from '../../common/events/eventsRouter';
import { Events } from '../../common/events/types';
import { getDispatch, getState } from '../store';
import { PhraseDetailsTabEffects } from './phraseDetailsTabEffects';
import { TLanguageCode } from '../store/models/types';
import { getGeneralStorage } from '../../common/utils/local-storage/local-storage-helpers';
import { getUserStateStorage } from '../../common/utils/local-storage/user-state';
import { getOneTimeTooltipsStorage } from '../../common/utils/local-storage/onetime-tooltips';
import { InstallUserGroupVideoRest } from '../../common/rest/installUserGroupVideo/createInstallUserGroupVideo';
import { getUserGroups } from '../store/models/selectors';
import { PhrasesExistEffects } from './phrasesExistEffects';
import { LanguageRest } from '../../common/rest/userLanguage/languageRest';
import { AuthUserEffects } from './authUserEffects';

export class LoginUserEffects {

  public static async prepareBeforeShowSelectLangs(user: TAuthUser) {
    const dispatch = getDispatch();
    dispatch(setAuthUserAction(user));
    await Promise.all([
      dispatch(updateUserLanguages()),
      dispatch(updateUserGroups(true)),
   //   GptPromptEffects.load(),
      PhraseDetailsTabEffects.load(),
      PhrasesExistEffects.load()
    ]);

    try {
      EventsRouter.setUser(user);
      EventsRouter.trackEvent(Events.INSTALL, new Date(), {
        userId: user.hashId
      });
    } catch(e) {
      console.error(e);
    }

  }

  public static async prepareLangsOnInstall(nativeLangCode: TLanguageCode, targetLangCode: TLanguageCode, landingName: string, createGroup: boolean, level?: EUserLevel) {
    const dispatch = getDispatch();
    await Promise.all([
      LanguageRest.updateUserLanguage({ code: nativeLangCode, status: ELanguageStatus.KNOW }),
      LanguageRest.updateUserLanguage({ code: targetLangCode, status: ELanguageStatus.TO_LEARN })
    ]);
    if (level) {
      await AuthUserEffects.updateUser({level});
    }

    getGeneralStorage().updateData({
      showTelegramBotPopup: true,
      showVideoPromptsTutorial: true,
      flashPhraseDetails: true,
    });

    getUserStateStorage().clear();
    getOneTimeTooltipsStorage().saveData({
      showPhrasesEdit: true,
      showNativeLanguage: true,
      showNativeAvailable: true,
      showTutorialPopup: true,
      showTutorial: true,
    });
    try {
      await dispatch(updateUserLanguages());
      if (createGroup) {
        await InstallUserGroupVideoRest.createInstallUserGroupVideo({
          targetLangCode, nativeLangCode, landingName
        })
        await dispatch(updateUserGroups(true));
      }
      await PhraseDetailsTabEffects.createDefaultFavorite();
    } catch (e) {
      console.error(e);
    }
    LoginUserEffects.storeDefaultVideo();
  }

  private static storeDefaultVideo() {
    const dispatch = getDispatch();
    const groups = getUserGroups(getState());
    if (groups && groups.length) {
      dispatch(setLibraryGroupIdAction(groups[0].id));
      if (groups[0].videos.length) {
        return getUserStateStorage().setVideoInfo(groups[0].videos[0].videoKey, groups[0].id, 0);
      }
    }
  }

}
