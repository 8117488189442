import { API_URL } from '../../constants';
import { getRequest } from '../../utils/requestService';
import { TAsyncThunk } from '../../../spa/store/types';
import { TUserGroup } from '../../../spa/store/models/types';

const USER_GROUPS_URL = API_URL + '/groups';

export const fetchUserGroups = (): TAsyncThunk => (
  dispatch
): Promise<TUserGroup[]> => {
  return dispatch(getRequest(USER_GROUPS_URL));
};
