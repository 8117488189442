import { AppThunk } from '../../../../store/types';
import { setShowInstallExtensionPopupAction } from '../../../../store/general/actions';

export class InstallExtensionPopupEvents {

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setShowInstallExtensionPopupAction(false));
    }
  }

}