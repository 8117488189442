import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabLib } from '../components/PhraseDetailsTabLib';
import { IPhraseDetailsTabLibEvents, IPhraseDetailsTabLibFields } from '../components/types';
import { PhraseDetailsTabLibEvents } from './events';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabLibFields => {
    const promptLibs = PhraseDetailsTabsEditorPopupSelectors.getPromptLibs(state);
    const loading = !promptLibs;
    return {
      openPromptLibId: PhraseDetailsTabsEditorPopupSelectors.getOpenPromptLibId(state),
      loading,
      promptLibs
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabLibEvents => ({
    onLoad: () => dispatch(PhraseDetailsTabLibEvents.onLoad()),
    onSelect: id => dispatch(PhraseDetailsTabLibEvents.onSelect(id))
  }
);

export const PhraseDetailsTabLibHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabLib);

PhraseDetailsTabLibHOC.displayName = 'PhraseDetailsTabLibHOC';