import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { ToolbarVideo } from '../components/ToolbarVideo';
import { IToolbarVideoFields } from '../components/types';
import { getLibraryGroup } from '../../../../../store/models/selectors';
import { VideoLibraryGroupItemHelpers } from '../../../VideoGroups/VideoLibraryGroupItem/hocs/helpers';
import { getCurrentMovieKey } from '../../../../../store/current-video/selectors';
import { EMPTY_ARRAY } from '../../../../../../common/types';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState,
	): IToolbarVideoFields => {
		const group = getLibraryGroup(state);
		const videoId = getCurrentMovieKey(state);
		const video = group?.videos.find(v => v.videoKey === videoId);
		const langCodes = group && video
			? VideoLibraryGroupItemHelpers.getSelectedVideoLangsByGroup(state, group, video)
			: EMPTY_ARRAY;
		const title = video?.info.title || '';
		const author = video?.info.author || '';

		return {
			title,
			author,
			langCodes,
		}
	};
}

export const ToolbarVideoHOC = connect(
	mapStateToProps,
	null
)(ToolbarVideo);

ToolbarVideoHOC.displayName = 'ToolbarVideoHOC';
