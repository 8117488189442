import { AppThunk } from '../../../../../../store/types';
import { UserTranslateLangEffects } from '../../../../../../effects/userTranslateLangEffects';

export class PhraseDetailsTabTranslationContainerEvents {

  public static onSort(langCodes: string[]): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      UserTranslateLangEffects.sortLangs(langCodes);
    }
  }

}