import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { getRequest } from '../../utils/requestService';
import { TLibGptPrompt } from '../../../spa/store/phrase-details-tabs-editor-popup/types';

export class LibGptPromptRest {

  private static LIST_API_URL = API_URL + '/libGptPrompt/list';

  public static async getLists(): Promise<TLibGptPrompt[]> {
    const dispatch = getDispatch();
    return await dispatch(getRequest(LibGptPromptRest.LIST_API_URL));
  }
}

