import React from 'react';
import { IPhraseDetailsTabListContainerProps } from './types';
import { Stack } from '@mui/material';
import { PhraseDetailsTabItemHOC } from '../../PhraseDetailsTabItem/hocs/PhraseDetailsTabItemHOC';
import { DragDropContext, Droppable, Draggable, DropResult, DragStart } from 'react-beautiful-dnd';

export const PhraseDetailsTabListContainer: React.FC<IPhraseDetailsTabListContainerProps> = ({
  tabs,
  onSort
}) => {

  const [dragTabId, setDragTabId] = React.useState<number>(0);

  const handleDrop = (droppedItem: DropResult) => {
    if (!droppedItem.destination) {
      setDragTabId(0);
      return;
    }
    const srcIndex = droppedItem.source.index;
    const sortedTabs = [...tabs];
    const srcTab = sortedTabs[srcIndex];
    const destTab = sortedTabs[droppedItem.destination.index];
    sortedTabs.splice(srcIndex, 1);
    let destIndex = sortedTabs.findIndex(t => t.id === destTab.id);

    if (droppedItem.destination.index > droppedItem.source.index)
      destIndex++;
    sortedTabs.splice(destIndex, 0, srcTab);
    setDragTabId(0);
    onSort(sortedTabs);
  };

  const handleStartDrag = (event: DragStart) => {
    setDragTabId(parseInt(event.draggableId) || 0);
  }

  return (
    <DragDropContext onDragEnd={handleDrop} onDragStart={handleStartDrag} >
      <Droppable droppableId="list-container">
        {(provided) => (
          <Stack
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {tabs.map((tab, index) => (
              <PhraseDetailsTabItemHOC
                id={tab.id}
                key={tab.id}
                index={index}
                firstTabItem={index===0}
                dragable={dragTabId === tab.id}
              />
            ))}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );


}

