import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Stack } from '@mui/material';
import { IPhraseDetailsTabHeaderProps } from './types';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { TopBarPanelHOC } from '../../../ToolbarPanel/Toolbar/hocs/TopBarPanelHOC';
import { Divider } from '../../../../../../common/components/Divider/Divider';
import { ButtonIconPrimary } from '../../../../../../common/components/ButtonIconPrimary/ButtonIconPrimary';
import { PlusIcon } from '../../../../../../../icons/PlusIcon';
import { Tabs } from '../../../../../../common/components/Tabs/Tabs';
import { Tab } from '../../../../../../common/components/Tab/Tab';

export const PhraseDetailsTabHeader: React.FC<IPhraseDetailsTabHeaderProps> = ({
  viewMode,
  onChangeTab,
  onCreateNew,
}) => {

  const isPrompts = viewMode === EPhraseDetailsTabsEditorViewMode.CREATE ||
    viewMode === EPhraseDetailsTabsEditorViewMode.LIBS ||
    viewMode === EPhraseDetailsTabsEditorViewMode.MAIN;
  
  let activeTab = 0;
  if (isPrompts && viewMode === EPhraseDetailsTabsEditorViewMode.LIBS) {
    activeTab = 1;
  }

  return (<>
    <TopBarPanelHOC />

    <Divider/>

    {isPrompts &&
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          mb: theme.spacing(3),
        }}
    >
        <Tabs
          value={activeTab}
          onChange={(e, value) => onChangeTab(value)}
        >
          <Tab label={'My Prompts'}/>
          <Tab label={'Library'}/>
        </Tabs>

        <ButtonIconPrimary
          onClick={onCreateNew}
        >
          <PlusIcon />
        </ButtonIconPrimary>
      </Stack>
    }
  </>);
}
