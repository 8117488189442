import { IState } from '../../../../../store/types';
import { getSavedVideoConfirm } from '../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { SavedVideoConfirmError } from '../components/SavedVideoConfirmError';
import { ISavedVideoConfirmErrorEvents, ISavedVideoConfirmErrorFields } from '../components/types';
import { YoutubeUrlUtil } from '../../../../../../common/utils/youtube-url-util';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
  ): ISavedVideoConfirmErrorFields => {
    const {errorMessage, videoUrl} = getSavedVideoConfirm(state);
    return {
      errorMessage,
      videoUrl
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): ISavedVideoConfirmErrorEvents => ({
});


export const SavedVideoConfirmErrorHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedVideoConfirmError);

SavedVideoConfirmErrorHOC.displayName = 'SavedVideoConfirmErrorHOC';