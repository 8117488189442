import { AppThunk } from '../store/types';
import { getUserGroupById } from '../store/models/selectors';
import { setGroupPhraseCount } from '../store/models/actions';

export const increasePhraseCount = (
  groupId: number,
  count: number = 1
): AppThunk => (
  dispatch,
  getState
) => {
  const state = getState();
  const group = getUserGroupById(state, groupId);
  if (!group) return; //todo throw

  dispatch(setGroupPhraseCount(groupId, group.phrasesCount + count));
};

export const decreasePhraseCount = (
  groupId: number,
  count: number = 1
): AppThunk => (
  dispatch,
  getState
) => {
  const state = getState();
  const group = getUserGroupById(state, groupId);
  if (!group) return; //todo throw

  dispatch(setGroupPhraseCount(groupId, group.phrasesCount - count));
};
