import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Stack } from '@mui/material';
import { IToolbarVideoProps } from './types';
import LangFlag from '../../../LangFlag/LangFlag';
import { Text } from '../../../../../../common/components/Text/Text';

export const ToolbarVideo: React.FC<IToolbarVideoProps> = ({
	title,
	author,
	langCodes,
}) => {

	return (
		<Stack
			sx={{
				pl: theme.spacing(1),
				maxWidth: `calc(100% - ${theme.spacing(6)})`,
			}}
			gap={theme.spacing(.5)}
		>
			<Text
				fontWeight={600}
				sx={{
					maxWidth: `100%`,
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				}}
			>
				{title}
            </Text>
			<Stack
				direction={'row'}
				sx={{
					maxWidth: `100%`,
				}}
				gap={theme.spacing(2)}
			>
				<Stack
					direction='row'
					sx={{
						height: theme.spacing(2)
					}}
					gap={theme.spacing(1)}
				>
					{langCodes.map(langCode => (
						<LangFlag code={langCode} showCode={true} key={langCode}/>
					))}
				</Stack>
				<Text
					fontWeight={600}
					sx={{
						color: theme.palette.grey[400],
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
				>
					{author}
				</Text>
			</Stack>
		</Stack>
	);
};