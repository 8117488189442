import React from 'react';
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

export const HintTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	minWidth: theme.spacing(39),
	[`& .${tooltipClasses.arrow}`]: {
		color: '#C7DDFF !important',
		['&::before']: {
			border: '2px solid #C7DDFF',
			backgroundColor: theme.palette.hint.light
		},
	},
	[`& .${tooltipClasses.tooltip}`]: {
		display: 'block',
		maxWidth: theme.spacing(39),
		padding: `${theme.spacing(2)} !important`,
		color: theme.palette.grey[900],
		fontSize: `14px !important`,
		lineHeight: `1.5 !important`,
		border: '2px solid #C7DDFF',
		boxShadow: theme.shadows[16],
		backgroundColor: theme.palette.hint.light
	},
}));