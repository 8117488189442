import { IState } from '../types';

export const AUDIO_MOVIE_KEY_PREFIX = 'audio-';

export const getCurrentMovieKey = (state: IState) => {
  return getCurrentAudioId(state) ? getCurrentAudioKey(state) : getCurrentVideoKey(state);
}

export const getCurrentVideoKey = (state: IState) => state.currentVideo.currentVideoId;
export const getCurrentAudioId = (state: IState) => state.currentVideo.currentAudioId;

export const getCurrentAudioKey = (state: IState) => {
  return AUDIO_MOVIE_KEY_PREFIX + getCurrentAudioId(state);
}

export const isAudioMovieKey = (key: string): boolean => {
  return key.indexOf(AUDIO_MOVIE_KEY_PREFIX) === 0;
}

export const isCurrentAudioMovie = (state: IState): boolean => {
  return !!getCurrentAudioId(state);
}

export const getAudioIdByKey = (key: string): number => {
  return parseInt(key.substring(AUDIO_MOVIE_KEY_PREFIX.length));
}

export const getAvailableCaptions = (state: IState) => state.currentVideo.availableCaptions;

export const getSelectedNativeCaption = (state: IState) => state.currentVideo.selectedNativeCaption;
export const getSelectedTargetCaption = (state: IState) => state.currentVideo.selectedTargetCaption;

export const getCurrentVideoClickedCaptionIndex = (state: IState) => state.currentVideo.clickedCaptionIndex;