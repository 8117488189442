import {getDispatch} from "../store";
import {fetchUserPhrasesExist} from "../../common/rest/video/fetchUserPhrasesExist";
import {setPhrasesExistAction} from "../store/general/actions";

export class PhrasesExistEffects {

    public static async load() {
        const dispatch = getDispatch();
        const exist = await dispatch(fetchUserPhrasesExist());
        dispatch(setPhrasesExistAction(exist));
    }
}