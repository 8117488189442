import { API_URL } from '../../constants';
import { IPhraseNote, IVocabularyPhrase } from '../../../spa/types/common';
import { postRequest } from '../../utils/requestService';
import { getDispatch } from '../../../spa/store';

const URL = API_URL + '/phrases/phrasesNotesList';

export type TFindPhrasesNotesListBody = {
  videoId: string,
  userGroupId: number,
  teacherMode: boolean,
}

export type TPhraseNotesList = {
  phrases: IVocabularyPhrase[],
  notes: IPhraseNote[]
}

export const fetchPhrasesNotesLists = async (body: TFindPhrasesNotesListBody): Promise<TPhraseNotesList> => {
  const dispatch = getDispatch();
  return await dispatch(postRequest(URL, body));
};
