import React from 'react';
import { Portal } from '@mui/material';
import { IOneTimeTooltipArrowProps } from './types';
import { HintTooltip } from '../../../../../../common/components/HintTooltip/HintTooltip';
import { theme } from '../../../../../../common/styles/theme';

export function OneTimeTooltipArrow ({
	className: classNameOwn = '',
	isCloseOutside,
	show,
	sx,
	target,
	targetSelector,
	text,
	tooltipProps,
	onClose,
}: IOneTimeTooltipArrowProps) {
	if (!show) return null;

	const className = 'one-time-tooltip';

	const container = target || (() => document.querySelector(targetSelector || ''));

	React.useEffect(() => {
		let container = target;
		if (!container) {
			container = document.querySelector(targetSelector || '') as Element;
		}
		let observer = new MutationObserver(mutations => {
			for (const mutation of mutations) {
				for (const el of mutation.removedNodes) {
					if (el === container) {
						observer.disconnect();
						onClose();
					}
				}
			}
		});
		if (container) {
			observer.observe(container.parentElement as Element, { childList: true });
		}

	}, [target, targetSelector]);

	React.useEffect(() => {
		function onClickOutside () {
			document.removeEventListener('click', onClickOutside);
			onClose();
		}

		if (isCloseOutside) {
			document.addEventListener('click', onClickOutside);
		}

		return () => {
			document.removeEventListener('click', onClickOutside);
		}
	}, []);

	return (
		<HintTooltip
			className={`${className} ${classNameOwn}`}
			arrow
			open
			title={
				<span dangerouslySetInnerHTML={{ __html: text }} />
			}
			{...tooltipProps}
			sx={{
				zIndex: theme.zIndex.drawer,
				...sx,
			}}
		>
			<Portal
				container={container}
				sx={{
					position: 'absolute',
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
				}}
			/>
		</HintTooltip>
	);
}
