import { createGlobalStyle } from 'styled-components';
import { SCROLLBAR_WIDTH } from '../../../../common/constants';

export const GlobalStyle = createGlobalStyle`
	html, body {
		position: relative;
		height: 100%;
	}

	#root {
		height: 100%;
	}

	.scroll-visible ::-webkit-scrollbar {
		width: ${SCROLLBAR_WIDTH}px;
		height: ${SCROLLBAR_WIDTH}px;
	}

	.scroll-visible ::-webkit-scrollbar-thumb {
		background: #9ca5b6;
		border-radius: ${SCROLLBAR_WIDTH}px;
	}
`
