import { AppThunk } from '../../../../../store/types';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PlayerManager } from '../../../../../effects/player/manager/playerManager';
import { getNativeCaptions, getTargetCaptions } from '../../../../../store/videos/selectors';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { getActiveGroupId } from '../../../../../store/models/selectors';
import { getAvailableCaptions, getCurrentMovieKey } from '../../../../../store/current-video/selectors';

export class CaptionsEvents {

  public static onKeyDown(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
    }
  }

  public static onVideoChange(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(PhraseEffects.deleteCurrentPreviewPhrase());
      dispatch(PhraseDetailsActions.hide());
    }
  }

  public static onTargetCaptionsChange(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerManager.getInstance().setTargetCaptions(getTargetCaptions(getState()));
    }
  }

  public static onNativeCaptionsChange(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerManager.getInstance().setNativeCaptions(getNativeCaptions(getState()));
    }
  }

 /* public static onTargetCaptionsScroll(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      PlayerController.getInstance().onTargetCaptionsScroll();
    }
  }*/



}