import { IState } from '../../../../../store/types';
import { PhraseListSelectors } from '../../../../../store/phrase-list/selectors';
import {
  findVideoPhraseById,
  getAllVideoPhrasesWithFilterOrphans,
  getTargetCaptions
} from '../../../../../store/videos/selectors';
import { ITargetCaptionsHOCOwnProps } from '../../../CaptionsContainer/TargetCaptions/hocs/types';
import {
  IPlayerListenerHolder,
  ITargetCaptionsEvents,
  ITargetCaptionsFields
} from '../../../CaptionsContainer/TargetCaptions/components/types';
import {
  getCurrentAudioId, getCurrentAudioKey,
  getCurrentMovieKey,
  getSelectedNativeCaption,
  getSelectedTargetCaption
} from '../../../../../store/current-video/selectors';
import { EVocabularyPhraseType } from '../../../../../types/common';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';
import { getPlayerState } from '../../../../../store/general/selectors';
import { TargetCaptionsEvents } from '../../../CaptionsContainer/TargetCaptions/hocs/events';
import { connect } from 'react-redux';
import { TargetCaptions } from '../../../CaptionsContainer/TargetCaptions/components/TargetCaptions';
import { PlayerManager } from '../../../../../effects/player/manager/playerManager';
import { AudioPlayerManager } from '../../../../../effects/audioPlayer/audioPlayerManager';
import { TargetAudioCaptionsEvents } from './events';

const getForwardCaptionIndex = (state: IState): number => {
  const playPhraseId = PhraseListSelectors.getPlayPhraseId(state);
  const forwardPhraseId = PhraseListSelectors.getForwardPhraseId(state);
  const phraseId = playPhraseId || forwardPhraseId;
  const phrase = phraseId ? findVideoPhraseById(state, phraseId) : null;
  return phrase ? phrase.startCaptionIndex : -1;
}

const listenerHolder: IPlayerListenerHolder = {
  addListener: (listener: any) => {
    AudioPlayerManager.getInstance().addListener(listener);
  },
  removeListener: (listener: any) => {
    AudioPlayerManager.getInstance().removeListener(listener);
  }
}

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    { elementRef, isSelectPopup }: ITargetCaptionsHOCOwnProps
  ): ITargetCaptionsFields => {
    const nativeLanguage = getSelectedNativeCaption(state);
    const targetLanguage = getSelectedTargetCaption(state);
    const solo = isSelectPopup || !nativeLanguage || nativeLanguage.code === targetLanguage?.code;
    const langCode = getSelectedTargetCaption(state)?.code || '';
    const phrasesAll = getAllVideoPhrasesWithFilterOrphans(state);
    let phrases = [];
    if (isSelectPopup) {
      phrases = phrasesAll.filter(item => item.type === EVocabularyPhraseType.PREVIEW_WORD_SELECTED || item.type === EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED);
    } else {
      const phraseIdList = phrasesAll.map(p => p.id)
      const editorPhrases = PhraseContextEditorSelectors.getPhrases(state)?.filter(p => !phraseIdList.includes(p.id));
      phrases = [
        ...phrasesAll,
        ...editorPhrases || []
      ]
    }


    return {
      elementRef,
      captions: getTargetCaptions(state),
      phrases,
      videoId: getCurrentAudioKey(state),
      solo,
      langCode,
      isShowCaptionsSelectionPopup: CaptionsSelectionPopupSelectors.isShow(state),
      selectResult: CaptionsSelectionPopupSelectors.getSelectResult(state),
      playerActive: AudioPlayerManager.getInstance().isPlayerActive(),
      forwardCaptionIndex: getForwardCaptionIndex(state),
      isSelectPopup,
      listenerHolder
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  {onChangeActiveIndex, onCaptionMouseEnter, onCaptionMouseLeave, onScrolling, isSelectPopup}: ITargetCaptionsHOCOwnProps
): ITargetCaptionsEvents => ({
    onCaptionClick: (caption, isPause) => {},
    onMouseEnter: () => dispatch(TargetAudioCaptionsEvents.onMouseEnter()),
    onMouseLeave: () => dispatch(TargetAudioCaptionsEvents.onMouseLeave()),
    onSelectText: (selection, element) => dispatch(TargetAudioCaptionsEvents.onSelectText(selection, element, isSelectPopup)),
    onDismissSelectText: () => dispatch(TargetAudioCaptionsEvents.onDismissSelectText()),
    onChangeActiveIndex: index => {
      dispatch(TargetAudioCaptionsEvents.onChangeActiveIndex(index));
      onChangeActiveIndex(index);
    },
    onCaptionMouseEnter: index => onCaptionMouseEnter(index),
    onCaptionMouseLeave: index => onCaptionMouseLeave(index),
    onCaptionMouseDown: index => dispatch(TargetAudioCaptionsEvents.onCaptionMouseDown()),
    onCaptionPlay: (caption, isPause) => dispatch(TargetAudioCaptionsEvents.onCaptionPlay(caption, isPause)),
  }
);

export const TargetAudioCaptionsHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetCaptions);

TargetAudioCaptionsHOC.displayName = 'TargetAudioCaptionsHOC';