import { Reducer } from 'redux';
import {
  EPhraseDetailsTabsEditorNavigationMode,
  EPhraseDetailsTabsEditorViewMode,
  IPhraseDetailsTabsEditorPopupActions,
  IPhraseDetailsTabsEditorPopupState,
  SET_PHRASE_DETAILS_TABS_EDITOR_EDIT_TAB_ID,
  SET_PHRASE_DETAILS_TABS_EDITOR_LAST_SAVE_TAB_ID,
  SET_PHRASE_DETAILS_TABS_EDITOR_NAVIGATION_MODE,
  SET_PHRASE_DETAILS_TABS_EDITOR_OPEN_PROMPT_LIB_ID,
  SET_PHRASE_DETAILS_TABS_EDITOR_POPUP_PROCESSING,
  SET_PHRASE_DETAILS_TABS_EDITOR_POPUP_SHOW_LIST,
  SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_LIBS,
  SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROCESSING,
  SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROMPT,
  SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_RESULT,
  SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_FAVOR_TOOLTIP,
  SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_TUTORIAL,
  SET_PHRASE_DETAILS_TABS_EDITOR_VIEW_MODE
} from './types';

const initialState: IPhraseDetailsTabsEditorPopupState = {
  viewMode: EPhraseDetailsTabsEditorViewMode.MAIN,
  navigationMode: EPhraseDetailsTabsEditorNavigationMode.MAIN,
  editTabId: 0,
  processing: false,
  promptTest: {
    prompt: '',
    result: '',
    processing: false
  },
  promptLibs: null,
  openPromptLibId: 0,
  showTutorial: false,
  showFavoritePromptTooltip: false,
  lastSaveTabId: 0
};

export const phraseDetailsTabsEditorPopupReducer: Reducer<IPhraseDetailsTabsEditorPopupState, IPhraseDetailsTabsEditorPopupActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_PHRASE_DETAILS_TABS_EDITOR_VIEW_MODE:
      return { ...state, viewMode: action.viewMode }
    case SET_PHRASE_DETAILS_TABS_EDITOR_NAVIGATION_MODE:
      return { ...state, navigationMode: action.navigationMode }
    case SET_PHRASE_DETAILS_TABS_EDITOR_EDIT_TAB_ID:
      return { ...state, editTabId: action.editTabId }
    case SET_PHRASE_DETAILS_TABS_EDITOR_POPUP_PROCESSING:
      return { ...state, processing: action.processing }
    case SET_PHRASE_DETAILS_TABS_EDITOR_POPUP_SHOW_LIST:
      return { ...state, activeTab: action.activeTab, editMode: false, processing: false, show: true }
    case SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROMPT:
      return { ...state, promptTest: {...state.promptTest, prompt: action.prompt}}
    case SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_RESULT:
      return { ...state, promptTest: {...state.promptTest, result: action.result}}
    case SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_TEST_PROCESSING:
      return { ...state, promptTest: {...state.promptTest, processing: action.processing}}
    case SET_PHRASE_DETAILS_TABS_EDITOR_PROMPT_LIBS:
      return { ...state, promptLibs: action.promptLibs }
    case SET_PHRASE_DETAILS_TABS_EDITOR_OPEN_PROMPT_LIB_ID:
      return { ...state, openPromptLibId: action.openPromptLibId }
    case SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_TUTORIAL:
      return { ...state, showTutorial: action.showTutorial }
    case SET_PHRASE_DETAILS_TABS_EDITOR_SHOW_FAVOR_TOOLTIP:
      return { ...state, showFavoritePromptTooltip: action.show }
    case SET_PHRASE_DETAILS_TABS_EDITOR_LAST_SAVE_TAB_ID:
      return { ...state, lastSaveTabId: action.lastSaveTabId }
    default:
      return state;
  }
};
