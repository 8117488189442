import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabHeader } from '../components/PhraseDetailsTabHeader';
import {
  IPhraseDetailsTabHeaderEvents,
  IPhraseDetailsTabHeaderFields
} from '../components/types';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsTabHeaderEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabHeaderFields => {
    const navigationMode = PhraseDetailsTabsEditorPopupSelectors.getNavigationMode(state);

    return {
      navigationMode,
      viewMode: PhraseDetailsTabsEditorPopupSelectors.getViewMode(state),
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabHeaderEvents => ({
    onChangeTab: (value) => dispatch(PhraseDetailsTabHeaderEvents.onChangeTab(value)),
    onCreateNew: () => dispatch(PhraseDetailsTabHeaderEvents.onCreateNew()),
  }
);

export const PhraseDetailsTabHeaderHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabHeader);

PhraseDetailsTabHeaderHOC.displayName = 'PhraseDetailsTabHeaderHOC';
