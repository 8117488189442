import { TPauseItem } from '../../common/types';

export class Pause {

  public static NoPauseValue = 0;
  public static FullStopValue = -1;

  public static pauseLengths: TPauseItem[] = [
    { value: 1, text: '1 sec' },
    { value: 3, text: '3 sec' },
    { value: 5, text: '5 sec' },
    { value: Pause.FullStopValue, text: 'With full stop' }
  ]

  public static pauseLengthsWithNo: TPauseItem[] = [
    { value: Pause.NoPauseValue, text: 'No pause' },
    ...this.pauseLengths,
  ]
}