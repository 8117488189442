import React from 'react';
import { useParams } from 'react-router-dom';
import { IAddVideoProps } from './types';

export const AddVideo: React.FC<IAddVideoProps> = ({
  onLoad
}) => {

  const { videoId, groupId } = useParams();

  React.useEffect(() => {
    onLoad(+groupId, videoId);
  }, []);

  return null;

};
