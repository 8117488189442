import { IconButton, IconButtonProps, styled } from "@mui/material";

export const ButtonIconPrimary = styled(IconButton)<IconButtonProps>(({ theme }) => ({
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    borderRadius: theme.spacing(.75),

    '.MuiSvgIcon-root': {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },

    '&:hover, &:focus': {
        background: theme.palette.primary[500],
    },
}));
