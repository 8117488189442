import React from 'react';
import { Box } from '@mui/material';
import { theme } from '../../styles/theme';

interface HintProps {
  children: React.ReactNode;
}

export const Hint: React.FC<HintProps> = ({ children }) => {
  return (
    <Box
      sx={{
        p: theme.spacing(2),
        borderRadius: theme.spacing(0.75),
        backgroundColor: theme.palette.hint.light,
        '& *': {
          fontSize: theme.typography.subtitle2.fontSize,
        },
        '& p': {
          margin: 0,
          marginBottom: theme.spacing(1),
          '&:last-child': {
            marginBottom: 0,
          },
        },
      }}
    >
      {children}
    </Box>
  );
};
