import React from 'react';
import { IPhraseTextProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { TextWithSpeakerHOC } from '../../../../../../common/components/TextWithSpeaker/hocs/TextWithSpeakerHOC';
import { Text } from '../../../../../../common/components/Text/Text';
import { HighLightedText } from '../../../../../../common/components/HighLightedText/HighLightedText';
import { alpha, Stack, SxProps, Tooltip } from '@mui/material';
import { PencilIcon } from '../../../../../../../icons/PencilIcon';
import { PlusIcon } from '../../../../../../../icons/PlusIcon';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { TranslationsIcon } from '../../../../../../../icons/TranslationsIcon';
import { CloseIcon } from '../../../../../../../icons/CloseIcon';

export const PhraseText: React.FC<IPhraseTextProps> = ({
	context,
	text,
	fromLangCode,
	isContext,
	isPhrase,
	isTranslation,
	showEditContext,
	onContextModify,
	onContextDelete,
	onContextTranslate,
}) => {
	if (!text) return null;

	const buttonStyle: SxProps = {
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),

		'.MuiSvgIcon-root': {
			width: theme.spacing(2),
			height: theme.spacing(2),
		},
	};

	return (<Stack
		className={`phrase-text ${isPhrase ? 'phrase' : 'selected'}`}
	>
		<TextWithSpeakerHOC
			lang={fromLangCode}
			children={
				<Text
					sx={{
						fontSize: theme.typography.subtitle2,
						fontWeight: 400,

						'.phrase-text.selected & .highlighted-span': {
							// background: '#d7aeff',
							background: `linear-gradient(0deg, #d7aeff 15%, ${alpha(theme.palette.info[100], .5)} 20%)`,
						},
					}}
				>
					{isContext
						? <HighLightedText
							text={context}
							highLighted={[text]}
						/>
						: text
					}
				</Text>
			}
			text={String(text)}
		/>

		{showEditContext &&
			<Stack
				flexDirection={'row'}
				justifyContent={'flex-end'}
				alignItems={'center'}
				gap={theme.spacing(.75)}
				sx={{
					pr: theme.spacing(1),
					color: theme.palette.grey[400],
					fontSize: theme.typography.fontSize,
				}}
			>
				Context:

				{!isContext &&
					<Tooltip
						title={'Add context'}
					>
						<ButtonIconGrey
							onClick={onContextModify}
							sx={buttonStyle}
						>
							<PlusIcon
								sx={{
									width: `${theme.spacing(2.5)} !important`,
									height: `${theme.spacing(2.5)} !important`,
								}}
							/>
						</ButtonIconGrey>
					</Tooltip>
				}

				{isContext &&
				<>
					<Tooltip
						title={'Edit context'}
					>
						<ButtonIconGrey
							onClick={onContextModify}
							sx={buttonStyle}
						>
							<PencilIcon />
						</ButtonIconGrey>
					</Tooltip>

					{isTranslation &&
						<Tooltip
							title={'Translate context'}
						>
							<ButtonIconGrey
								onClick={onContextTranslate}
								sx={buttonStyle}
							>
								<TranslationsIcon />
							</ButtonIconGrey>
						</Tooltip>
					}

					<Tooltip
						title={'Remove context'}
					>
						<ButtonIconGrey
							onClick={onContextDelete}
							sx={buttonStyle}
						>
							<CloseIcon
								sx={{
									width: `${theme.spacing(2.5)} !important`,
									height: `${theme.spacing(2.5)} !important`,
								}}
							/>
						</ButtonIconGrey>
					</Tooltip>
				</>
				}
			</Stack>
		}
	</Stack>);

}
