import { API_URL } from '../../constants';
import { TAsyncThunk } from '../../../spa/store/types';
import { postRequest } from '../../utils/requestService';

const VIDEO_MOVE_TO_GROUP_URL = API_URL + '/video/move-to-group';

export const videoMoveToGroup = (
  videoKey: string,
  fromGroupId: number,
  toGroupId: number
): TAsyncThunk => async(
  dispatch
) => {
  const body = { videoKey, fromGroupId, toGroupId };
  return await dispatch(postRequest(VIDEO_MOVE_TO_GROUP_URL, body));
};
