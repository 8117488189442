import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { EPhraseDetailsTabType, TPhraseDetailsTab } from '../../../../../store/models/types';
import { getPhraseDetailsTabs } from '../../../../../store/models/selectors';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { getState } from '../../../../../store';

export class PhraseDetailsHeaderTabSelector {

  public static getTabs(): TPhraseDetailsTab[] {
    const state = getState();
    const activeTabId = PhraseDetailsSelectors.getActiveTab(state)?.id;
    const sortTypePriority: EPhraseDetailsTabType[] =
      [EPhraseDetailsTabType.CUSTOM_PROMPT, EPhraseDetailsTabType.EXPLAIN, EPhraseDetailsTabType.USAGE, EPhraseDetailsTabType.LIB_PROMPT];

    const chat = PhraseDetailsSelectors.getPromptsChat(state);
    
    const ret = getPhraseDetailsTabs(state)
      .filter(t => {
        return !!t.title &&
          t.favorite ||
          t.type === EPhraseDetailsTabType.TRANSLATION || t.type === EPhraseDetailsTabType.NOTES
      }).sort((t1, t2) => {
        const index1 = sortTypePriority.indexOf(t1.type);
        const index2 = sortTypePriority.indexOf(t2.type);
        const ret = index1 - index2;
        if (ret !== 0)
          return ret;
        if (t1.type === EPhraseDetailsTabType.LIB_PROMPT && t2.type === EPhraseDetailsTabType.LIB_PROMPT) {
          const promptLib1 = PhraseDetailsTabsEditorPopupSelectors.getPromptLibById(state, t1.relationId);
          const promptLib2 = PhraseDetailsTabsEditorPopupSelectors.getPromptLibById(state, t2.relationId);
          if (promptLib1 && promptLib2) {
            return promptLib1.orderNum - promptLib2.orderNum;
          }
        }
        return t1.orderNum - t2.orderNum;
      })
    return ret;
  }
}