import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabsLibContainer } from '../components/PhraseDetailsTabsLibContainer';
import { IPhraseDetailsTabsLibContainerEvents, IPhraseDetailsTabsLibContainerFields } from '../components/types';
import { PhraseDetailsTabsLibContainerEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabsLibContainerFields => {
    return {

    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabsLibContainerEvents => ({
    onLoad: () => dispatch(PhraseDetailsTabsLibContainerEvents.onLoad())
  }
);

export const PhraseDetailsTabsLibContainerHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabsLibContainer);

PhraseDetailsTabsLibContainerHOC.displayName = 'PhraseDetailsTabsLibContainerHOC';