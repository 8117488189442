import { IState } from '../../../../store/types';
import { getMainLayoutShowLeftPanel, getMainViewMode } from '../../../../store/general/selectors';
import { connect } from 'react-redux';
import { PhrasesView } from '../components/PhrasesView';
import { IPhrasesViewEvents, IPhrasesViewFields } from '../components/types';
import { EMainViewMode } from '../../../../store/general/types';
import { PhrasesViewEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhrasesViewFields => {
    return {
      isShow: getMainViewMode(state) === EMainViewMode.PHRASES,
      showLeftPanel: getMainLayoutShowLeftPanel(state),
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhrasesViewEvents => ({
    onShowLeftPanel: () => dispatch(PhrasesViewEvents.onShowLeftPanel()),
    onShowHelp: () => dispatch(PhrasesViewEvents.onShowHelp()),
  }
);

export const PhrasesViewHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhrasesView);

PhrasesViewHOC.displayName = 'PhrasesViewHOC';
