import { ICaptionsItem } from '../../../../types/common';
import { TTargetCaptionsPhraseInfo } from '../../CaptionsContainer/TargetCaptions/components/helper';

export interface IPhraseContextEditorFields {
  show: boolean;
  activeCaptionIndex: number;
  startCaptionIndex: number;
  captions: ICaptionsItem[];
  phrases: TTargetCaptionsPhraseInfo[];
  allowSave: boolean;
  isSavedPhrase?: boolean;
  isContext?: boolean;
}

export interface IPhraseContextEditorEvents {
  onClose: () => void;
  onSelectText: (selection: Selection) => void;
  onDeSelectText: () => void;
  onSave: () => void;
}

export interface IPhraseContextEditorProps extends
  IPhraseContextEditorFields,
  IPhraseContextEditorEvents
{}

export const PHRASE_CONTEXT_EDITOR_CLASS_NAME = 'phrase-context-editor'