import { AppThunk } from '../../../../../store/types';
import { setVocabularyPhraseDetailsHeightAction } from '../../../../../store/general/actions';


export class PhraseListDetailsColumnEvents {

  public static onPhraseDetailsHeightChange(height: number): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setVocabularyPhraseDetailsHeightAction(height));
    }
  }

}