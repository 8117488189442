import { IState } from '../../../../../store/types';
import { getEditPlaylistModalId, getPublicProfile } from '../../../../../store/general/selectors';
import {
  getGroupNativeLanguage,
  getGroupTargetLanguage,
  getUserGroupById,
  getUserGroupTitle
} from '../../../../../store/models/selectors';
import { connect } from 'react-redux';
import { EditGroup } from '../components/EditGroup';
import { IEditGroupEvents, IEditGroupFields } from '../components/types';
import { EditGroupEvents } from './events';
import { EUserLevel } from '../../../../../store/general/types';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
  ): IEditGroupFields => {
    const groupId = getEditPlaylistModalId(state);
    const group = getUserGroupById(state, groupId);
    return {
      show: !!group,
      isPublicProfile: !!getPublicProfile(state),
      title: group ? getUserGroupTitle(state, group) : '',
      publicGroup: !!group?.public,
      targetLangCode: group && getGroupTargetLanguage(state, group)?.code || '',
      nativeLangCode: group && getGroupNativeLanguage(state, group)?.code || '',
      level: group ? group.level : EUserLevel.UNKNOWN
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IEditGroupEvents => ({
    onSave: (title, publicGroup, level) => dispatch(EditGroupEvents.onSave(title, publicGroup, level)),
    onClose: () => dispatch(EditGroupEvents.onClose())
  }
);

export const EditGroupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditGroup);

EditGroupHOC.displayName = 'EditGroupHOC';