
import { connect } from 'react-redux';
import { ILangSelectFields } from '../components/types';
import { getLanguages } from "../../../../store/models/selectors";
import { ILangSelectHOCProps } from "./types";
import { IState } from "../../../../store/types";
import { LangSelect } from "../components/LangSelect";

const mapStateToProps = (
    state: IState,
    props: ILangSelectHOCProps,
  ): ILangSelectFields => {
    return {
      ...props,
      langs: getLanguages(state),
    }
}

export const LangSelectHOC = connect(
  mapStateToProps,
  null
)(LangSelect);

LangSelectHOC.displayName = 'LangSelectHOC';
