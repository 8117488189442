import { Reducer } from 'redux';
import {
  IPublicProfilesState,
  PUBLIC_PROFILE_CLEAR_ACTION,
  PUBLIC_PROFILE_SET_ACTION,
  TPublicProfileActions
} from './types';

const initialState: IPublicProfilesState = {
  loading: false,
  groups: [],
  notes: []
};

export const publicProfileReducer: Reducer<IPublicProfilesState, TPublicProfileActions> = (
  state = initialState,
  action
) => {

  switch (action.type) {
    case PUBLIC_PROFILE_SET_ACTION:
      return {
        ...state,
        ...action.data
      };

    case PUBLIC_PROFILE_CLEAR_ACTION:
      return {};

    default:
      return state;
  }
};