import React from 'react';
import { Stack } from '@mui/material';
import { INoPhraseHintProps } from './types';
import { NO_PHRASE_HINT } from '../../wordings';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { Hint } from '../../../../../../common/components/Hint/Hint';

export function NoPhraseHint ({
}: INoPhraseHintProps) {

	return (
		<Stack
			alignItems={'left'}
			margin={theme.spacing(0, 2)}
		>
			<Hint>
				<Text>{NO_PHRASE_HINT}</Text></Hint>
		</Stack>
	);
}
