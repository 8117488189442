import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PhraseDetailsExplainService } from '../../../../../effects/phrase-details/phraseDetailsService/phrase-details-explain-service';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';
import { setMainViewMode } from '../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../store/general/types';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { StatLogManager } from '../../../../../../common/stats/statLogManager';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { PhraseDetailsUsageService } from '../../../../../effects/phrase-details/phraseDetailsService/phrase-details-usage-service';
import { PhraseDetailsLibPromptService } from '../../../../../effects/phrase-details/phraseDetailsService/phrase-details-lib-prompt-service';
import { PhraseDetailsCustomPromptService } from '../../../../../effects/phrase-details/phraseDetailsService/phrase-details-custom-prompt-service';
import { PhraseDetailsPromptLibEffects } from '../../../../../effects/phrase-details/phraseDetailsPromptLibEffects';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { getPhraseDetailsTabs } from '../../../../../store/models/selectors';
import { LibGptPromptRest } from '../../../../../../common/rest/libGptPrompt/libGptPromptRest';

export class PhraseChatItemEvents {

	public static onLoad (
		useCache: boolean,
		type: EPhraseDetailsTabType,
		id?: number,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();

			const activeTab = PhraseDetailsSelectors.getActiveTab(state);
			if (activeTab?.type !== type || activeTab.relationId !== id) {
				const tabs = getPhraseDetailsTabs(getState());
				const tab = tabs.find(t => t.relationId === id && t.type === type);
				if (tab) dispatch(PhraseDetailsActions.setActiveTab(tab));
			}

			const text = PhraseDetailsSelectors.getText(state) || '';
			const context = PhraseContextEditorSelectors.getPhraseContext(state)?.fullPhrase || PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state) || '';
			const fromLang = PhraseDetailsSelectors.getFromLang(state);
			const toLang = PhraseDetailsSelectors.getToLang(state);
			let result;

			if (id && type === EPhraseDetailsTabType.CUSTOM_PROMPT) {
				dispatch(PhraseDetailsActions.setCustomPromptResult(id, '', true, false));
				result = await new PhraseDetailsCustomPromptService(id, text, context, fromLang?.code || '', toLang?.code || '').load(useCache);
				if (result !== undefined) {
					dispatch(PhraseDetailsActions.setCustomPromptResult(id, result, false, true));
				}
			} else if (id && type === EPhraseDetailsTabType.LIB_PROMPT) {
				dispatch(PhraseDetailsActions.setLibPromptResult(id, '', true, false));
				let libs = PhraseDetailsTabsEditorPopupSelectors.getPromptLibs(state);
				if (!libs) {
					libs = await LibGptPromptRest.getLists();
					dispatch(PhraseDetailsTabsEditorPopupActions.setPromptLibs(libs));
				}
				result = await new PhraseDetailsLibPromptService(id, text, context, fromLang?.code || '', toLang?.code || '').load(useCache);
				if (result !== undefined) {
					dispatch(PhraseDetailsActions.setLibPromptResult(id, result, false, true));
				}
			} else if (type === EPhraseDetailsTabType.EXPLAIN) {
				dispatch(PhraseDetailsActions.setExplainResult('', true, false));
				result = await new PhraseDetailsExplainService(text, context, fromLang?.code || '', toLang?.code || '').load(useCache);
				if (result !== undefined) {
					dispatch(PhraseDetailsActions.setExplainResult(result, false, true));
				}
			} else if (type === EPhraseDetailsTabType.USAGE) {
				dispatch(PhraseDetailsActions.setUsageResult('', true, false));
				result = await new PhraseDetailsUsageService(text, context, fromLang?.code || '', toLang?.code || '').load(useCache);
				if (result !== undefined) {
					dispatch(PhraseDetailsActions.setUsageResult(result, false, true));
				}
			}

			StatLogManager.logPromptAccess();
		}
	}

	public static onClickSettings(
		type: EPhraseDetailsTabType,
		id?: number,
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {

			if (type === EPhraseDetailsTabType.LIB_PROMPT) {
				await PhraseDetailsPromptLibEffects.loadPromptLibs();
				const lib = PhraseDetailsTabsEditorPopupSelectors.getPromptLibById(getState(), id || 0);
				if (lib) {
					dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS_TABS_EDITOR));
					dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.LIBS));
					dispatch(PhraseDetailsTabsEditorPopupActions.setOpenPromptLibId(lib.id));
					dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestResult(''));
				}
			} else {
				const tabs = getPhraseDetailsTabs(getState());
				if (tabs && tabs.length) {
					let t = tabs.find(tab => {
						return (type === EPhraseDetailsTabType.CUSTOM_PROMPT)
							? tab.relationId === id
							: tab.type === type;
					});
					id = t?.id || 0;
				}
				dispatch(setMainViewMode(EMainViewMode.PHRASE_DETAILS_TABS_EDITOR));
				dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.CREATE));
				dispatch(PhraseDetailsTabsEditorPopupActions.setEditTabId(id || 0));
			}
		}
	}

}