import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';
import { TApiResponse } from '../../../spa/effects/phrase-details/phraseDetailsService/phrase-details-service';

export type TPhraseTranslateApiBody = {
  text: string;
  fromLang: string;
  toLang: string;
  hash: string;
}

export type TPhraseTranslateMultiLangApiBody = {
  text: string;
  fromLang: string;
  toLangs: string[];
  hash: string;
}

export type TTranslateLangResultResp = {
  langCode: string,
  result?: string,
  success: boolean
}

export type TTranslateLangsResultResp = {
  result: TTranslateLangResultResp[],
  hash: string
}

export class PhraseTranslateRest {

  private static TRANSLATE_API_URL = API_URL + '/translationApi/translate';
  private static TRANSLATE_MULTI_LANG_API_URL = API_URL + '/translationApi/translateMultiLang';

  public static async translateApi(body: TPhraseTranslateApiBody): Promise<TApiResponse> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(PhraseTranslateRest.TRANSLATE_API_URL, body));
  }

  public static async translateMultiLangApi(body: TPhraseTranslateMultiLangApiBody): Promise<TTranslateLangsResultResp> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(PhraseTranslateRest.TRANSLATE_MULTI_LANG_API_URL, body));
  }


}