import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { getRequest, getRequestWithoutToken, postRequest } from '../../utils/requestService';
import { TLanguage, TUserLanguage } from '../../../spa/store/models/types';

export class LanguageRest {

  private static USER_LANGUAGES_URL = API_URL + '/languages';
  private static LANGUAGE_CHANGE_URL = API_URL + '/languages/put';
  private static LANGUAGES_LIST_URL = API_URL + '/languageList/';

  public static async fetchUserLanguages(): Promise<TUserLanguage[]>  {
    const dispatch = getDispatch();
    return dispatch(getRequest(LanguageRest.USER_LANGUAGES_URL));
  }

  public static async updateUserLanguage(
    language: Pick<TUserLanguage, 'code' | 'status'>
  ): Promise<any>  {
    const dispatch = getDispatch();
    return dispatch(postRequest(LanguageRest.LANGUAGE_CHANGE_URL, language));
  }

  public static async getAllLanguages(): Promise<TLanguage[]>  {
    return getRequestWithoutToken(LanguageRest.LANGUAGES_LIST_URL);
  }
}

