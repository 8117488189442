import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';
import { API_URL } from '../../constants';
import { IPhraseNote, IVocabularyPhrase } from '../../../spa/types/common';

export type TFindTeacherPhrasesNotesListBody = {
  userId: number,
  videoId: string,
  userGroupId: number
}

export type TTeacherPhraseNotesList = {
  phrases: IVocabularyPhrase[],
  notes: IPhraseNote[]
}

export class TeacherRest {

  private static FIND_URL = API_URL + '/teachers/phrasesNotesList';

  public static async findPhrasesNotesLists(body: TFindTeacherPhrasesNotesListBody): Promise<TTeacherPhraseNotesList> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(this.FIND_URL, body));
  }
}


