import { IState } from '../../../../../store/types';
import { getVocabularyPhraseDetailsHeight } from '../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { PhraseListDetailsColumn } from '../components/PhraseListDetailsColumn';
import { IPhraseListDetailsColumnEvents, IPhraseListDetailsColumnFields } from '../components/types';
import { PhraseListDetailsColumnEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseListDetailsColumnFields => {
    const phraseDetailsHeight = getVocabularyPhraseDetailsHeight(state);
    return {
      phraseDetailsHeight
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseListDetailsColumnEvents => ({
    onPhraseDetailsHeightChange: height => dispatch(PhraseListDetailsColumnEvents.onPhraseDetailsHeightChange(height)),
  }
);

export const PhraseListDetailsColumnHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseListDetailsColumn);

PhraseListDetailsColumnHOC.displayName = 'PhraseListDetailsColumnHOC';