import React from 'react';
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LogoSmall = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 36 36">
        <mask id="mask0_4124_16487" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
            <path d="M11.6129 24.9677L24.3871 24.9677C23.8548 28.2581 23.0081 30.9194 21.8468 32.9516C20.6855 34.9839 19.4032 36 18 36C16.5968 36 15.3145 34.9839 14.1532 32.9516C12.9919 30.9194 12.1452 28.2581 11.6129 24.9677ZM24.75 13.3548C24.8952 14.9032 24.9677 16.4516 24.9677 18C24.9677 19.5484 24.8952 21.0968 24.75 22.6452L11.25 22.6452C11.1048 21.0968 11.0323 19.5484 11.0323 18C11.0323 16.4516 11.1048 14.9032 11.25 13.3548L24.75 13.3548ZM1.37903 24.9677L9.21774 24.9677C9.94355 29.4194 11.1532 32.8548 12.8468 35.2742C10.2823 34.5 8.00806 33.1935 6.0242 31.3548C3.99194 29.5645 2.44355 27.4355 1.37903 24.9677ZM23.1532 35.2742C24.8952 32.8548 26.1048 29.4194 26.7823 24.9677L34.621 24.9677C33.5565 27.4355 32.0323 29.5645 30.0484 31.3548C28.0161 33.1935 25.7177 34.5 23.1532 35.2742ZM0.653226 22.6452C0.217744 21.0968 1.43825e-06 19.5484 1.57361e-06 18C1.70897e-06 16.4516 0.217745 14.9032 0.653227 13.3548L8.92742 13.3548C8.78226 14.9516 8.70968 16.5 8.70968 18C8.70968 19.5 8.78226 21.0484 8.92742 22.6452L0.653226 22.6452ZM27.2903 18C27.2903 16.5484 27.2177 15 27.0726 13.3548L35.3468 13.3548C35.7823 14.9516 36 16.5 36 18C36 19.5 35.7823 21.0484 35.3468 22.6452L27.0726 22.6452C27.2177 21.0484 27.2903 19.5 27.2903 18ZM24.3871 11.0323L11.6129 11.0323C12.1452 7.74193 12.9919 5.08064 14.1532 3.04838C15.3145 1.01613 16.5968 -1.69628e-06 18 -1.57361e-06C19.4032 -1.45094e-06 20.6855 1.01613 21.8468 3.04838C23.0081 5.08064 23.8548 7.74193 24.3871 11.0323ZM12.8468 0.725801C11.1048 3.14515 9.89516 6.58064 9.21774 11.0323L1.37903 11.0323C2.44355 8.56451 3.99194 6.41129 6.0242 4.57258C8.00807 2.78225 10.2823 1.49999 12.8468 0.725801ZM34.621 11.0323L26.7823 11.0323C26.0565 6.58064 24.8468 3.14516 23.1532 0.725802C25.7177 1.5 28.0161 2.78226 30.0484 4.57258C32.0323 6.41129 33.5565 8.56451 34.621 11.0323Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_4124_16487)">
            <rect x="38" y="36" width="40" height="11" transform="rotate(-180 38 36)" fill="#29BA98"/>
            <rect x="38" y="23" width="40" height="10" transform="rotate(-180 38 23)" fill="#1787FF"/>
            <rect x="38" y="11" width="40" height="11" transform="rotate(-180 38 11)" fill="#FF9F1F"/>
        </g>
    </SvgIcon>
);
