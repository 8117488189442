import { EPhraseListMode, EPhraseListPlayMode } from '../../../../../store/phrase-list/types';
import { IVocabularyPhrase } from '../../../../../types/common';

export interface IVocabularyListMode {
  id: string,
  title: string;
  listMode: EPhraseListMode,
  langCode?: string
}

export interface IVocabularyFields {
  groupId: number,
  videoId: string,
  phraseList: IVocabularyPhrase[],
  phrasesPlayStatus: EPhraseListPlayMode,
  phraseDetailsHeight: number,
  pauseSize: number,
  phrasesExist: boolean,
  lazyLoadingPhrases: boolean,
  activeListModeId: string,
  listModes: IVocabularyListMode[]
}

export interface IVocabularyEvents {
  onPlay: () => void;
  onStop: () => void;
  onChangePause: (pause: number) => void;
  onSpacePress: () => void,
  onPhraseDetailsHeightChange: (height: number) => void,
  onChangeListMode: (listModeId: string) => void,
  onLoadPhrases: (count: number) => Promise<boolean>,
  onAppendPhrases: (offset: number, count: number) => Promise<boolean>
}

export interface IVocabularyProps extends
  IVocabularyFields,
  IVocabularyEvents
{}

export const phrasesPartLoadSize = 50;