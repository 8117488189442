import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { AddVideoButton } from '../components/AddVideoButton';
import { IAddVideoButtonEvents, IAddVideoButtonFields } from '../components/types';
import { AddVideoButtonEvents } from './events';
import { getUserGroupsWithCheckedLangs } from '../../../../../store/models/selectors';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IAddVideoButtonFields => {
    const groups = getUserGroupsWithCheckedLangs(state);
    return {
      show: groups.length > 0
    }
  };
}


const mapDispatchToProps = (
  dispatch: any
): IAddVideoButtonEvents => ({
    onClick: () => dispatch(AddVideoButtonEvents.onClick())
  }
);

export const AddVideoButtonHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVideoButton);

AddVideoButtonHOC.displayName = 'AddVideoButtonHOC';