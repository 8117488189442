import { API_URL } from '../../constants';
import { IVocabularyPhrase } from '../../../spa/types/common';
import { TAsyncThunk } from '../../../spa/store/types';
import { postRequest } from '../../utils/requestService';

const PHRASE_UPDATE_URL = API_URL + '/phrases/update';

export const updatePhrase = (
  phrase: IVocabularyPhrase
): TAsyncThunk => async(
  dispatch
) => {
  const phraseBody = { phrase };
  return await dispatch(postRequest(PHRASE_UPDATE_URL, phraseBody));
};