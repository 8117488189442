import React from 'react';
import _ from 'lodash';
import { Box, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { ResizeIcon } from '../../../../../../../icons/ResizeIcon';
import { PhraseDetailsContainerHOC } from '../../../PhraseDetails/PhraseDetailsContainer/hocs/PhraseDetailsContainerHOC';
import { PhraseListHOC } from '../../../PhraseList/hocs/PhraseListHOC';
import { IPhraseListContext, IPhraseListDetailsColumnProps, PhraseListContext } from './types';

export const PhraseListDetailsColumn: React.FC<IPhraseListDetailsColumnProps> = ({
  phraseDetailsHeight,
  onPhraseDetailsHeightChange
}) => {

  const paddingTop = 16;
  const headerHeight = 64;
  const minPanelHeight = 240;

  const resizeRef = React.useRef<HTMLElement | null>(null);
  const [isMouseDown, setMouseDown] = React.useState<boolean | null>(null);
  const [minHeight, setMinHeight] = React.useState(phraseDetailsHeight);

  React.useEffect(() => {
    let pos: number;

    const onMouseUp = () => {
      setMouseDown(false);
      document.body.style.cursor = 'unset';
      document.removeEventListener('mousemove', onResize);
    };

    const onResize = (e: MouseEvent) => {
      if (pos) {
        const move = pos - e.y;
        const newHeight = window.innerHeight - (pos - move);
        if (
          newHeight >= minPanelHeight &&
          window.innerHeight - newHeight - paddingTop - headerHeight >= minPanelHeight
        ) {
          setMinHeight(newHeight);
          _.debounce(() => onPhraseDetailsHeightChange(newHeight), 300)();
        }
      }
    };

    const onMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      setMouseDown(true);
      document.body.style.cursor = 'ns-resize';
      pos = e.y;
      document.addEventListener('mousemove', onResize);
    };

    const resize = resizeRef.current as HTMLElement;
    if (resize) {
      resize.addEventListener('mousedown', onMouseDown);
      document.addEventListener('mouseup', onMouseUp);
    }

    return () => {
      if (resize) {
        resize.removeEventListener('mousedown', onMouseDown);
      }
    }
  }, []);

  const containerStyle = {
    position: 'relative',
    minWidth: theme.spacing(47.25),
    maxWidth: theme.spacing(47.25),
    height: 1,
    [theme.breakpoints.up('xl')]: {
      minWidth: theme.spacing(58),
      maxWidth: theme.spacing(58),
    }
  }

  const resizeContainerStyle = {
    minHeight: theme.spacing(1),
    py: theme.spacing(1),
    width: 1,
    cursor: 'ns-resize',
    '&:hover': {
      '.MuiBox-root': {
        background: theme.palette.grey[400],
        opacity: 1
      }
    },
    '&:active': {
      '.MuiBox-root': {
        opacity: 1
      }
    }
  };

  const resizeStyle = {
    position: 'relative',
    height: theme.spacing(0.25),
    width: 1,
    borderRadius: theme.spacing(0.25),
    background: isMouseDown ? theme.palette.grey[400] : theme.palette.grey[200],
  };

  const handleStyle = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    translate: '-50% -50%',
    width: theme.spacing(2),
    height: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: `${theme.palette.secondary.main} !important`,
    border: `2px solid ${theme.palette.grey[400]}`,
    borderRadius: '50%',
    opacity: 0
  };

  const iconStyle = {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.grey[400]
  }

  const phraseListContext: IPhraseListContext = {
    height: minHeight
  }

  return (
    <Stack sx={containerStyle}>
      <PhraseListContext.Provider value={phraseListContext}>
        <PhraseListHOC />
      </PhraseListContext.Provider>
      <>
        <Box ref={resizeRef} sx={resizeContainerStyle}>
          <Box sx={resizeStyle}>
            <Box sx={handleStyle}>
              <ResizeIcon sx={iconStyle} />
            </Box>
          </Box>
        </Box>
        <PhraseDetailsContainerHOC />
      </>

    </Stack>
  );
};
