import { AppThunk } from '../../../../../../store/types';
import { setCreatePlaylistModalOpenAction, setEditPlaylistModalOpenAction } from '../../../../../../store/general/actions';

export class EditPlaylistEvents {

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setEditPlaylistModalOpenAction(false));
    }
  }

  public static onShowAddPlayList(
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setEditPlaylistModalOpenAction(false));
      dispatch(setCreatePlaylistModalOpenAction(true))
    }
  }

}
