import { ICaptionsItem } from '../../../../../types/common';
import React from 'react';

export interface INativeCaptionsFields {
  elementRef: React.RefObject<HTMLDivElement>;
  captions: ICaptionsItem[];
  videoId: string;
  langCode: string;
}

export interface INativeCaptionsEvents {
  onCaptionClick: (
    startSelIndex: number,
    endSelIndex: number,
    isMobile?: boolean,
  ) => void;
  onChangeActiveIndex: (index: number) => void;
}

export interface INativeCaptionsProps extends
  INativeCaptionsFields,
  INativeCaptionsEvents
{}


export interface INativeCaptionsItemFields {
  index: number,
  startTime: number,
  endTime: number;
  text: string;
  isActive?: boolean;
}
export interface INativeCaptionsItemEvents {
  onSetHeight?: (height: number) => void
  onMouseDown: () => void;
  onMouseUp: () => void;
  onMouseEnter: () => void;
}
export interface INativeCaptionsItemProps extends
  INativeCaptionsItemFields,
  INativeCaptionsItemEvents
{}

export enum ECaptionsItemType {
  TARGET,
  NATIVE
}