import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import { App } from './components/dashboard/App/App';
import { createApplicationStore } from './store';

const root = document.getElementById('root');

const init = async() => {
  const store = createApplicationStore({});
  const AppElement = React.createElement(App);
  const ProviderElement = React.createElement(Provider, { store }, AppElement);

  //BelOW GAPI Integration code
  const gapi = document.createElement('script');
  gapi.src = 'https://apis.google.com/js/api.js';
  gapi.async = true;
  gapi.defer = true;
  gapi.onload = function() {
    ReactDOM.render(ProviderElement, root);
  };
  //@ts-ignore
  gapi.onreadystatechange = 'if (this.readyState === \'complete\') this.onload()';
  document.getElementsByTagName('body')[0].append(gapi);

 /* if (isProduction()) {
    (function() {
      var ga4 = document.createElement('script'); ga4.type = 'text/javascript'; ga4.async = true;
      ga4.src = 'https://www.googletagmanager.com/gtag/js?id=' + getGa4Id();
      document.getElementsByTagName('body')[0].append(ga4);
      //@ts-ignore
      window.dataLayer = window.dataLayer || [];
      //@ts-ignore
      function gtag(){dataLayer.push(arguments);}
      //@ts-ignore
      gtag('js', new Date());
      //@ts-ignore
      gtag('config', getGa4Id());
    })();
  }*/
};
init();
