import * as React from 'react';
import { Box, Button, List } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { IVideoLibraryGroupProps } from './types';
import { VideoLibraryGroupItemHOC } from '../../VideoLibraryGroupItem/hocs/VideoLibraryGroupItemHOC';
import { CLASS_ONBOARDING_TRIGGER } from '../../../Onboarding/constants';
import { EXTENSION_INSTALL_URL, YOUTUBE_URL } from '../../../../../../common/constants';
import { AudioLibraryGroupItemHOC } from '../../AudioLibraryGroupItem/hocs/AudioLibraryGroupItemHOC';
import { Hint } from '../../../../../../common/components/Hint/Hint';

export const VideoLibraryGroup: React.FC<IVideoLibraryGroupProps> = ({
  videoId,
  groupId,
  videos,
  audios,
}) => {
  if (!groupId) return null;

  return (
    <>
      {videos.length === 0 && (
        <Hint>
          <Text>You don't have any videos in this playlist yet.</Text>
          <Text>
            Navigate to{' '}
            <Button
              href={YOUTUBE_URL}
              sx={{
                // fontSize: theme.typography.fontSize,
                verticalAlign: 'unset',
              }}
              rel={'nofollow'}
              target={'_blank'}
            >
              YouTube,
            </Button>{' '}
            find a video with subtitles in a language you want to learn.
          </Text>
          <Text>
            Then add that video to the app either by copying and pasting a video's URL, or by using
            our{' '}
            <Button
              href={EXTENSION_INSTALL_URL}
              sx={{
                // fontSize: theme.typography.fontSize,
                verticalAlign: 'unset',
              }}
              rel={'nofollow'}
              target={'_blank'}
            >
              Chrome extension.
            </Button>
          </Text>
        </Hint>
      )}
      <List
        disablePadding
        sx={{
          position: 'relative',
          width: `calc(100% + ${theme.spacing(2)})`,
          ml: `${theme.spacing(-1)} !important`,
        }}
      >
        {audios.length > 0 &&
          audios.map((audio) => (
            <AudioLibraryGroupItemHOC key={audio.key} groupId={groupId} audioId={audio.id} />
          ))}
        {videos.length > 0 &&
          videos.map((video) => (
            <VideoLibraryGroupItemHOC
              key={video.id}
              isOpened={videoId === video.videoKey}
              groupId={groupId}
              video={video}
            />
          ))}
        <Box
          className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step1`}
          sx={{
            position: 'absolute',
            pointerEvents: 'none',
            left: theme.spacing(-0.5),
            right: theme.spacing(-0.5),
            top: theme.spacing(-0.5),
            bottom: theme.spacing(-0.5),
            borderRadius: theme.spacing(1),
          }}
        />
      </List>
    </>
  );
};
