import { IState } from '../../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabTranslationLang } from '../components/PhraseDetailsTabTranslationLang';
import { IPhraseDetailsTabTranslationLangEvents, IPhraseDetailsTabTranslationLangFields } from '../components/type';
import { IPhraseDetailsTabTranslationLangHOCOwnProps } from './types';
import { LangUtil } from '../../../../../../../common/utils/lang-util';
import { PhraseDetailsTabTranslationLangEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    { langCode, index, color, dragable}: IPhraseDetailsTabTranslationLangHOCOwnProps
  ): IPhraseDetailsTabTranslationLangFields => {
    return {
      index,
      langCode,
      langName: LangUtil.getLangNameByCode(langCode),
      color,
      dragable
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  { langCode}: IPhraseDetailsTabTranslationLangHOCOwnProps
): IPhraseDetailsTabTranslationLangEvents => ({
    onDelete: () => dispatch(PhraseDetailsTabTranslationLangEvents.onDelete(langCode))
  }
);

export const PhraseDetailsTabTranslationLangHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabTranslationLang);

PhraseDetailsTabTranslationLangHOC.displayName = 'PhraseDetailsTabTranslationLangHOC';