import { getAuthUser } from '../../spa/store/general/selectors';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { IState, TAsyncThunk } from '../../spa/store/types';
import { API_URL } from '../constants';
import { setAuthUserAction } from '../../spa/store/general/actions';
import { AuthManager } from './auth-manager';

const AUTH_REFRESH_TOKEN_URL = API_URL + '/user/auth/refreshToken';

export const getRequest = (
  url: string,
  params: any = {}
): TAsyncThunk => (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    execGetRequest(getState, url, params)
      .then(resolve)
      .catch(async (reason: AxiosError) => {
        // if (checkErrorCode(reason, 404)) {
        //   return resolve(reason);
        // }
        if (!checkErrorCode(reason, 401))
          return reject(reason);
        await refreshToken(dispatch, getState);
        execGetRequest(getState, url, params)
          .then(resolve)
          .catch(reject)
      })
  });
};

export const getRequestWithoutToken = async (
  url: string,
  params: any = {}
): Promise<any> => {
  const requestConfig = {
    ...params
  };
  return axios.get(url, requestConfig)
    .then((response: AxiosResponse) => {
      return response.data;
    });
}

export const postRequest = (
  url: string,
  body: any,
  params: any = {}
): TAsyncThunk => (
  dispatch,
  getState
) => {
  return new Promise((resolve, reject) => {
    execPostRequest(getState, url, body, params)
      .then(resolve)
      .catch(async (reason: AxiosError) => {
        if (!checkErrorCode(reason, 401))
          return reject(reason);
        await refreshToken(dispatch, getState);
        execPostRequest(getState, url, body, params)
          .then(resolve)
          .catch(reject)
      })
  });
}

export const postRequestWithoutToken = async (
  url: string,
  body: any,
  params: any = {}
): Promise<any> => {
  const requestConfig = {
    ...params
  };
  return axios.post(url, body, requestConfig)
    .then((response: AxiosResponse) => response.data);
}

const checkErrorCode = (reason: AxiosError, code: number) => {
  return reason && reason.message && reason.message.indexOf(''+code) >= 0;
}

const execGetRequest = (
  getState: () => IState,
  url: string,
  params: any = {},
): Promise<any> => {
  const state = getState();
  const authUser = getAuthUser(state);
  if (!authUser || !authUser.googleId) {
    return Promise.reject('unauth user');
  }

  const requestConfig = {
    headers: { authorization: authUser.token },
    ...params
  };
  return axios.get(url, requestConfig)
    .then((response: AxiosResponse) => response.data);
}

export const execGetRequestByToken = (
  url: string,
  token: string,
  params: any = {},
): Promise<any> => {
  const requestConfig = {
    headers: { authorization: token },
    ...params
  };
  return axios.get(url, requestConfig)
    .then((response: AxiosResponse) => response.data);
}

const execPostRequest = (
  getState: () => IState,
  url: string,
  body: any,
  params: any = {}
): Promise<any> => {
  const state = getState();
  const authUser = getAuthUser(state);
  if (!authUser || !authUser.googleId) {
    return Promise.reject('unauth user');
  }

  const requestConfig = {
    headers: { authorization: authUser.token },
    ...params
  };
  return axios.post(url, body, requestConfig)
    .then((response: AxiosResponse) => response.data);
}

const refreshToken = async (
  dispatch: (actions: any) => Promise<any>,
  getState: () => IState
) => {
  const authUser = getAuthUser(getState());
  if (!authUser || !authUser.googleId) {
    return Promise.reject('unauth user');
  }
  return new Promise((resolve, reject) => {
    const body = { hashId: authUser.hashId}
    axios.post(AUTH_REFRESH_TOKEN_URL, body, {}).then((response: AxiosResponse) => {
      const newAccessToken = response.data.accessToken;
      if (newAccessToken) {
        const authUser = getAuthUser(getState());
        if (authUser) {
          authUser.token = newAccessToken;
          dispatch(setAuthUserAction(authUser));
          AuthManager.updateUserToken(newAccessToken);
          resolve();
        }
      }
    });
  });
}

