import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { AddVideo } from '../component/AddVideo';
import { IAddVideoEvents, IAddVideoFields } from '../component/types';
import { AddVideoEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IAddVideoFields => {
    return {
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IAddVideoEvents => ({
    onLoad: (groupId, videoId) => dispatch(AddVideoEvents.onLoad(groupId, videoId)),
  }
);

export const AddVideoHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVideo);

AddVideoHOC.displayName = 'AddVideoHOC';
