import { IState } from '../../../../../store/types';
import { ILangSelectFields } from '../../../LangSelect/components/types';
import { connect } from 'react-redux';
import { LangSelect } from '../../../LangSelect/components/LangSelect';
import { IContentLibLangSelectHOCProps } from './types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';


const mapStateToProps = (
  state: IState,
  props: IContentLibLangSelectHOCProps,
): ILangSelectFields => {
  const langLists = ContentLibSelectors.getLangs(state);
  const langs = props.targetLangs ? langLists.targetLangs : langLists.nativeLangs;
  return {
    ...props,
    langs
  }
}

export const ContentLibLangSelectHOC = connect(
  mapStateToProps,
  null
)(LangSelect);

ContentLibLangSelectHOC.displayName = 'ContentLibLangSelectHOC';