import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';
import { TApiResponse } from '../../../spa/effects/phrase-details/phraseDetailsService/phrase-details-service';


export type TGptChatExecBodyRest = {
  prompt: string;
  hash: string;
}

export class GptChatRest {

  private static GPT_CHAT_API_URL = API_URL + '/gptChartApi/exec';

  public static async exec(body: TGptChatExecBodyRest): Promise<TApiResponse> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(GptChatRest.GPT_CHAT_API_URL, body));
  }
}
