import {
	IUserProfileRest, TTeacherPhraseNoteGroupInfo,
	TTeacherPhraseNoteVideoInfo,
	TUserProfileGroup
} from '../../../../../../common/rest/user/userProfileRest';

export enum EPublicProfileViewMode {
	PLAYLISTS, NOTES
}

export interface IPublicProfileFields {
	loading: boolean,
	canEdit: boolean,
	userName: string,
	profile: TPublicProfile,
	groups: TUserProfileGroup[],
	notes: TTeacherPhraseNoteGroupInfo[]
}

export interface IPublicProfileEvents {
	onClickBack: () => void;
	onClickLogo: () => void;
	onLoad: (nick: string) => void;
	onUnload: () => void;
	onCopyPublicLink: (nick: string) => void;
}

export interface IPublicProfileProps extends
	IPublicProfileFields,
	IPublicProfileEvents {
}

export type TPublicProfile = IUserProfileRest

export type TPublicProfileData = {
	profile: TPublicProfile,
	groups: TUserProfileGroup[],
	notes: TTeacherPhraseNoteVideoInfo[]
}