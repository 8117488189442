import { AppThunk } from '../../../../../store/types';
import { getGeneralStorage } from '../../../../../../common/utils/local-storage/local-storage-helpers';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';

export class PhraseDetailsTabsEditorRouterEvents {

  public static onChange(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const viewMode = PhraseDetailsTabsEditorPopupSelectors.getViewMode(getState());
      if (viewMode !== EPhraseDetailsTabsEditorViewMode.TRANSLATIONS) { // promts
        EventsRouter.trackEvent(Events.PROMPT_PAGE_VIEWED);
      }
    }
  }

  public static onLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (getGeneralStorage().getData().showVideoPromptsTutorial) {
        dispatch(PhraseDetailsTabsEditorPopupActions.setShowTutorial(true));
      }
    }
  }

}