import React from 'react';
import { Divider, Stack } from "@mui/material";
import { Shortcut } from "./Shortcut";
import { theme } from "../../../../common/styles/theme";
import { CLASS_ONBOARDING_BLUR } from '../Onboarding/constants';

export type TShortcutsProps = {}

export const Shortcuts: React.FC<TShortcutsProps> = () => {
	const dividerStyle = {
		m: 0,
		mb: 0.5,
		height: theme.spacing(0.25),
		borderRadius: theme.spacing(0.25),
		background: theme.palette.secondary.main,
		border: 'none'
	}
	return (
		<Stack
			className={CLASS_ONBOARDING_BLUR}
		>
			<Shortcut
				label={'Play/Pause'}
				shortcutKey={'Space'}
			/>
			<Divider sx={dividerStyle}/>
			<Shortcut
				label={'Previous phrase'}
				shortcutKey={<>&larr;</>}
				alternativeKey={'A'}
			/>
			<Divider sx={dividerStyle}/>
			<Shortcut
				label={'Next phrase'}
				shortcutKey={<>&rarr;</>}
				alternativeKey={'D'}
			/>
			<Divider sx={dividerStyle}/>
			<Shortcut
				label={'Repeat phrase'}
				shortcutKey={<>&darr;</>}
				alternativeKey={'S'}
			/>
			<Divider sx={dividerStyle}/>
			<Shortcut
				label={'Toggle Auto pause'}
				shortcutKey={'Q'}
			/>
			<Divider sx={dividerStyle} />
			<Shortcut
				label={'Save current subtitle'}
				shortcutKey={'R'}
			/>
		</Stack>
	);
};
