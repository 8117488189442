import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';

export interface IPhraseDetailsTabsEditorRouterFields {
  show: boolean;
  viewMode: EPhraseDetailsTabsEditorViewMode;
}

export interface IPhraseDetailsTabsEditorRouterEvents {
  onChange: () => void;
  onLoad: () => void;
}

export interface IPhraseDetailsTabsEditorRouterProps extends
  IPhraseDetailsTabsEditorRouterFields,
  IPhraseDetailsTabsEditorRouterEvents
{}

export const PHRASE_DETAILS_TAB_EDITOR_CLASS_NAME = 'phraseDetailsTabEditor';