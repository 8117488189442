import { createContext } from 'react';

export interface IPhraseListDetailsColumnFields {
  phraseDetailsHeight: number,
}

export interface IPhraseListDetailsColumnEvents {
  onPhraseDetailsHeightChange: (height: number) => void
}

export interface IPhraseListDetailsColumnProps extends
  IPhraseListDetailsColumnFields,
  IPhraseListDetailsColumnEvents
{}

export interface IPhraseListContext {
  height: number
}

export const PhraseListContext = createContext<IPhraseListContext | null>(null);