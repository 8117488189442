import { AppThunk } from '../../../../../store/types';
import { setSavedVideoConfirmAction } from '../../../../../store/general/actions';
import { ESavedVideoConfirmShowMode } from '../../../../../store/general/types';

export class SavedVideoConfirmPopupEvents {

  public static onClose(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(setSavedVideoConfirmAction({showMode: ESavedVideoConfirmShowMode.HIDE}));
    }
  }

}