import { Reducer } from 'redux';
import {
  IPhraseSelectPopupActions,
  IPhraseSelectPopupState,
  SET_PHRASE_SELECT_POPUP_SHOW_ACTION,
} from './types';

const initialState: IPhraseSelectPopupState = {
  show: false,
};

export const phraseSelectPopupReducer: Reducer<IPhraseSelectPopupState, IPhraseSelectPopupActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_PHRASE_SELECT_POPUP_SHOW_ACTION:
      return {
        ...state,
        show: action.show
      }
    
    default:
      return state;
  }
};