import { contentLibVideosLoadPartSize, IContentLibVideoListProps } from './types';
import React from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { ContentLibGroupInfoHOC } from '../../ContentLibGroupInfo/hocs/ContentLibGroupInfoHOC';
import { Loader } from '../../../../../../common/components/Loader/Loader';
import { IContentLibVideo } from '../../../../../store/contentLib/types';
import { ContentLibVideo } from '../../ContentLibVideo/components/ContentLibVideo';

export function ContentLibVideoList({
  groupId,
  searchText,
  targetLangCode,
  nativeLangCode,
  onLoadVideos,
  onVideoClick
}: IContentLibVideoListProps) {

  const [videoList, setVideoList] = React.useState<IContentLibVideo[]>([]);
  const [currentStartIndex, setCurrentStartIndex] = React.useState<number>(0);
  const [allListLoaded, setAllListLoaded] = React.useState<boolean>(false);

  const currentStartIndexRef = React.useRef<number>(0);
  currentStartIndexRef.current = currentStartIndex;

  const allListLoadedRef = React.useRef<boolean>(false);
  allListLoadedRef.current = allListLoaded;

  const videoListRef = React.useRef<IContentLibVideo[]>([]);
  videoListRef.current = videoList;



  const onListScrollToBottom = async () => {
    if (allListLoadedRef.current || videoListRef.current?.length <= 0) return;
    setCurrentStartIndex(currentStartIndexRef.current + contentLibVideosLoadPartSize);
  }

  React.useEffect(() => {
    setCurrentStartIndex(0);
    setVideoList([]);
    setAllListLoaded(false);
  }, [groupId, searchText, targetLangCode, nativeLangCode]);

  React.useEffect(() => {
    onLoadVideos(currentStartIndexRef.current, contentLibVideosLoadPartSize).then(list => {
      if (list?.length <= 0) {
        setAllListLoaded(true);
      } else {
        setVideoList([...videoListRef.current, ...list]);
      }
    })
  }, [groupId, searchText, targetLangCode, nativeLangCode, currentStartIndexRef.current])

  const handleVideoClick = (id: number) => {
    const video = videoList.find(v => v.id === id);
    if (video) {
      onVideoClick(video);
    }
  }

  return (
    <Stack
      gap={2}
      sx={{
        py: 3,

        '& + &': {
          borderTop: `2px solid ${theme.palette.grey[200]}`
        },
      }}
    >
      {!!groupId &&
      <ContentLibGroupInfoHOC
        groupId={groupId}
      />
      }

    <Box
				sx={{
					position: 'relative',
					display: 'grid',
					gridTemplateColumns: `repeat(5, 1fr)`,
					gap: 2,

					'@media (min-width: 1600px)': {
						gridTemplateColumns: `repeat(7, 1fr)`,
					},

					'@media (max-width: 1000px)': {
						gridTemplateColumns: `repeat(3, 1fr)`,
					},

					'@media (max-width: 600px)': {
						gridTemplateColumns: `repeat(2, 1fr)`,
					}
				}}
			>
        {videoList.map((video) => {
          return (
            <ContentLibVideo
              key={video.id}
              title={video.title}
              thumbUrl={video.youtubeThumbMediumUrl || video.youtubeThumbUrl}
              channel={video.youtubeChannel}
              onClick={() => handleVideoClick(video.id)}
            />

          )
        })}

        {!allListLoaded &&
        <Loader onShow={onListScrollToBottom}>
          <CircularProgress size='small' sx={{ alignSelf: 'center' }}/>
        </Loader>
        }

      </Box>
    </Stack>
  )
};
