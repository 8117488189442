import {
  getTargetCaptions,
  getVideoPhraseNoteByCaption, getVideoPhraseNoteById,
  getVideoPhraseNoteTimeScales
} from '../../store/videos/selectors';
import { getDispatch, getState } from '../../store';
import { ICaptionsItem, IPhraseNote } from '../../types/common';
import { IPhraseNoteTimeScale } from '../../store/videos/types';
import { setPhraseTimeScaleAction } from '../../store/videos/actions';

export class PhraseNoteTimeScaleEffects {

  private static NOTE_SHOW_SEC = 5;

  public static load() {
    const state = getState();
    const dispatch = getDispatch();

    const result: IPhraseNoteTimeScale[] = [];
    const captions: ICaptionsItem[] = getTargetCaptions(state);
    captions.forEach((caption, index) => {
      const note = getVideoPhraseNoteByCaption(state, caption);
      let endTime = caption.startTime + this.NOTE_SHOW_SEC;
      if (caption.endTime > endTime)
        endTime = caption.endTime;
      if (note) {
        result.push({
          noteId: note.id,
          phraseId: note.phraseId,
          startTime: caption.startTime,
          endTime
        });
      }
    });

    dispatch(setPhraseTimeScaleAction(result));
  }

  public static findPhraseNoteByTime(time: number): IPhraseNote | null {
    let noteId = 0;
    getVideoPhraseNoteTimeScales(getState()).forEach(t => {
      if (t.startTime <= time && t.endTime > time) {
        noteId = t.noteId;
      }
    })
    return noteId ? getVideoPhraseNoteById(getState(), noteId) : null;
  }
}

