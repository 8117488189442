import { API_URL } from '../../constants';
import { TAsyncThunk } from '../../../spa/store/types';
import { postRequest } from '../../utils/requestService';
import { getDispatch } from '../../../spa/store';

const INSTALL_USER_GROUP_VIDEO_CREATE_URL = API_URL + '/installUserGroupVideo/';

export type TInstallUserGroupVideoCreateBody = {
  nativeLangCode: string;
  targetLangCode: string;
  landingName?: string
}

export class InstallUserGroupVideoRest {

  public static async createInstallUserGroupVideo(data: TInstallUserGroupVideoCreateBody): Promise<any> {
    const dispatch = getDispatch();
    return dispatch(postRequest(INSTALL_USER_GROUP_VIDEO_CREATE_URL, data));
  }
}