import { AppThunk } from '../../../../../store/types';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';

export class AudioCaptionsContainerEvents {

  public static onKeyDown(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
    }
  }

  public static onVideoChange(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(PhraseEffects.deleteCurrentPreviewPhrase());
      dispatch(PhraseDetailsActions.hide());
    }
  }

  public static onTargetCaptionsChange(): AppThunk {
    return (
      dispatch,
      getState
    ) => {

    }
  }

  public static onNativeCaptionsChange(): AppThunk {
    return (
      dispatch,
      getState
    ) => {

    }
  }



}