import { API_URL } from '../../constants';
import { TPhraseDetailsTab } from '../../../spa/store/models/types';
import { getDispatch } from '../../../spa/store';
import { getRequest, postRequest } from '../../utils/requestService';

export type TPhraseDetailsTabRest = TPhraseDetailsTab

export class PhraseDetailsTabRest {

  private static LIST_API_URL = API_URL + '/phraseDetailsTab/list';
  private static SAVE_API_URL = API_URL + '/phraseDetailsTab/save';
  private static REMOVE_API_URL = API_URL + '/phraseDetailsTab/remove';
  private static SORT_API_URL = API_URL + '/phraseDetailsTab/sort';
  private static REMOVE_ALL_API_URL = API_URL + '/phraseDetailsTab/removeAll';

  public static async getLists(): Promise<TPhraseDetailsTabRest[]> {
    const dispatch = getDispatch();
    return await dispatch(getRequest(PhraseDetailsTabRest.LIST_API_URL));
  }

  public static async save(tab: TPhraseDetailsTabRest): Promise<TPhraseDetailsTabRest> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(PhraseDetailsTabRest.SAVE_API_URL, tab));
  }

  public static async remove(id: number): Promise<void> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(PhraseDetailsTabRest.REMOVE_API_URL, {id}));
  }

  public static async sort(tabs: TPhraseDetailsTabRest[]): Promise<TPhraseDetailsTabRest[]> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(PhraseDetailsTabRest.SORT_API_URL, tabs));
  }

  public static async removeAll(): Promise<void> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(PhraseDetailsTabRest.REMOVE_ALL_API_URL, {}));
  }
}