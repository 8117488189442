import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { GptUtils } from '../../../../../effects/gptUtils';
import { PhraseNoteEffects } from '../../../../../effects/phraseNoteEffects';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';

export class PhraseChatEvents {

	public static onCopyNote(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const state = getState();
			const chat = PhraseDetailsSelectors.getPromptsChat(state);
			let text = '';
			if (chat && chat.length) {
				chat.forEach(i => {
					let result = '';
					if (i.id) {
						let promptResult;
						if (i.type === EPhraseDetailsTabType.CUSTOM_PROMPT) {
							promptResult = PhraseDetailsSelectors.getCustomPrompt(state, i.id);
						} else if (i.type === EPhraseDetailsTabType.LIB_PROMPT) {
							promptResult = PhraseDetailsSelectors.getLibPrompt(state, i.id);
						}
						result = promptResult?.resultText;
					}
					if (!result) {
						if (i.type === EPhraseDetailsTabType.EXPLAIN) {
							result = PhraseDetailsSelectors.getExplainResultText(state) || '';
						} else if (i.type === EPhraseDetailsTabType.USAGE) {
							result = PhraseDetailsSelectors.getUsageResultText(state) || '';
						}
					}
					if (result) text += '\n\n' + result;
				});
			}
			PhraseNoteEffects.runCopyToNoteText(GptUtils.clear(text));
		}
	}


}