import React from 'react';
import { Modal } from '../../../../../../../common/components/Modal/Modal';
import { Button, Divider, Stack } from '@mui/material';
import { theme } from '../../../../../../../common/styles/theme';
import { Text } from '../../../../../../../common/components/Text/Text';
import { IEditPlaylistProps } from './types';
import { EditPlaylistItemHOC } from '../../hocs/EditPlaylistItem/EditPlaylistItemHOC';
import { PlusIcon } from '../../../../../../../../icons/PlusIcon';
import { DeviceContext } from '../../../../../../../common/contexts/DeviceContext';

export const EditPlaylist: React.FC<IEditPlaylistProps> = ({
  isOpen,
  groups,
  onClose,
  onShowAddPlaylist
}) => {

  const {isMobile} = React.useContext(DeviceContext);

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      title={'Playlists'}
      isMobile={isMobile}
    >
      <Stack
        sx={{
          width: theme.spacing(80),
          maxWidth: '100%',
        }}
      >
        <Button
          variant={'contained'}
          startIcon={<PlusIcon sx={{ width: theme.spacing(3), height: theme.spacing(3) }} />}
          onClick={onShowAddPlaylist}
        >
          Create Playlist
        </Button>

        <Stack
          sx={{
            minHeight: theme.spacing(2)
          }}
        >
        </Stack>

        {!groups || !groups.length &&
        <Stack sx={{ alignItems: 'center', p: theme.spacing(2) }}>
          <Text fontWeight={600}>
            You have no playlists yet
          </Text>
        </Stack>
        }
        {
          groups.map((group, index) => {
            return (
              <Stack key={group.id}>
                <EditPlaylistItemHOC groupId={group.id}/>
                {index !== groups.length - 1 &&
                    <Divider
                        sx={{
                          margin: theme.spacing(0.5),
                          height: theme.spacing(0.25),
                          borderRadius: theme.spacing(0.25),
                          background: theme.palette.secondary.main,
                          border: 'none'
                        }}
                    />
                }
              </Stack>
            );
          })
        }
      </Stack>
    </Modal>
  );
}
