import { TUserRole } from '../../../spa/store/models/types';
import { API_URL } from '../../constants';

export class UserRoleRest {

  private static LIST = API_URL + '/userRole/list';

  public static async getList(): Promise<TUserRole[]> {
    const response = await fetch(UserRoleRest.LIST);
    return await response.json();
  }

}
