import { TAuthUser } from '../store/general/types';
import { UserRest } from '../../common/rest/user/userRest';
import { getDispatch, getState } from '../store';
import { getAuthUser } from '../store/general/selectors';
import { setAuthUserAction } from '../store/general/actions';

export class AuthUserEffects {

  public static async updateUser(data: Partial<TAuthUser>) {
    let authUser = getAuthUser(getState());
    if (authUser) {
      await UserRest.updateUser(data);
      authUser = {...authUser, ...data};
      getDispatch()(setAuthUserAction(authUser));
    }
  }

}