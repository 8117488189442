import React from 'react';
import { DeviceContext } from '../../../../../common/contexts/DeviceContext';
import { theme } from '../../../../../common/styles/theme';
import { Modal } from '../../../../../common/components/Modal/Modal';
import { Stack } from '@mui/material';
import { InstallExtensionWarningHOC } from '../../InstallExtensionWarning/hocs/InstallExtensionWarningHOC';
import { IInstallExtensionPopupProps } from './types';


export const InstallExtensionPopup: React.FC<IInstallExtensionPopupProps> = ({
                                                                     isOpen,
                                                                     onClose
                                                                   }) => {
  if (!isOpen) return null;

  const {isMobile} = React.useContext(DeviceContext);


  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      title={'Install Extension'}
      isMobile={isMobile}
    >
      <Stack
        gap={theme.spacing(2)}
        sx={{
          width: theme.spacing(45.75),
          maxWidth: '100%',
        }}
      >
        <InstallExtensionWarningHOC />
      </Stack>
    </Modal>
  );
}