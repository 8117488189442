import { IState } from '../../../../../store/types';
import { getLibraryGroup, getUserGroups, getUserGroupsWithCheckedLangs } from '../../../../../store/models/selectors';
import { connect } from 'react-redux';
import { VideoLibraryHeader } from '../components/VideoLibraryHeader';
import { IVideoLibraryHeaderEvents, IVideoLibraryHeaderFields } from '../components/types';
import { getLibraryGroupId } from '../../../../../store/general/selectors';
import { VideoLibraryHeaderEvents } from './events';
import { ELanguageStatus } from '../../../../../store/general/types';
import { TUserLanguage } from '../../../../../store/models/types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IVideoLibraryHeaderFields => {
    const group = getLibraryGroup(state);
    const libraryGroupId = getLibraryGroupId(state) || 0;
    const groups = getUserGroupsWithCheckedLangs(state);
    return {
      group,
      groups,
      libraryGroupId
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IVideoLibraryHeaderEvents => ({
    onGroupChange: (groupId: number) => dispatch(VideoLibraryHeaderEvents.onGroupChange(groupId)),
    onShowEditPlayLists: () => dispatch(VideoLibraryHeaderEvents.onShowEditPlayLists()),
    onCopyLink: () => dispatch(VideoLibraryHeaderEvents.onCopyLink()),
  }
);

export const VideoLibraryHeaderHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoLibraryHeader);

VideoLibraryHeaderHOC.displayName = 'VideoLibraryHeaderHOC';