import { IEvent, IEventData, IEventsProvider } from '../types';
import { TAuthUser } from '../../../spa/store/general/types';
import { getGa4Id } from '../../constants';

export class GoogleAnalyticsEventsProvider implements IEventsProvider {

  public setUser(user: TAuthUser): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(['config', getGa4Id(), {
      user_id: user.hashId
    }]);
  }

  public trackEvent(event: IEvent, date: Date, data: IEventData): void {
    // @ts-ignore
    if (window.gtag) {
      const eventName = event.subject + ': ' + event.action;
      // @ts-ignore
      window.gtag('event', eventName, data);
    }
  }

}
