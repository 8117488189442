export enum EOneTimeTooltipType {
	PHRASES_EDIT,
	NATIVE_LANGUAGE,
	NATIVE_AVAILABLE,
	TUTORIAL,
}

export class OneTimeTooltipTextProvider {

	private static texts: Record<EOneTimeTooltipType, string> = {
		[EOneTimeTooltipType.PHRASES_EDIT]: `Highlight the text <span class="highlighted-span">around your phrase</span> to modify a phrase context then click <strong>“{0}”</strong> button.`,
		[EOneTimeTooltipType.NATIVE_LANGUAGE]: `Subtitles in your native language are not available.
      		<p>Close this message to collapse the column.</p>`,
		[EOneTimeTooltipType.NATIVE_AVAILABLE]: `Subtitles in your native language are&nbsp;available for this video`,
		[EOneTimeTooltipType.TUTORIAL]: `Click the help icon anytime to access video tutorials`,
	}

	public static getText(
		type: EOneTimeTooltipType,
		vars?: string[],
	) {
		let text = OneTimeTooltipTextProvider.texts[type] || '';
		if (text && vars && vars.length) {
			for (let i = 0; i < vars.length; i++) {
				text = text.replace(`{${i}}`, vars[i]);
			}
		}
		return text;
	}
}
