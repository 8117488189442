import { AppThunk } from '../../../../store/types';
import { AudioPlayerManager } from '../../../../effects/audioPlayer/audioPlayerManager';
import { AudioEffects } from '../../../../effects/audioEffects';

export class AudioEvents {

  public static onInit(
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {

    }
  }

  public static onLoad(
    audioId: number,
    groupId: number
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      await AudioEffects.load(audioId, groupId)
      /*

         if (phraseId) {
           let phrase = PhraseListSelectors.findPhraseById(state, phraseId);
           const startPosition = phrase ? phrase.startTime : 0;
           const onVideoLoaded = () => {
             if (phrase) {
               PlayerController.getInstance().playPhrase(phrase);
             }
           }
           dispatch(loadVideoEffect(videoId, playerApi, groupId, pause, startPosition, onVideoLoaded));
         } else {
           dispatch(loadVideoEffect(videoId, playerApi, groupId, pause));
         }
         getUserStateStorage().setVideoInfo(videoId, groupId, phraseId);
       }*/
    }
  }

  public static onUnload(
    groupId: number
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
    /*  const videoId = getCurrentVideoId(getState());
      if (videoId && groupId) {
        const playerApi = await PlayerFactory.getPlayerApi();
        dispatch(updateVideoPosition(videoId, groupId, await playerApi.player.getCurrentTime()));
      }*/
    }
  }

  public static onUpdateAudioPlayer(
    player: HTMLAudioElement
  ): AppThunk {
    return (
      dispatch
    ) => {
      AudioPlayerManager.getInstance().setPlayer(player);
    }
  }


}