import React from 'react';
import { Button, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { IPhraseDetailsTabTranslationLangAddProps } from './types';
import { PlusIcon } from '../../../../../../../../icons/PlusIcon';
import { theme } from '../../../../../../../common/styles/theme';
import { LangUtil } from '../../../../../../../common/utils/lang-util';

const DEFAULT_LANG_SEL = 'selLang';

export const PhraseDetailsTabTranslationLangAdd: React.FC<IPhraseDetailsTabTranslationLangAddProps> = ({
  langCodes,
  onAdd
}) => {

  const [selectMode, setSelectMode] = React.useState<boolean>(false);
  const [selectLang, setSelectLang] = React.useState<string>(DEFAULT_LANG_SEL);

  const handleButtonClick = () => {
    setSelectMode(true);
  }

  const handleSelectChange = ({ target }: SelectChangeEvent) => {
    const value = target.value;
    setSelectLang(value);
    if (value !== DEFAULT_LANG_SEL) {
      setSelectLang(DEFAULT_LANG_SEL);
      onAdd(value);
    }
  };

  const handleSelectClose = () => {
    setSelectMode(false);
  }

  return (
    <Stack direction={'row'}>
      {!selectMode &&
      <Button
        variant={'contained'}
        fullWidth
        startIcon={<PlusIcon sx={{ width: theme.spacing(3), height: theme.spacing(3) }}/>}
        onClick={handleButtonClick}
      >
        Add Language
      </Button>
      }

      {selectMode &&
      <Select
        open={true}
        value={selectLang}
        variant={'filled'}
        onChange={handleSelectChange}
        onClose={handleSelectClose}
        fullWidth
        sx={{
          '.MuiSelect-iconFilled': {
            display: 'none'
          }
        }}
        MenuProps={{ sx: { mt: theme.spacing(-5) }}}
      >
        <MenuItem
          data-nohotkey
          key={DEFAULT_LANG_SEL}
          value={DEFAULT_LANG_SEL}
        >Select language
        </MenuItem>
        {
          langCodes.map(langCode => (
            <MenuItem
              data-nohotkey
              key={langCode}
              value={langCode}
            >
              {LangUtil.getLangNameByCode(langCode)}
            </MenuItem>
          ))
        }
      </Select>
      }

    </Stack>
  );

}
