import * as React from 'react';
import { INativeCaptionsProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Box, CircularProgress, Stack } from '@mui/material';
import { NativeCaptionsItem } from './NativeCaptionsItem';
import { IPlayerManagerListener } from '../../../../../effects/player/manager/playerManagerListener';
import { PlayerManager } from '../../../../../effects/player/manager/playerManager';
import _ from 'lodash';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';

export let nativeItemsHeightList: number[] = [];

type TOnCaptionClickCallback = (
  startSelIndex: number,
  endSelIndex: number,
  isMobile?: boolean,
) => void;
const onCaptionClick = (
  startSelIndex: number,
  endSelIndex: number,
  isMobile: boolean,
  callback: TOnCaptionClickCallback,
) => {
  callback(startSelIndex, endSelIndex, isMobile);
}
const onCaptionClickDebounce = _.debounce(onCaptionClick , 300);

export const NativeCaptions: React.FC<INativeCaptionsProps> = ({
  elementRef,
  captions,
  videoId,
  langCode,
  onCaptionClick,
  onChangeActiveIndex
}) => {

  const {isMobile} = React.useContext(DeviceContext);

  const nativeCaptionsContainer = elementRef;

  const [nativeScrollTop, setNativeScrollTop] = React.useState<number>(-1);
  const [activeCaptionIndex, setActiveCaptionIndex] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [startSelCaptionIndex, setStartSelCaptionIndex] = React.useState<number>(-1);
  const [lastMouseEnterCaptionIndex, setLastMouseEnterCaptionIndex] = React.useState<number>(-1);

  const startSelCaptionIndexRef = React.useRef<number>(-1);
  startSelCaptionIndexRef.current = startSelCaptionIndex;

  const lastMouseEnterCaptionIndexRef = React.useRef<number>(-1);
  lastMouseEnterCaptionIndexRef.current = lastMouseEnterCaptionIndex;

  React.useEffect(() => {
    if (captions && captions.length > 0) {
      const defaultHeight = captions.length > 500 ? 18 : 0; // do not allow load all target caption items for big videos
      nativeItemsHeightList = Array(captions.length).fill(defaultHeight);
      setLoading(false);
    } else {
      nativeItemsHeightList = [];
      setLoading(true);
    }
    setStartSelCaptionIndex(-1);
  }, [captions]);

  React.useEffect(() => {
    const onMouseUp = (e: MouseEvent) => {
      if (startSelCaptionIndexRef?.current >= 0 && lastMouseEnterCaptionIndexRef?.current >= 0) {
        _onCaptionClick(startSelCaptionIndexRef.current, lastMouseEnterCaptionIndexRef.current);
      }
    }
    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    }
  }, []);

  const _onCaptionClick = (
    startSelIndex: number,
    endSelIndex: number,
    isMobile?: boolean,
  ) => {
    onCaptionClickDebounce(startSelIndex, endSelIndex, !!isMobile, onCaptionClick);
  }

  const captionMouseDownHandler = (index: number) => {
    setLastMouseEnterCaptionIndex(index);
    setStartSelCaptionIndex(index);
  }

  const captionMouseEnterHandler = (index: number) => {
    setLastMouseEnterCaptionIndex(index);
  }

  const captionMouseUpHandler = (index: number) => {
    setStartSelCaptionIndex(-1);
    _onCaptionClick(startSelCaptionIndex, index, isMobile);
  }

  const contextMenuHandler = (e: any) => {
    e.preventDefault();
    
    _onCaptionClick(0, 0, isMobile);
  }

  const onItemSetHeight = (index: number, height: number) => {
    nativeItemsHeightList[index] = height;
  }

  const getVisibleItemsIndexRange = () => {
    let firstItemIndex = -1;
    let lastItemIndex = -1;
    let currentTop = 0;
    const scrollHeight = nativeCaptionsContainer && nativeCaptionsContainer.current ? nativeCaptionsContainer.current.offsetHeight : 0;
    const scrollBottom = nativeScrollTop + scrollHeight;
    for(let i=0; i<captions.length; i++) {
      currentTop += nativeItemsHeightList[i];
      if (firstItemIndex < 0 && currentTop >= nativeScrollTop) {
        firstItemIndex = i - 1;
      }
      if (lastItemIndex < 0 && currentTop >= scrollBottom) {
        lastItemIndex = i + 1;
      }
    }
    if (firstItemIndex < 0)
      firstItemIndex = 0;
    if (lastItemIndex < 0)
      lastItemIndex = captions.length-1;
    return {firstItemIndex, lastItemIndex};
  }

  const {firstItemIndex, lastItemIndex} = getVisibleItemsIndexRange();

  let topSpaceHeight = 0;
  for(let i=0; i<firstItemIndex; i++) {
    topSpaceHeight += nativeItemsHeightList[i];
  }
  let bottomSpaceHeight = 0;
  for(let i=lastItemIndex+1; i<captions.length; i++) {
    bottomSpaceHeight += nativeItemsHeightList[i];
  }

  const playerListener: IPlayerManagerListener = {
    onNativeIndexChange(index: number): void {
      setActiveCaptionIndex(index);
      onChangeActiveIndex(index);
    }
  }

  const onScroll = () => {
   // setScrolling(true);
    if (nativeCaptionsContainer && nativeCaptionsContainer.current) {
      setNativeScrollTop(nativeCaptionsContainer.current.scrollTop);
    }
  }

  React.useEffect(() => {
    PlayerManager.getInstance().addListener(playerListener);
    return () => {
      PlayerManager.getInstance().removeListener(playerListener);
    };
  }, [captions, videoId, langCode]);

  const getCaptionItems = (firstItemIndex: number, lastItemIndex: number) => {
    const list: JSX.Element[] = [];
    for(let index = firstItemIndex; index <= lastItemIndex; index++) {
      const caption = captions[index];
      if (!caption) continue;
      list.push(<NativeCaptionsItem
        key={`${caption.startTime}-${caption.endTime}`}
        index={index}
        startTime={caption.startTime}
        endTime={caption.endTime}
        text={caption.text}
        isActive={index === activeCaptionIndex}
        onMouseDown={()=>captionMouseDownHandler(index)}
        onMouseUp={()=>captionMouseUpHandler(index)}
        onMouseEnter={()=>captionMouseEnterHandler(index)}
        onSetHeight={(height)=>onItemSetHeight(index, height)}
      />)
    }
    return list;
  }

  return (
    <Box
      ref={elementRef}
      onScroll={onScroll}
      sx={{
        overflow: 'auto',
        flex: 1,
        position: 'relative',
        pl: theme.spacing(3),
        pt: theme.spacing(3),
      }}
      onContextMenu={contextMenuHandler}
    >
      {loading &&
      <Stack alignItems={'center'} justifyContent={'center'}>
        <CircularProgress />
      </Stack>
      }

      {!loading &&
        <>
          {topSpaceHeight > 0 && <div	style={{ minHeight: topSpaceHeight+'px' }}></div>}
          {getCaptionItems(firstItemIndex, lastItemIndex)}
          {bottomSpaceHeight > 0 && <div style={{ minHeight: bottomSpaceHeight+'px' }}></div>}
        </>
      }

    </Box>
  )
};
