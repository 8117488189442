import * as React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { alpha, Box, CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu as MenuMUI, Stack, SwipeableDrawer, SxProps } from '@mui/material';
import { QuestionIcon } from '../../../../../../../icons/QuestionIcon';
import { IMenuProps } from './types';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { LogoLabel } from '../../../../../../../icons/LogoLabel';
import { CloseIcon } from '../../../../../../../icons/CloseIcon';
import { MenuIcon } from '../../../../../../../icons/MenuIcon';
import { HomeIcon } from '../../../../../../../icons/HomeIcon';
import { BookIcon } from '../../../../../../../icons/BookIcon';
import { ChatGptIcon } from '../../../../../../../icons/ChatGptIcon';
import { BookmarkIcon } from '../../../../../../../icons/BookmarkIcon';
import { EMainMenuActive } from '../../../../../store/general/types';
import { AccountButtonHOC } from '../../AccountButton/hocs/AccountButtonHOC';
import { Divider } from '../../../../../../common/components/Divider/Divider';
import { TelegramFilledBlueIcon } from '../../../../../../../icons/TelegramFilledBlueIcon';
import { ChromeStoreIcon } from '../../../../../../../icons/ChromeStoreIcon';
import { CommentIcon } from '../../../../../../../icons/CommentIcon';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { ListIcon } from '../../../../../../../icons/ListIcon';
import { ProfilesListHOC } from '../../../ProfilesList/hocs/ProfilesListHOC';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { ArrowIcon } from '../../../../../../../icons/ArrowIcon';
import { UsersIcon } from '../../../../../../../icons/UsersIcon';
import { ChartIcon } from '../../../../../../../icons/ChartIcon';
import { SAVED_PHRASES_PANEL_TITLE } from '../../../../../../common/wordings';

export const Menu: React.FC<IMenuProps> = ({
	active,
	version,
	isLoadingProfiles,
	onClickChatGpt,
	onClickContent,
	onClickHelp,
	onClickHome,
	onClickPhrases,
	onClickPlaylists,

	onExtension,
	onTelegram,
	onClickFeedback,
	onClickStatistics,
	onLoadProfiles,
}) => {
	const {isMobile} = React.useContext(DeviceContext);

	const [isMenuOpened, setMenuOpened] = React.useState(false);
	
	const toggleDrawer = (value?: boolean) => {
		if (value === undefined) value = !isMenuOpened;
		setMenuOpened(value);

		if (value) {
			const floatPanel = document.querySelector('.left-panel-float .MuiBackdrop-root') as HTMLElement;
			if (floatPanel) {
				floatPanel.click();
			}
		}
	}

	const onClick = (callback: ()=>void) => {
		callback();
		toggleDrawer(false);
	}

	const linksSx: SxProps = {
		'& + &': {
			mt: theme.spacing(1),
		},

		'.MuiButtonBase-root': {
			background: theme.palette.grey[50],

			'&:hover': {
				background: theme.palette.grey[100],
			},
		},
	};

	const [anchorProfiles, setAnchorProfiles] = React.useState<null | HTMLElement>(null);
	const openProfiles = (e: React.MouseEvent<HTMLElement>) => {
		setAnchorProfiles(e.currentTarget);
		onLoadProfiles();
	}
	const closeProfiles = () => {
		setAnchorProfiles(null);
	}

	return (<>
		<ButtonIconGrey
			sx={{
				'.MuiSvgIcon-root': {
					width: theme.spacing(3),
					height: theme.spacing(3),
				},
			}}
			onClick={()=>toggleDrawer()}
		>
			<MenuIcon />
		</ButtonIconGrey>
		<SwipeableDrawer
			anchor={'left'}
			open={isMenuOpened}
			onClose={()=>toggleDrawer(false)}
			onOpen={()=>toggleDrawer(true)}
			sx={{
				'.MuiDrawer-paper': {
					minWidth: theme.spacing(42), // same as VideoLibrary
					maxWidth: theme.spacing(42),
					height: `calc(100% - ${theme.spacing(4)})`,
					m: theme.spacing(2),
					p: theme.spacing(2),
					borderRadius: theme.spacing(.75),

					[theme.breakpoints.up('xl')]: {
						minWidth: theme.spacing(49),
						maxWidth: theme.spacing(49),
					}
				},
				'.MuiBackdrop-root': {
					background: alpha(theme.palette.grey[900], .2),
				},
				'.mobile & .MuiDrawer-paper': {
					minWidth: theme.spacing(40),
					height: '100%',
					m: 0,
					p: theme.spacing(1),
					borderRadius: 0,
				},
			}}
		>
			<Stack
				height={'100%'}
				justifyContent={'space-between'}
				gap={theme.spacing(2)}
			>
				<Stack
					gap={theme.spacing(2)}
				>
					<Stack
						direction={'row'}
						alignItems={'center'}
						gap={theme.spacing(1)}
					>
						<ButtonIconGrey
							sx={{
								'.MuiSvgIcon-root': {
									width: theme.spacing(3),
									height: theme.spacing(3),
								},
							}}
							onClick={()=>toggleDrawer()}
						>
							<CloseIcon />
						</ButtonIconGrey>
						<IconButton
							sx={{ p: 0, }} 
							onClick={onClickHome}
						>
							<LogoLabel sx={{ height: theme.spacing(4), width: 'auto' }} />
						</IconButton>
					</Stack>

					<List
						disablePadding
						sx={{
							'.MuiButtonBase-root': {
								px: theme.spacing(1),
								borderRadius: theme.spacing(.75),

								'.MuiListItemIcon-root': {
									flex: `0 0 ${theme.spacing(3)}`,
									minWidth: theme.spacing(3),
									display: 'flex',
									justifyContent: 'center',
									mr: theme.spacing(1.25),
									color: theme.palette.grey[400],

									'.MuiSvgIcon-root': {
										width: theme.spacing(3),
										height: theme.spacing(3),
									}
								},

								'&:hover': {
									background: theme.palette.grey[50],
								},

								'&.Mui-selected': {
									'.MuiListItemIcon-root': {
										color: theme.palette.primary[400],
									}
								},
							},
							'.MuiListItemText-primary': {
								fontSize: theme.typography.h6,
								fontWeight: 600,
							},
						}}
					>
						<ListItem disablePadding>
							<ListItemButton onClick={()=>onClick(onClickHome)} selected={active===EMainMenuActive.HOME}>
								<ListItemIcon>
									<HomeIcon/>
								</ListItemIcon>
								<ListItemText primary="Home" />
							</ListItemButton>
						</ListItem>
						{isMobile &&
							<ListItem disablePadding>
								<ListItemButton onClick={()=>onClick(onClickPlaylists)} selected={active===EMainMenuActive.PLAYLISTS}>
									<ListItemIcon>
										<ListIcon/>
									</ListItemIcon>
									<ListItemText primary="Playlists" />
								</ListItemButton>
							</ListItem>
						}

						<ListItem disablePadding>
							<ListItemButton onClick={()=>onClick(onClickContent)} selected={active===EMainMenuActive.CONTENT_LIBRARY}>
								<ListItemIcon>
									<BookIcon />
								</ListItemIcon>
								<ListItemText primary="Content Library" />
							</ListItemButton>
						</ListItem>

						{!isMobile &&
							<ListItem disablePadding>
								<ListItemButton onClick={()=>onClick(onClickChatGpt)} selected={active===EMainMenuActive.CHATGPT_PROMPTS}>
									<ListItemIcon>
										<ChatGptIcon sx={{color: 'white'}} />
									</ListItemIcon>
									<ListItemText primary="ChatGPT Prompts" />
								</ListItemButton>
							</ListItem>
						}
						{!isMobile &&
							<ListItem disablePadding>
								<ListItemButton onClick={()=>onClick(onClickPhrases)} selected={active===EMainMenuActive.SAVED_PHRASES}>
									<ListItemIcon>
										<BookmarkIcon />
									</ListItemIcon>
									<ListItemText primary={SAVED_PHRASES_PANEL_TITLE} />
								</ListItemButton>
							</ListItem>
						}
						<ListItem disablePadding>
							<ListItemButton onClick={()=>onClick(onClickHelp)} selected={active===EMainMenuActive.HELP}>
								<ListItemIcon>
									<QuestionIcon />
								</ListItemIcon>
								<ListItemText primary="Help Center" />
							</ListItemButton>
						</ListItem>

						<Divider />

						{!isMobile &&
							<ListItem disablePadding
								sx={linksSx}
							>
								<ListItemButton
									onClick={()=>onClick(onExtension)}
								>
									<ListItemIcon>
										<ChromeStoreIcon />
									</ListItemIcon>
									<ListItemText primary="Chrome Extension" />
								</ListItemButton>
							</ListItem>
						}

						<ListItem disablePadding
							sx={linksSx}
						>
							<ListItemButton
								onClick={()=>onClick(onTelegram)}
							>
								<ListItemIcon>
									<TelegramFilledBlueIcon />
								</ListItemIcon>
								<ListItemText primary="Telegram Bot" />
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding
							sx={{
								mt: theme.spacing(1),
							}}
						>
							<ListItemButton onClick={()=>onClick(onClickFeedback)} selected={active===EMainMenuActive.HELP}>
								<ListItemIcon>
									<CommentIcon
										sx={{
											width: `${theme.spacing(2.5)} !important`,
											height: `${theme.spacing(2.5)} !important`,
										}}
									/>
								</ListItemIcon>
								<ListItemText primary="Send Feedback" />
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding
							sx={{
								mt: theme.spacing(1),

								'.MuiButtonBase-root': {
									background: anchorProfiles ? theme.palette.grey[50] : '',
								},
							}}
						>
							<ListItemButton
								onClick={openProfiles}
							>
								<ListItemIcon>
									<UsersIcon />
								</ListItemIcon>
								<ListItemText primary={'Public profiles'} />

								{isLoadingProfiles
									? <CircularProgress
										size={theme.spacing(3)}
									/>
									: <ArrowIcon
										sx={{
											color: theme.palette.grey[400],
										}}
									/>
								}
								
							</ListItemButton>
						</ListItem>

						{!isMobile &&
							<ListItem disablePadding
								sx={{
									mt: theme.spacing(1),
								}}
							>
								<ListItemButton onClick={()=>onClick(onClickStatistics)}>
									<ListItemIcon>
										<ChartIcon />
									</ListItemIcon>
									<ListItemText primary="Show Statistics" />
								</ListItemButton>
							</ListItem>
						}

						
					</List>
					
				</Stack>
				<Stack
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
					gap={theme.spacing(1)}
				>
					<Stack
						direction={'row'}
						gap={theme.spacing(1)}
					>
						<AccountButtonHOC
							closeMenu={()=>toggleDrawer(false)}
						/>
						
					</Stack>
					<Box
						sx={{
							fontSize: theme.typography.subtitle2,
							opacity: .5,
						}}
					>
						LangMagic
						{version && <>
							{' '} v{version}
						</>}
					</Box>
				</Stack>
			</Stack>
		</SwipeableDrawer>

		{isMobile
			? <Modal
				isMobile
				open={!isLoadingProfiles && !!anchorProfiles}
				onClose={closeProfiles}
				PaperProps={{
					sx: {
						'& > .MuiStack-root': {
							gap: 0,
						},
					},
				}}
			>
				<ProfilesListHOC />
			</Modal>
			: <MenuMUI
				anchorEl={anchorProfiles}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				open={!isLoadingProfiles && !!anchorProfiles}
				onClose={closeProfiles}
				sx={{
					ml: theme.spacing(-2),

					'.MuiMenu-list': {
						display: 'flex',
						flexDirection: 'column',
						gap: theme.spacing(.5),
						maxWidth: theme.spacing(40),
					},
				}}
			>
				<ProfilesListHOC />
			</MenuMUI>
		}
	</>);
};