import { ICaptionsItem, PlayerStates } from '../../../types/common';
import { PlayerFactory } from '../player-factory';
import { IPlayerManagerListener } from './playerManagerListener';
import { IPlayerStateChangeListener, YtUtils } from '../yt-utils';
import { PlayerController } from './playerController';
import { IPlayerApiProps } from '../player-api-context';

export class PlayerManager implements IPlayerStateChangeListener {

  private static instance: PlayerManager;

  public static getInstance(): PlayerManager {
    if (!PlayerManager.instance)
      PlayerManager.instance = new PlayerManager();
    return PlayerManager.instance;
  }

  private targetCaptions: ICaptionsItem[];
  private nativeCaptions: ICaptionsItem[];
  private targetIndex: number;
  private nativeIndex: number;
  private timer: number;
  private listeners: IPlayerManagerListener[] = [];
  private processTimer;
  private playLastStartTime: number = 0;
  private pauseLastStartTime: number = 0;

  constructor() {
    YtUtils.stateChangeListeners.push(this);
    this.startProcess();
  }

  private stopProcess() {
    this.timer = 0;
    if (this.processTimer) {
      clearInterval(this.processTimer);
      this.processTimer = null;
    }
  }

  private startProcess() {
    this.processTimer = setInterval(this.checkProcess.bind(this), 50);
   // setTimeout(this.runProcess.bind(this), 500);
  }

  /*private runProcess() {
    this.processTimer = setInterval(this.checkProcess.bind(this), 50);
  }*/

  public addListener(listener: IPlayerManagerListener) {
    this.listeners.push(listener);
  }

  public removeListener(listener: IPlayerManagerListener) {
    const index = this.listeners.findIndex(l => l === listener);
    if (index >= 0) {
      this.listeners.splice(index, 1);
    }
  }

  public setTargetIndex(targetIndex: number) {
    this.targetIndex = targetIndex;
  }

  public setTargetCaptions(targetCaptions: ICaptionsItem[]) {
    this.targetCaptions = targetCaptions;
  }
  public getTargetCaptions(): ICaptionsItem[] {
    return this.targetCaptions;
  }

  public setNativeCaptions(nativeCaptions: ICaptionsItem[]) {
    this.nativeCaptions = nativeCaptions;
  }

  public setTimer(time: number) {
    this.timer = time;
  }

  public async startPlay(time?: number) {
    this.stopProcess();
    const playerApi = await PlayerFactory.getPlayerApi();

    this.playLastStartTime = new Date().getTime();
    if (time !== undefined) {
      await this.waitStartPlay(time, playerApi);
    } else {
      await playerApi.playVideo();
    }

    this.startProcess();
  }

  private async waitStartPlay(targetTime: number, playerApi: IPlayerApiProps): Promise<null> {
    const firstTime = await playerApi.player.getCurrentTime();
    const moveForward = targetTime >= firstTime;

    await playerApi.seekVideoTo(targetTime);
    await playerApi.playVideo();

    return new Promise(resolve => {
      const timer = setInterval(async () => {
        const currentTime = await playerApi.player.getCurrentTime();
        if (moveForward) {
          if (currentTime >= targetTime) {
            clearInterval(timer);
            resolve();
          }
        } else {
          if (currentTime < firstTime) {
            clearInterval(timer);
            resolve();
          }
        }

      }, 50)
    })

  }

  public async onNativePlayClicked() {
    this.stopProcess();
    this.playLastStartTime = new Date().getTime();
    this.startProcess();
  }

  public async pausePlay() {
    const playerApi = await PlayerFactory.getPlayerApi();
    await playerApi.pauseVideo();
    this.pauseLastStartTime = new Date().getTime();
    this.stopProcess();
  }

  public async onNativePauseClicked() {
    this.pauseLastStartTime = new Date().getTime();
  }

  public async getActiveTargetCaptionIndex(): Promise<number> {
    const playerApi = await PlayerFactory.getPlayerApi();
    const currentTime = await playerApi.player.getCurrentTime();
    return this.getActiveCaptionIndex(currentTime, this.targetCaptions);
  }

  public async getCurrentTime(): Promise<number> {
    const playerApi = await PlayerFactory.getPlayerApi();
    return await playerApi.player.getCurrentTime();
  }

  private async checkProcess() {
    const playerApi = await PlayerFactory.getPlayerApi();
    const currentTime = await playerApi.player.getCurrentTime();
    if (this.timer) {
      if (currentTime >= this.timer) {
        this.timer = 0;
        this.listeners.forEach(l => {
          if (l.onTimerDone) {
            l.onTimerDone();
          }
        });
      }
    }
    if (this.targetCaptions) {
      const targetIndex = this.getActiveCaptionIndex(currentTime, this.targetCaptions);
      if (this.targetIndex !== targetIndex) {
        this.targetIndex = targetIndex;
        this.listeners.forEach(l => {
          if (l.onTargetIndexChange) {
            l.onTargetIndexChange(this.targetIndex);
          }
        });
      }
    }
    if (this.nativeCaptions) {
      const nativeIndex = this.getActiveCaptionIndex(currentTime, this.nativeCaptions);
      if (this.nativeIndex !== nativeIndex) {
        this.nativeIndex = nativeIndex;
        this.listeners.forEach(l => {
          if (l.onNativeIndexChange) {
            l.onNativeIndexChange(this.nativeIndex)
          }
        });
      }
    }
  }

  private getActiveCaptionIndex(currentTime: number,
                                captions: ICaptionsItem[]): number {
    for(let i=0; i<captions.length; i++) {
      const startTime = captions[i].startTime;
      let endTime = captions[i].endTime;
      if (i < captions.length - 1) {
        if (endTime > captions[i+1].startTime) {
          endTime = captions[i+1].startTime;
        }
      }
      if (currentTime >= startTime && currentTime < endTime) {
        return i;
      }
    }
    return -1;
  }

  public onStateChange(playerState: PlayerStates) {
    if (playerState === PlayerStates.PLAYING) {
      const period = new Date().getTime() - this.playLastStartTime;
      if (period > 1500) {
        PlayerController.getInstance().onNativePlayClicked();
      }
    } else if (playerState === PlayerStates.PAUSED) {
      const period = new Date().getTime() - this.pauseLastStartTime;
      if (period > 1500) {
        PlayerController.getInstance().onNativePauseClicked();
      }
    }

  }
}


