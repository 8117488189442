import { SyncStorage } from './local-storage-helpers';

export class ProfileStorage extends SyncStorage {

	private static LS_OPEN_TEACHER_ANNOTATIONS = 'open_teacher_annotations';

	public static getOpenTeacherAnnotations(): number {
		return Number(window.localStorage.getItem(this.LS_OPEN_TEACHER_ANNOTATIONS));
	}

	public static setOpenTeacherAnnotations(value: number) {
		return window.localStorage.setItem(this.LS_OPEN_TEACHER_ANNOTATIONS, String(value));
	}

}
