import * as React from 'react';
import { MenuItem, Select } from '@mui/material';
import { IPlayListLevelSelectProps, PlayListLevelTitles } from './types';
import { EUserLevel } from '../../../../store/general/types';


export const PlayListLevelSelect: React.FC<IPlayListLevelSelectProps> = ({
  value,
  onChange
}) => {

  const levels: EUserLevel[] = Object.keys(PlayListLevelTitles) as any;

  const handleChange = ({ target }: any) => {
    onChange(target.value);
  };

  return (
    <Select
      variant={'filled'}
      onChange={handleChange}
      value={value}
      fullWidth
    >
      {levels.map((level) => {
        return (
          <MenuItem
            key={level}
            value={level}
          >
            {PlayListLevelTitles[level]}
          </MenuItem>
        )
      })}
    </Select>
  );
};