import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { OneTimeTooltipNativeAvailableEvents } from './events';
import { EOneTimeTooltipType, OneTimeTooltipTextProvider } from '../oneTimeTooltipTextProvider';
import { getMainViewMode, getOneTimeTooltip, getShowNativeCaptions } from '../../../../../store/general/selectors';
import { OneTimeTooltipArrow } from '../../components/OneTimeTooltipArrow/OneTimeTooltipArrow';
import { IOneTimeTooltipArrowEvents, IOneTimeTooltipArrowFields } from '../../components/OneTimeTooltipArrow/types';
import { getSelectedNativeCaption } from '../../../../../store/current-video/selectors';
import { EMainViewMode } from '../../../../../store/general/types';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IOneTimeTooltipArrowFields => {
		const oneTimeTooltips = getOneTimeTooltip(state);
		const nativeLang = getSelectedNativeCaption(state)?.code;

		return {
			isCloseOutside: true,
			show: oneTimeTooltips.showNativeAvailableTooltip &&
				!oneTimeTooltips.showTutorialTooltip &&
				!oneTimeTooltips.showTutorialPopup &&
				!getShowNativeCaptions(state) &&
				getMainViewMode(state) !== EMainViewMode.PHRASES &&
				!!nativeLang,
			sx: {
				minWidth: '260px',
				width: '260px',
			},
			text: OneTimeTooltipTextProvider.getText(EOneTimeTooltipType.NATIVE_AVAILABLE),
			targetSelector: '.playback-settings-button',
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IOneTimeTooltipArrowEvents => ({
	onClose: () => dispatch(OneTimeTooltipNativeAvailableEvents.onClose()),
});

export const OneTimeTooltipNativeAvailableHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(OneTimeTooltipArrow);

OneTimeTooltipNativeAvailableHOC.displayName = 'OneTimeTooltipNativeAvailableHOC';