import { EVocabularyPhraseType, ICaptionsItem } from '../../../../../types/common';
import { AppThunk } from '../../../../../store/types';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { EShortcutsListenerContainer, setShortcutsListenerContainer } from '../../../../../effects/shortcutsListener';
import { CaptionsSelectionPopupActions } from '../../../../../store/captions-selection-popup/actions';
import { getSelectedNativeCaption, getSelectedTargetCaption } from '../../../../../store/current-video/selectors';
import {
  findVideoPhrasesByType,
  getTargetCaptions,
  getVideoPhraseNoteByCaption
} from '../../../../../store/videos/selectors';
import { LangUtil } from '../../../../../../common/utils/lang-util';
import { PhraseSelectPreparator } from '../../../../../effects/phrase/utils/phrase-select-preparator';
import { getActiveGroupNativeLanguage, getPhraseDetailsTabByType } from '../../../../../store/models/selectors';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { TextSelectionPopupEvents } from '../../../TextSelectionPopup/hocs/events';
import { SelectionUtil } from '../../../../../../common/utils/selection-util';
import { SelectionPopupEffects } from '../../../../../effects/selectionPopupEffects';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { AudioPlayerManager } from '../../../../../effects/audioPlayer/audioPlayerManager';
import { batch } from 'react-redux';
import { setCurrentVideoClickedCaptionIndex } from '../../../../../store/current-video/actions';
import { PhraseListActions } from '../../../../../store/phrase-list/actions';

export class TargetAudioCaptionsEvents {

  public static onCaptionPlay(caption: ICaptionsItem, isPause?: boolean,): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (isPause) {
        AudioPlayerManager.getInstance().pause();
      } else {
        AudioPlayerManager.getInstance().play(caption.startTime);
      }
      setShortcutsListenerContainer(EShortcutsListenerContainer.CAPTIONS);
    }
  }

  public static onCaptionMouseDown(): AppThunk {
    return (
      dispatch,
      getState
    ) => {

      if (AudioPlayerManager.getInstance().isPlayerActive()) {
        AudioPlayerManager.getInstance().pause();
      }
    }
  }

  public static onMouseLeave(): AppThunk {
    return (
      dispatch,
      getState
    ) => {

    }
  }

  public static onMouseEnter(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
    }
  }

  public static onSelectText(
    selection: Selection,
    element: HTMLElement,
    isSelectPopup?: boolean,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      dispatch(CaptionsSelectionPopupActions.setShow(true));

      const targetLanguage = getSelectedTargetCaption(state);
      const nativeLanguage = getSelectedNativeCaption(state);

      const wordSelectPhrases = findVideoPhrasesByType(state, EVocabularyPhraseType.WORD_SELECTED);
      const contextPhrases = findVideoPhrasesByType(state, EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED);
      const useCheckSpanWords = !LangUtil.isHieroglyphLang(targetLanguage?.code || '');
      const selResult = new PhraseSelectPreparator(selection, getTargetCaptions(state), wordSelectPhrases, contextPhrases, element, useCheckSpanWords).execute();

      batch(() => {
        dispatch(CaptionsSelectionPopupActions.setShow(true));
        dispatch(CaptionsSelectionPopupActions.setSelection(selection));
        dispatch(CaptionsSelectionPopupActions.setSelectResult(selResult));
        dispatch(setCurrentVideoClickedCaptionIndex(-1));
        dispatch(PhraseListActions.setPlayCaptionByPhraseId(0));
        dispatch(PhraseListActions.setCurrentPhraseId(0));
        dispatch(PhraseListActions.setPlayPhraseId(0));
        dispatch(PhraseDetailsActions.disActivate());
      })

      if (targetLanguage && selResult && selResult.text) {
        if (selResult.text) {
          const activeGroupNativeLang = getActiveGroupNativeLanguage(state);
          if (selResult.insideWordActiveId) {
            PhraseDetailsEffects.showFromPhrase(targetLanguage, nativeLanguage, activeGroupNativeLang,
              selResult.insideWordActiveId, selResult.text);
          } else {
            PhraseDetailsEffects.showFromSelectText(targetLanguage, nativeLanguage, activeGroupNativeLang, selResult);
          }

          await AudioPlayerManager.getInstance().pause();
        }
      }

      if (selResult && isSelectPopup) {
        dispatch(TextSelectionPopupEvents.onPreviewPhrase(selResult));
      }
    }
  }

  public static onDismissSelectText(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (document.body.classList.contains('onboarding-active')) return;

      SelectionUtil.removeWindowSelection();
      SelectionPopupEffects.dismissSelectText();
    }
  }

  public static onChangeActiveIndex(index: number) : AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const state = getState();
      const captions = getTargetCaptions(state);
      if (index >= 0 && index < captions.length) {
        dispatch(PhraseDetailsActions.setPhraseId(0));
        const note = getVideoPhraseNoteByCaption(state, captions[index]);
        if (note) {
          const noteTab = getPhraseDetailsTabByType(state, EPhraseDetailsTabType.NOTES);
          if (noteTab) {
            dispatch(PhraseDetailsActions.setActiveTab(noteTab));
            dispatch(PhraseDetailsActions.setNotePreviewMode(true));
            if (note.pause) {
              PlayerController.getInstance().pause();
              dispatch(PhraseDetailsActions.setPhraseId(note.phraseId));
            }
          }
        } else {
          if (PhraseDetailsSelectors.getNotePreviewMode(state)) {
            const translateTab = getPhraseDetailsTabByType(state, EPhraseDetailsTabType.TRANSLATION);
            if (translateTab) {
              dispatch(PhraseDetailsActions.setActiveTab(translateTab));
            }
            dispatch(PhraseDetailsActions.setNotePreviewMode(false));
          }
        }
      }
    }
  }

}
