import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PublicProfile } from '../components/PublicProfile';
import { IPublicProfileEvents, IPublicProfileFields } from '../components/types';
import { PublicProfileEvents } from './events';
import { UrlUtil } from '../../../../../../common/utils/url-util';
import { getAuthUser } from '../../../../../store/general/selectors';
import { PublicProfileSelectors } from '../../../../../store/public-profile/selector';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState,
	): IPublicProfileFields => {
		const userName = UrlUtil.getRouteProfileNick();
		const {loading, profile, groups, notes} = PublicProfileSelectors.getData(state);
		const user = getAuthUser(state);
		const canEdit = user && user?.id === profile?.userId;

		return {
			loading,
			canEdit,
			profile,
			userName,
			groups,
			notes
		}
	};
};

const mapDispatchToProps = (
	dispatch: any
): IPublicProfileEvents => ({
	onClickLogo: () => dispatch(PublicProfileEvents.onClickLogo()),
	onLoad: (nick) => dispatch(PublicProfileEvents.onLoad(nick)),
	onClickBack: () => dispatch(PublicProfileEvents.onClickBack()),
	onUnload: () =>  dispatch(PublicProfileEvents.onUnLoad()),
	onCopyPublicLink: nick => dispatch(PublicProfileEvents.onCopyPublicLink(nick))
});

export const PublicProfileHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(PublicProfile);

PublicProfileHOC.displayName = 'PublicProfileHOC';
