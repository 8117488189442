import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPhraseDetailsTabPromptTestEvents, IPhraseDetailsTabPromptTestFields } from '../components/types';
import { PhraseDetailsTabPromptTest } from '../components/PhraseDetailsTabPromptTest';
import { PhraseDetailsTabPromptTestEvents } from './events';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { GptUtils } from '../../../../../effects/gptUtils';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabPromptTestFields => {
    const prompt = PhraseDetailsTabsEditorPopupSelectors.getPromptTestPrompt(state);
    let phrasePreview = PhraseContextEditorSelectors.getPhraseText(state);
    let phrase = phrasePreview && phrasePreview.id < 0 && phrasePreview?.highlighted || '';
    let context = PhraseContextEditorSelectors.getPhraseContext(state)?.fullPhrase || PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state) || '';
    let isNoPhrase;
    if (!phrase) {
      isNoPhrase = true;
      const firstCaptionAsPhrase = PhraseDetailsTabsEditorPopupSelectors.getFirstCaptionAsPhrase(state);
      if (firstCaptionAsPhrase && firstCaptionAsPhrase.text) {
        phrase = firstCaptionAsPhrase.text;
        context = firstCaptionAsPhrase.context || '';
      }
    }
    return {
      show: !!prompt,
      prompt,
      result: GptUtils.formatResult(PhraseDetailsTabsEditorPopupSelectors.getPromptTestResult(state) || ''),
      processing: PhraseDetailsTabsEditorPopupSelectors.isPromptTestProcessing(state),
      phrase,
      context,
      isNoPhrase,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabPromptTestEvents => ({
    onTest: () => dispatch(PhraseDetailsTabPromptTestEvents.onTest()),
    onBack: () => dispatch(PhraseDetailsTabPromptTestEvents.onBack()),
    onClickContext: () => dispatch(PhraseDetailsTabPromptTestEvents.onClickContext()),
  }
);

export const PhraseDetailsTabPromptTestHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabPromptTest);

PhraseDetailsTabPromptTestHOC.displayName = 'PhraseDetailsTabPromptTestHOC';