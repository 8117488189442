import { IPlayerApiProps } from './player-api-context';
import { IPlayerApiPropsImpl } from './yt-utils';
import { ytElementId } from '../../types/common';

export class PlayerFactory {

  private static inited = false;
  private static playerApiPromise: Promise<IPlayerApiProps>;
//  private static changePositionListeners: ChangePositionListener[] = [];

  public static async create(
    videoId: string,
    onStateChange: (event: any) => void,
    onPlaybackRateChange: (event: any) => void
  ) {

    const _onStateChange = (event: any) => {
      onStateChange(event);
    }

    if (!PlayerFactory.inited) {
      await PlayerFactory.init();
      PlayerFactory.inited = true;
    }
    PlayerFactory.playerApiPromise = PlayerFactory.createPlayer(videoId, _onStateChange, onPlaybackRateChange);

    /*let lastPlayerPos = 0;
    setInterval(() => {
      PlayerFactory.playerApiPromise.then(playerApi => {
        const currentPlayerPos = playerApi.player.getCurrentTime();
        if (Math.abs(currentPlayerPos - lastPlayerPos) > 1.0) {
          PlayerFactory.changePositionListeners.forEach(listener => {
            listener();
          })
        }
        lastPlayerPos = currentPlayerPos;

      })
    }, 500);*/

    return await PlayerFactory.playerApiPromise;
  }

 /* public static addChangePositionListener(listener: ChangePositionListener) {
    PlayerFactory.changePositionListeners.push(listener);
  }

  public static removeChangePositionListener(listener: ChangePositionListener) {
    PlayerFactory.changePositionListeners =
      PlayerFactory.changePositionListeners.filter((lst) => lst !== listener);
  }*/

  public static async getPlayerApi(): Promise<IPlayerApiProps> {
    //return await PlayerFactory.playerApiPromise;
    if (PlayerFactory.playerApiPromise) {
      return PlayerFactory.playerApiPromise;
    }
    return new Promise<IPlayerApiProps>((resolve) => {
      const timer = setInterval(() => {
        if (PlayerFactory.playerApiPromise) {
          PlayerFactory.playerApiPromise.then(pApi => {
            if (pApi) {
              clearInterval(timer);
              resolve(pApi);
            }
          })
        }
      }, 100);
    })

  }

  private static async init(): Promise<void> {
    return new Promise((resolve) => {
      if (window.YT) return resolve();

      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      tag.id = 'yt_iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode && firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = async() => {
        resolve();
      };
    });
  };

  private static async createPlayer(
    videoId: string,
    onStateChange: (event: any) => void,
    onPlaybackRateChange: (event: any) => void
  ): Promise<IPlayerApiProps> {
    return new Promise((resolve) => {
      const youTubePlayer: any = new window.YT.Player(ytElementId, {
        height: 390,
        width: 640,
        videoId: videoId,
        playerVars: {
          autoplay: 0,
          controls: 1,
          disablekb: 1
        },
        events: {
          onReady: () => {
            const player = PlayerFactory.createPlayerApi(youTubePlayer);
            resolve(player);
          },
          onStateChange: onStateChange,
          onPlaybackRateChange: onPlaybackRateChange,
        } as any
      });
      window.player = youTubePlayer;
    });
  };

  private static createPlayerApi = (player: any): IPlayerApiProps => {
    const playerApi: IPlayerApiPropsImpl = {
      //lastPlayTime: 0,
      playVideo: () => {
        //playerApi.lastPlayTime = new Date().getTime();
        player.playVideo();
      },
      pauseVideo: () => {
        player.pauseVideo();
        /*if (checkPlayPeriod) {
          if (new Date().getTime() - playerApi.lastPlayTime >= 500) {
            // костыль фиксит глюк на autopause когда pauseVideo иногда вызывается сразу после playVideo
            player.pauseVideo();
          }
        } else {
          player.pauseVideo();
        }*/
      },
      stopVideo: () => {
        player.stopVideo();
      },
      seekVideoTo: (time: number) => {
        player.seekTo(time, true);
      },
      getPlaybackRate: () => player.getPlaybackRate(),
      setPlaybackRate: (speed: number) => {
        player.setPlaybackRate(speed);
      },
      playPauseVideo: () => {
        if (player.getPlayerState() === 1) {
          player.pauseVideo();
        } else {
          player.playVideo();
        }
      },
      player
    }
    return playerApi;
  };
}