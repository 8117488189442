import React from 'react';
import { theme } from '../../../../../common/styles/theme';
import { Button } from '@mui/material';
import { Modal } from '../../../../../common/components/Modal/Modal';
import { IPhraseSelectPopupProps } from './types';
import { TargetCaptionsHOC } from '../../CaptionsContainer/TargetCaptions/hocs/TargetCaptionsHOC';


export const PhraseSelectPopup: React.FC<IPhraseSelectPopupProps> = ({
  show,
  phrase,
  onClose,
  onSave
}) => {

  if (!show) return null;

  return (
    <Modal
      onClose={onClose}
      open={true}
      title={'Select phrase'}
      PaperProps={{
        sx: {
          '& > .MuiPaper-root': {
            maxWidth: '500px',
  
            '.captions-parent': {
              height: '100%',
              maxHeight: `calc(90vh - ${theme.spacing(17)})`,
              pr: theme.spacing(2),
            },
  
            '.caption-item__play': {
              display: 'none',
            },
          },
        }
      }}
    >
        
          <TargetCaptionsHOC
            isSelectPopup
            onChangeActiveIndex={(i)=>{}}
            onCaptionMouseEnter={()=>{}}
            onCaptionMouseLeave={()=>{}}
            onScrolling={()=>{}}
          />
          <Button
            variant={'contained'}
            disabled={!phrase}
            sx={{
              '&.Mui-disabled': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
                opacity: .3,
              },
            }}
            onClick={onSave}
          >
            Apply
          </Button>
    </Modal>
  );

}
