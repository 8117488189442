import { LangUtil } from './lang-util';
import { IPhraseSelectResult, PhraseSelectPreparator } from '../../spa/effects/phrase/utils/phrase-select-preparator';

export class SnapWordChecker {

  public static getSelectText(selection: Selection | null, text: string, langCode?: string): string {

    let selectionText = selection?.toString()?.trim();
    if (!selectionText) return '';

    selectionText = selectionText.replace(/[\n\r]/gm, ' ');
    if (!selectionText) return '';

    const fullText = text.replace(/[\n\r]/gm, ' ');
    const startPosition = fullText.indexOf(selectionText);
    const useCheckSpanWords  = !langCode || !LangUtil.isHieroglyphLang(langCode);
    if (useCheckSpanWords) {
      if (startPosition >= 0) {
        const result: IPhraseSelectResult = {
          elementId: undefined, endCaptionIndex: 0, endTime: 0, startCaptionIndex: 0, startTime: 0,
          text: selectionText,
          startPosition,
          endPosition: startPosition + selectionText.length
        }

        const ret = new PhraseSelectPreparator(selection, [{
          startTime: 0, endTime: 0, duration: 0, text: fullText
        }], [], [], null, true).checkSpanWords(result);
        return ret.text;
      }
    }
    return '';
  }
}