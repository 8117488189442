import React from 'react';
import { IPhraseDetailsTabItemProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { DragIcon } from '../../../../../../../icons/DragIcon';
import { ConfirmPopup } from '../../../ConfirmPopup/ConfirmPopup';
import { PhraseDetailsTabItemCheckButton } from './PhraseDetailsTabItemCheckButton';
import { Draggable } from 'react-beautiful-dnd';
import { TrashIcon } from '../../../../../../../icons/TrashIcon';
import { PhraseDetailsTabItemTooltips, PhraseDetailsTabItemTooltipType } from './constants';
import { PinIcon } from '../../../../../../../icons/PinIcon';
import { PinEmptyIcon } from '../../../../../../../icons/PinEmptyIcon';

const favoriteTooltip = 'Pin your most used prompt for quick and easy access when you select any phrase';

export const PhraseDetailsTabItem: React.FC<IPhraseDetailsTabItemProps> = ({
  index,
  tabId,
  title,
  text,
  favorite,
  canEdit,
  canDelete,
  showFavoriteTooltip,
  isSelected,
  onEdit,
  onDelete,
  onFavoriteToggle
}) => {
  const [editMenuAnchor, setEditMenuAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [showDeletePrompt, setShowDeletePrompt] = React.useState<boolean>(false);

  const ref = React.useRef(null);

  const CLASS_CONTROLS = 'phrase-details-tab-item-controls';

  const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
    setEditMenuAnchor(e.currentTarget);
    setShowDeletePrompt(true);
  };

  const handleClick = (e: MouseEvent) => {
    if (!canEdit) return;

    const target = e.target as HTMLElement;
    const controls = target.closest(`.${CLASS_CONTROLS}`);
    if (controls) return;

    onEdit();
  }

  const containerStyle = {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: theme.spacing(2),
    mb: theme.spacing(1),
    borderRadius: theme.spacing(0.75),
    border: `${theme.spacing(0.25)} solid ${theme.palette.grey[200]}`,
    bgcolor: canEdit ? theme.palette.common.white : theme.palette.grey[100],
    cursor: (canEdit ? 'pointer' : 'default') + ' !important',
    outline: 'none !important',
    '&:hover, &:focus': {
      borderColor: theme.palette.grey[300]
    },

    '&:hover .drag-icon': {
      opacity: 1,
    },

    '&.selected': {
      borderColor: `${theme.palette.primary.main} !important`,
      background: theme.palette.primary[100],
    },
  };

  const dragStyle = {
    position: 'absolute',
    left: theme.spacing(-2.5),
    top: '50%',
    transform: `translateY(-50%)`,
    color: theme.palette.grey[400],
    opacity: 0,
    pointerEvents: 'none',
  };

  const textStyle = {
    maxWidth: theme.spacing(25),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const descStyle = {
    display: '-webkit-box',
  	overflow: 'hidden',
   	textOverflow: 'ellipsis',
   	'-webkit-line-clamp': '2',
   	'-webkit-box-orient': 'vertical',
    maxHeight: '2.8em',
    lineHeight: 1.4,
    opacity: .5,
  }

  const controlsStyle = {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(.5),
  }

  const iconStyle = {
		width: `${theme.spacing(3)} !important`,
    height: `${theme.spacing(3)} !important`,
	}

  React.useEffect(() => {
    if (isSelected && ref.current) {
      ref.current.scrollIntoViewIfNeeded();
    }
  }, [isSelected]);

  return (
      <Draggable key={tabId} draggableId={''+tabId} index={index}>
        {(provided) => (
          <Box
            className={isSelected ? 'selected' : ''}
            direction='row'
            spacing={theme.spacing(0.5)}
            sx={containerStyle}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            onClick={handleClick}
            ref={ref}
          >
            <Text
                fontWeight={600}
                noWrap
                variant={'subtitle2'}
                sx={textStyle}
            >
              {title}
            </Text>
            <Text sx={descStyle}>{text}</Text>
            {(canEdit) &&
                <Stack
                  className={CLASS_CONTROLS}  
                  direction={'row'}
                  justifyContent={'flex-end'}
                  alignItems={'center'}
                  gap={theme.spacing(1)}
                  sx={controlsStyle}
                >

                  {canDelete &&
                        <>
                          <IconButton
                              onClick={handleDelete}
                              color={'primary'}
                              sx={{
                                color: theme.palette.grey[400],
                                '.MuiSvgIcon-root': { width: theme.spacing(2), height: theme.spacing(2) },
                                '&:hover, &:focus': {
                                  color: theme.palette.error.main,
                                }
                              }}
                          >
                            <TrashIcon />
                          </IconButton>

                          {showDeletePrompt && <ConfirmPopup
                              anchor={editMenuAnchor}
                              text={'Delete prompt?'}
                              onConfirm={onDelete}
                              onClose={() => setShowDeletePrompt(false)}
                          />}
                        </>
                    }
                   {/* <PhraseDetailsTabItemCheckButton active={visible} onClick={onVisibleToggle} tooltip={PhraseDetailsTabItemTooltips[PhraseDetailsTabItemTooltipType.APP]}>
                      <AppIcon sx={{ height: `${theme.spacing(2.75)} !important`, width: `${theme.spacing(3.75)} !important` }} />
                    </PhraseDetailsTabItemCheckButton>

                    <PhraseDetailsTabItemCheckButton active={botVisible} onClick={onBotVisibleToggle} tooltip={PhraseDetailsTabItemTooltips[PhraseDetailsTabItemTooltipType.TELEGRAM]}>
                      <TelegramFilledIcon sx={{ height: theme.spacing(2.75), width: theme.spacing(2.75)  }} />
                    </PhraseDetailsTabItemCheckButton>*/}

                    <Tooltip
                        open={showFavoriteTooltip}
                        title={showFavoriteTooltip ? favoriteTooltip : null}
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: theme.palette.warning[100],
                              color: theme.palette.warning[900],
                              boxShadow: `0 2px 7px 0 rgba(0, 0, 0, 0.15)`,
                              '& .MuiTooltip-arrow': {
                                color: theme.palette.warning[100],
                              },
                            },
                          },
                        }}
                    >
                      <Stack>
                        <PhraseDetailsTabItemCheckButton active={favorite} onClick={onFavoriteToggle} tooltip={PhraseDetailsTabItemTooltips[PhraseDetailsTabItemTooltipType.FAVORITE]}>
                          {favorite ? <PinIcon sx={iconStyle} />
                              : <PinEmptyIcon sx={iconStyle} />}
                        </PhraseDetailsTabItemCheckButton>
                      </Stack>
                    </Tooltip>

                </Stack>
            }

           <DragIcon
            className={'drag-icon'}
            sx={dragStyle}
            />
          </Box> 
        )}
      </Draggable>
  );
}


