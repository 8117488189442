import { Events, IEvent, IEventData, IEventsProvider } from '../types';
import { TAuthUser } from '../../../spa/store/general/types';

export class FbqEventsProvider implements IEventsProvider {

  private static EVENTS: IEvent[] = [Events.NEW_USER_SIGNED_UP];

  public setUser(user: TAuthUser): void {
  }

  public trackEvent(event: IEvent, date: Date, data: IEventData): void {
    // @ts-ignore
    if (window.fbq) {
      if (this.isValidEvent(event)) {
        const eventName = event.subject + ': ' + event.action;
        // @ts-ignore
        window.fbq('track', eventName);
      }
    }
  }

  private isValidEvent(event: IEvent): boolean {
    return FbqEventsProvider.EVENTS.some(e => {
      return this.isEqEvents(event, e);
    })
  }

  private isEqEvents(e1: IEvent, e2: IEvent) {
    return e1.subject === e2.subject && e1.action === e2.action;
  }

}