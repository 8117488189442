import { AppThunk } from '../../../../../store/types';
import { ContentLibEffects } from '../../../../../effects/contentLibEffects';
import { setMainViewMode, setPublicPageAction } from '../../../../../store/general/actions';
import { EMainViewMode } from '../../../../../store/general/types';

export class ContentLibraryDashboardEvents {

  public static onLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setPublicPageAction(true));
      await ContentLibEffects.loadLangs();
    }
  }

  public static onUnLoad(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setPublicPageAction(false));
      dispatch(setMainViewMode(EMainViewMode.DASHBOARD));
    }
  }


}