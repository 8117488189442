const md5 = require('md5');

export class PhraseDetailsCache {

  private static MAX_SIZE = 300;
  private static cache: Map<string, string> = new Map<string, string>();

  public static put(params: string[], result: string): string {
    if (PhraseDetailsCache.cache.size > PhraseDetailsCache.MAX_SIZE) {
      PhraseDetailsCache.cache.clear();
    }
    const key = PhraseDetailsCache.getKey(params);
    PhraseDetailsCache.cache.set(key, result);
  }

  public static get(params: string[]): string | undefined {
    const key = PhraseDetailsCache.getKey(params);
    return PhraseDetailsCache.cache.get(key);
  }

  private static getKey(params: string[]): string {
    return md5(params.join('|'));
  }
}