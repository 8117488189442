import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { Landing } from '../component/Landing';
import { ILandingEvents, ILandingFields } from '../component/types';
import { LandingEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): ILandingFields => {
    return {}
  };
}

const mapDispatchToProps = (
  dispatch: any
): ILandingEvents => ({
    onDidMount: () => dispatch(LandingEvents.onDidMount()),
    onLogin: (targetLang, nativeLang, path) => dispatch(LandingEvents.onLogin(path))
  }
);

export const LandingHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(Landing);

LandingHOC.displayName = 'LandingHOC';