import { IOneTimeTooltipNativeLanguageButtonEvents } from '../../components/OneTimeTooltipNativeLanguageButton/types';
import { connect } from 'react-redux';
import { OneTimeTooltipNativeLanguageButton } from '../../components/OneTimeTooltipNativeLanguageButton/OneTimeTooltipNativeLanguageButton';
import { OneTimeTooltipNativeLanguageEvents } from './events';

const mapDispatchToProps = (
	dispatch: any
): IOneTimeTooltipNativeLanguageButtonEvents => ({
	onClick: () => dispatch(OneTimeTooltipNativeLanguageEvents.onClickClose()),
});

export const OneTimeTooltipNativeLanguageButtonHOC = connect(
	null,
	mapDispatchToProps
)(OneTimeTooltipNativeLanguageButton);

OneTimeTooltipNativeLanguageButtonHOC.displayName = 'OneTimeTooltipNativeLanguageButtonHOC';