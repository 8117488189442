import { Tab as TabMUI, TabProps, alpha, styled } from "@mui/material";

export const Tab = styled(TabMUI)<TabProps>(({ theme }) => ({
    minHeight: theme.spacing(3.5),
    minWidth: 0,
    padding: theme.spacing(0, 2),
    color: theme.palette.grey[900],
    borderRadius: theme.spacing(0.5),

    '&:hover': {
        background: alpha(theme.palette.common.white, .5),
    },

    '&.Mui-selected': {
        color: theme.palette.grey[900],
        background: theme.palette.common.white,
        boxShadow: theme.shadows[2],
    }
}));
