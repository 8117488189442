import { getDispatch } from '../store';
import { setShowNewFeaturesPopupAction } from '../store/general/actions';
import { VERSION } from '../../common/constants';

export class NewFeaturesChecker {

  private static CURRENT_VERSION_KEY = 'curentVersion';

  public run() {
    setTimeout(this.check.bind(this), 1000);
  }

  private async check() {
    const dispatch = getDispatch();
    const currentVersion = NewFeaturesChecker.getCurrentVersion();
    if (currentVersion && this.isUpdateExist(currentVersion, VERSION)) {
      dispatch(setShowNewFeaturesPopupAction(true));
    }
    this.setCurrentVersion(VERSION);
  }

  static getCurrentVersion(): string {
    return localStorage.getItem(NewFeaturesChecker.CURRENT_VERSION_KEY) || '';
  }

  private setCurrentVersion(version: string) {
    localStorage.setItem(NewFeaturesChecker.CURRENT_VERSION_KEY, version);
  }

  /*private async getUpdateVersionsInfo(): Promise<IUpdateVerionsInfo | undefined> {
    return new Promise<IUpdateVerionsInfo | undefined>(resolve => {
      const listener = async (message: TMessage, sender: chrome.runtime.MessageSender, sendResponse: Function) => {
        if (message.type === EMessageType.SET_UPDATE_VERSIONS_INFO) {
          const updateVerionsInfo: IUpdateVerionsInfo | undefined  = message.payload;
          window.chrome.runtime.onMessage.removeListener(listener);
          resolve(updateVerionsInfo);
        }
        sendResponse({ status: true });
      }

      window.chrome.runtime.onMessage.addListener(listener);
      MessageSender.sendMessage(EMessageType.GET_UPDATE_VERSIONS_INFO);
    })

  }*/

  private isUpdateExist(prevVersion: string, currentVersion: string): boolean {
    const currentVerArr = this.prepareVersionNumbers(currentVersion);
    const prevVerArr = this.prepareVersionNumbers(prevVersion);
    for(let i=0; i<currentVerArr.length; i++) {
      if (currentVerArr[i] < prevVerArr[i]) {
        return false;
      }
      if (currentVerArr[i] > prevVerArr[i]) {
        return true;
      }
    }
    return false;
  }

  private prepareVersionNumbers(version: string): number[] {
    const result: number[] = version.split('.').map(s => Number(s));
    while(result.length < 3) {
      result.push(0);
    }
    return result;
  }
}
