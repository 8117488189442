import { SelectionPopupEffects } from '../../../effects/selectionPopupEffects';
import { getDispatch, getState } from '../../../store';
import { setOnboardingAction } from '../../../store/general/actions';
import { PhraseDetailsActions } from '../../../store/phrase-details/actions';
import { IOnboardingHintSettings } from './types';
import { OnboardingEffects } from '../../../effects/onboardingEffects';
import { getPhraseDetailsTabs } from '../../../store/models/selectors';
import { EPhraseDetailsTabType } from '../../../store/models/types';

export const CLASS_ONBOARDING_ACTIVE = 'onboarding-active';
export const CLASS_ONBOARDING_TRIGGER = 'onboarding-trigger';
export const CLASS_ONBOARDING_HIDE = 'onboarding-hide';
export const CLASS_ONBOARDING_BLUR = 'onboarding-blur';
export const CLASS_ONBOARDING_NOEVENT = 'onboarding-noevent';

export const ONBOARDING_HINTS: IOnboardingHintSettings[] = [
	{
		id: 'step1',
		tooltipProps: {
			placement: 'right',
			PopperProps: {
				modifiers: [
					{
					name: 'offset',
					options: {
						offset: [0, -18],
					},
					},
				],
			},
		},
		highlightSelector: `.${CLASS_ONBOARDING_TRIGGER}--step1`,
		targetSelector: `.video-library-item`,
		text: 'Click any video on&nbsp;this panel to&nbsp;open&nbsp;it',
		title: '🍿 Select a video',
		onShow: onShow1,
	},
	{
		id: 'step2',
		tooltipProps: {
			placement: 'right',
			PopperProps: {
				modifiers: [
					{
					name: 'offset',
					options: {
						offset: [0, 1],
					},
					},
				],
			},
		},
		highlightSelector: `.${CLASS_ONBOARDING_TRIGGER}--step2-highlight`,
		targetSelector: `.${CLASS_ONBOARDING_TRIGGER}--step2`,
		text: 'Select part of subtitles to access translations',
		title: '📝 Highlight a phrase',
		onShow: onShow2,
		onHide: onHide2,
		onBack: onBack2,
	},
	{
		id: 'step3',
		canSkip: true,
		tooltipProps: {
			placement: 'right',
		},
		highlightSelector: `.${CLASS_ONBOARDING_TRIGGER}--step3-highlight`,
		targetSelector: `.${CLASS_ONBOARDING_TRIGGER}--step3`,
		text: 'Here is your prompt’s button output. You can modify it and create your own prompts later in settings.',
		title: '✨ Prompts output',
		onShow: onShow3,
		onBack: onBack3,
	},
	{
		id: 'step4',
		tooltipProps: {
			placement: 'bottom',
		},
		targetSelector: `.${CLASS_ONBOARDING_TRIGGER}--step4`,
		text: 'Click this button to toggle the Prompts panel.',
		title: '✨ More prompts panel',
		onShow: onShow4,
		onHide: onHide4,
		onBack: onBack3,
	},
	{
		id: 'step5',
		canSkip: true,
		tooltipProps: {
			placement: 'right',
		},
		targetSelector: `.${CLASS_ONBOARDING_TRIGGER}--step5`,
		text: 'Quickly access and pin your favorite prompts.',
		title: '✨ More prompts panel',
		onHide: onHide5,
		onNext: onNext5,
		onBack: onBack3,
	},
	{
		id: 'step6',
		canSkip: true,
		tooltipProps: {
			placement: 'bottom',
		},
		highlightSelector: `.${CLASS_ONBOARDING_TRIGGER}--step3-highlight`,
		targetSelector: `.${CLASS_ONBOARDING_TRIGGER}--step6`,
		text: 'Translations of the selected phrase appear on this panel. Add more languages in the translation settings later.',
		title: '🌍 Translations',
		onBack: onBack3,
	},
	{
		id: 'step7',
		tooltipProps: {
			placement: 'right',
		},
		targetSelector: `.${CLASS_ONBOARDING_TRIGGER}--step7`,
		text: 'Click to save selected phrase',
		title: '💾 Save a phrase',
		onShow: onShow7,
		onHide: onHide7,
	},
	{
		id: 'step8',
		canSkip: true,
		tooltipProps: {
			placement: 'bottom',
		},
		highlightSelector: `.${CLASS_ONBOARDING_TRIGGER}--step8-highlight`,
		targetSelector: `.${CLASS_ONBOARDING_TRIGGER}--step8`,
		text: 'All your saved phrases will appear here. Go&nbsp;back to your material anytime.',
		title: '🗂️ Saved phrases',
		onShow: onShow8,
		onBack: onBack3,
	},
	{
		id: 'step9',
		canSkip: true,
		tooltipProps: {
			placement: 'left',
		},
		targetSelector: `.${CLASS_ONBOARDING_TRIGGER}--step9`,
		text: 'Click the pencil icon to modify a saved phrase context.',
		title: '✏️ Edit saved phrase',
	},
];


function toggleElement (selector, blur) {
	let element = document.querySelector(selector);
	if (element) {
		if (blur) {
			element.classList.add(CLASS_ONBOARDING_BLUR)
		} else {
			element.classList.remove(CLASS_ONBOARDING_BLUR);
		}
	}
};

function toggleColumnPhraseDetails (blur = true) {
	toggleElement('.phrase-details-column', blur);
};

function toggleColumnPhrasesList (blur = true) {
	toggleElement('.phrases-list', blur);
};

function toggleColumnVideo (blur = true) {
	toggleElement('.video-column', blur);
};

export function onShow () {
	document.body.classList.add(CLASS_ONBOARDING_ACTIVE);
};

export function onHide () {
	document.body.classList.remove(CLASS_ONBOARDING_ACTIVE);

	const elements = document.querySelectorAll(`.${CLASS_ONBOARDING_BLUR}`);
	if (elements) {
		elements.forEach(item => {
			item.classList.remove(CLASS_ONBOARDING_BLUR);
		});
	}

	toggleColumnVideo(false);
	toggleColumnPhraseDetails(false);
	toggleColumnPhrasesList(false);

	window.removeEventListener('popstate', onPopstate1, false);
	window.removeEventListener('popstate', onPopstate2, false);

	const dispatch = getDispatch();
	dispatch(setOnboardingAction({ isActive: false }));
};

function onPopstate1 () {
	if (location.pathname.includes('/video/')) { // browser forward button
		const dispatch = getDispatch();
		dispatch(setOnboardingAction({ step: 1 }));
	}
};

function onShow1 () {
	window.addEventListener('popstate', onPopstate1, false);

	toggleColumnVideo();
	toggleColumnPhraseDetails();
	toggleColumnPhrasesList();
};

function onPopstate2 () {
	if (!location.pathname.includes('/video/')) { // back browser
		const dispatch = getDispatch();
		dispatch(setOnboardingAction({ step: 0 }));
		dispatch(PhraseDetailsActions.disActivate());
	}
};

let observer;

function onShow2 () {
	window.removeEventListener('popstate', onPopstate1, false);
	window.addEventListener('popstate', onPopstate2, false);

	toggleColumnVideo(false);
	toggleColumnPhraseDetails();
	toggleColumnPhrasesList();

	observer = new MutationObserver(mutations => {
		setTimeout(() => { // таймаут, потому что TextSelectionPopup глючит. Может открыться и закрыться
			const trigger = document.querySelector(`.textSelectionPopupRoot.visible`);
			if (trigger) { // TextSelectionPopup открылся
				const dispatch = getDispatch();
				const tabs = getPhraseDetailsTabs(getState());
				if (tabs && tabs.length) {
					let t = tabs.find(tab => tab.type === EPhraseDetailsTabType.EXPLAIN);
					if (t) {
						dispatch(PhraseDetailsActions.setActiveTab(t));
					}
				}
				dispatch(setOnboardingAction({ step: 2 }));
			}
		}, 500);
	});
	const trigger = document.querySelector(`.textSelectionPopupRoot`);
	if (trigger) observer.observe(trigger, { attributes: true });
};

function onHide2 () {
	observer.disconnect();
};

function onBack2 () {
	history.back();
};

function onShow3 () {
	toggleColumnPhraseDetails(false);
};

function onBack3 () {
	const dispatch = getDispatch();
	dispatch(PhraseDetailsActions.disActivate());
	SelectionPopupEffects.dismissSelectText();
	dispatch(setOnboardingAction({ step: 2 }));
};

function onShow4 () {
	observer.disconnect();
	observer = new MutationObserver(mutations => {
		const trigger = document.querySelector(`.${CLASS_ONBOARDING_TRIGGER}--step5`);
		if (trigger) { // Prompts popup открылся
			const dispatch = getDispatch();
			dispatch(setOnboardingAction({ step: 4 }));
			observer.disconnect();
		}
	});
	observer.observe(document.body, { childList: true });

	const button = document.querySelector('.more-prompts-button') as HTMLElement;
	if (button) {
		button.classList.remove(CLASS_ONBOARDING_NOEVENT);
	}
};

function onHide4 () {
	observer.disconnect();

	const button = document.querySelector('.more-prompts-button') as HTMLElement;
	if (button) {
		button.classList.add(CLASS_ONBOARDING_NOEVENT);
	}
};

function onHide5 () {
	const backdrop = document.querySelector('.more-prompts-popup .MuiBackdrop-root') as HTMLElement;
	if (backdrop) backdrop.click();
};

function onNext5 () {
	const dispatch = getDispatch();
	const tabs = getPhraseDetailsTabs(getState());
	if (tabs && tabs.length) {
		let t = tabs.find(tab => tab.type === EPhraseDetailsTabType.TRANSLATION);
		if (t) {
			dispatch(PhraseDetailsActions.setActiveTab(t));
		}
	}
};

function onShow7 () {
	document.body.classList.add(`${CLASS_ONBOARDING_ACTIVE}--step7`);

	observer = new MutationObserver(mutations => {
		if (document.querySelector(`.textSelectionPopupRoot.visible`)) { // TextSelectionPopup переместился
			const dispatch = getDispatch();
			dispatch(setOnboardingAction({ step: 5 }));
			dispatch(setOnboardingAction({ step: 6 }));
		}
	});
	const trigger = document.querySelector(`.textSelectionPopupRoot`);
	if (trigger) observer.observe(trigger, { attributes: true });
};

function onHide7 () {
	observer.disconnect();
	document.body.classList.remove(`${CLASS_ONBOARDING_ACTIVE}--step7`);
};

function onShow8 () {
	toggleColumnPhrasesList(false);
	observer.disconnect();
	observer = new MutationObserver(mutations => {
		const trigger = document.querySelector(`.phrase-context-editor`);
		if (trigger) { // phrase-context-editor открылся
			OnboardingEffects.setShow();
		}
	});
	observer.observe(document.body, { childList: true });
};
