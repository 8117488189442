import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';
import { TVideoInfo } from '../../../spa/store/models/types';


export type TCreateCopyLinkBody = {
  videoId: string;
  groupId: number;
};

export type TCreateCopyLinkResponse = {
  hash: string;
};

export type TCreateCopyResponse = {
  groupId: number;
  videoId: string;
  videoInfo: TVideoInfo;
}

export type TCopyCheckExistResponse = {
  exist?: boolean;
  groupId?: number;
  videoInfo?: TVideoInfo;
  videoId?: string;
};

export type TCopyVideoInfoResponse = {
  videoInfo: TVideoInfo;
  videoId: string;
  groupTargetLang: string;
  groupNativeLang: string;
};

export class VideoCopyRest {

  private static CREATE_COPY_LINK_URL = API_URL + '/video/createCopyLink';
  private static CREATE_COPY_URL = API_URL + '/video/createCopy';
  private static COPY_CHECK_EXIST_URL = API_URL + '/video/copyCheckExist';
  private static COPY_VIDEO_INFO_URL = API_URL + '/video/getCopyInfo';

  public static async createCopyLink(data: TCreateCopyLinkBody): Promise<TCreateCopyLinkResponse> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(VideoCopyRest.CREATE_COPY_LINK_URL, data));
  }

  public static async createCopy(hash: string, groupId: number): Promise<TCreateCopyResponse> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(VideoCopyRest.CREATE_COPY_URL, {hash, groupId}));
  }

  public static async copyCheckExist(hash: string, groupId: number): Promise<TCopyCheckExistResponse> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(VideoCopyRest.COPY_CHECK_EXIST_URL, {hash, groupId}));
  }

  public static async getCopyVideoInfo(hash: string): Promise<TCopyVideoInfoResponse> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(VideoCopyRest.COPY_VIDEO_INFO_URL, {hash}));
  }




}
