import { PhraseDetailsGptchatService } from './phrase-details-gptchat-service';

export class PhraseDetailsCustomPromptTestService extends PhraseDetailsGptchatService {

  private prompt: string;

  constructor(text: string, context: string, targetLangCode: string, nativeLangCode: string, prompt: string) {
    super(text, context, targetLangCode, nativeLangCode);
    this.prompt = prompt;
  }

  protected saveHash(hash: string): void {
  }

  protected getHash(): string {
    return '';
  }

  protected async getPromptText(): Promise<string> {
    return this.prompt;
  }

  protected useHashCallApi(): boolean {
    return false;
  }
}