import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PromptsListItem } from '../components/PromptsListItem';
import { IPromptsListItemEvents, IPromptsListItemFields } from '../components/types';
import { getPhraseDetailsTabById } from '../../../../../store/models/selectors';
import { IPromptsListItemHOCOwnProps } from './types';
import { PromptsListItemEvents } from './events';
import { PhraseDetailsTabEffects } from '../../../../../effects/phraseDetailsTabEffects';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState,
		{ id }: IPromptsListItemHOCOwnProps
	): IPromptsListItemFields => {
		const prompt = getPhraseDetailsTabById(state, id);
		const promptDetails = prompt && PhraseDetailsTabEffects.getCustomPromptByTab(prompt);

		return {
			favorite: prompt?.favorite || false,
			isSelected: PhraseDetailsSelectors.getActiveTab(state)?.id === id,
			text: promptDetails ? promptDetails.description : '',
			title: prompt ? prompt.title : '',
		}
	};
}

const mapDispatchToProps = (
	dispatch: any,
	{ id, onSelected }: IPromptsListItemHOCOwnProps
): IPromptsListItemEvents => ({
	onClick: () => dispatch(PromptsListItemEvents.onClick(id, onSelected)),
	onClickFavorite: () => dispatch(PromptsListItemEvents.onClickFavorite(id)),
}
);

export const PromptsListItemHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(PromptsListItem);

PromptsListItemHOC.displayName = 'PromptsListItemHOC';
