import { TApiResponse } from '../phraseDetailsService/phrase-details-service';
import { ILanguageItem, TAuthUser } from '../../../store/general/types';
import { PhraseDetailsSelectors } from '../../../store/phrase-details/selectors';
import { getState } from '../../../store';
import { LangUtil } from '../../../../common/utils/lang-util';
import { IPhrasesDetailTranslateLangResult } from '../../../store/phrase-details/types';
import {
  PhraseTranslateRest,
  TTranslateLangsResultResp
} from '../../../../common/rest/phraseTranslate/phraseTranslateRest';
import { ErrorEffects } from '../../errorEffects';


export class PhraseDetailsTranslateManager {

  public static async translateLangsForUser(user: TAuthUser, text: string): Promise<IPhrasesDetailTranslateLangResult[]> {
    const state = getState();
    const toLang = PhraseDetailsSelectors.getToLang(state);
    let toLangs: ILanguageItem[] = toLang ? [toLang] : [];
    if (user.translateLangs?.length) {
      user.translateLangs.forEach(translLang => {
          const exists = toLangs.find(l => l.code === translLang.code);
          if (!exists) {
            toLangs.push(LangUtil.getLanguageItemByCode(translLang.code));
          }
      })
    }
    const resultList: IPhrasesDetailTranslateLangResult[] = await PhraseDetailsTranslateManager.translateLangs(null, toLangs, text);
    return resultList;
  }

  public static async translateLang(text: string, toLangCode: string): Promise<IPhrasesDetailTranslateLangResult | null> {
    const state = getState();
    const fromLang = PhraseDetailsSelectors.getFromLang(state);
    if (!fromLang) {
      return null;
    }
    const toLang: ILanguageItem  = {code: toLangCode, name: ''};
    const result = await PhraseDetailsTranslateManager.translate(fromLang, toLang, text);
    return result ? {
      success: true, langCode: toLangCode, result
    } : {
      success: false, langCode: toLangCode, result: ''
    }
  }

  private static async translateLangs(
    fromLang: ILanguageItem | null,
    toLangs: ILanguageItem[],
    text: string
  ): Promise<IPhrasesDetailTranslateLangResult[]> {
    try {
      return await PhraseDetailsTranslateManager.callMultiLangsService(text, fromLang, toLangs);
    } catch(e) {
      console.log('error', e)
      ErrorEffects.logError(e);
      return [];
    }
  }

  public static async translate(
    fromLang: ILanguageItem,
    toLang: ILanguageItem,
    text: string
  ): Promise<string | undefined> {
    let result = '';
    if (fromLang && toLang) {
      return await PhraseDetailsTranslateManager.callService(text, fromLang, toLang);
    }
    return result;
  }


  private static async callService(text: string, fromLang: ILanguageItem, toLang: ILanguageItem): Promise<string | undefined> {
    let hash = PhraseDetailsSelectors.getHash(getState());

    const resp: TApiResponse = await PhraseTranslateRest.translateApi({
      text,
      fromLang: LangUtil.checkLangCode(fromLang.code),
      toLang: LangUtil.checkLangCode(toLang.code),
      hash
    });

    hash = PhraseDetailsSelectors.getHash(getState());
    if (hash !== resp.hash) {
      return undefined;
    }
    return resp.result;
  }

  private static async callMultiLangsService(text: string, fromLang: ILanguageItem | null, toLangList: ILanguageItem[]): Promise<IPhrasesDetailTranslateLangResult[] | undefined> {
    let hash = PhraseDetailsSelectors.getHash(getState());

    const toLangs = toLangList.map(l => LangUtil.checkLangCode(l.code));
    const resp: TTranslateLangsResultResp = await PhraseTranslateRest.translateMultiLangApi({
      text,
      fromLang: fromLang ? LangUtil.checkLangCode(fromLang.code) : '',
      toLangs,
      hash
    });

    hash = PhraseDetailsSelectors.getHash(getState());
    if (hash && hash !== resp.hash) {
      return undefined;
    }

    return resp.result as IPhrasesDetailTranslateLangResult[];
  }
}