import { IconButton, IconButtonProps, styled } from "@mui/material";

export const ButtonIconGrey = styled(IconButton)<IconButtonProps>(({ theme }) => ({
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: theme.palette.grey[400],
    background: theme.palette.grey[100],
    borderRadius: theme.spacing(.75),

    '.MuiSvgIcon-root': {
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
    },

    '&:hover, &:focus': {
        background: theme.palette.grey[200],
    },
}));
