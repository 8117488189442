import { ICreatePublicProfileModalProps } from './types';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { Button, CardMedia, CircularProgress, Stack, TextField } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from '../../../../../../common/styles/theme';
import { CopyIcon } from '../../../../../../../icons/CopyIcon';
import React from 'react';
import { PUBLIC_PROFILE_USERNAME_MAX_LENGTH } from '../../../../../../common/constants';
import { Hint } from '../../../../../../common/components/Hint/Hint';
import { EUserProfileSaveErrorType } from '../../../../../../common/rest/user/userProfileRest';
import { PUBLIC_PROFILE_BIO_PLACEHOLDER, PUBLIC_PROFILE_ERROR_NICK, PUBLIC_PROFILE_SHOW_AVATAR, PUBLIC_PROFILE_SUBMIT, PUBLIC_PROFILE_SUBMIT_CREATE, PUBLIC_PROFILE_TITLE, PUBLIC_PROFILE_TITLE_CREATE } from './wordings';
import { PUBLIC_PLAYLIST_TEXT } from '../../../PlayList/CreatePlaylist/components/wordings';
import { CheckIcon } from '../../../../../../../icons/CheckIcon';
import { ControlSwitch } from '../../../Controls/component/ControlSwitch';

export const CreatePublicProfileModal = ({
    isEdit,
    isShow,
    name: currentName,
    userName: currentUserName,
    bio: currentBio = '',
    avatar: currentAvatar,
    showAvatar: currentShowAvatar,
    onClose,
    onCreate,
}: ICreatePublicProfileModalProps) => {
    if (!isShow) return null;

    const [userName, setUserName] = React.useState<string>(currentUserName);
    const [name, setName] = React.useState<string>(currentName);
    const [bio, setBio] = React.useState<string>(currentBio);
    const [avatar, setAvatar] = React.useState<string>(currentAvatar);
    const [showAvatar, setShowAvatar] = React.useState<boolean>(!!currentShowAvatar);
    const [error, setError] = React.useState('');

    const [copied, setCopied] = React.useState(false);
    const onCopy = () => {
        navigator.clipboard.writeText(`${window.location.origin}/@${userName}`);
        setCopied(true);
    };

    const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if (value.length > PUBLIC_PROFILE_USERNAME_MAX_LENGTH) {
            value = value.substring(0, PUBLIC_PROFILE_USERNAME_MAX_LENGTH);
        }
        if (value !== userName) {
            setUserName(value);
            setCopied(false);
            setError('');
        }
    };

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const onBioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBio(e.target.value);
    };

    const onSubmit = async () => {
        setError('');
        const error = await onCreate({ name, userName, bio, showAvatar });
        if (error && error === EUserProfileSaveErrorType.USER_NAME_EXIST) {
            setError(PUBLIC_PROFILE_ERROR_NICK);
        }
    }

    const [checkNickProgress, setCheckNickProgress] = React.useState(false);
    // const checkNickDebounce = React.useRef<any>(null);
    // React.useEffect(() => {
    //     if (checkNickDebounce.current) clearTimeout(checkNickDebounce.current);
    //     checkNickDebounce.current = setTimeout(() => {
    //         if (error) setCheckNickProgress(true);

    //         checkNick(userName)
    //             .then((res) => setError(res ? PUBLIC_PROFILE_ERROR_NICK : '')) // res === true if nick exists
    //             .catch(() => setError(PUBLIC_PROFILE_ERROR_NICK))
    //             .finally(() => setCheckNickProgress(false));
    //     }, 1000);
    // }, [userName]);

  return <Modal
        onClose={onClose}
        open={isShow}
        title={isEdit ? PUBLIC_PROFILE_TITLE : PUBLIC_PROFILE_TITLE_CREATE}
    >
    <Stack
        gap={theme.spacing(2)}
        sx={{
            width: theme.spacing(60),
            maxWidth: `100%`,
        }}
    >
        {!isEdit &&
            <Hint>
                <Text>
                    {PUBLIC_PLAYLIST_TEXT}
                </Text>
            </Hint>
        }
            <Stack sx={{ position: 'relative', }}>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={2}
                    sx={{
                        mb: 2,
                    }}
                >
                    <CardMedia
                        component='img'
                        image={avatar}
                        alt={name}
                        sx={{
                            width: theme.spacing(8),
                            height: theme.spacing(8),
                            borderRadius: '50%',
                        }}
                    />
                    <ControlSwitch
                        title={PUBLIC_PROFILE_SHOW_AVATAR}
                        labelPlacement={'start'}
                        checked={showAvatar}
                        sx={{
                            flex: `1 1 100%`,
                            m: 0,
                            '.MuiFormControlLabel-label': {
                                fontWeight: 400,
                                color: theme.palette.grey[500],
                            },
                        }}
                        onChange={()=>setShowAvatar(!showAvatar)}
                    />
                </Stack>
                <Text
                    sx={{
                        position: 'absolute',
                        bottom: theme.spacing(),
                        left: theme.spacing(1.5),
                        width: `150px`,
                        overflow: 'hidden',
                        lineHeight: '25px',
                        color: theme.palette.grey[400],
                        zIndex: 10,
                        [theme.breakpoints.down('sm')]: {
                            width: `110px`,
                            fontSize: '10px',
                        },
                    }}
                    variant={'subtitle2'}
                >
                    app.langmagic.com/@
                </Text>
                <TextField
                    label={'Username'}
                    variant={'filled'}
                    fullWidth
                    sx={{
                        '.MuiInputBase-input': {
                            pl: `150px`,
                            pr: theme.spacing(9)
                        },
                        [theme.breakpoints.down('sm')]: {
                            '.MuiInputBase-input': {
                                pl: `110px`,
                                pr: 0,
                            },
                        },
                    }}
                    value={userName}
                    onChange={onUsernameChange}
                    multiline={false}
                />
                <Button
                    sx={{
                        position: 'absolute',
                        bottom: theme.spacing(0.5),
                        right: theme.spacing(0.5),
                        width: theme.spacing(10),
                        p: theme.spacing(0.75, 1.25),
                        bgcolor: `${theme.palette.common.white} !important`,
                        [theme.breakpoints.down('sm')]: {
                            display: 'none',
                        },
                    }}
                    startIcon={copied
                        ? <CheckIcon sx={{
                            width: theme.spacing(1.5),
                            mr: theme.spacing(-.5),
                        }} />
                        : <CopyIcon />
                    }
                    onClick={onCopy}
                >
                    {copied ? 'Copied' : 'Copy'}
                </Button>
        </Stack>

        {(error || checkNickProgress) &&
            <Text
                sx={{
                    color: theme.palette.error.main,
                    mt: theme.spacing(-1),
                }}
            >
                {checkNickProgress
                    ? <CircularProgress
                        size={theme.spacing(1.5)}
                    />
                    : error
                }
            </Text>
        }

            <TextField
                label={'Name'}
                variant={'filled'}
                fullWidth
                value={name}
                onChange={onNameChange}
                multiline={false}
                placeholder={'Name'}
            />
            <TextField
                label={'Bio'}
                variant={'filled'}
                fullWidth
                value={bio}
                onChange={onBioChange}
                multiline
                minRows={3}
                maxRows={3}
                placeholder={PUBLIC_PROFILE_BIO_PLACEHOLDER}
            />

            <Button
                disabled={!!error || !name || !userName}
                onClick={onSubmit}
                variant={'contained'}
                sx={{
                    justifySelf: 'flex-start',
                    width: 'fit-content',
                }}
            >
                {isEdit ? PUBLIC_PROFILE_SUBMIT : PUBLIC_PROFILE_SUBMIT_CREATE}
            </Button>
    </Stack>
</Modal>;
}
