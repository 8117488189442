import React from 'react';
import { IconButton, MenuItem, Stack } from '@mui/material';
import { theme } from '../../styles/theme';
import { IIconMenuItemProps } from './types';

export const IconMenuItem: React.FC<IIconMenuItemProps> = ({
  title,
  children,
  sx,
  onClick
}) => {

  return (
    <MenuItem
      onClick={onClick}
      sx={{
        '.MuiStack-root': {
          fontWeight: 500,
        },
        ...sx
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
      >
        <IconButton
          onClick={onClick}
          color={'primary'}
          sx={{
            width: theme.spacing(5),
            height: theme.spacing(4),
            py: theme.spacing(.5),
            color: theme.palette.grey[400],
          }}
        >
          {children}
        </IconButton>
        {title}
      </Stack>
    </MenuItem>
  );
}