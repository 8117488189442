import { IContentLibVideo } from '../../../../../store/contentLib/types';

export interface IContentLibVideoListFields {
  groupId: number,
  searchText: string,
  targetLangCode: string,
  nativeLangCode: string
}

export interface IContentLibVideoListEvents {
  onLoadVideos: (startIndex: number, count: number) => Promise<IContentLibVideo[]>
  onVideoClick: (video: IContentLibVideo) => void;
}

export interface IContentLibVideoListProps extends
  IContentLibVideoListFields,
  IContentLibVideoListEvents
{}

export const contentLibVideosLoadPartSize = 200;