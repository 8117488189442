import { AppThunk } from '../store/types';
import { fetchUserGroups } from '../../common/rest/userGroup/fetchUserGroups';
import { addGroup, setUserAvailableGroups } from '../store/models/actions';
import { EExtMessageType, ExtMessageSender } from '../../common/utils/ext-message-sender';
import { getUserGroups } from '../store/models/selectors';
import { TUserGroup } from '../store/models/types';
import { LangUtil } from '../../common/utils/lang-util';
import { fetchUserGroup } from '../../common/rest/userGroup/fetchUserGroup';

export const updateUserGroups = (enforceLoad: boolean): AppThunk => async (
  dispatch,
  getState
) => {
  let userGroups = getUserGroups(getState());
  if (!enforceLoad && userGroups && userGroups.length) {
    return userGroups;
  }
  userGroups = await dispatch(fetchUserGroups());
  prepareVideoLangList(userGroups);
  dispatch(setUserAvailableGroups(userGroups));
  ExtMessageSender.send({
    type: EExtMessageType.SET_USER_GROUPS,
    payload: {userGroups}
  });
}



const prepareVideoLangList = (userGroups: TUserGroup[]) => {
  userGroups.forEach(group => {
    group.videos.forEach(v => {
      const langs =  v.info.langs || '';
      const codes = langs.split(',');
      v.info.langList = codes.map(code => LangUtil.checkLangCode(code));
    })
  })
}
