import * as React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { IFeedbackProps } from './types';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { Box, Button, CircularProgress, FormGroup, IconButton, InputLabel, List, ListItem, styled } from '@mui/material';
import { FEEDBACK_BUTTON, FEEDBACK_TEXT_LABEL, FEEDBACK_TEXT_SENT, FEEDBACK_TITLE } from './wordings';
import { PlusIcon } from '../../../../../../../icons/PlusIcon';
import { CloseIcon } from '../../../../../../../icons/CloseIcon';
import { Textarea } from '../../../../../../common/components/Textarea/Textarea';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';

export const Feedback: React.FC<IFeedbackProps> = ({
	isShow,
	onClose,
	onSend,
}) => {
	if (!isShow) return null;

	const {isMobile} = React.useContext(DeviceContext);

	const className = 'feedback-form';
	const [files, setFiles] = React.useState<FileList>();
	const [text, setText] = React.useState('');
	const [progress, setProgress] = React.useState(false);
	const [isSent, setSent] = React.useState(false);

	const onTextChange = (
		e: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setText(e.target.value);
	}

	const handleSend = async () => {
		setProgress(true);
		try {
		  	const response = await onSend({
				files,
				text
			});
			  	setProgress(false);
			if (response.id) { // ticke created
				setSent(true);
			}
		} catch(e) {
		  	setProgress(false);
		}
	}

	const addFile = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		if (e.target.files && e.target.files.length) {
			const fileList = new DataTransfer();
			if (files) {
				Array.from(files).forEach(file => {
					fileList.items.add(file);
				});
			}
			Array.from(e.target.files).forEach(file => {
				fileList.items.add(file);
			});
			setFiles(fileList.files);
		}
	};

	const removeFile = (
		index: number,
	) => {
		if (files && files[index]) {
			const fileList = new DataTransfer();
			if (files) {
				Array.from(files).forEach((file, i) => {
					if (i !== index) fileList.items.add(file);
				});
			}
			setFiles(fileList.files);
		}
	};

	const fileSize = (
		size: number
	): string => {
		const sizes = ['B', 'KB', 'MB', 'GB'];
		let order = 0;
		while (size >= 1024 && order < sizes.length - 1) {
			order++;
			size = size / 1024;
		}
		return size.toFixed(2) + sizes[order];
	};
	

	return (
		<Modal
			isMobile={isMobile}
			onClose={onClose}
			open
			title={FEEDBACK_TITLE}
			PaperProps={{
				sx: {
					width: theme.spacing(50),
				}
			}}
		>
			<>
				{isSent
					? <Box
						sx={{
							py: theme.spacing(5),
							fontSize: theme.typography.subtitle2,
							textAlign: 'center',
						}}
						dangerouslySetInnerHTML={{ __html: FEEDBACK_TEXT_SENT }}
					/>
					: <FormGroup
						className={`${progress ? 'progress' : ''}`}
						sx={{
							gap: theme.spacing(2),

							'&.progress *': {
								pointerEvents: 'none',
								opacity: .3,
							},
						}}
					>
						<Box>
							<InputLabel
								sx={{
									mb: theme.spacing(.5),
								}}
							>
								{FEEDBACK_TEXT_LABEL}
							</InputLabel>
							<Textarea
								autoFocus
								value={text}
								minRows={5}
								onChange={onTextChange}
							/>
						</Box>

						<List
							sx={{
								p: 0,
	
								'.MuiListItem-root': {
									justifyContent: 'space-between',
									mb: theme.spacing(1),
									p: `0 0 0 ${theme.spacing(1)}`,
									border: `2px solid ${theme.palette.grey[50]}`,
									borderRadius: theme.spacing(.75),
								},

								'.feedback-form__file-name': {
									flex: `1 1 100%`,
								},

								'.feedback-form__file-size': {
									flex: `0 0 ${theme.spacing(5)}`,
									color: theme.palette.grey[400],
									textAlign: 'right',
								},

								'.feedback-form__file-remove': {
									flex: `0 0 ${theme.spacing(4)}`,
									color: theme.palette.grey[400],
									'&:hover, &:active, &:focus': { color: theme.palette.grey[500] },
								},
							}}
						>
							{files && !!files.length &&
								Array.from(files).map((file, index) => {
									return (
										<ListItem>
											<Box
												className={`${className}__file-name`}
											>
												{file.name}
											</Box>
											<Box
												className={`${className}__file-size`}
											>
												{fileSize(file.size)}
											</Box>
											<IconButton
												className={`${className}__file-remove`}
												color={'primary'}
												onClick={()=>removeFile(index)}
											>
												<CloseIcon />
											</IconButton>
										</ListItem>
									)
								})
							}
							<Button
								component={'label'}
								role={undefined}
								variant={'outlined'}
								tabIndex={-1}
								startIcon={<PlusIcon />}
								sx={{
									width: 1,
								}}
							>
								Add files
								<VisuallyHiddenInput
									multiple
									type={'file'}
									onChange={addFile}
								/>
							</Button>
						</List>

						<Button
							disabled={progress || !text.length}
							onClick={handleSend}
							variant={'contained'}
							fullWidth
						>
							{FEEDBACK_BUTTON}
						</Button>
					</FormGroup>
				}
				{progress &&
					<Box
						sx={{
							position: 'absolute',
							left: '50%',
							top: '50%',
							transform: 'translate(-50%, -50%)',
						}}
					>
						<CircularProgress
							size={theme.spacing(3)}
						/>
					</Box>
				}
			</>
		</Modal>
	);
};

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});
