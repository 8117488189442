import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';
import { PhraseDetailsTabEffects } from '../../../../../effects/phraseDetailsTabEffects';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';

export class PhraseDetailsTabItemEvents {

  public static onEdit(id: number): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(PhraseDetailsTabsEditorPopupActions.setEditTabId(id));
      dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.CREATE));
      dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestResult(''));
    }
  }

  public static onDelete(id: number): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      await PhraseDetailsTabEffects.deleteTab(id);
    }
  }

  public static onFavoriteToggle(id: number): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      await PhraseDetailsTabEffects.toggleFavorite(id);
      if (PhraseDetailsTabsEditorPopupSelectors.isShowFavoritePromptTooltip(getState())) {
        dispatch(PhraseDetailsTabsEditorPopupActions.setShowFavoritePromptTooltip(false));
      }
    }
  }



}
