import { UserRest } from '../../common/rest/user/userRest';
import { getDispatch, getState } from '../store';
import { setUserRolesAction } from '../store/models/actions';
import { TUserRole } from '../store/models/types';
import { getAuthUser } from '../store/general/selectors';
import { setAuthUserAction } from '../store/general/actions';
import { UserRoleRest } from '../../common/rest/userRole/userRoleRest';

export class UserRoleEffects {

  public static async loadUserRoles() {
    const dispatch = getDispatch();
    const list: TUserRole[] = await UserRoleRest.getList();
    dispatch(setUserRolesAction(list));
  }

  public static async saveUserRoles(roles: number[]) {
    const dispatch = getDispatch();
    const savedRoles: number[] = await UserRest.saveRoles(roles);
    const authUser = getAuthUser(getState());
    if (authUser) {
      const user = {...authUser, roles: savedRoles};
      dispatch(setAuthUserAction(user));
    }
  }
}