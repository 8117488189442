import { Tabs as TabsMUI, TabsProps, styled } from "@mui/material";

export const Tabs = styled(TabsMUI)<TabsProps>(({ theme }) => ({
    padding: theme.spacing(0.75),
    minHeight: 'unset',
    background: theme.palette.grey[100],
    borderRadius: theme.spacing(0.75),

    '.MuiTabs-flexContainer': {
        gap: theme.spacing(.75),
        justifyContent: 'center',
    },

    '.MuiTabs-indicator': {
        display: 'none',
    },
}));
