import { IState } from '../../../../store/types';
import { isAuthorised } from '../../../../store/general/selectors';
import { connect } from 'react-redux';
import { MainContainer } from '../components/MainContainer';
import { IMainContainerEvents, IMainContainerFields } from '../components/types';
import { MainContainerEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IMainContainerFields => {
    return {
      isAuth: isAuthorised(state)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IMainContainerEvents => ({
    onLoad: () => dispatch(MainContainerEvents.onLoad()),
    onMouseDown: () => dispatch(MainContainerEvents.onMouseDown()),
    onChangeRoute: () => dispatch(MainContainerEvents.onChangeRoute()),
  }
);

export const MainContainerHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainContainer);

MainContainerHOC.displayName = 'MainContainerHOC';
