import { TAction } from '../../../common/types';
import { ELanguageStatus, EUserLevel } from '../general/types';

export const SET_GROUPS = 'MODELS/SET_GROUPS';
export const ADD_GROUP = 'MODELS/ADD_GROUP';
export const REFRESH_GROUP = 'MODELS/REFRESH_GROUP';
export const REMOVE_GROUP = 'MODELS/REMOVE_GROUP';
export const UPDATE_GROUP = 'MODELS/UPDATE_GROUP';
export const ADD_VIDEO_IN_GROUP = 'MODELS/ADD_VIDEO_IN_GROUP';
export const REMOVE_VIDEO_FROM_GROUP = 'MODELS/REMOVE_VIDEO_FROM_GROUP';
export const MOVE_VIDEO_TO_GROUP = 'MODELS/MOVE_VIDEO_TO_GROUP';
export const SET_ACTIVE_GROUP = 'MODELS/SET_ACTIVE_GROUP';
export const SET_LANGUAGES = 'MODELS/SET_LANGUAGES';
export const SET_GROUP_COUNT = 'MODELS/SET_GROUP_COUNT';
export const SET_PHRASES = 'MODELS/SET_PHRASES';
export const REMOVE_PHRASES_BY_VIDEO_GROUP = 'MODELS/REMOVE_PHRASES_BY_VIDEO_GROUP';
export const REMOVE_PHRASES_BY_GROUP = 'MODELS/REMOVE_PHRASES_BY_GROUP';

export const SET_GROUPS_VIDEO_LANG_LIST = 'MODELS/SET_GROUPS_VIDEO_LANG_LIST';

export const SET_DEFAULT_GPT_PROMPTS = 'MODELS/SET_DEFAULT_GPT_PROMPTS';
export const SET_USER_GPT_PROMPTS = 'MODELS/SET_USER_GPT_PROMPTS';
export const USER_GPT_PROMPT_SAVE = 'MODELS/USER_GPT_PROMPT_SAVE';
export const USER_GPT_PROMPT_DELETE = 'MODELS/USER_GPT_PROMPT_DELETE';

export const SET_PHRASE_DETAILS_TABS = 'MODELS/SET_PHRASE_DETAILS_TABS';
export const PHRASE_DETAILS_TAB_SAVE = 'MODELS/PHRASE_DETAILS_TAB_SAVE';
export const PHRASE_DETAILS_TAB_DELETE = 'MODELS/PHRASE_DETAILS_TAB_DELETE';

export const SET_VIDEO_TUTORIAL_LIST = 'MODELS/SET_VIDEO_TUTORIAL_LIST';
export const SET_LANGS_ACTION = 'MODELS/SET_LANGS_ACTION';
export const SET_USER_ROLES_ACTION = 'MODELS/SET_USER_ROLES_ACTION';

export interface IModelsState {
  groups: TUserGroup[];
  languages: TUserLanguage[];
  activeGroupId: number | null;
  defaultGptPrompts: TDefaultGptPrompt[];
  userGptPrompts: TUserGptPrompt[];
  phraseDetailsTabs: TPhraseDetailsTab[];
  videoTutorials: TVideoTutorial[];
  langs: TLanguage[];
  userRoles: TUserRole[];
}

export interface ISetGroups extends TAction<typeof SET_GROUPS> {
  groups: TUserGroup[];
}

export interface ISetLanguages extends TAction<typeof SET_LANGUAGES> {
  languages: TUserLanguage[]
}

export interface IRemovePhrasesByVideoGroup extends TAction<typeof REMOVE_PHRASES_BY_VIDEO_GROUP> {
  videoKey: string
  groupId: number
}

export interface IRemovePhrasesByGroup extends TAction<typeof REMOVE_PHRASES_BY_GROUP> {
  groupId: number
}

export interface ISetActiveGroupId extends TAction<typeof SET_ACTIVE_GROUP> {
  activeGroupId: number | null;
}

export interface IAddGroup extends TAction<typeof ADD_GROUP> {
  group: TUserGroup;
}

export interface IRefreshGroupAction extends TAction<typeof REFRESH_GROUP> {
  group: TUserGroup;
}

export interface IRemoveGroup extends TAction<typeof REMOVE_GROUP> {
  groupId: number;
}

export interface IUpdateGroup extends TAction<typeof UPDATE_GROUP> {
  groupId: number;
  name: string;
  languages?: TUserLanguage[];
  publicGroup?: boolean;
  level?: EUserLevel;
}

export interface IAddVideoInGroup extends TAction<typeof ADD_VIDEO_IN_GROUP> {
  groupId: number;
  video: TVideo;
}

export interface IMoveVideoToGroup extends TAction<typeof MOVE_VIDEO_TO_GROUP> {
  videoId: string;
  fromGroupId: number;
  toGroupId: number;
}

export interface IRemoveVideoFromGroup extends TAction<typeof REMOVE_VIDEO_FROM_GROUP> {
  groupId: number;
  videoKey: string;
}

export interface ISetGroupPhraseCount extends TAction<typeof SET_GROUP_COUNT> {
  groupId: number;
  count: number;
}

export interface ISetGroupsVideoLangList extends TAction<typeof SET_GROUPS_VIDEO_LANG_LIST> {
  videoId: string,
  langList: string[]
}

export interface ISetUserGptPrompts extends TAction<typeof SET_USER_GPT_PROMPTS> {
  prompts: TUserGptPrompt[]
}

export interface ISetDefaultGptPrompts extends TAction<typeof SET_DEFAULT_GPT_PROMPTS> {
  defaultPrompts: TDefaultGptPrompt[]
}

export interface IUserGptPromptSave extends TAction<typeof USER_GPT_PROMPT_SAVE> {
  prompt: TUserGptPrompt
}

export interface IUserGptPromptDelete extends TAction<typeof USER_GPT_PROMPT_DELETE> {
  id: number
}


export interface ISetPhraseDetailsTabs extends TAction<typeof SET_PHRASE_DETAILS_TABS> {
  tabs: TPhraseDetailsTab[]
}

export interface IPhraseDetailsTabSave extends TAction<typeof PHRASE_DETAILS_TAB_SAVE> {
  tab: TPhraseDetailsTab,
  replaceIndex?: number
}

export interface IPhraseDetailsTabDelete extends TAction<typeof PHRASE_DETAILS_TAB_DELETE> {
  id: number
}

export interface ISetVideoTutorialListAction extends TAction<typeof SET_VIDEO_TUTORIAL_LIST> {
  videoTutorials: TVideoTutorial[];
}

export interface ISetLangsAction extends TAction<typeof SET_LANGS_ACTION> {
  langs: TLanguage[];
}

export interface ISetUserRolesAction extends TAction<typeof SET_USER_ROLES_ACTION> {
  userRoles: TUserRole[];
}


export type IModelsActions = ISetGroups
  | IAddGroup
  | IRefreshGroupAction
  | IRemoveGroup
  | IAddVideoInGroup
  | IMoveVideoToGroup
  | IRemoveVideoFromGroup
  | ISetLanguages
  | ISetActiveGroupId
  | ISetGroupPhraseCount
  | IRemovePhrasesByVideoGroup
  | IRemovePhrasesByGroup
  | IUpdateGroup
  | ISetGroupsVideoLangList
  | ISetUserGptPrompts
  | IUserGptPromptSave
  | IUserGptPromptDelete
  | ISetPhraseDetailsTabs
  | IPhraseDetailsTabSave
  | IPhraseDetailsTabDelete
  | ISetDefaultGptPrompts
  | ISetVideoTutorialListAction
  | ISetLangsAction
  | ISetUserRolesAction
  ;

//**************** MODEL TYPES ********************/

export type TLanguageCode = string;

export type TUserLanguage = {
  name: string;
  code: TLanguageCode;
  status?: ELanguageStatus;
};


export type TLanguage = {
  id: number;
  name: string;
  code: TLanguageCode;
  installUserGroupExist?: boolean
};

export type TVideoInfo = {
  id: number
  languageId: number
  videoId: number
  createdAt: Date
  updatedAt: Date
  title: string;
  thumb: string;
  author: string;
  langs: string;
  langsChecked: boolean;
  langList?: string[];
}

export type TVideo = {
  id: string;
  videoKey: string;
  info: TVideoInfo;
}

export interface TVideoRest extends TVideo {
  addedToGroup: boolean,
  group: TUserGroup
}

export type TAudio = {
  id: number;
  key: string,
  url: string,
  language: TLanguage
}

export type TUserGroup = {
  id: number;
  ownerId: number;
  phrasesCount: number;
  languages: TUserLanguage[];
  videos: TVideo[];
  audios: TAudio[];
  name: string;
  public: boolean;
  level: EUserLevel;
}

export const EN_LANG_CODE = 'en';
export const EN_AUTO_GEN_LANG_CODE = 'en_autogen';

export enum EGpPromptGroupType {
  EXPLAIN = 1,
  USAGE = 2
}

export enum EDefaultGptPromptType {
  EXPLAIN = 5,
  USAGE = 6
}

export type TDefaultGptPrompt = {
  id: number;
  prompt: string;
  description: string;
  type: EDefaultGptPromptType;
}

export enum EUserGpPromptType {
  CUSTOM = 1
}

export type TUserGptPrompt = {
  id: number;
  title: string;
  prompt: string;
  description: string;
  type: EUserGpPromptType;
}

export enum EPhraseDetailsTabType {
  TRANSLATION = 1,
  EXPLAIN = 2,
  USAGE = 3,
  NOTES = 4,
  CUSTOM_PROMPT = 5,
  LIB_PROMPT = 6,
}

export type TPhraseDetailsTab = {
  id: number;
  title: string;
  type: EPhraseDetailsTabType;
  orderNum: number;
  relationId?: number;
  favorite: boolean;
  isTemp?: boolean; // temporary add  promt from lib to my prompts
  isNoteEdit?: boolean; // note is opend to edit
}


export enum EVideoTutorialType {
  OPEN = 0,
  COMMON = 1,
  PROMPTS = 2,
  PLAYLISTS = 3,
  CAPTIONS = 4,
  PHRASES = 5,
  MULTILANG_TRANSLATE = 6,
  NATIVE_LANGUAGE = 7
}

export type TVideoTutorial = {
  type: EVideoTutorialType,
  title: string,
  videoId: string
  description?: string;
}

export enum EUserRoleId {
  Teacher = 1,
  Influencer = 2,
  Polyglot = 3,
  Student = 4
}

export type TUserRole = {
  id: number;
  name: string;
}

export type TUserTranslation = {
  fullText: string;
  text: string;
  startTime: number;
  endTime: number;
  videoKey: string;
  userGroupId: number;
  startCaptionIndex: number;
  endCaptionIndex: number;
  startPosition: number;
  endPosition: number;
}