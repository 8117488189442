import React from 'react';
import { useHistory } from 'react-router-dom';
import { EPhraseDetailsTabType, TPhraseDetailsTab } from '../../../../../store/models/types';
import { theme } from '../../../../../../common/styles/theme';
import { IPhraseDetailsContainerHeaderProps, PHRASE_DETAILS_HEADER_CLASS_NAME } from './types';
import { Box, Button, IconButton, Menu, Stack, alpha } from '@mui/material';
import { CLASS_ONBOARDING_NOEVENT, CLASS_ONBOARDING_TRIGGER } from '../../../Onboarding/constants';
import { ArrowIcon } from '../../../../../../../icons/ArrowIcon';
import { SettingsIcon } from '../../../../../../../icons/SettingsIcon';
import { PencilIcon } from '../../../../../../../icons/PencilIcon';
import { PromptsListHOC } from '../../../PhraseDetailsTabsEditor/PromptsList/hocs/PromptsListHOC';
import { Divider } from '../../../../../../common/components/Divider/Divider';
import { ArrowLightLeftIcon } from '../../../../../../../icons/ArrowLightLeftIcon';
import { Text } from '../../../../../../common/components/Text/Text';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';

export const PhraseDetailsContainerHeader: React.FC<IPhraseDetailsContainerHeaderProps> = ({
	tabs,
	activeTab,
	notesDisabled,
	onShowGptPromptsPopup,
	onChangeTab,
	onSelectDefaultTab,
	onBack,
}) => {

	const history = useHistory();

	const tabsStyle = {
		position: 'relative',
		gap: theme.spacing(0.75),
		mb: theme.spacing(1.5),
		ml: theme.spacing(1),
	};

	const tabStyle = {
		maxWidth: theme.spacing(20),
		overflow: 'hidden',
		textOverflow: 'ellipsis',
    	justifyContent: 'flex-start',
		height: theme.spacing(3.5),
		p: theme.spacing(1),
	};

	const dividerStyle = {
		height: theme.spacing(3),
		width: '2px',
		m: `0 ${theme.spacing(.5)}`,
	};

	const isNotes = activeTab && activeTab.type === EPhraseDetailsTabType.NOTES;

	const notesStyle = isNotes ? {
		...tabStyle,
		'.MuiButton-startIcon': {
			ml: 0,
			mr: theme.spacing(.5),
			'.MuiSvgIcon-root': {
				width: theme.spacing(2),
			}
		}
	} : {
		...tabStyle,
		bgcolor: theme.palette.warning[100],
		color: theme.palette.warning[900],
		'&:hover': {
			bgcolor: theme.palette.warning[100],
		},
		'.MuiButton-startIcon': {
			ml: 0,
			mr: theme.spacing(.5),
			color: theme.palette.warning[900],
			'.MuiSvgIcon-root': {
				width: theme.spacing(2),
			}
		}
	};

	const backStyle = {
		...tabStyle,
		color: theme.palette.grey[900],
		background: theme.palette.grey[100],
		'&:hover, &:focus': {
			background: theme.palette.grey[200],
		},
		'.MuiButton-startIcon': {
			mr: theme.spacing(.5),
		},
		'&:active': {
			color: theme.palette.grey[900],
			bgcolor: theme.palette.grey[200],
		},
	}

	React.useEffect(() => {
		if (!activeTab && tabs && tabs.length) {
			onSelectDefaultTab();
		}
	}, []);

	const handleChange = (event: React.SyntheticEvent, tab: TPhraseDetailsTab) => {
		if (tab !== null) {
			onChangeTab(tab);
		}
	};

	const handleTranslationTabClick = () => {
		if (translationTab) {
			onChangeTab(translationTab);
		}
	};

	const handleNotesTabClick = () => {
		if (notesTab) {
			onChangeTab(notesTab);
		}
	};

	const commonTabTypes = [EPhraseDetailsTabType.CUSTOM_PROMPT, EPhraseDetailsTabType.EXPLAIN, EPhraseDetailsTabType.USAGE, EPhraseDetailsTabType.LIB_PROMPT];
	const translationTab = tabs.find(t => t.type === EPhraseDetailsTabType.TRANSLATION);
	const notesTab = tabs.find(t => t.type === EPhraseDetailsTabType.NOTES);

	const commonTabs: TPhraseDetailsTab[] = tabs.filter(t => commonTabTypes.includes(t.type));

	const [propmptsEl, setPropmptsEl] = React.useState<null | HTMLElement>(null);
	const handlePromptsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setPropmptsEl(event.currentTarget);
	};
	const handlePromptsClose = () => {
		setPropmptsEl(null);
	};

	const {isMobile} = React.useContext(DeviceContext);

	const prompts = !commonTabs.length
		? null
		: commonTabs.map((tab, idx) => {
			const active = false// activeTab?.id === tab.id;
			return (
				<Button
					className={`
						${CLASS_ONBOARDING_NOEVENT}
					`}
					onClick={(e) => handleChange(e, tab)}
					variant={active ? 'contained' : 'outlined'}
					color={active ? 'primary' : 'secondary'}
					sx={tabStyle}
					key={tab.id}
				>
					{tab.title}
				</Button>
			)
		});

	const more = (<>
		<Button
			className={`${propmptsEl ? 'opened' : 'closed'}
				${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step4
				${CLASS_ONBOARDING_NOEVENT}
				more-prompts-button
			`}
			onClick={handlePromptsClick}
			variant={'contained'}
			color={'primary'}
			sx={{
				...tabStyle,

				minWidth: '74px', // hard tune
				color: theme.palette.primary.main,
				background: theme.palette.primary[100],

				'&:hover': {
					background: theme.palette.primary.light,
				},

				'.more-prompts-icon': {
					width: theme.spacing(1.75),
					height: theme.spacing(1.75),
					ml: theme.spacing(-.5),
					mr: theme.spacing(.5),
				},

				'&.closed .more-prompts-icon': {
					transform: 'scaleX(-1)',
				},
			}}
		>
			<ArrowIcon className={'more-prompts-icon'} />
			{propmptsEl
				? 'Less'
				: 'More'
			}
		</Button>

		<Menu
			className={`
				more-prompts-popup
			`}
			anchorEl={propmptsEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={!!propmptsEl}
			onClose={handlePromptsClose}
			sx={{
				'.MuiBackdrop-root': {
					background: alpha(theme.palette.grey[900], .2),
				},
				'.MuiPaper-root': {
					width: isMobile ? '60%' : '30%',
					height: `calc(100% - ${theme.spacing(4)})`,
					maxHeight: `calc(100% - ${theme.spacing(4)})`,
					ml: theme.spacing(-1),
					boxShadow: theme.shadows[16],
				},
			}}
		>
			<Stack
				gap={theme.spacing(2)}
				sx={{
					px: theme.spacing(2),
					py: theme.spacing(1),
				}}
			>
				<Stack
					flexDirection={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
				>
					<Box
						sx={{
							fontSize: theme.typography.h5,
							fontWeight: 600,
						}}
					>
						Prompts
					</Box>
					<IconButton
						className={`
							${CLASS_ONBOARDING_NOEVENT}
						`}
						onClick={()=>onShowGptPromptsPopup(history)}
						color={'secondary'}
					>
						<SettingsIcon />
					</IconButton>
				</Stack>

				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						pointerEvents: 'none',
						height: '1px',
					}}
				>
					<Box
						className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step5`}
						sx={{
							position: 'fixed',
							pointerEvents: 'none',
							height: '1px',
							mt: '80px',
						}}
					/>
				</Box>

				<PromptsListHOC />
			</Stack>
		</Menu>
	</>);
	 

	return (
		<Stack sx={tabsStyle}
			className={`
				${PHRASE_DETAILS_HEADER_CLASS_NAME}
			`}
		>
			<Stack
				direction={'row'}
				flexWrap={'wrap'}
				gap={theme.spacing(.5)}
				alignItems={'center'}
			>
				{isMobile
					? prompts
					: <>
						{!isNotes && <>
							{more}
							{prompts}
							{!!prompts &&
								<Divider sx={dividerStyle} />
							}
						</>}

						{!isNotes &&
						!notesDisabled && // translation of native subtitles
							<Button
								className={`
										${CLASS_ONBOARDING_NOEVENT}
									`}
								onClick={handleNotesTabClick}
								variant={'contained'}
								sx={notesStyle}
								startIcon={<PencilIcon/>}
							>
								{notesTab?.title}
							</Button>
						}

						{isNotes && <>
							<Button
								onClick={onBack}
								variant={'contained'}
								color={'secondary'}
								sx={backStyle}
								startIcon={<ArrowLightLeftIcon />}
							>
								Back
							</Button>
							<Text
								variant={'h6'}
								fontWeight={600}
								sx={{pl: theme.spacing(1)}}
							>
								Notes
							</Text>
						</>}
					</>
				}
			</Stack>
		</Stack>
	);

}