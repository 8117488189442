import React from 'react';
import { IVideoLibraryHeaderSelectProps } from './types';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { getUserGroupTitle } from '../../../../../store/models/selectors';
import { getState } from '../../../../../store';
import { VideoLibraryHeaderSelectItemText } from "./VideoLibraryHeaderSelectItemText";

export const VideoLibraryHeaderSelect: React.FC<IVideoLibraryHeaderSelectProps> = ({
  groupId,
  groups,
  sx,
  onGroupChange
}) => {

  const selectRef = React.useRef<HTMLElement | null>(null);

  const handleGroupChange = ({ target }: SelectChangeEvent) => {
    onGroupChange(Number(target.value));
  }

  const handleClose = () => {
    if (selectRef.current) {
      const selectList = selectRef.current?.querySelector('.MuiSelect-select') as HTMLElement;
      if (selectList) setTimeout(() => selectList.blur(), 0);
    };
  }

  return (
      <Select
        ref={selectRef}
        value={groupId && String(groupId) || ''}
        onChange={handleGroupChange}
        onClose={handleClose}
        variant={'filled'}
        displayEmpty
        sx={sx}
      >
        {
          groups.map((group) => {
            const groupText = getUserGroupTitle(getState(), group);
            const paddings = 16 * 2;
            const maxWidth = (selectRef.current?.offsetWidth || 0) - paddings;
            return (
                <MenuItem
                    key={group.id}
                    value={String(group.id)}
                >
                  <VideoLibraryHeaderSelectItemText
                        title={groupText}
                        maxWidth={maxWidth}
                    />
                </MenuItem>
            );
          })
        }
      </Select>
  );
}
