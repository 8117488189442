import React from 'react';
import { IInstallUserRoleCheckboxProps } from './types';
import { theme } from '../../../../../common/styles/theme';
import { FormControlLabel } from '@mui/material';
import { Check } from '../../../../../common/components/Check/Check';

export const InstallUserRoleCheckbox: React.FC<IInstallUserRoleCheckboxProps> = ({
  id,
  title,
  checked,
  onChange
}) => {

  const style = {
    backgroundColor: '#fff',
    p: theme.spacing(0.25),
    pr: theme.spacing(1.5),
    border: `2px solid ${checked ? theme.palette.primary.main : theme.palette.grey[200]}`,
    borderRadius: theme.spacing(1),
    userSelect: 'none',
    span: {
      fontSize: '14px',
    },
  }

  return (
    <FormControlLabel
      control={
        <Check
          checked={checked}
          onChange={onChange}
        />
      }
      label={title}
      sx={style}
    />
  );
};
