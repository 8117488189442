import React from 'react';
import { Stack } from '@mui/material';
import { IPhraseDetailsTabsCreateContainerProps } from './types';
import { PhraseDetailsTabCustomPromptFormHOC } from '../../PhraseDetailsTabPromptForm/hocs/custom/PhraseDetailsTabCustomPromptFormHOC';
import { theme } from '../../../../../../common/styles/theme';
import { VideoTutorialTooltipPromptsHOC } from '../../../VideoTutorial/VideoTutorialTooltip/hocs/VideoTutorialTooltipPrompts/VideoTutorialTooltipPromptsHOC';
import { PhraseDetailsTabsEditorContainerHOC } from '../../PhraseDetailsTabsEditorContainer/hocs/PhraseDetailsTabsEditorContainerHOC';


export const PhraseDetailsTabsCreateContainer: React.FC<IPhraseDetailsTabsCreateContainerProps> = ({
  createNew,
  onLoad
}) => {

  React.useEffect(() => {
    onLoad();
  }, [])

  const containerStyle = {
    gap: theme.spacing(2),
    height: 1,
  };

  return (
      <>
        <PhraseDetailsTabsEditorContainerHOC />
        <PhraseDetailsTabCustomPromptFormHOC />
        <VideoTutorialTooltipPromptsHOC />
      </>
  );

}
