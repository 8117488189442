import { AppThunk } from '../../../../spa/store/types';
import { textSpeaker } from '../../../../spa/effects/textSpeaker';
import { SnapWordChecker } from '../../../utils/snapWordChecker';
import { SelectionUtil } from '../../../utils/selection-util';
import { LangUtil } from '../../../utils/lang-util';
import { getActiveGroupTargetLanguage } from '../../../../spa/store/models/selectors';

export class TextWithSpeakerEvents {

  public static onSpeak(
    text: string,
    lang?: string,
    onStop?: () => void
  ): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const selText = SnapWordChecker.getSelectText(SelectionUtil.getSelectedText(), text, lang);
      const speakText = selText || text;
      const priorityLang = getActiveGroupTargetLanguage(getState())?.code;
      textSpeaker.speak(speakText, LangUtil.checkLangCode(lang), true, onStop, priorityLang);
    }
  }

  public static onStopSpeak(
  ): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      textSpeaker.stop();
    }
  }



}