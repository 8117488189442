import React from 'react';
import { SvgIcon, SvgIconProps } from "@mui/material";

export const GoogleIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox='0 0 24 24'>
        <path d="M11.9998 4.75C13.7698 4.75 15.3548 5.36 16.6048 6.55L20.0298 3.125C17.9498 1.19 15.2348 0 11.9998 0C7.30979 0 3.25479 2.69 1.27979 6.61L5.26979 9.705C6.21479 6.86 8.86979 4.75 11.9998 4.75Z" fill="#EA4335"/>
        <path d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z" fill="#4285F4"/>
        <path d="M5.265 14.2949C5.025 13.5699 4.885 12.7999 4.885 11.9999C4.885 11.1999 5.02 10.4299 5.265 9.70486L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.265 14.2949Z" fill="#FBBC05"/>
        <path d="M11.9999 24C15.2399 24 17.9649 22.935 19.9449 21.095L16.0799 18.095C15.0049 18.82 13.6199 19.245 11.9999 19.245C8.8699 19.245 6.2149 17.135 5.2649 14.29L1.2749 17.385C3.2549 21.31 7.3099 24 11.9999 24Z" fill="#34A853"/>
    </SvgIcon>
);
