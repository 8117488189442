import { IState } from '../../../../store/types';
import {
  getMainViewMode,
} from '../../../../store/general/selectors';
import { connect } from 'react-redux';
import { PlaylistsMobile } from '../components/PlaylistsMobile';
import { IPlaylistsMobileEvents, IPlaylistsMobileFields } from '../components/types';
import { EMainViewMode } from '../../../../store/general/types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPlaylistsMobileFields => {
    const mainViewMode = getMainViewMode(state);

    return {
      isShow: mainViewMode === EMainViewMode.PLAYLISTS,
    }
  };
}

export const PlaylistsMobileHOC = connect(
  mapStateToProps,
  null
)(PlaylistsMobile);

PlaylistsMobileHOC.displayName = 'PlaylistsMobileHOC';
