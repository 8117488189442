import { AppThunk } from '../../../../../store/types';
import { setFeedbackModalOpenAction } from '../../../../../store/general/actions';
import { IFeedbackForm } from '../components/types';
import { getAuthUser } from '../../../../../store/general/selectors';
import { FeedbackRest } from '../../../../../../common/rest/feedback/feedbackRest';


export class FeedbackEvents {

	public static onClose(): AppThunk {
		return (
			dispatch
		) => {
			dispatch(setFeedbackModalOpenAction(false));
		}
	}

	public static onSend(
		body: IFeedbackForm,
	): AppThunk {
		return (
			dispatch,
			getState,
		) => {
			const user = getAuthUser(getState());
			const form = new FormData();
			form.append('email', user?.email || '');
			form.append('subject', user?.name || '');
			form.append('description', body.text);

			if (body.files) {
				Array.from(body.files).forEach(file => {
					form.append('attachments[]', file);
				});
			}

			return FeedbackRest.createTicket(form);
		}
	}


}