import React from 'react';
import { theme } from '../../../../../../../common/styles/theme';
import { Modal } from '../../../../../../../common/components/Modal/Modal';
import { Box, Button, CircularProgress, FormGroup, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { Text } from '../../../../../../../common/components/Text/Text';
import { ICopyVideoConfirmProps } from './types';
import { createGroupNameByLangs } from "../../../../../../store/models/selectors";
import { DeviceContext } from '../../../../../../../common/contexts/DeviceContext';
import { COPY_VIDEO_CONFIRM_TEXT } from './wordings';
import { Divider } from '../../../../../../../common/components/Divider/Divider';
import { VideoCard } from '../../../../VideoCard/VideoCard';
import { CheckIcon } from '../../../../../../../../icons/CheckIcon';

export const CopyVideoConfirm: React.FC<ICopyVideoConfirmProps> = ({
    groupId,
    groupName,
    groups,
    show,
    videoInfo,
    langCodes,
	newListName,
    onClose,
    onChange,
}) => {
    if (!show) return null;

    const { isMobile } = React.useContext(DeviceContext);

    const [progress, setProgress] = React.useState<boolean>(false);
	const [selected, setSelected] = React.useState(-1);
	const [_newListName, setNewListName] = React.useState(newListName);
	const [isNewList, setIsNewList] = React.useState(false);
	const [success, setSuccess] = React.useState(false);

	const onChangeHandle = (groupId: number, newListName?: string) => {
		setSelected(groupId);
		setProgress(true);
		try {
			onChange(groupId, newListName);
		} catch(e) {
			setProgress(false);
		}
		setSuccess(true);
	};

	const onChangeSelect = (event: SelectChangeEvent) => {
		const groupId = +event.target.value;
		if (groupId) {
			setSelected(groupId);
		} else {
			setIsNewList(true);
		}
	};

	const onChangeNewList = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewListName(event.target.value);
	};

	const onSubmitNewList = () => {
		onChangeHandle(0, _newListName);
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			onSubmitNewList();
		}
	};

	const CLASS_NEWLIST_CONTROL = 'newlist-control';
	const onBlurNewList = (event: React.FocusEvent) => {
		if (event.relatedTarget && event.relatedTarget.classList.contains(CLASS_NEWLIST_CONTROL)) {
			// not blur
		} else {
			setIsNewList(false);
		}
	};

	const onSubmit = () => {
		onChangeHandle(selected > -1 ? selected : groupId);
	};

	const loader = <Box
		sx={{
			flex: `0 1 50%`,
			height: theme.spacing(5),
			width: 1,
			pt: theme.spacing(1),
			textAlign: 'center',
		}}
	>
		<CircularProgress
			size={theme.spacing(3)}
		/>
	</Box>;
    
    return (
        <Modal
			onClose={onClose}
			open={show}
			isMobile={isMobile}
			PaperProps={{
				sx: {
					width: 1,
					maxWidth: `${theme.spacing(62)} !important`,
				}
			}}
		>
			<Stack
				gap={theme.spacing(4)}
			>
				<Box
					sx={{
						mt: -2,
						p: 2,
						opacity: .8,
						background: theme.palette.grey[50],
						borderRadius: theme.spacing(1),
					}}
				>
					<VideoCard
						author={videoInfo.author}
						img={videoInfo.thumb}
						title={videoInfo.title}
						langCodes={langCodes}
					/>
				</Box>
				
				<Text>
					<Text
						sx={{
							fontSize: theme.typography.h4,
							fontWeight: 600,
						}}
					>
						Select playlist
					</Text>

					<Text
						sx={{
							mt: theme.spacing(.5),
							fontSize: theme.typography.subtitle2,
							fontWeight: 300,
						}}
					>
						{COPY_VIDEO_CONFIRM_TEXT}
					</Text>
				</Text>

				<Box
					sx={{
						position: 'relative',
					}}
				>
					{success
						? <Stack
							sx={{
								height: theme.spacing(5),
								width: 1,
								alignItems: 'center',
							}}
						>
							<Stack
								className={'animate__animated animate__fadeIn'}
								justifyContent={'center'}
								alignItems={'center'}
								sx={{
									width: theme.spacing(4),
									height: theme.spacing(4),
									border: `3px solid ${theme.palette.success.main}`,
									borderRadius: '50%',
								}}
							>
								<CheckIcon sx={{
									width: theme.spacing(2),
									color: theme.palette.success.main,
								}} />  
							</Stack>
						</Stack>
						: <FormGroup
							sx={{
								flexFlow: 'row nowrap',
								gap: theme.spacing(1),

								[theme.breakpoints.down('sm')]: {
									flexWrap: 'wrap',
									rowGap: theme.spacing(1),
								}
							}}
							onKeyDown={handleKeyDown}
							onBlur={onBlurNewList}
						>
							{isNewList || !groups.length
								? <>     
									<TextField
										autoFocus
										fullWidth
										value={_newListName}
										onChange={onChangeNewList}
										multiline={false}
										variant={'filled'}
										sx={{
											flex: `0 1 50%`,
										}}
									/>
									{progress
										? loader
										: <Button
											className={CLASS_NEWLIST_CONTROL}
											disabled={progress}
											onClick={onSubmitNewList}
											variant={'contained'}
											fullWidth
											sx={{
												flex: `0 1 50%`,

												'&[disabled]': {
													background: theme.palette.grey[100],
												}
											}}
										>
											Create new playlist
										</Button>
									}
								</>
								: <>
									<Select
										disabled={progress}
										variant={'filled'}
										fullWidth
										value={String(selected)}
										onChange={onChangeSelect}
										sx={{
											flex: `1 1 50%`,
											maxWidth: selected < 0 ? '100%' : '50%',
										}}
										MenuProps={{
											anchorOrigin: {
												vertical: 'bottom',
												horizontal: 'left', 
											},
											transformOrigin: {
												vertical: 'top',
												horizontal: 'left', 
											},
										}}
									>
										<MenuItem
											disabled
											value={'-1'}
											key={'placeholder'}
											sx={{
												display: 'none',
											}}
										>
											Select playlist
										</MenuItem>

										{groups.map((group) => {
											return <MenuItem
												key={group.id}
												value={group.id}
											>
												{group.name || createGroupNameByLangs(group)}
											</MenuItem>
										})}

										<Divider sx={{
											mt: theme.spacing(.5),
											mb: theme.spacing(.5),
										}}/>

										<MenuItem
											key={0}
											value={0}
										>
											Create new playlist
										</MenuItem>
									</Select>

									{progress
										? loader
										: selected > -1 &&
											<Button
												disabled={progress}
												onClick={onSubmit}
												variant={'contained'}
												fullWidth
												sx={{
													flex: `0 1 50%`,

													'&[disabled]': {
														background: theme.palette.grey[100],
													}
												}}
											>
												OK
											</Button>
									}
								</>
							}
						</FormGroup>
					}
				</Box>
			</Stack>
		</Modal>
    );
}
