export const SET_PHRASE_SELECT_POPUP_SHOW_ACTION = 'SET_PHRASE_SELECT_POPUP_SHOW_ACTION';

export interface IPhraseSelectPopupState {
  show: boolean;
}

export interface IPhraseSelectPopupSetShowAction {
  type: typeof SET_PHRASE_SELECT_POPUP_SHOW_ACTION;
  show: boolean;
}

export type IPhraseSelectPopupActions =
  | IPhraseSelectPopupSetShowAction