import { IState } from '../../../../store/types';
import {
  getMainViewMode,
} from '../../../../store/general/selectors';
import { connect } from 'react-redux';
import { PromptsMobile } from '../components/PromptsMobile';
import { IPromptsMobileEvents, IPromptsMobileFields } from '../components/types';
import { EMainViewMode } from '../../../../store/general/types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPromptsMobileFields => {
    const mainViewMode = getMainViewMode(state);

    return {
      isShow: mainViewMode === EMainViewMode.PHRASE_DETAILS,
    }
  };
}

export const PromptsMobileHOC = connect(
  mapStateToProps,
  null
)(PromptsMobile);

PromptsMobileHOC.displayName = 'PromptsMobileHOC';
