import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabsEditorContainer } from '../components/PhraseDetailsTabsEditorContainer';
import { IPhraseDetailsTabsEditorContainerEvents, IPhraseDetailsTabsEditorContainerFields } from '../components/types';
import { PhraseDetailsTabsEditorContainerEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabsEditorContainerFields => {
    return {

    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabsEditorContainerEvents => ({
    onAddFromLib: () => dispatch(PhraseDetailsTabsEditorContainerEvents.onAddFromLib()),
    onCreateNew: () => dispatch(PhraseDetailsTabsEditorContainerEvents.onCreateNew()),
    onReset: () => dispatch(PhraseDetailsTabsEditorContainerEvents.onReset()),
  }
);

export const PhraseDetailsTabsEditorContainerHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabsEditorContainer);

PhraseDetailsTabsEditorContainerHOC.displayName = 'PhraseDetailsTabsEditorContainerHOC';