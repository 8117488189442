import React from 'react';
import { IOneTimeTooltipNativeLanguageButtonEvents } from './types';
import { Button } from '@mui/material';
import { COMMON_CLOSE } from '../../wordings';
import { CloseIcon } from '../../../../../../../icons/CloseIcon';

export const OneTimeTooltipNativeLanguageButton = ({
	onClick,
}: IOneTimeTooltipNativeLanguageButtonEvents) => {
	return (
		<Button
			onClick={onClick}
		>
			<CloseIcon sx={{
				width: '8px',
				mr: '6px',
			}} /> {COMMON_CLOSE}
		</Button>
	);
}
