import { AppThunk } from '../../../../../store/types';
import { getAuthUser } from '../../../../../store/general/selectors';
import { setUserTeacherModeAction } from '../../../../../store/general/actions';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';

export class UserTeacherModeSwitchEvents {

  public static onSwitch(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const {teacherMode} = getAuthUser(getState());
      dispatch(setUserTeacherModeAction(!teacherMode));
      await PhraseEffects.reloadPhrasesNotes();
    }
  }
}
