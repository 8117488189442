import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { AddVideoUrl } from '../components/AddVideoUrl';
import { IAddVideoUrlEvents, IAddVideoUrlFields } from '../components/types';
import { AddVideoUrlEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IAddVideoUrlFields => {
    return {
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IAddVideoUrlEvents => ({
    onLoad: videoUrl => dispatch(AddVideoUrlEvents.onLoad(videoUrl)),
  }
);

export const AddVideoUrlHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVideoUrl);

AddVideoUrlHOC.displayName = 'AddVideoUrlHOC';
