import { IState } from '../../../../store/types';
import { ICopyVideoEvents, ICopyVideoFields } from '../../CopyVideo/CopyVideo/components/types';
import { connect } from 'react-redux';
import { CopyGroup } from '../components/CopyGroup';
import { CopyGroupEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): ICopyVideoFields => {
    return {}
  };
}

const mapDispatchToProps = (
  dispatch: any
): ICopyVideoEvents => ({
    onLoad: hash => dispatch(CopyGroupEvents.onLoad(hash))
  }
);

export const CopyGroupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyGroup);

CopyGroupHOC.displayName = 'CopyGroupHOC';
