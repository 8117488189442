import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PromptsList } from '../components/PromptsList';
import { IPromptsListEvents, IPromptsListFields } from '../components/types';
import { getPhraseDetailsTabs } from '../../../../../store/models/selectors';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';
import { PromptsListEvents } from './events';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { IPromptsListHOCOwnProps } from './types';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IPromptsListFields => {
		const tabs = getPhraseDetailsTabs(state)
			.filter(t => {
				return t.type === EPhraseDetailsTabType.EXPLAIN ||
					t.type === EPhraseDetailsTabType.USAGE ||
					t.type === EPhraseDetailsTabType.CUSTOM_PROMPT && !t.isTemp
			})
		
		const lib = PhraseDetailsTabsEditorPopupSelectors.getPromptLibs(state);
		const loading = !lib;
		return {
			lib,
			loading,
			tabs,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any,
	props: IPromptsListHOCOwnProps,
): IPromptsListEvents => ({
		onLoad: () => dispatch(PromptsListEvents.onLoad()),
	}
);

export const PromptsListHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(PromptsList);

PromptsListHOC.displayName = 'PromptsListHOC';
