import React from 'react';
import { IVideoLibraryHeaderSelectItemTextProps } from './types';
import { Tooltip } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from "../../../../../../common/components/Text/Text";

export const VideoLibraryHeaderSelectItemText: React.FC<IVideoLibraryHeaderSelectItemTextProps> = ({
    title,
    maxWidth
}) => {
    const [isWrap, setWrap] = React.useState(false);
    const ref = React.useRef<HTMLElement | null>(null);

    React.useEffect(() => {
        const item = ref.current;
        if (item) setWrap(item.offsetWidth > maxWidth);
    }, []);

    return (
        <Tooltip
            sx={{ bgcolor: theme.palette.grey[900] }}
            title={isWrap ? <Text sx={{ color: theme.palette.common.white }}>{title}</Text> : ''}
            arrow
            placement='right'
        >
            <Text
                ref={ref}
                noWrap={isWrap}
                sx={{
                    fontSize: theme.typography.subtitle2,
                    background: 'none',
                }}
            >
                {title}
            </Text>
        </Tooltip>
    );
}
