import { AppThunk } from '../store/types';
import { setLangsAction, setUserLanguages } from '../store/models/actions';
import { LanguageRest } from '../../common/rest/userLanguage/languageRest';

export const updateUserLanguages = (loadUserLangs: boolean = true): AppThunk => async (
  dispatch,
  getState
) => {
  const langList = await LanguageRest.getAllLanguages();
  dispatch(setLangsAction(langList));

  if (loadUserLangs) {
    const userLangs = await LanguageRest.fetchUserLanguages();
    dispatch(setUserLanguages(userLangs));
  }
}