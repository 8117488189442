import { EMessageType } from './utils/messageSender';


export type TTokenInfo = {
  iss: string; //"https://accounts.google.com",
  sub: string; //"110169484474386276334",
  azp: string; //"1008719970978-hb24n2dstb40o45d4feuo2ukqmcc6381.apps.googleusercontent.com",
  aud: string; //"1008719970978-hb24n2dstb40o45d4feuo2ukqmcc6381.apps.googleusercontent.com",
  iat: string; //"1433978353",
  exp: string; //"1433981953",

  // These seven fields are only included when the user has granted the "profile" and
  // "email" OAuth scopes to the application.
  email: string; //"testuser@gmail.com",
  email_verified: string; //"true",
  name : string; //"Test User",
  picture: string; //"https://lh4.googleusercontent.com/-kYgzyAWpZzJ/ABCDEFGHI/AAAJKLMNOP/tIXL9Ir44LE/s99-c/photo.jpg",
  given_name: string; //"Test",
  family_name: string; //"User",
  locale: string; //"en"
}

export type TMessage = {
  type: EMessageType;
  payload?: any
}

export type TClientInitProps = {
  clientId: string,
  scope: string;
  fetch_basic_profile?: boolean;
}

export type TClientProps = {
  init: (options: TClientInitProps) => Promise<any>
}

export type TGapi = {
  client: TClientProps;
  load: (lib: string, callback: any) => void
  auth2: any
}

export type InnerModel<KeyType extends string, InnerModel> = {
  [key in KeyType]: InnerModel;
}

export interface IMessage {
  type: EMessageType;
  payload: any;
}

export enum ESettingLanguageKey {
  nativeLanguage = 'nativeLanguage',
  targetLanguage = 'targetLanguage'
}

export type TAction<Type> = {
  type: Type;
}

export type TPauseItem = {
  value: number;
  text: string | number
}

export interface IGoogleAnalytic {
  push: (data: any[]) => void;
}

export const EMPTY_ARRAY: any[] = [];
