import { setMainViewMode } from '../../../../store/general/actions';
import { EMainViewMode } from '../../../../store/general/types';
import { AppThunk } from '../../../../store/types';

export class NoVideoMobileEvents {

  public static onShow(
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setMainViewMode(EMainViewMode.PLAYLISTS));
    }
  }
}