import { styled, TextareaAutosize, TextareaAutosizeProps, } from '@mui/material';

export const Textarea = styled(TextareaAutosize)<TextareaAutosizeProps>(({ theme }) => ({
    display: 'block',
    width: `100%`,
	padding: theme.spacing(1),

    fontFamily: 'inherit',
    lineHeight: 1.4,

	border: `2px solid ${theme.palette.grey[200]}`,
	borderRadius: theme.spacing(.75),

	'&:focus': {
		border: `2px solid ${theme.palette.primary.main}`,
	},

	'&:focus-visible': {
		outline: 0,
	}
}));
