import * as React from 'react';
import { ILangFlagProps } from './types';
import { theme } from '../../../../common/styles/theme';
import { Text } from '../../../../common/components/Text/Text';
import { Stack } from '@mui/material';
import emojiFlags from 'emoji-flags';
import { getLangCodeTitle, LANGUAGE_TO_COUNTRY_CODE } from './constants';
import { LogoSmall } from '../../../../../icons/LogoSmall';

export const LangFlag: React.FC<ILangFlagProps> = ({ code, showCode }) => {
  if (!code) return null;

  const className = 'lang-flag';

  const countryCode = LANGUAGE_TO_COUNTRY_CODE[code.toLowerCase()];
  const flag = countryCode ? emojiFlags.countryCode(countryCode.toUpperCase()) : null;
  const codeTile = getLangCodeTitle(code);

  return (
    <Stack direction={'row'} gap={theme.spacing(0.5)} sx={containerStyle}>
      {flag ? (
        <Text variant={'h6'} sx={flagStyle}>
          {flag.emoji}
        </Text>
      ) : (
        <LogoSmall sx={{
          width: theme.spacing(1.8),
          height: theme.spacing(1.8),
        }} />
      )}
      {showCode &&
        <Text
          className={`${className}__code`}
          sx={textStyle}
        >
          {codeTile}
        </Text>
      }
    </Stack>
  );
};

const containerStyle = {
  alignItems: 'center',
};

const flagStyle = {
  userSelect: 'none',
};

const textStyle = {
  textTransform: 'uppercase',
  fontWeight: 600,
  userSelect: 'none',
};

export default LangFlag;