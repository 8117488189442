import React from 'react';
import { ISavedVideoConfirmPopupProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { ESavedVideoConfirmShowMode } from '../../../../../store/general/types';
import { SavedVideoConfirmNotifyHOC } from '../../notify/hocs/SavedVideoConfirmNotifyHOC';
import { SavedVideoConfirmErrorHOC } from '../../error/hocs/SavedVideoConfirmErrorHOC';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { Modal } from '../../../../../../common/components/Modal/Modal';


export const SavedVideoConfirmPopup: React.FC<ISavedVideoConfirmPopupProps> = ({
  showMode,
  onClose
}) => {
  const { isMobile } = React.useContext(DeviceContext);

  const isNotify = showMode === ESavedVideoConfirmShowMode.NOTIFY;
  const isError = showMode === ESavedVideoConfirmShowMode.ERROR;

  return (
    <Modal
      open={showMode !== ESavedVideoConfirmShowMode.HIDE}
      onClose={onClose}
      isCloseButton={!isNotify}
			isMobile={isMobile}
			PaperProps={{
				sx: {
          width: 1,
					maxWidth: `${theme.spacing(62)} !important`,
				}
			}}
		>
     <>
       {isNotify && <SavedVideoConfirmNotifyHOC/>}
       {isError && <SavedVideoConfirmErrorHOC/>}
     </>
    </Modal>
  );
}
