import * as React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Box, CircularProgress, FormGroup, Stack, SxProps } from '@mui/material';
import { EStatDateRangeId, IStatisitcsProps, statDateRangeTitles, TStatsResultList } from './types';
import { StatisitcsItem } from './StatisticsItem';
import { Text } from '../../../../../../common/components/Text/Text';

export const Statisitcs: React.FC<IStatisitcsProps> = ({
  show,
  onLoad
}) => {
  if (!show) return null;

  const [progress, setProgress] = React.useState<boolean>(false);
  const [statResultList, setStatResultList] = React.useState<TStatsResultList | null>(null);

  React.useEffect(() => {
    setProgress(true);
    setStatResultList(null);
    onLoad().then((result) => {
      setProgress(false);
      setStatResultList(result);
    }).catch((e) => {
      setProgress(false);
      console.log('error', e);
    })
  }, [])


  return (

        <FormGroup
            className={`${progress ? 'progress' : ''}`}
            sx={{
              gap: theme.spacing(2),

              '&.progress *': {
                pointerEvents: 'none',
                opacity: .3,
              },
            }}
          >

          {statResultList &&
          <Stack direction={'column'}>

            <Stack direction={'row'}>
              <Box style={{minWidth: '250px'}}></Box>
              {statResultList.map(item => {
                return (
                  <Box
                    style={{minWidth: '200px'}}
                    key={item.dateRangeId}
                  >
                    <Text variant='h6' fontWeight={600}>
                      {statDateRangeTitles[item.dateRangeId as EStatDateRangeId]}
                    </Text>
                  </Box>
                )
              })}
            </Stack>

            <StatisitcsItem
                title={'Translations accessed'}
                dataKey={'translationCount'}
                resultList={statResultList}
            />
            <StatisitcsItem
                title={'Saved phrases'}
                dataKey={'phraseSaveCount'}
                resultList={statResultList}
            />
            <StatisitcsItem
              title={'Prompts accessed'}
              dataKey={'promptAccessCount'}
              resultList={statResultList}
            />
            <StatisitcsItem
              title={'Video watch time (minutes)'}
              dataKey={'videoWatchMin'}
              resultList={statResultList}
            />

          </Stack>
          }

          {progress &&
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress
              size={theme.spacing(3)}
            />
          </Box>
          }

          </FormGroup>



  );
};