import { AppThunk } from '../../../../../store/types';
import { CopyGroupEffects } from '../../../../../effects/copyGroupEffects';
import { updateUserGroups } from '../../../../../effects/updateUserGroups';
import { isAuthorised } from '../../../../../store/general/selectors';
import { setCopyGroupOnLoginAction } from '../../../../../store/general/actions';
import { Navigator } from '../../../../../effects/navigator';

export class ProfileGroupInfoEvents {

  public static onCopyGroup(groupId: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      if (!isAuthorised(getState())) {
        dispatch(setCopyGroupOnLoginAction({groupId}));
        Navigator.openDefault();
      } else {
        const result = await CopyGroupEffects.copyPublic(groupId);
        if (result) {
          await dispatch(updateUserGroups(true));
        }
      }

    }
  }

}