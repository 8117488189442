import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { IVideoTutorialTooltipProps } from './types';
import { Paper } from "../../../../../../common/components/Paper/Paper";
import { Text } from "../../../../../../common/components/Text/Text";
import { CloseIcon } from "../../../../../../../icons/CloseIcon";
import { IconButton } from "@mui/material";

export const VideoTutorialTooltip: React.FC<IVideoTutorialTooltipProps> = ({
                                                               show,
                                                               videoId,
  title,
                                                               onClose
}) => {

   if (!show) return null;

  const containerStyle = {
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      p: theme.spacing(2),
      right: theme.spacing(2),
      bottom: theme.spacing(8),
      width: '30%',
      minWidth: theme.spacing(35),
      borderRadius: theme.spacing(),
      boxShadow: theme.shadows[16],
    'iframe': {
      width: '100%',
      borderRadius: theme.spacing(0.5),
      border: 'none',
      aspectRatio: '16/9'
    }
  };

  return (
    <Paper sx={containerStyle}>
      <IconButton
        onClick={onClose}
        color={'primary'}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
            color: theme.palette.grey[400],
            '&:hover, &:active, &:focus': { color: theme.palette.grey[500] },
        }}
      >
        <CloseIcon />
      </IconButton>
      <Text fontWeight={600} variant={'h6'}>{title}</Text>
      <iframe
        src={'https://www.youtube.com/embed/' + videoId}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
      />
    </Paper>
  );
}
