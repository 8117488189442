import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { CardMedia, ListItem, ListItemButton, Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { IAudioLibraryGroupItemProps } from './types';

export const AudioLibraryGroupItem: React.FC<IAudioLibraryGroupItemProps> = ({
                                                                               isOpened,
                                                                               onClick,
                                                                               onDelete,
                                                                             }) => {

  const [show, setShow] = React.useState(false);

  const [className, setClassName] = React.useState<string>('');
  const itemRef = React.useRef<HTMLElement | null>(null);
 /* const [menuBtnAnchor, setMenuBtnAnchor] = React.useState<null | HTMLElement>(null);
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const menuBtnRef = React.useRef<HTMLButtonElement | null>(null);*/

 /* const handleDelete = () => {
    setAnchor(null);
    onDelete();
  };*/

  const onMouseOver = () => {
    setShow(true);
  };

  const onMouseLeave = () => {
    setShow(false);
  };

  const imageStyle = {
    minWidth: theme.spacing(14.25),
    maxWidth: theme.spacing(14.25),
    height: theme.spacing(9),
    borderRadius: theme.spacing(0.75),
  };
/*
  const handleMenuButton = (e: React.MouseEvent<HTMLElement>) => {
    setMenuBtnAnchor(e.currentTarget);
    setShowMenu(true);
  }
  const handleCloseMenu = () => {
    setMenuBtnAnchor(null);
    setShowMenu(false);
  }

  const handleConfirmDelete = () => {
    setMenuBtnAnchor(null);
    setShowMenu(false);
    setAnchor(menuBtnRef.current);
  }*/

  return (
    <ListItem
      className={'video-library-item'}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      sx={{ p: 0 }}
    >
      <ListItemButton
        onClick={onClick}
        sx={{
          p: theme.spacing(),
          borderRadius: theme.spacing(0.75),
          bgcolor: isOpened ? `${theme.palette.primary[100]} !important` : ''
        }}
      >            <Text
        fontWeight={600}
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden'
        }}
      >
        AUDIO
      </Text>
        <Stack
          ref={itemRef}
          className={className}
          direction='row'
          spacing={theme.spacing(2)}
          sx={{ alignItems: 'center', pr: theme.spacing(4), overflow: 'hidden' }}
        >
          <Stack
            spacing={'3px'}
            sx={{
              maxWidth: `calc(100% - ${theme.spacing(15.75)})`, // - img width + gap
              height: theme.spacing(9) // image height
            }}
          >
          </Stack>
        </Stack>
      </ListItemButton>

   {/*
      {showActionMenu &&
      <>
        <ListItemSecondaryAction
          onMouseOver={onMouseOver}
          sx={{ right: 0 }}
        >
          <IconButton
            ref={menuBtnRef}
            onClick={handleMenuButton}
            color={'primary'}
            sx={{
              opacity: show ? 1 : 0,
              color: theme.palette.grey[400],
              '&:hover, &:active, &:focus': { color: theme.palette.grey[600] },
              '.MuiSvgIcon-root': { width: theme.spacing(2), height: theme.spacing(2) }
            }}
          >
            <MoreIcon/>
          </IconButton>
          <Menu
            id='account-menu'
            anchorEl={menuBtnAnchor}
            keepMounted
            open={showMenu}
            onClose={handleCloseMenu}
            sx={{
              ml: -1,

              '.MuiMenuItem-root': {
                pl: theme.spacing(.5),
              }
            }}
          >
            <IconMenuItem
              title={'Delete'}
              onClick={handleConfirmDelete}
            >
              <TrashIcon />
            </IconMenuItem>
          </Menu>
        </ListItemSecondaryAction>

        <ConfirmPopup
          anchor={anchor}
          text={'Delete audio?'}
          onConfirm={handleDelete}
          onClose={() => setAnchor(null)}
        />
      </>
      }*/}

    </ListItem>
  );
};
