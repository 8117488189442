import { AppThunk } from '../../../../../store/types';
import { PlayerController } from '../../../../../effects/player/manager/playerController';
import { SelectionUtil } from '../../../../../../common/utils/selection-util';
import { ICaptionsItem, PlayerStates } from '../../../../../types/common';
import {
  IPhraseSelectResult,
  PhraseSelectPreparator
} from '../../../../../effects/phrase/utils/phrase-select-preparator';
import { LangUtil } from '../../../../../../common/utils/lang-util';
import { getNativeCaptions } from '../../../../../store/videos/selectors';
import {
  getActiveUserGroup,
  getGroupNativeLanguage, getGroupTargetLanguage,
  getUserLanguageByStatus
} from '../../../../../store/models/selectors';
import { PhraseDetailsEffects } from '../../../../../effects/phrase-details/phrase-details-effects';
import { getPlayerState } from '../../../../../store/general/selectors';
import { ELanguageStatus } from '../../../../../store/general/types';
import { CaptionsSelectionPopupActions } from '../../../../../store/captions-selection-popup/actions';

export class NativeCaptionsEvents {

  public static onCaptionClick(
    startSelIndex: number,
    endSelIndex: number,
    isMobile?: boolean,
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (startSelIndex < 0 || endSelIndex < 0) return;

      const state = getState();
      PlayerController.getInstance().pause();
      const selection = SelectionUtil.getSelectedText();
      const startIndex = startSelIndex < endSelIndex ? startSelIndex : endSelIndex;
      const endIndex = startSelIndex > endSelIndex ? startSelIndex : endSelIndex;
      const group = getActiveUserGroup(state);
      const nativeUserLang = getUserLanguageByStatus(state, ELanguageStatus.KNOW);
      const nativeLang = getGroupNativeLanguage(state, group);
      const captions = getNativeCaptions(getState());
      let text;
      if (selection.toString()) {
        text = NativeCaptionsEvents.getSelectionText(selection, captions, startIndex, endIndex, nativeLang.code);
      } else {
        text = captions[startSelIndex].text;
      }
      const context = NativeCaptionsEvents.getSelectionContext(captions, startIndex, endIndex);

      PhraseDetailsEffects.showFromNativeSelect(nativeLang, nativeUserLang, text, context);
      if (getPlayerState(getState()) === PlayerStates.PLAYING) {
        PlayerController.getInstance().pause();
      }

      if (isMobile) dispatch(CaptionsSelectionPopupActions.setShow(true)); // show translation
    }
  }

  private static getSelectionContext(captions: ICaptionsItem[], startSelIndex: number, endSelIndex: number) {
    let result = '';
    for (let i=startSelIndex; i<=endSelIndex; i++) {
      if (i >= 0 && i < captions.length) {
        result += captions[i].text + ' ';
      }
    }
    return result;
  }

  private static getSelectionText(selection: Selection, captions: ICaptionsItem[], startIndex: number, endIndex: number, langCode: string): string {
    let s = selection.toString().trim().replace(/[\n\r]/gm, ' ');
    if (!s) return '';

    const context = NativeCaptionsEvents.getSelectionContext(captions, startIndex, endIndex);

    const startPosition = context.indexOf(s);
    if (startPosition >= 0) {
      const result: IPhraseSelectResult = {
        element: undefined, endCaptionIndex: 0, endTime: 0, startCaptionIndex: 0, startTime: 0,
        text: s,
        startPosition,
        endPosition: startPosition + s.length
      }
      const useCheckSpanWords  = !LangUtil.isHieroglyphLang(langCode);
      if (useCheckSpanWords) {
        const selection = SelectionUtil.getSelectedText();
        if (selection) {
          const ret = new PhraseSelectPreparator(selection, [{
            startTime:0, endTime:0, duration:0, text: context
          }], [], [], null, true).checkSpanWords(result);
          s = ret.text;
        }
      }
    }

    return s;
  }


}