export class FeedbackRest {

	private static API_KEY = 'ocuC0RfwooUdWXnphoOK';

	private static HEADERS = {
		// 'Content-Type': 'application/json',
		'Authorization': 'Basic ' + btoa(this.API_KEY + ':X')
	}

	private static PARAMS = {
		priority: 1, // Low
		source: 2, // Portal
		status: 2, // Open
	}

	private static API_URL = 'https://langmagic.freshdesk.com/api/v2';

	private static URL_TICKETS = this.API_URL + '/tickets';

  
	public static async createTicket(
		body: FormData,
	): Promise <any> {
		body.append('priority', String(this.PARAMS.priority));
		body.append('source', String(this.PARAMS.source));
		body.append('status', String(this.PARAMS.status));

		const response = await fetch(this.URL_TICKETS, {
			method: 'POST',
			headers: this.HEADERS,
			body,
		});
		return await response.json();
	}

}
