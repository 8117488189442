import { EXTENSION_ID } from '../constants';
import { TUserGroup } from '../../spa/store/models/types';
import { TAuthUser } from '../../spa/store/general/types';

export enum EExtMessageType {
  GET_VIDEO_CAPTIONS,
  SET_USER_GROUPS,
  SET_AUTH_USER,
  GET_INSTALL_DATE,
  CLEAR_INSTALL_DATE,
  PING
}

export interface IExtMessagePayloadVideoId {
  videoUrl: string
}

export interface IExtMessagePayloadUserGroups {
  userGroups: TUserGroup[]
}

export interface IExtMessagePayloadAuthUser {
  authUser: TAuthUser | null
}

export type TExtMessagePayload =
  IExtMessagePayloadVideoId | IExtMessagePayloadUserGroups | IExtMessagePayloadAuthUser

export type TExtMessage = {
  type: EExtMessageType;
  payload?: TExtMessagePayload
}

export class ExtMessageSender {

  public static async send(message: TExtMessage): Promise<any> {
    return new Promise<any>(resolve => {
      if (chrome.runtime) {
        chrome.runtime.sendMessage(EXTENSION_ID, message, resolve);
      } else {
        resolve(false);
      }
    })
  }
}
