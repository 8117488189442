import { IState } from '../../../../../store/types';
import { getLibraryGroup, getUserGroupLangKnown, getUserGroupLangToLearn } from '../../../../../store/models/selectors';
import { connect } from 'react-redux';
import { VideoLibraryFlags } from '../components/VideoLibraryFlags';
import { IVideoLibraryFlagsFields } from '../components/types';
import { LangUtil } from '../../../../../../common/utils/lang-util';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IVideoLibraryFlagsFields | null => {
    const group = getLibraryGroup(state);
    if (!group) return {
      show: false
    };
    const nativeLang = getUserGroupLangKnown(state, group);
    const targetLang = getUserGroupLangToLearn(state, group);
    return {
      show: true,
      nativeLangCode: LangUtil.checkLangCode(nativeLang?.code),
      targetLangCode: LangUtil.checkLangCode(targetLang?.code)
    }
  };
}

export const VideoLibraryFlagsHOC = connect(
  mapStateToProps,
  null,
)(VideoLibraryFlags);

VideoLibraryFlagsHOC.displayName = 'VideoLibraryFlagsHOC';