import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabsCreateContainer } from '../components/PhraseDetailsTabsCreateContainer';
import { IPhraseDetailsTabsCreateContainerEvents, IPhraseDetailsTabsCreateContainerFields } from '../components/types';
import { PhraseDetailsTabsCreateContainerEvents } from './events';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabsCreateContainerFields => {
    return {
      createNew: !PhraseDetailsTabsEditorPopupSelectors.getEditTabId(state)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabsCreateContainerEvents => ({
    onLoad: () => dispatch(PhraseDetailsTabsCreateContainerEvents.onLoad())
  }
);

export const PhraseDetailsTabsCreateContainerHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabsCreateContainer);

PhraseDetailsTabsCreateContainerHOC.displayName = 'PhraseDetailsTabsCreateContainerHOC';