const path = require('path');

const rootPath = process.cwd();
const backendOutputPath = path.resolve(rootPath, 'build/backend');
const pluginOutputPath = path.resolve(rootPath, 'build/plugin');
const spaPath = path.resolve(rootPath, 'src/spa');
const popupPath = path.resolve(rootPath, 'src/extension/popup');
const backgroundPath = path.resolve(rootPath, 'src/extension/background');
const youtubeButtonInjectPath = path.resolve(rootPath, 'src/extension/youtube-button');
const publicPath = '/build/';

const mode = process.env.NODE_ENV;
const production = 'production';
const development = 'development';
const isProd = mode === production;
const isDev = mode === development;
const isTestMode = 0;

module.exports = {
  rootPath,
  popupPath,
  spaPath,
  backgroundPath,
  mode,
  production,
  development,
  isProd,
  isDev,
  isTestMode,
  publicPath,
  youtubeButtonInjectPath,
  backendOutputPath,
  pluginOutputPath
};
