import { IState } from '../../../../../store/types';
import { IPhraseTextFields } from '../../PhraseText/components/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { connect } from 'react-redux';
import { PhraseText } from '../../PhraseText/components/PhraseText';
import {
  getVideoTeacherPhraseById,
  getVideoTeacherPhraseByWordId,
  getVideoTeacherShowPhraseId,
  getVideoTeacherUserActiveId
} from '../../../../../store/videos/selectors';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseTextFields => {
    const userId = getVideoTeacherUserActiveId(state);
    const phraseId = getVideoTeacherShowPhraseId(state);
    const phrase = getVideoTeacherPhraseById(state, userId, phraseId);
    const contextPhrase = getVideoTeacherPhraseByWordId(state, userId, phrase?.wordPhraseId || 0);
    const text = phrase?.highlighted || '';
    const context = contextPhrase?.fullPhrase || phrase?.fullPhrase || '';
    const fromLangCode = PhraseDetailsSelectors.getFromLang(state)?.code || '';
    return {
      context,
      fromLangCode,
      text,
      showEditContext: false,
      isContext: contextPhrase && PhraseEffects.isContextExist(contextPhrase),
    }
  };
}

export const PhraseTeacherTextHOC = connect(
  mapStateToProps,
  null
)(PhraseText);

PhraseTeacherTextHOC.displayName = 'PhraseTeacherTextHOC';
