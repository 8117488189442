import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { getRequest, postRequest } from '../../utils/requestService';
import { TUserGptPrompt } from '../../../spa/store/models/types';

export class UserGptPromptRest {

  private static LIST_API_URL = API_URL + '/userGptPrompt/list';
  private static SAVE_API_URL = API_URL + '/userGptPrompt/save';
  private static REMOVE_API_URL = API_URL + '/userGptPrompt/remove';

  public static async getLists(): Promise<TUserGptPrompt[]> {
    const dispatch = getDispatch();
    return await dispatch(getRequest(UserGptPromptRest.LIST_API_URL));
  }

  public static async save(prompt: TUserGptPrompt): Promise<TUserGptPrompt> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(UserGptPromptRest.SAVE_API_URL, prompt));
  }

  public static async remove(id: number): Promise<void> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(UserGptPromptRest.REMOVE_API_URL, {id}));
  }
}