import { PhraseDetailsGptchatService } from './phrase-details-gptchat-service';
import { getDispatch, getState } from '../../../store';
import { PhraseDetailsActions } from '../../../store/phrase-details/actions';
import { PhraseDetailsSelectors } from '../../../store/phrase-details/selectors';
import { getUserGptPromptById } from '../../../store/models/selectors';

export class PhraseDetailsCustomPromptService extends PhraseDetailsGptchatService {

  private id: number;

  constructor(id: number, text: string, context: string, targetLangCode: string, nativeLangCode: string) {
    super(text, context, targetLangCode, nativeLangCode);
    this.id = id;
  }

  protected saveHash(hash: string): void {
    getDispatch()(PhraseDetailsActions.setCustomPromptHash(this.id, hash));
  }

  protected getHash(): string {
    const customPrompt = PhraseDetailsSelectors.getCustomPrompt(getState(), this.id);
    return customPrompt && customPrompt.hash || '';
  }

  protected async getPromptText(): Promise<string> {
    const state = getState();
    const gptUserPrompt = getUserGptPromptById(state, this.id);
    return gptUserPrompt?.prompt || '';
  }

}