import { ELogRecordQueueType, StatRest, TStatDateRange, TStatsResponse } from '../rest/stats/statRest';
import { IDateRange } from '../../spa/components/dashboard/ToolbarPanel/Statisitcs/components/types';

export class StatLogManager {

  public static async logTranslation() {
    return StatRest.sendLog({type: ELogRecordQueueType.TRANSLATION});
  }

  public static async logPromptAccess() {
    return StatRest.sendLog({type: ELogRecordQueueType.PROMPT_ACCESS});
  }

  public static async logPhraseSave() {
    return StatRest.sendLog({type: ELogRecordQueueType.PHRASE_SAVE});
  }

  public static async logVideoWatch(sec: number) {
    return StatRest.sendLog({type: ELogRecordQueueType.VIDEO_WATCH, value: sec});
  }

  public static async getStat(dateRanges: IDateRange[]): Promise<TStatsResponse> {
    const reqDateRanges: TStatDateRange[] = dateRanges.map(dt => {
      return {
        id: dt.id,
        fromDate: this.getDateParam(dt.from),
        toDate: this.getDateParam(dt.to)
      }
    })
    return StatRest.getStat(reqDateRanges)
  }

  private static getDateParam(date: Date): string {
    return date.toISOString().substring(0, 10);
  }

}