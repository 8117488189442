import React from 'react';
import { Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from '../../../../../../common/styles/theme';
import { ISavedVideoConfirmErrorProps } from './types';

export const SavedVideoConfirmError: React.FC<ISavedVideoConfirmErrorProps> = ({
	errorMessage,
	videoUrl
}) => {

  return (
    <Stack >
      <Text
				sx={{
					fontSize: theme.typography.subtitle2,
				}}
			>
				{!videoUrl &&
					<>
						<Text
							sx={{
								mt: -2,
								fontSize: theme.typography.h6,
								fontWeight: 600,
							}}
						>
							An error occurred while adding the video:
						</Text>
						{errorMessage}
					</>
				}

				{videoUrl && <>
					<Text
					sx={{
						mt: -2,
						fontSize: theme.typography.h6,
						fontWeight: 600,
					}}
				>
					Something went wrong!
				</Text>
					Try opening the video in YouTube, following this <a href={videoUrl} target={'_blank'}>link</a>, and try adding it again using LangMagic Chrome extension.
				</>
				}
			</Text>

    </Stack>
  );
}
