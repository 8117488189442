import img01 from './imgs/img01.jpg';
import img02 from './imgs/img02.png';
import img03 from './imgs/img03.png';
import img04 from './imgs/img04.png';


export const LOGIN_FEATURES = [
    {
        title: `Use the power of ChatGPT for pattern learning`,
        imgUrl: img01
    },
    {
        title: 'Translate to multiple languages simultaneously',
        imgUrl: img02
    },
    {
        title: 'Save and repeat phrases with context',
        imgUrl: img03
    },
    {
        title: 'Pre-made playlists at your hand',
        imgUrl: img04,
    },
]
