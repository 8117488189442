import { EPlayerControllerMode, PlayerController } from '../playerController';
import { PlayerManager } from '../playerManager';
import { getAutoPause } from '../../../../store/general/selectors';
import { getState } from '../../../../store';
import { Pause } from '../../../../types/pause-constants';

export class PlayCommonHandler {

  public async play(time?: number) {
    const playerController = PlayerController.getInstance();
    playerController.reset();
    playerController.setMode(EPlayerControllerMode.PLAY_COMMON);
    await PlayerManager.getInstance().startPlay(time);
  }

  public onTargetIndexChange(index: number): void {
    const pause = getAutoPause(getState());
    if (pause === Pause.NoPauseValue) return;
    PlayerManager.getInstance().pausePlay();
    if (pause !== Pause.FullStopValue) {
      PlayerController.getInstance().startWaitTimer(pause * 1000);
    }
  }
  
  public onNativePlayClicked() {
    const playerController = PlayerController.getInstance();
    playerController.reset();
    playerController.setMode(EPlayerControllerMode.PLAY_COMMON);
    PlayerManager.getInstance().onNativePlayClicked();
  }

}