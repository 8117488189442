import {
  CURRENT_VIDEO_SET_CLICKED_CAPTION_INDEX,
  CURRENT_VIDEO_SET_ID,
  IAvailableCaptionsItem, ICurrentVideoSetClickedCaptionIndex,
  ICurrentVideoSetId,
  ISetAvailableCaptions,
  ISetSelectedNativeCaptions,
  ISetSelectedTargetCaptions,
  SET_AVAILABLE_CAPTIONS,
  SET_SELECTED_NATIVE_CAPTIONS,
  SET_SELECTED_TARGET_CAPTIONS
} from './types';

export const changeCurrentVideoId = (videoId: string): ICurrentVideoSetId => ({
  type: CURRENT_VIDEO_SET_ID,
  videoId,
  audioId: 0
});
export const changeCurrentAudioId = (audioId: number): ICurrentVideoSetId => ({
  type: CURRENT_VIDEO_SET_ID,
  audioId,
  videoId: ''
});
export const setAvailableCaptions = (captions: IAvailableCaptionsItem[]): ISetAvailableCaptions => ({
  type: SET_AVAILABLE_CAPTIONS,
  captions
})
export const setSelectedNativeCaptions = (caption: IAvailableCaptionsItem | null): ISetSelectedNativeCaptions => ({
  type: SET_SELECTED_NATIVE_CAPTIONS,
  caption
})
export const setSelectedTargetCaptions = (caption: IAvailableCaptionsItem | null): ISetSelectedTargetCaptions => ({
  type: SET_SELECTED_TARGET_CAPTIONS,
  caption
})

export const setCurrentVideoClickedCaptionIndex = (clickedCaptionIndex: number): ICurrentVideoSetClickedCaptionIndex => ({
  type: CURRENT_VIDEO_SET_CLICKED_CAPTION_INDEX,
  clickedCaptionIndex
})