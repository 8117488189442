import { setFlashPhraseIdAction } from '../../store/general/actions';
import { AppThunk } from '../../store/types';
import { removePhrase } from '../../../common/rest/video/removeVideoPhrase';
import { decreasePhraseCount } from '../phraseCountEffects';
import { PhraseListSelectors } from '../../store/phrase-list/selectors';
import { PhraseListActions } from '../../store/phrase-list/actions';
import { getCurrentMovieKey } from '../../store/current-video/selectors';
import { deleteVideoPhraseAction } from '../../store/videos/actions';
import { getAuthUser } from '../../store/general/selectors';

export class VocabularyEffects {

  public static removePhrase(
    phraseId: number
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const state = getState();
      const {teacherMode} = getAuthUser(state);
      await dispatch(removePhrase(phraseId, teacherMode));
      const phrase = PhraseListSelectors.findPhraseById(state, phraseId);
      if (phrase) {
        dispatch(PhraseListActions.deletePhrase(phraseId));
        const videoId = getCurrentMovieKey(state);
        if (videoId === phrase.videoKey) {
          dispatch(deleteVideoPhraseAction(phrase.id));
        }
        if (phrase && phrase.userGroupId) {
          dispatch(decreasePhraseCount(phrase.userGroupId));
        }
      }
    }
  }

  public static flashPhrase(
    phraseId: number
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setFlashPhraseIdAction(phraseId));
      setTimeout(() => {
        dispatch(setFlashPhraseIdAction(0));
      }, 3000);
    }
  }

}


