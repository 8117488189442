import { TPhraseDetailsTab } from '../../../../../store/models/types';

export interface IPhraseDetailsContainerHeaderFields {
  tabs: TPhraseDetailsTab[],
  activeTab?: TPhraseDetailsTab,
  notesDisabled: boolean
}

export interface IPhraseDetailsContainerHeaderEvents {
  onShowGptPromptsPopup: (history: History) => void;
  onChangeTab: (tab: TPhraseDetailsTab) => void;
  onSelectDefaultTab: () => void;
  onBack: () => void;
}

export interface IPhraseDetailsContainerHeaderProps extends
  IPhraseDetailsContainerHeaderFields,
  IPhraseDetailsContainerHeaderEvents
{}

export const PHRASE_DETAILS_HEADER_CLASS_NAME = 'phraseDetailsHeader';