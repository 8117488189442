import { IState } from '../../../../../store/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { connect } from 'react-redux';
import { PhraseDetailsContainerHeader } from '../components/PhraseDetailsContainerHeader';
import { IPhraseDetailsContainerHeaderEvents, IPhraseDetailsContainerHeaderFields } from '../components/types';
import { PhraseDetailsContainerHeaderEvents } from './events';
import { PhraseDetailsHeaderTabSelector } from './phraseDetailsHeaderTabSelector';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsContainerHeaderFields => {
    return {
      tabs: PhraseDetailsHeaderTabSelector.getTabs(),
      activeTab: PhraseDetailsSelectors.getActiveTab(state),
      notesDisabled: PhraseDetailsSelectors.isNotesDisabled(state)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsContainerHeaderEvents => ({
    onChangeTab: tab => dispatch(PhraseDetailsContainerHeaderEvents.onChangeTab(tab)),
    onShowGptPromptsPopup: history => dispatch(PhraseDetailsContainerHeaderEvents.onShowGptPromptsPopup(history)),
    onSelectDefaultTab: () => dispatch(PhraseDetailsContainerHeaderEvents.onSelectDefaultTab()),
    onBack: () => dispatch(PhraseDetailsContainerHeaderEvents.onBack()),
  }
);

export const PhraseDetailsContainerHeaderHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsContainerHeader);

PhraseDetailsContainerHeaderHOC.displayName = 'PhraseDetailsContainerHeaderHOC';
