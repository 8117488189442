export class TextUtils {

  public static replaceNewLines(text: string): string {
    return text
      .replace(/[\r]/gm, '')
      .replace(/[\n]/gm, ' ');
  }

  public static extractStartWords(text: string, wordCount: number): string {
    const list = text.split(' ');
    if (list.length <= wordCount) {
      return text;
    }
    return list.slice(0, wordCount).join(' ');
  }

  public static getPlural (
    number: number,
    variants: string[]
  ) {
    if (!variants.length || variants.length < 2) return '';
    return number === 1 ? variants[0] : variants[1];
  };

  public static getText (
    text: string,
    vars?: Array<string | number>,
  ) {
    if (vars && vars.length) {
      vars.forEach((v, i) => {
        text = text.replace(`{${i}}`, String(v));
      });
    }
    return text;
  };
}