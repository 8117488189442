import { AppThunk } from '../../../../../../store/types';
import { UserTranslateLangEffects } from '../../../../../../effects/userTranslateLangEffects';

export class PhraseDetailsTabTranslationLangAddEvents {

  public static onAdd(langCode: string): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      UserTranslateLangEffects.addLang(langCode);
    }
  }

}