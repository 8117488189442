import { IState } from '../../../../../store/types';
import { getLibraryGroup } from '../../../../../store/models/selectors';
import { connect } from 'react-redux';
import { IVideoLibraryFields } from '../components/types';
import { VideoLibrary } from '../components/VideoLibrary';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IVideoLibraryFields => {
    const group = getLibraryGroup(state);
    return {
      selectedGroupId: group ? group.id : 0
    }
  };
}

export const VideoLibraryHOC = connect(
  mapStateToProps
)(VideoLibrary);

VideoLibraryHOC.displayName = 'VideoLibraryHOC';