import { History } from 'history';
import { HistoryProrvider } from './historyProrvider';
import { getDispatch, getState } from '../store';
import { getMainViewMode } from '../store/general/selectors';
import { EMainViewMode } from '../store/general/types';
import { MainLayoutEffects } from './mainLayoutEffects';

const _history = HistoryProrvider.getHistory();

export class Navigator {

  public static openVideo(groupId: number, videoId: string, phraseId?: number, pause?: boolean) {
    if (!groupId || !videoId) return;

    const list = [groupId, videoId];
    if (phraseId !== undefined) {
      list.push(phraseId);
    }
    if (pause !== undefined) {
      list.push(pause ? '1' : '0');
    }
    const url = '/video/' + list.join('/');
    _history.push(url);

    if (getMainViewMode(getState()) === EMainViewMode.PLAYLISTS) {
      getDispatch()(MainLayoutEffects.backToDashboard());
    }
  }

  public static openAudio(groupId: number, audioId: number) {
    const url = '/audio/' + [groupId, audioId].join('/');
    _history.push(url);
  }

  public static openContentLib() {
    const url = '/contentLib';
    _history.push(url);
  }

  public static openDefault() {
    _history.push('/');
  }

  public static openInstallSelectLangs() {
    _history.push('/install/selectLangs');
  }

  public static openGroup(groupId: number) {
    _history.push(`/video/${groupId}/`);
  }

  public static openUrl(url: string) {
    _history.push(url);
  }

  public static openPublicProfile(userName: string) {
    const url = '/@' + userName;
    _history.push(url);
  }

}
