import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { SavedVideoConfirmPopup } from '../components/SavedVideoConfirmPopup';
import { ISavedVideoConfirmPopupEvents, ISavedVideoConfirmPopupFields } from '../components/types';
import { getSavedVideoConfirm } from '../../../../../store/general/selectors';
import { SavedVideoConfirmPopupEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
  ): ISavedVideoConfirmPopupFields => {
    return {
     showMode: getSavedVideoConfirm(state).showMode
    }
  };
}


const mapDispatchToProps = (
  dispatch: any
): ISavedVideoConfirmPopupEvents => ({
  onClose: () => dispatch(SavedVideoConfirmPopupEvents.onClose())
});


export const SavedVideoConfirmPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedVideoConfirmPopup);

SavedVideoConfirmPopupHOC.displayName = 'SavedVideoConfirmPopupHOC';