import { historyPush } from '../../../../common/utils/historyHelper';
import { ContentLibActions } from '../../../store/contentLib/actions';
import { ContentLibSelectors } from '../../../store/contentLib/selectors';
import { EContentLibViewMode } from '../../../store/contentLib/types';
import { AppThunk } from '../../../store/types';

export class ContentLibEvents {
    public static openGroup(groupId: number): AppThunk {
        return (
          dispatch,
          getState
        ) => {
          const viewMode = ContentLibSelectors.getViewMode(getState());
          dispatch(ContentLibActions.setSelectedGroupId(groupId));
          dispatch(ContentLibActions.setViewMode(EContentLibViewMode.GROUP_VIDEO_LIST));

          historyPush({
            state: { page: 'Playlist' },
            title: 'Playlist',
            onpopstate: () => dispatch(this.closeGroup(groupId, viewMode)),
          });
        }
    }

    public static closeGroup(
      groupId: number,
      viewMode: EContentLibViewMode,
    ): AppThunk {
      return (
        dispatch,
        getState
      ) => {
        dispatch(ContentLibActions.setViewMode(viewMode));
        const group = document.querySelector(`.content-lib-group-${groupId}`) as HTMLElement;
        if (group) {
          group.scrollIntoView({ block: 'center'});
        }
      }
  }
}