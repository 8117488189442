import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPhraseTextEvents, IPhraseTextFields } from '../components/types';
import { PhraseText } from '../components/PhraseText';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { getPlayerState } from '../../../../../store/general/selectors';
import { PlayerStates } from '../../../../../types/common';
import { findVideoPhraseById, findVideoPhraseByWordId } from '../../../../../store/videos/selectors';
import { EPlayerControllerMode, PlayerController } from '../../../../../effects/player/manager/playerController';
import { PhraseListSelectors } from '../../../../../store/phrase-list/selectors';
import { PhraseTextEvents } from './events';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';
import { IPhraseTextHOCProps } from './types';

const mapStateToProps = (
	dispatch: any,
	props: IPhraseTextHOCProps,
) => {
	return (
		state: IState
	): IPhraseTextFields => {
		let text = '';
		let context = '';
		let isContext;
		let phraseId;
		let phrase;
		let isPhrase;

		const playMode = PlayerController.getInstance().getMode();
		if (playMode === EPlayerControllerMode.PLAY_PHRASE_LIST || playMode === EPlayerControllerMode.PLAY_PHRASE) {
			phraseId = PhraseListSelectors.getCurrentPhraseId(state);
		} else if (getPlayerState(state) === PlayerStates.PLAYING) {
			phraseId = PhraseDetailsSelectors.getNotePhraseId(state) || 0;
		} else {
			phraseId = PhraseDetailsSelectors.getPhraseId(state) || 0;
		}

		if (phraseId && phraseId > 0) phrase = findVideoPhraseById(state, phraseId);
		if (phrase && !phrase.wordPhraseId) { // find fullPhrase by wordPhrase
			phrase = findVideoPhraseByWordId(state, phraseId);
		}
		if (phrase) {
			text = PhraseDetailsSelectors.getSelectText(state) || phrase.highlighted || '';
			context = phrase.fullPhrase || '';
			isContext = PhraseEffects.isContextExist(phrase);
			isPhrase = true;
		} else {
			text = PhraseContextEditorSelectors.getPhraseText(state)?.highlighted || '';
			isContext = PhraseContextEditorSelectors.getIsContext(state);
			if (isContext) {
				context = PhraseContextEditorSelectors.getPhraseContext(state)?.fullPhrase || '';
			}
		}
		const fromLangCode = PhraseDetailsSelectors.getFromLang(state)?.code || '';

		return {
			context,
			fromLangCode,
			text,
			isContext,
			isPhrase,
			showEditContext: true,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IPhraseTextEvents => ({
	  	onContextModify: () => dispatch(PhraseTextEvents.onContextModify()),
		onContextDelete: () => dispatch(PhraseTextEvents.onContextDelete()),
		onContextTranslate: () => dispatch(PhraseTextEvents.onContextTranslate()),
	}
);

export const PhraseTextHOC = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PhraseText);

PhraseTextHOC.displayName = 'PhraseTextHOC';
