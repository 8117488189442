import React from 'react';
import { IPhraseDetailsTabPromptFormVarProps } from './types';
import { theme } from '../../../../../../common/styles/theme';
import { Button } from "@mui/material";

export const PhraseDetailsTabPromptFormVar: React.FC<IPhraseDetailsTabPromptFormVarProps> = ({
  color,
  name,
  onClick
}) => {
  const style = {
    height: 'unset',
    p: theme.spacing(0.5, 1),
    fontSize: theme.typography.body1,
    color: `${theme.palette.grey[900]} !important`,
    border: `none !important`,
    bgcolor: color,
    '&:hover, &:focus, &:active': {
      border: `1px solid`,
      color: theme.palette.primary.main,
      bgcolor: color,
    }
  };

  return (
    <Button onClick={onClick} sx={style}>{name}</Button>
  );
}
