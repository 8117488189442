import * as React from 'react';
import { TTextModel } from './helper/textPartHelperEffect';
import { Box } from '@mui/material';
import { theme } from '../../styles/theme';
import { HighLightedSpan } from './HighLightedSpan';
import { TextPartUtil } from './helper/textPartUtil';

export enum EHighlightedType {
    Highlighted,
    Normal
}

export type THighLightedTextProps = {
    text: string;
    highLighted: string[];
    highlightedStartPos?: number
    underscore?: boolean
}


export const HighLightedText: React.FC<THighLightedTextProps> = ({
    highLighted,
    text,
  highlightedStartPos,
  underscore
}) => {
    const [textParts, setTextParts] = React.useState<TTextModel[]>([]);

    React.useEffect(() => {
        let textParts = TextPartUtil.getTextParts(text, highLighted.join(''), highlightedStartPos || 0);
        setTextParts(textParts);
    }, [text, highLighted.join('')]);

    return (<>
        {
            textParts.map((textPart, idx) => {
                const isStart = idx === 0;
                const isEnd = idx === textParts.length - 1;
                return textPart.type === EHighlightedType.Highlighted ?
                    <HighLightedSpan
                        key={idx}
                        isStart={idx === 0 || (textPart[idx - 1] && textPart[idx - 1].type !== EHighlightedType.Highlighted)}
                        isEnd={idx === textParts.length -1 || (textPart[idx + 1] && textPart[idx + 1].type !== EHighlightedType.Highlighted)}
                        style={{ backgroundColor: theme.palette.info[100] }}
                    >
                        {textPart.text}
                    </HighLightedSpan>
                    :
                    <Box
                        key={idx}
                        component='span'
                        dangerouslySetInnerHTML={{ __html: textPart.text || '' }}
                        sx={{
                            padding: '4px 0',
                        }}
                    />;
            })
        }
    </>);
};
