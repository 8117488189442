import React from 'react';
import { IPromptsMobileProps } from './types';
import { Stack } from '@mui/material';
import { theme } from '../../../../../common/styles/theme';
import { TopBarPanelHOC } from '../../ToolbarPanel/Toolbar/hocs/TopBarPanelHOC';
import { PhraseDetailsContainerHOC } from '../../PhraseDetails/PhraseDetailsContainer/hocs/PhraseDetailsContainerHOC';
import { CLASS_PHRASE_DETAILS_COLUMN_SCROLL } from '../../PhraseDetails/PhraseDetailsContainer/components/types';

export const PromptsMobile: React.FC<IPromptsMobileProps> = ({
  isShow,
}) => {
  if (!isShow) return null;

  return (
	<Stack
		className={CLASS_PHRASE_DETAILS_COLUMN_SCROLL}
		gap={theme.spacing(2)}
        sx={{
			overflowY: 'auto',
			overflowX: 'hidden',
			width: 1,
			height: 1,
        }}
      >
        <Stack
          sx={{
			flex: `1 1 100%`,
			minHeight: 0,
			background: theme.palette.secondary.main,
          }}
        >
        	<TopBarPanelHOC />

			<Stack
				sx={{
					flex: `1 1 100%`,
					minHeight: 0,
					p: theme.spacing(1),
					zIndex: 1,
				}}
			>
				<PhraseDetailsContainerHOC />
			</Stack>
		</Stack>
	</Stack>
  );
};

export default PromptsMobile;
