import React from 'react';
import { Stack } from '@mui/material';
import { IPinExtensionPopupStepProps } from './types';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from "../../../../../../common/styles/theme";
//@ts-ignore
import img from '../../../../../img/pinExtentionPopupImage.svg';

export const PinExtensionPopupStep: React.FC<IPinExtensionPopupStepProps> = ({
  icon,
  text
}) => {
  const containerStyle = {
    top: `${theme.spacing(0.5)} !important`,
    right: `${theme.spacing(0.5)} !important`,
    m: `0 !important`
  };

  return (
      <Stack
          direction={'row'}
          spacing={theme.spacing()}
          alignItems={'center'}
      >
        {icon}
        <Text sx={{ color: theme.palette.common.white }}>
          {text}
        </Text>
      </Stack>
  );
}
