import React from 'react';
import { Box, Button, CircularProgress, Stack, SxProps, Tooltip } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { IPhraseDetailsTabPromptTestProps } from './types';
import { PhraseDetailsPhrasePrepareHelper } from '../../helpers/phraseDetailsPhrasePrepareHelper';
import { Paper } from "../../../../../../common/components/Paper/Paper";
import { TextWithSpeakerHOC } from '../../../../../../common/components/TextWithSpeaker/hocs/TextWithSpeakerHOC';
import { ButtonIconGrey } from '../../../../../../common/components/ButtonIconGrey/ButtonIconGrey';
import { PencilIcon } from '../../../../../../../icons/PencilIcon';

export const PhraseDetailsTabPromptTest: React.FC<IPhraseDetailsTabPromptTestProps> = ({
  show,
  prompt,
  phrase,
  context,
  result,
  processing,
  isNoPhrase,
  onTest,
  onBack,
  onClickContext,
}) => {

  if (!show) return null;

  const [promptHtml, setPromptHtml] = React.useState<string>('');
  const [phraseContext, setPhraseContext] = React.useState<string>('');

  React.useEffect(() => {
    const _promptHtml = PhraseDetailsPhrasePrepareHelper.format({
      prompt, phrase, context,
      phraseCssClass: 'phrase',
      contextCssClass: 'context'
    });
    setPromptHtml(_promptHtml);
  }, [prompt, phrase, context]);

  React.useEffect(() => {
    const input = context ? context : phrase;
    const _phraseContextHtml = PhraseDetailsPhrasePrepareHelper.format({
      prompt: input,
      phrase, context,
      phraseCssClass: 'phrase',
      contextCssClass: 'context'
    });
    setPhraseContext(_phraseContextHtml);
  }, [phrase, context])


    const paperStyle = {
      flex: `1 1 50%`,
      p: theme.spacing(2),
    };

  const promptStyle = {
    'span': {
      p: '4px 0',
      lineHeight: theme.spacing(3.25),
    },
    '.context': {
      backgroundColor: theme.palette.info[500],
    },
    '.phrase': {
      bgcolor: theme.palette.info[100],
    },

    '.prompt-param_targetLang, .prompt-param_nativeLang, .prompt-param_phraseList': {
      backgroundColor: theme.palette.primary[100],
    },
  };

  const handleBackClick = () => {
    onBack();
    return false;
  }

  const phraseStyle: SxProps = {
    mt: theme.spacing(1),
    fontSize: theme.typography.subtitle2,

    'span': {
      p: '4px 0',
      lineHeight: theme.spacing(3.25),
    },
    '.context': {
      backgroundColor: theme.palette.info[500],
    },
    '.phrase': {
      bgcolor: theme.palette.info[100],
    }
  };

  const buttonStyle: SxProps = {
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),

		'.MuiSvgIcon-root': {
			width: theme.spacing(2),
			height: theme.spacing(2),
		},
	};

  const showPrompt = !!(phrase && context);

  return (
      <Paper sx={paperStyle}>
        <Stack spacing={theme.spacing(3)} sx={{ maxHeight: '100%' }}>
          <Text variant={'h6'} fontWeight={600}>
              Test your prompt here
          </Text>

            <Stack spacing={theme.spacing(2)}>
              {showPrompt &&
                <>
                <Box>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    gap={theme.spacing(1)}
                  >
                    <Text fontWeight={600}>Phrase and context</Text>        
                  </Stack>
                  <Text
                      sx={phraseStyle}
                      dangerouslySetInnerHTML={{ __html: phraseContext}}
                  />
                  {!isNoPhrase &&
                    <Stack
                      flexDirection={'row'}
                      justifyContent={'flex-end'}
                      alignItems={'center'}
                      gap={theme.spacing(.75)}
                      sx={{
                        mb: theme.spacing(-2),
                        pr: theme.spacing(1),
                        color: theme.palette.grey[400],
                        fontSize: theme.typography.fontSize,
                      }}
                    >
                      Context:
                      <Tooltip
                        title={'Edit context'}
                      >
                        <ButtonIconGrey
                          onClick={onClickContext}
                          sx={buttonStyle}
                        >
                          <PencilIcon />
                        </ButtonIconGrey>
                      </Tooltip>

                        {/*<Button
                          onClick={onShowPhraseSelectPopup}
                          variant={'contained'}
                          sx={buttonStyle}
                        >
                          {PHRASE_RESELECT} temporary hide, тормозит попап из за того что грузятся все captions
                        </Button>*/}
                        
                      </Stack>
                    }
                </Box>

                <Stack spacing={theme.spacing(1)}>
                  <Text variant={'subtitle1'} fontWeight={600}>
                    The following will be sent to ChatGPT
                  </Text>
                  <Text
                    variant={'subtitle2'}
                    sx={promptStyle}
                    dangerouslySetInnerHTML={{__html: promptHtml}}
                  />
                </Stack>
                </>
              }
              {!showPrompt &&
              <Text variant={'subtitle1'} fontWeight={600}>
                Please go <a href={'#'} onClick={handleBackClick}>back</a> to subtitles and select a word or phrase to start building/testing Chat GPT prompts
              </Text>
              }
            </Stack>

          {showPrompt &&
          <Button
            onClick={onTest}
            variant={'contained'}
            color={'secondary'}
          >
            Test my prompt
          </Button>
          }

          {processing &&
              <Stack alignItems={'center'} justifyContent={'center'}>
                  <CircularProgress />
              </Stack>
          }
          {!processing && result &&
            <>
              <Stack gap={theme.spacing()} sx={{
                flexGrow: 1,
                ml: theme.spacing(-1),
                pl: theme.spacing(2.5),
              }}>
                  <Text variant={'subtitle1'} fontWeight={600}
                    sx={{
                      ml: theme.spacing(-2.5),
                    }}
                  >
                      ChatGPT output
                  </Text>
         
                <Box
                  sx={{
                    
                  }}
                >
                  <TextWithSpeakerHOC
                    text={result}
                  />
                </Box>
                
              </Stack>
              </>
          }

        </Stack>
      </Paper>
  );
}
