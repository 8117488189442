import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseDetailsCustomPromptTestService } from '../../../../../effects/phrase-details/phraseDetailsService/phrase-details-custom-prompt-test-service';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';
import { PhraseContextEditorActions } from '../../../../../store/phrase-context-editor/actions';

export class PhraseDetailsTabPromptTestEvents {

  public static onTest(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const state = getState();
      let phrasePreview = PhraseContextEditorSelectors.getPhraseText(state);
      let text = phrasePreview && phrasePreview.id < 0 && phrasePreview?.highlighted || '';
      let context = PhraseContextEditorSelectors.getPhraseContext(state)?.fullPhrase || PhraseDetailsSelectors.getContext(state) || PhraseDetailsSelectors.getCaptionLineContext(state) || '';
      let fromLang = PhraseDetailsSelectors.getFromLang(state);
      let toLang = PhraseDetailsSelectors.getToLang(state);

      if (!text) { // no phrase selected
        const firstCaptionAsPhrase = PhraseDetailsTabsEditorPopupSelectors.getFirstCaptionAsPhrase(state);
        if (firstCaptionAsPhrase && firstCaptionAsPhrase.text) {
          text = firstCaptionAsPhrase.text;
          context = firstCaptionAsPhrase.context || '';
          fromLang = firstCaptionAsPhrase.fromLang;
          toLang = firstCaptionAsPhrase.toLang;
        }
      }

      const prompt = PhraseDetailsTabsEditorPopupSelectors.getPromptTestPrompt(state)
      const service = new PhraseDetailsCustomPromptTestService(text, context, fromLang?.code || '', toLang?.code || '', prompt);

      dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestProcessing(true));
      dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestResult(''));
      try {
        const result = await service.load();
        dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestResult(result || ''));
      } catch (e) {
        console.error(e);
      }
      dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestProcessing(false));
    }
  }

  public static onBack(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(MainLayoutEffects.backToDashboard());
    }
  }

  public static onClickContext(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
        show: true,
      }));
		}
	}


}