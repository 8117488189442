import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Editor } from 'react-draft-wysiwyg';
// @ts-ignore
import css from '!!raw-loader!react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { IDraftWysiwygProps } from './types';
import { Box, IconButton, Stack, alpha } from '@mui/material';
import { theme } from '../../../styles/theme';
import draftToHtml from 'draftjs-to-html';
import { MoreIcon } from '../../../../../icons/MoreIcon';
import { CloseIconLight } from '../../../../../icons/CloseIconLight';

const DraftWysiwygStyle = createGlobalStyle`${css}`;

const IMAGE_MAX_SIZE = 1024 * 1024;

export function DraftWysiwyg(props: IDraftWysiwygProps) {
    const {
        value,
        isActive,
        isEdit,
        onChangeEditor,
      onLoadImage,
        ...editorProps
    } = props;

    const className = 'draft-wysiwyg';
    
    const [editorState, setEditorState] = React.useState<EditorState>(EditorState.createEmpty());
    const [isToolbar, setToolbar] = React.useState(false);
    const editorRef = React.useRef(null);
    const ref = React.useRef(null);
    const [editorHtml, setEditorHtml] = React.useState<string>(value);

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setEditorHtml(html);
    };

    React.useEffect(() => {
        const contentBlock = htmlToDraft(value);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            setTimeout(() => { // иначе иногда при открытии нет текста в редакторе
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
                const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                setEditorHtml(html);
            })
        }
    }, [value]);

    const onFocus = () => {
        setToolbar(true);
    }

    React.useEffect(() => {
        if (isToolbar) {
            onChangeEditor(editorHtml);
        }
    }, [editorHtml])

    let [isMore, setMore] = React.useState(false);
    const resizeObserverDebounce = React.useRef<any>(null);
    const data = React.useRef({ isMore });
	data.current = { isMore }

    const uploadImageCallBack = async (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            //reader.onload = (e: any) => resolve({ data: { link: e.target.result } });
            reader.onload = async (e: any) => {
                const result = e.target.result;
                if (result.length > IMAGE_MAX_SIZE) {
                    alert('Image too large');
                    return reject('image too large');
                }
                const url = await onLoadImage(result);
                resolve({ data: { link: url } });
            }
            reader.onerror = (e) => reject(e);
            reader.readAsDataURL(file);
        });
    }

    const onClickToolbar = React.useCallback(() => {
        if (data.current.isMore === false) {
            setMore(true); // to show dropdowns
        }
    }, []);

    const onResizeWindow = React.useCallback(() => {
        const oneLineHeight = 40;
        if (resizeObserverDebounce.current) clearTimeout(resizeObserverDebounce.current);
        resizeObserverDebounce.current = setTimeout(() => {
            let toolbar = ref.current?.querySelector('.rdw-editor-toolbar') as HTMLElement;
            if (toolbar) {
                if (data.current.isMore === null) {
                    if (toolbar.scrollHeight > oneLineHeight) {
                        setMore(false);
                    }
                } else {
                    if (toolbar.scrollHeight < oneLineHeight) {
                        setMore(null);
                    }
                }
                toolbar.removeEventListener('click', onClickToolbar);
                toolbar.addEventListener('click', onClickToolbar);
            }
        }, 100);
    }, []);

    const setEditorReference = (editor: HTMLElement) => {
        if (editor && !editorRef.current) {
            editorRef.current = editor;
            onResizeWindow();
            window.addEventListener('resize', onResizeWindow);

            let observer = new MutationObserver(mutations => { // fix modal top to button bottom
                let modal = ref.current.querySelector('.rdw-colorpicker-modal');
                if (modal) {
                    const wrapper = ref.current.querySelector('.rdw-colorpicker-wrapper');
                    modal.style.top = (wrapper.offsetTop + wrapper.offsetHeight) + 'px';
                    return;
                }
                modal = ref.current.querySelector('.rdw-link-modal');
                if (modal) {
                    const wrapper = ref.current.querySelector('.rdw-link-wrapper');
                    modal.style.top = (wrapper.offsetTop + wrapper.offsetHeight) + 'px';
                    return;
                }
                modal = ref.current.querySelector('.rdw-image-modal');
                if (modal) {
                    const wrapper = ref.current.querySelector('.rdw-image-wrapper');
                    modal.style.top = (wrapper.offsetTop + wrapper.offsetHeight) + 'px';
                    return;
                }
            });
            setTimeout(() => {
                let toolbar = ref.current?.querySelector('.rdw-editor-toolbar') as HTMLElement;
                if (toolbar) {
                    observer.observe(toolbar, { childList: true, subtree: true });
                }
            }, 100);
        }
    }

    const stripTags = (input: string, allowed?: string,) => {
        allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');
        let tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
        return input.replace(tags, function ($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });
    };

    const onRemoveHTML = () => {
        const htmlString = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const newHtml = stripTags(htmlString.replaceAll('<br>', '\n').replaceAll('&nbsp;', ' '));
        const contentState = ContentState.createFromText(newHtml);
        const newEditorState = EditorState.push(editorState, contentState, 'change-block-type');
        setEditorState(newEditorState);
        onEditorStateChange(newEditorState);
    };

    React.useEffect(() => {
        if (isActive) {
            if (isEdit) {
                if (editorRef.current) {
                    setTimeout(() => {
                        editorRef.current.focus();
                        document.execCommand('selectAll', false, null);
                        document.getSelection().collapseToEnd(); // set cursor to end
                    }, 100)
                }
                setToolbar(true);
            } else {
                setToolbar(false);
            }
        }
    }, [isActive, isEdit]);

    React.useEffect(() => {
        return () => {
            if (resizeObserverDebounce.current) clearTimeout(resizeObserverDebounce.current);
            window.removeEventListener('resize', onResizeWindow);
        }
    }, []);

    return <Stack
        className={`${className}
            ${isMore === false ? 'closed' : ''}
            ${isToolbar ? '' : 'toolbar-hidden'}
        `}
        ref={ref}
        sx={{
            position: 'relative',
            flex: `1 1 100%`,
            minHeight: 0,
            borderRadius: theme.spacing(0.75),
            border: `2px solid ${theme.palette.grey[200]}`,

            '.public-DraftEditorPlaceholder-root': {
                pl: theme.spacing(1),
                pt: theme.spacing(1),
            },

            '.rdw-editor-toolbar': {
                m: 0,
                maxHeight: 'none',
                overflow: 'visible',
                p: `${theme.spacing(.5)} ${theme.spacing(4)} 0 ${theme.spacing(.5)}`,
                fontSize: '12px',
                color: theme.palette.grey[500],
                border: 'none',
                background: '#fcfdfe',
            },

            '&.closed .rdw-editor-toolbar': {
                maxHeight: theme.spacing(3.75),
                overflow: 'hidden',
            },

            '&.toolbar-hidden': {
                [`
                    .rdw-editor-toolbar,
                    .${className}__more
                `]: {
                    position: 'absolute',
                    opacity: 0,
                    pointerEvents: 'none',
                },
            },

            '.rdw-editor-wrapper': {
                flex: `1 1 100%`,
                minHeight: 0,
                display: 'flex',
                flexDirection: 'column',
            },

            '.rdw-editor-main': {
                flex: `1 1 100%`,
                minHeight: 0,

                'blockquote': {
                    ml: `${theme.spacing(3)} !important`,
                    pl: 1,
                    borderLeft: `4px solid ${theme.palette.grey[100]}`,
                },
            },

            '.public-DraftEditor-content': {
                p: theme.spacing(1),
                fontSize: theme.typography.subtitle2,
                wordBreak: 'break-word',

                ' > [data-contents] > [data-block]': {
                    m: '0 0 .7em',
                },
            },

            '.public-DraftStyleDefault-block': {
                m: 0,
            },

            '.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul': {
                paddingInlineStart: 3,
            },

            '.rdw-center-aligned-block': {
                '& > *': {
                    textAlign: 'center',
                },
            },

            '.rdw-right-aligned-block': {
                '& > *': {
                    textAlign: 'right',
                },
            },

            '.rdw-justify-aligned-block': {
                '& > *': {
                    textAlign: 'justify',
                },
            },

            'figure': {
                m: 0,
            },

            '.rdw-image-alignment': {
                display: 'inline-block',

                'img': {
                    maxWidth: 1,
                }
            },

            '.rdw-option-wrapper': {
                m: `0 ${theme.spacing(.25)}`,
                p: `${theme.spacing(.25)} ${theme.spacing(.5)}`,
                minWidth: theme.spacing(2),
                borderRadius: theme.spacing(.5),
                background: 'none',
                border: 'none',

                '&:hover': {
                    boxShadow: 'none',
                    background: alpha(theme.palette.grey[200], .3),
                },

                '&.rdw-option-active': {
                    boxShadow: 'none',
                    background: alpha(theme.palette.grey[200], .6),
                },

                'img': {
                    opacity: .6,
                },
            },

            '.rdw-colorpicker-option': {
                m: '2px',
                p: 0,

                '&.rdw-option-wrapper': {
                    '&.rdw-option-active': {
                        background: 'none',
                        outline: `2px solid ${theme.palette.primary.main}`,

                        '.rdw-colorpicker-cube': {
                            width: '18px',
                            height: '18px',
                            borderRadius: theme.spacing(.5),
                        },
                    },
                },
            },

            '.rdw-block-dropdown': {
                width: theme.spacing(10),
            },

            '.rdw-dropdown-wrapper': {
                height: theme.spacing(3),
                borderColor: theme.palette.grey[100],
                borderRadius: theme.spacing(.5),
                background: 'none',

                '&:hover': {
                    boxShadow: 'none',
                    background: alpha(theme.palette.grey[200], .3),
                },

                'img': {
                    opacity: .6,
                },
            },

            [`.rdw-dropdown-carettoopen, .rdw-dropdown-carettoclose`]: {
                opacity: .4,
            },

            '.rdw-dropdown-optionwrapper': {
                border: 'none',
                borderRadius: theme.spacing(.75),
                boxShadow: theme.shadows[16],

                '&:hover': {
                    boxShadow: theme.shadows[16],
                },
            },

            [`.rdw-colorpicker-wrapper,
            .rdw-link-wrapper,
            .rdw-image-wrapper`]: {
                position: 'static', // fit modal to toolbar width
            },

            [`.rdw-colorpicker-wrapper .rdw-colorpicker-modal,
            .rdw-link-wrapper .rdw-link-modal,
            .rdw-image-wrapper .rdw-image-modal`]: {
                left: 0,
                right: 0,
                width: 'auto',
                height: 'auto',
                borderRadius: theme.spacing(.75),
                boxShadow: theme.shadows[16],
            },

            '.rdw-colorpicker-modal-style-label': {
                fontSize: '12px',
                fontWeight: 500,
                p: `0px ${theme.spacing(1)} ${theme.spacing(.5)}`,
            },

            '.rdw-colorpicker-modal-style-label-active': {
                color: theme.palette.primary.main,
                borderBottom: `2px solid ${theme.palette.primary.main}`,
            },

            '.rdw-colorpicker-modal-options': {
                width: 'auto',
                height: 'auto',
            },

            '.rdw-link-modal-label': {
                fontSize: '12px',
            },

            '.rdw-link-modal-input': {
                borderRadius: theme.spacing(.5),
                border: `1px solid ${theme.palette.grey[200]}`,
            },

            '.rdw-link-modal-btn': {
                fontWeight: 600,
                color: theme.palette.grey[500],
                borderRadius: theme.spacing(.75),
                border: `2px solid ${theme.palette.grey[200]}`,

                '&:hover': {
                    boxShadow: 'none',
                },

                '&:first-of-type': {
                    color: theme.palette.common.white,
                    background: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },

                '&:disabled': {
                    opacity: .3,
                }
            },

            '.rdw-image-modal-header': {
                m: 0,
            },

            '.rdw-image-modal-header-option': {
                fontSize: '12px',
                fontWeight: 500,
                p: `0 0 ${theme.spacing(.5)}`,
            },

            '.rdw-image-modal-header-label': {
                width: 1,
                borderColor: 'transparent',
                background: 'transparent',
            },

            '.rdw-image-modal-header-label-highlighted': {
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },

            '.rdw-image-modal-upload-option': {
                color: theme.palette.grey[400],
                fontSize: '12px',
                backgroundColor: theme.palette.grey[50],
                outline: `2px dashed ${theme.palette.grey[400]}`,
            },

            '.rdw-image-modal-btn': {
                fontWeight: 600,
                color: theme.palette.grey[500],
                borderRadius: theme.spacing(.75),
                border: `2px solid ${theme.palette.grey[200]}`,

                '&:hover': {
                    boxShadow: 'none',
                },

                '&:first-of-type': {
                    color: theme.palette.common.white,
                    background: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },

                '&:disabled': {
                    opacity: .3,
                }
            },

            '.rdw-image-modal input': {
                borderRadius: theme.spacing(.5),
                border: `1px solid ${theme.palette.grey[200]}`,
            },
        }}
    >
        <Editor
            {...editorProps}
            toolbar={{
                options: ['inline', 'colorPicker', 'link', 'blockType', 'fontSize', 'list', 'textAlign', 'image',],
                inline: {
                    options: ['bold', 'italic', 'underline', 'monospace',] // 'strikethrough',
                },
                list: {
                    options: ['unordered', 'ordered',],
                },
                textAlign: {
                    inDropdown: true,
                },
                image: {
                    alignmentEnabled: false,
                    uploadCallback: uploadImageCallBack,
                    previewImage: true,
                },
            }}
            toolbarCustomButtons={[
                <Box
                    className={'rdw-option-wrapper'}
                    aria-selected={false}
                    role={'button'}
                    title={'Clear all formatting'}
                    onClick={onRemoveHTML}
                >
                    <CloseIconLight />
                </Box>
            ]}
            editorState={editorState}
            editorRef={setEditorReference}
            onEditorStateChange={onEditorStateChange}
            onFocus={onFocus}
        />
        {isMore !== null &&
            <IconButton
                className={`${className}__more ${isMore ? 'opened' : ''}`}
                onClick={() => setMore(!isMore)}
                color={'primary'}
                sx={{
                    position: 'absolute',
                    right: 0,
                    top: '1px',
                    color: theme.palette.grey[400],
                    '&:hover, &:active, &:focus': { color: theme.palette.grey[900], },
                    '.MuiSvgIcon-root': { width: theme.spacing(2), height: theme.spacing(2) },
                    '&.opened': {
                        transform: `rotate(90deg)`,
                    },
                }}
            >
                <MoreIcon />
            </IconButton>
        }

        <DraftWysiwygStyle />
    </Stack>
};
