import { IState } from '../../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabTranslationLangAdd } from '../components/PhraseDetailsTabTranslationLangAdd';
import {
  IPhraseDetailsTabTranslationLangAddEvents,
  IPhraseDetailsTabTranslationLangAddFields
} from '../components/types';
import { PhraseDetailsTabTranslationLangAddEvents } from './events';
import { LangUtil } from '../../../../../../../common/utils/lang-util';
import { getAuthUserTranslateLangs } from '../../../../../../store/general/selectors';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabTranslationLangAddFields => {
    const existsCodes = getAuthUserTranslateLangs(state).map(l => l.code) || [];
    const langCodes = LangUtil.getAllLangCodes().filter(code => !existsCodes.includes(code));
    return {
      langCodes
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabTranslationLangAddEvents => ({
    onAdd: langCode => dispatch(PhraseDetailsTabTranslationLangAddEvents.onAdd(langCode))
  }
);

export const PhraseDetailsTabTranslationLangAddHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabTranslationLangAdd);

PhraseDetailsTabTranslationLangAddHOC.displayName = 'PhraseDetailsTabTranslationLangAddHOC';