import React from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { ITeacherPhraseListProps } from './types';
import { TeacherPhraseHOC } from '../../TeacherPhrase/hocs/TeacherPhraseHOC';


export const TeacherPhraseList: React.FC<ITeacherPhraseListProps> = ({
  show,
  phraseList,
  loading,
}) => {
  if (!show) return null;

  return (
    loading
      ? <Stack alignItems={'center'} justifyContent={'center'}>
        <CircularProgress />
      </Stack>
      : <Stack>
        {phraseList.map(p => {
          return <TeacherPhraseHOC
              key={p.id}
              phraseId={p.id}
          />
        })}
      </Stack>  
  );
}
