import { IState } from '../../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseTranslationSettings } from '../components/PhraseTranslationSettings';
import {
	IPhraseTranslationSettingsEvents,
	IPhraseTranslationSettingsFields
} from '../components/types';
import { getAuthUserTranslateLangs } from '../../../../../../store/general/selectors';
import { PhraseTranslationSettingsEvents } from './events';


const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IPhraseTranslationSettingsFields => {
		return {
			translateLangs: getAuthUserTranslateLangs(state)
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IPhraseTranslationSettingsEvents => ({
	onSort: (langCodes) => dispatch(PhraseTranslationSettingsEvents.onSort(langCodes))
}
);

export const PhraseTranslationSettingsHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(PhraseTranslationSettings);

PhraseTranslationSettingsHOC.displayName = 'PhraseTranslationSettingsHOC';
