import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPhraseDetailsPopupMenuEvents, IPhraseDetailsPopupMenuFields } from '../components/types';
import { PhraseDetailsPopupMenuEvents } from './events';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseDetailsPopupMenu } from '../components/PhraseDetailsPopupMenu';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsPopupMenuFields => {
    const popupMenu = PhraseDetailsSelectors.getPopupMenu(state);
    return {
      show: popupMenu?.show,
      anchorEl: popupMenu?.anchorElement
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsPopupMenuEvents => ({
    onCopy: () => dispatch(PhraseDetailsPopupMenuEvents.onCopy()),
    onClose: () => dispatch(PhraseDetailsPopupMenuEvents.onClose())
  }
);

export const PhraseDetailsPopupMenuHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsPopupMenu);

PhraseDetailsPopupMenuHOC.displayName = 'PhraseDetailsPopupMenuHOC';