import { IState } from '../types';
import { IPhraseSelectResult } from '../../effects/phrase/utils/phrase-select-preparator';
import { EVocabularyPhraseType, IVocabularyPhrase } from '../../types/common';

export class PhraseContextEditorSelectors {

  public static getShow(state: IState): boolean {
    return state.phraseContextEditor.show
  }

  public static getPhraseId(state: IState): number {
    return state.phraseContextEditor.phraseId
  }

  public static getSelectResult(state: IState): IPhraseSelectResult | null {
    return state.phraseContextEditor.selectResult
  }

  public static getPreviewContextPhrase(state: IState): IVocabularyPhrase | null {
    return state.phraseContextEditor.previewContextPhrase
  }

  public static getIsContext(state: IState): boolean {
    return !!state.phraseContextEditor.isContext;
  }

  public static getPhrases (
    state: IState
  ): IVocabularyPhrase[] | undefined {
    return state.phraseContextEditor.phrases;
  }

  public static getPhraseText (
    state: IState
  ): IVocabularyPhrase | undefined {
    return this.getPhrases(state)?.find(p => p.type === EVocabularyPhraseType.WORD_SELECTED ||
      p.type === EVocabularyPhraseType.PREVIEW_WORD_SELECTED
    );
  }

  public static getPhraseContext (
    state: IState
  ): IVocabularyPhrase | undefined {
    return this.getPhrases(state)?.find(p => p.type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED ||
      p.type === EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED
    );
  }

  public static getIsTranslateContext(state: IState): boolean {
    return !!state.phraseContextEditor.isTranslateContext;
  }

}