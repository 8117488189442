import { IState } from '../../../../../store/types';
import { IOneTimeTooltipSimpleEvents, IOneTimeTooltipSimpleFields } from '../../components/OneTimeTooltipSimple/types';
import { connect } from 'react-redux';
import { OneTimeTooltipSimple } from '../../components/OneTimeTooltipSimple/OneTimeTooltipSimple';
import { OneTimeTooltipPhrasesEditEvents } from './events';
import { EOneTimeTooltipType, OneTimeTooltipTextProvider } from '../oneTimeTooltipTextProvider';
import { isShowOneTimeTooltipPhrasesEdit } from '../../../../../store/general/selectors';
import { IOneTimeTooltipPhrasesEditHOCProps } from './types';


const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState,
		props: IOneTimeTooltipPhrasesEditHOCProps,
	): IOneTimeTooltipSimpleFields => {
		const { buttonTitle } = props;
		return {
			show: isShowOneTimeTooltipPhrasesEdit(state),
			text: OneTimeTooltipTextProvider.getText(EOneTimeTooltipType.PHRASES_EDIT, [buttonTitle]),
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IOneTimeTooltipSimpleEvents => ({
	onClickHelp: () => dispatch(OneTimeTooltipPhrasesEditEvents.onClickHelp()),
	onClose: () => dispatch(OneTimeTooltipPhrasesEditEvents.onClose()),
});

export const OneTimeTooltipPhrasesEditHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(OneTimeTooltipSimple);

OneTimeTooltipPhrasesEditHOC.displayName = 'OneTimeTooltipPhrasesEditHOC';