import { ESystemHotKeys, IShortcutHandler } from '../types';
import { getAutoPause } from '../../../store/general/selectors';
import { getState } from '../../../store';
import { PlayerController } from '../../player/manager/playerController';
import { addPhraseByHotKey } from '../../phrase/phraseVocabularyEffect';
import { PlayerManager } from '../../player/manager/playerManager';
import { UserSettingEffects } from '../../userSettingEffects';

export class CaptionsShortcutHandler implements IShortcutHandler {

  public async handle(keyCode: ESystemHotKeys) {

    switch (keyCode) {
      case ESystemHotKeys.SPACE:
        PlayerController.getInstance().togglePlayPause();
        break;

      case ESystemHotKeys.Q:
        const pause = getAutoPause(getState());
        UserSettingEffects.setAutoPause(pause ? 0 : 1);
        break;

      case ESystemHotKeys.R:
        addPhraseByHotKey(await PlayerManager.getInstance().getCurrentTime());
        break;

      case ESystemHotKeys.S:
      case ESystemHotKeys.ArrowDown:
        PlayerController.getInstance().repeat();
        break;

      case ESystemHotKeys.A:
      case ESystemHotKeys.ArrowLeft:
        PlayerController.getInstance().playPrev();
        break;

      case ESystemHotKeys.D:
      case ESystemHotKeys.ArrowRight:
        PlayerController.getInstance().playNext();
        break;
    }
  }

}