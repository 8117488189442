import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Menu } from '@mui/material';
import { IconMenuItem } from '../../../../../../common/components/IconMenuItem/IconMenuItem';
import { IPhraseDetailsPopupMenuProps, PHRASE_DETAILS_POPUP_MENU_CLASS_NAME } from './types';
import { CopyIcon } from '../../../../../../../icons/CopyIcon';

export const PhraseDetailsPopupMenu: React.FC<IPhraseDetailsPopupMenuProps> = ({
  show,
  anchorEl,
  onCopy,
  onClose
}) => {

  return (
          <Menu
            className={PHRASE_DETAILS_POPUP_MENU_CLASS_NAME}
            anchorEl={anchorEl}
            keepMounted
            open={show}
            onClose={onClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              ml: -1,
              '.MuiMenuItem-root': {
                pl: theme.spacing(.5),
              }
            }}
          >
            <IconMenuItem
              title={'Copy text to clipboard'}
              onClick={onCopy}
            >
              <CopyIcon />
            </IconMenuItem>
          </Menu>
  );
};