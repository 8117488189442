import { API_URL } from '../../constants';
import { TUserGroup } from '../../../spa/store/models/types';
import { TAsyncThunk } from '../../../spa/store/types';
import { postRequest } from '../../utils/requestService';

const USER_GROUP_REMOVE_URL = API_URL + '/groups/remove';

export const removeUserGroups = (
  userGroupId: number
): TAsyncThunk => async (
  dispatch
): Promise<TUserGroup> => {
  return await dispatch(postRequest(USER_GROUP_REMOVE_URL, { userGroupId }));
};