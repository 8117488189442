import * as React from 'react';
import { Box, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { IPhrasePlaySettingsProps } from './types';
import { Text } from '../../../../../common/components/Text/Text';
import { theme } from '../../../../../common/styles/theme';
import { Pause } from '../../../../types/pause-constants';
import { SettingsIcon } from '../../../../../../icons/SettingsIcon';
import { ControlSwitch } from '../../Controls/component/ControlSwitch';
import { EPhraseListViewType } from '../../../../store/phrase-list/types';

const MAX_START_END_VALUE = 10;

export const PhrasePlaySettings: React.FC<IPhrasePlaySettingsProps> = ({
  pause,
  start,
  end,
  viewType,
  onPauseChange,
  onStartChange,
  onEndChange,
  onChangeViewType,
}) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePauseChange = ({ target }: SelectChangeEvent) => {
    onPauseChange(Number(target.value));
  }
  const handleStartChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(target.value);
    if (isValidValue(value)) {
      onStartChange(value);
    }
  }
  const handleEndChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(target.value);
    if (isValidValue(value)) {
      onEndChange(value);
    }
  }

  const isValidValue = (value: number): boolean => {
    return value >= 0 && value <= MAX_START_END_VALUE;
  }

  const handleChangeViewType = () => {
    const value = viewType === EPhraseListViewType.DEFAULT ? EPhraseListViewType.COMPACT : EPhraseListViewType.DEFAULT;
    onChangeViewType(value);
  }

  return (<Box>
    <IconButton
        color={'secondary'}
        onClick={handleClick}
    >
        <SettingsIcon />
    </IconButton>
    <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            width: theme.spacing(33),
            mt: theme.spacing(),
            boxShadow: theme.shadows[16],
          },

          '& .MuiList-root': {
            p: theme.spacing(2),
          },
        }}
      >
        <Text
          sx={{
            fontSize: theme.typography.subtitle2,
            fontWeight: 600,
          }}
        >
          Playback settings
        </Text>

        <FormControl
          sx={{
            width: '100%',
            mt: theme.spacing(2),
          }}
        >
          <InputLabel>Auto pause</InputLabel>
          <Select
              variant={'filled'}
              value={String(pause) || ''}
              onChange={handlePauseChange}
            >
              {Pause.pauseLengthsWithNo.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                  >
                    {item.text}
                  </MenuItem>
                ))
              }
            </Select>
        </FormControl>

        <Stack
          direction={'row'}
          gap={theme.spacing()}
          sx={{
            mt: theme.spacing(2),
          }}
        >
          <TextField
            label={'Start offset'}
            variant={'filled'}
            value={start}
            InputProps={{
              endAdornment: <InputAdornment position={'end'}>sec</InputAdornment>,
            }}
            onChange={handleStartChange}
          />
          <TextField
            label={'End offset'}
            variant={'filled'}
            value={end}
            InputProps={{
              endAdornment: <InputAdornment position={'end'}>sec</InputAdornment>,
            }}
            onChange={handleEndChange}
          />  
        </Stack>

        <Text
          sx={{
            mt: theme.spacing(3),
            fontSize: theme.typography.subtitle2,
            fontWeight: 600,
          }}
        >
          View settings
        </Text>

        <ControlSwitch
          title='Notes compact view'
          checked={viewType === EPhraseListViewType.COMPACT}
          sx={{
            width: 1,
          }}
          onChange={handleChangeViewType}
        />

      </Menu>
    </Box>);
};
