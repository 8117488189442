import { AppThunk } from '../../../../store/types';
import { setShowPinExtensionPopupAction } from '../../../../store/general/actions';
import { getGeneralStorage } from '../../../../../common/utils/local-storage/local-storage-helpers';

export class PinExtensionPopupEvents {

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setShowPinExtensionPopupAction(false));
      getGeneralStorage().setShowPinExtensionPopup(false);
    }
  }

}
