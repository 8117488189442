import { IState } from '../../../../../../store/types';
import { getCopyVideoConfirm, getVideoTutorialMode } from '../../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { IVideoTutorialPopupContainerFields } from '../../components/VideoTutorialPopupContainer/types';
import { VideoTutorialPopupContainer } from '../../components/VideoTutorialPopupContainer/VideoTutorialPopupContainer';

const mapStateToProps = (
  dispatch: any,
) => {
  return (
    state: IState
  ): IVideoTutorialPopupContainerFields => {
    const activeId = getVideoTutorialMode(state);
    let isShow = activeId !== undefined && activeId !== null;
    if (isShow) {
      const copyVideoConfirm = getCopyVideoConfirm(state);
      if (copyVideoConfirm.show) isShow = false;
    }
    return {
      isShow
    }
  };
}

export const VideoTutorialPopupContainerHOC = connect(
  mapStateToProps,
  null,
)(VideoTutorialPopupContainer);

VideoTutorialPopupContainerHOC.displayName = 'VideoTutorialPopupContainerHOC';