import { AppThunk } from '../../../../store/types';
import { ExtensionInstallChecker } from '../../../../effects/extension-install-checker';
import { EXTENSION_INSTALL_URL } from '../../../../../common/constants';
import { EventsRouter } from '../../../../../common/events/eventsRouter';
import { Events } from '../../../../../common/events/types';

export class InstallExtensionWarningEvents {

  public static onCheckInstalled(): AppThunk {
    return async (
      dispatch,
      getState
    ): Promise<boolean> => {
   /*   if (getBuildMode() === EBuildMode.DEVELOPMENT)
        return true;*/
      return ExtensionInstallChecker.check();
    }
  }

  public static onInstall(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      EventsRouter.trackEvent(Events.INSTALL_EXTENSION_BUTTON_CLICKED);
      window.open(EXTENSION_INSTALL_URL);
      // для CHROME_EXTENSION_INSTALLED можно добавить setInterval ExtensionInstallChecker.check(),
      // но он не работает (chrome.runtime undefined)
    }
  }
}