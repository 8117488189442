import {
  IPhraseSelectPopupSetShowAction,
  SET_PHRASE_SELECT_POPUP_SHOW_ACTION,
} from './types';

export class PhraseSelectPopupActions {

  public static setShow(show: boolean): IPhraseSelectPopupSetShowAction {
    return {
      type: SET_PHRASE_SELECT_POPUP_SHOW_ACTION,
      show
    }
  }

}