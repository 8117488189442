import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';

export class PhraseDetailsTabsLibContainerEvents {

  public static onLoad(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestPrompt(''));
      dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestResult(''));
      dispatch(PhraseDetailsTabsEditorPopupActions.setOpenPromptLibId(0));
    }
  }

}