import { SyncStorage } from './local-storage-helpers';

export class OneTimeTooltipSelectStorage extends SyncStorage {

	private static LS_KEY = 'onetime-tooltip-select';
	private static CLICKS_COUNT = [3, 6, 20, 40]; // number of clicks to show tooltip

	public static getItem(): number {
		return Number(window.localStorage.getItem(this.LS_KEY));
	}

	public static setItem(value: number) {
		return window.localStorage.setItem(this.LS_KEY, String(value));
	}

	public static isShow(): boolean {	
		let item = this.getItem();
		
		return this.CLICKS_COUNT.includes(item);
	}

	public static inc() {
		let item = this.getItem();
		this.setItem(item + 1);
	}

	public static disable() {
		this.setItem(this.CLICKS_COUNT[this.CLICKS_COUNT.length - 1] + 1);
	}

}
