import { AppThunk } from '../../../../../store/types';
import { setPlayerRate } from '../../../../../store/general/actions';
import {
  getAutoSync,
  getShowNativeCaptions,
  isHoverAutoPause, isPauseOnNote
} from '../../../../../store/general/selectors';
import { UserSettingEffects } from '../../../../../effects/userSettingEffects';
import { CaptionsSelectionPopupSelectors } from '../../../../../store/captions-selection-popup/selectors';
import { PlayerFactory } from '../../../../../effects/player/player-factory';


export class PlaybackSettingsEvents {

  public static onPauseSizeChange(pause: number): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      UserSettingEffects.setAutoPause(pause);
    }
  }

  public static onAutoSyncChange(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const autoSync = getAutoSync(getState());
      UserSettingEffects.setAutoSync(!autoSync);
    }
  }

  public static onHoverAutoPauseChange(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const pause = isHoverAutoPause(getState());
      UserSettingEffects.setHoverAutoPause(!pause);
    }
  }

  public static onShowNativeCaptionsChange(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const show = getShowNativeCaptions(getState());
      UserSettingEffects.setShowNativeCaptions(!show);
    }
  }

  public static onAutoSpeechTextChange(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const value = CaptionsSelectionPopupSelectors.getAutoSpeechText(getState());
      UserSettingEffects.setAutoSpeechSelectedText(!value);
    }
  }

  public static onRateChange(rate: number): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const playerApi = await PlayerFactory.getPlayerApi();
      playerApi.setPlaybackRate(rate);
      dispatch(setPlayerRate(rate));
    }
  }

  public static onPauseOnNoteChange(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      const value = isPauseOnNote(getState());
      UserSettingEffects.setPauseOnNote(!value);
    }
  }


}