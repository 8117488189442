import { AppThunk } from '../../../../../store/types';
import { PhraseContextEditorActions } from '../../../../../store/phrase-context-editor/actions';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { findVideoPhraseByWordId } from '../../../../../store/videos/selectors';
import { PhraseEffects } from '../../../../../effects/phrase/PhraseEffects';
import { PhraseContextEditorEffects } from '../../../PhraseContextEditor/effects/phraseContextEditorEffects';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';

export class PhraseTextEvents {

	public static onContextModify(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			dispatch(PhraseContextEditorEffects.open());
		}
	}

	public static onContextDelete(
	): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const phraseId = PhraseDetailsSelectors.getPhraseId(getState());
			let phrase;
			if (phraseId) {
				phrase = findVideoPhraseByWordId(getState(), phraseId);
			}
			if (phrase && phrase.id) {
				PhraseEffects.deleteContext(phrase.id);
			} 
			dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
				isContext: false,
			}));
		}
	}

	public static onContextTranslate(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			const isTranslateContext = PhraseContextEditorSelectors.getIsTranslateContext(getState());
			if (!isTranslateContext) {
				dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
					isTranslateContext: true,
				}));
			}
		}
	}

}