import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { setSnackbarPanelAction } from '../../../../../store/general/actions';
import { getDispatch } from '../../../../../store';

export class PhraseDetailsPopupMenuEvents {

  public static onCopy(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const text = PhraseDetailsSelectors.getPopupMenu(getState())?.text;
      if (text) {
        await navigator.clipboard.writeText(text);
        dispatch(setSnackbarPanelAction(true, 'Text copied to clipboard'));
        PhraseDetailsPopupMenuEvents.closeMenu();
      }
    }
  }

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      PhraseDetailsPopupMenuEvents.closeMenu();
    }
  }

  private static closeMenu() {
    getDispatch()(PhraseDetailsActions.setPopupMenu({
      show: false,
      text: '',
      anchorElement: null
    }))
  }


}