import { IState } from '../../../../store/types';
import { isShowNewFeaturesPopup } from '../../../../store/general/selectors';
import { connect } from 'react-redux';
import { NewFeaturesPopup } from '../components/NewFeaturesPopup';
import { INewFeaturesPopupEvents, INewFeaturesPopupFields } from '../components/types';
import { NewFeaturesPopupEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): INewFeaturesPopupFields => {
    return {
      isShow: isShowNewFeaturesPopup(state)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): INewFeaturesPopupEvents => ({
    onLoad: () => dispatch(NewFeaturesPopupEvents.onLoad()),
    onClose: () => dispatch(NewFeaturesPopupEvents.onClose())
  }
);

export const NewFeaturesPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewFeaturesPopup);

NewFeaturesPopupHOC.displayName = 'NewFeaturesPopupHOC';
