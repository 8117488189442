import React from 'react';
import { theme } from '../../../../../../../common/styles/theme';
import { Box, Stack } from '@mui/material';
import { PhraseDetailsTabHeaderHOC } from '../../../PhraseDetailsTabHeader/hocs/PhraseDetailsTabHeaderHOC';
import { IPhraseDetailsTabTranslationContainerProps } from './types';
import { PhraseDetailsTabTranslationLangHOC } from '../../PhraseDetailsTabTranslationLang/hocs/PhraseDetailsTabTranslationLangHOC';
import { PhraseDetailsTabTranslationLangAddHOC } from '../../PhraseDetailsTabTranslationLangAdd/hocs/PhraseDetailsTabTranslationLangAddHOC';
import { DragDropContext, DragStart, Droppable, DropResult } from 'react-beautiful-dnd';
import { Text } from '../../../../../../../common/components/Text/Text';
import { Paper } from '../../../../../../../common/components/Paper/Paper';
import { UserLangsTranslationColors } from '../../helpers/userLangsTranslationColors';


export const PhraseDetailsTabTranslationContainer: React.FC<IPhraseDetailsTabTranslationContainerProps> = ({
  translateLangs,
  onSort
}) => {

  const [dragLangCode, setDragLangCode] = React.useState<string>('');

  const handleDrop = (droppedItem: DropResult) => {
    if (!droppedItem.destination) {
      setDragLangCode('');
      return;
    }
    const srcIndex = droppedItem.source.index;
    const sortedLangs = [...translateLangs];
    const srcLang = translateLangs[srcIndex];
    const destLang = translateLangs[droppedItem.destination.index];
    sortedLangs.splice(srcIndex, 1);
    let destIndex = sortedLangs.findIndex(l => l.code === destLang.code);

    if (droppedItem.destination.index > droppedItem.source.index)
      destIndex++;
    sortedLangs.splice(destIndex, 0, srcLang);
    setDragLangCode('');
    onSort(sortedLangs.map(l => l.code));
  };

  const handleStartDrag = (event: DragStart) => {
    setDragLangCode(event.draggableId);
  }

  const paperStyle = {
    minWidth: theme.spacing(50),
    maxWidth: theme.spacing(50),
    height: 1,
    p: `${theme.spacing(2)} ${theme.spacing(2)} 0`,
  };


  return (
    <Paper sx={paperStyle}>
      <Stack sx={{
        height: '100%',
      }}>
        <Stack>
          <PhraseDetailsTabHeaderHOC />

          <Text variant={'h5'} fontWeight={600} sx={{
            mb: theme.spacing(1),
          }}>
              Translations
          </Text>
        </Stack>

        <Box sx={{
          flex: `1 1 100%`,
          mx: theme.spacing(-2),
          overflow: 'auto',
        }}>

          
          <Stack
            gap={theme.spacing(2)}
            sx={{
              maxWidth: theme.spacing(48),
              mt: theme.spacing(1),
              pl: theme.spacing(2),
              pb: theme.spacing(2),
            }}
          >
            <PhraseDetailsTabTranslationLangAddHOC />

            {translateLangs && !!translateLangs.length &&
              <DragDropContext onDragEnd={handleDrop} onDragStart={handleStartDrag} >
                <Droppable droppableId="list-container">
                  {(provided) => (
                      <Stack
                          spacing={theme.spacing()}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                      >
                        {translateLangs.map((lang, index) => {
                          return <PhraseDetailsTabTranslationLangHOC
                              key={lang.code}
                              index={index}
                              langCode={lang.code}
                              color={UserLangsTranslationColors.getColor(lang.code)}
                              dragable={dragLangCode === lang.code}
                          />
                        })}
                        {provided.placeholder}
                      </Stack>
                  )}
                </Droppable>
              </DragDropContext>
            }
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );

}

