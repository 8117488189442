import { IState } from '../../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabPromptForm } from '../../components/PhraseDetailsTabPromptForm';
import { IPhraseDetailsTabPromptFormEvents, IPhraseDetailsTabPromptFormFields } from '../../components/types';
import { getPhraseDetailsTabById } from '../../../../../../store/models/selectors';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsTabCustomPromptFormEvents } from './events';
import { PhraseDetailsCustomPromptVars } from '../../../../../../effects/phrase-details/phraseDetailsCustomPromptVars';
import { PhraseDetailsTabEffects } from '../../../../../../effects/phraseDetailsTabEffects';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabPromptFormFields => {
    const tabId = PhraseDetailsTabsEditorPopupSelectors.getEditTabId(state);
    const tab = getPhraseDetailsTabById(state, tabId);   
    const prompt = tab && PhraseDetailsTabEffects.getCustomPromptByTab(tab);
    const lastSaveTabId = PhraseDetailsTabsEditorPopupSelectors.getLastSaveTabId(state);
    const showSaveConfirm = lastSaveTabId > 0 && lastSaveTabId === tabId;

    return {
      id: tabId,
      show: true,
      showSaveConfirm,
      title: prompt?.title || '',
      prompt: prompt?.prompt || '',
      description: prompt?.description || '',
      processing: PhraseDetailsTabsEditorPopupSelectors.isProcessing(state),
      vars: PhraseDetailsCustomPromptVars.getList(),
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabPromptFormEvents => ({
    onShow: () => dispatch(PhraseDetailsTabCustomPromptFormEvents.onShow()),
    onSave: (title, prompt, description) => dispatch(PhraseDetailsTabCustomPromptFormEvents.onSave(title, prompt, description)),
    onValidate: prompt => dispatch(PhraseDetailsTabCustomPromptFormEvents.onValidate(prompt)),
    onPromptChange: prompt => dispatch(PhraseDetailsTabCustomPromptFormEvents.onPromptChange(prompt)),
  }
);

export const PhraseDetailsTabCustomPromptFormHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabPromptForm);

PhraseDetailsTabCustomPromptFormHOC.displayName = 'PhraseDetailsTabCustomPromptFormHOC';