import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsPromptLibEffects } from '../../../../../effects/phrase-details/phraseDetailsPromptLibEffects';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';

export class PhraseDetailsTabLibEvents {

  public static onLoad(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      PhraseDetailsPromptLibEffects.loadPromptLibs();
    }
  }

  public static onSelect(id: number): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(PhraseDetailsTabsEditorPopupActions.setOpenPromptLibId(id));
      dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestResult(''));
    }
  }
}


