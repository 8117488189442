import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { PinExtensionPopup } from '../components/PinExtensionPopup/PinExtensionPopup';
import { IPinExtensionPopupEvents, IPinExtensionPopupFields } from '../components/PinExtensionPopup/types';
import { PinExtensionPopupEvents } from './events';
import { isShowPinExtensionPopup } from '../../../../store/general/selectors';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPinExtensionPopupFields => {
    return {
      show: isShowPinExtensionPopup(state)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPinExtensionPopupEvents => ({
    onClose: () => dispatch(PinExtensionPopupEvents.onClose())
  }
);

export const PinExtensionPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PinExtensionPopup);

PinExtensionPopupHOC.displayName = 'PinExtensionPopupHOC';
