import { AppThunk } from '../../../../../store/types';
import { setEditPlaylistModalIdAction } from '../../../../../store/general/actions';
import { getEditPlaylistModalId } from '../../../../../store/general/selectors';
import { UserGroupEffects } from '../../../../../effects/userGroupEffects';
import { EUserLevel } from '../../../../../store/general/types';

export class EditGroupEvents {

  public static onSave(
    name: string,
    publicGroup: boolean,
    level: EUserLevel
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      const groupId = getEditPlaylistModalId(getState());
      await UserGroupEffects.updateGroup(groupId, name, undefined, undefined, publicGroup, level);
    }
  }

  public static onClose(): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      dispatch(setEditPlaylistModalIdAction(0));
    }
  }

}