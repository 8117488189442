import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { Modal } from '../../../../../../common/components/Modal/Modal';
import { alpha, Box, Button, Stack } from '@mui/material';
import { IProfileVideoPopupProps } from './types';
import { Text } from '../../../../../../common/components/Text/Text';
import { PhraseNoteHTML } from '../../../PhraseDetails/PhraseNoteHTML/components/PhraseNoteHTML';
import { PlusIcon } from '../../../../../../../icons/PlusIcon';

export const ProfileVideoPopup: React.FC<IProfileVideoPopupProps> = ({
  video,
  onClose,
  onSave,
}) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSaveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectGroup = (groupId: number) => {
    setAnchorEl(null);
    // onSave(groupId);
  }

  const videoImageUrl = video.thumbStandard || video.thumbMedium || video.thumb;

  const player = React.useRef(null);	
	React.useEffect(() => {
    let videoId = video.videoKey;
		requestAnimationFrame(() => {

			function init() {
				player.current = new window.YT.Player('ytplayer', {
					videoId,
					playerVars: {
						autoplay: !video.notes?.length,
						showinfo: 0,
						autohide: 1,
						modestbranding: 1,
					},
					events: {
						'onStateChange': (event: any) => {},
					}
				});
			}
			if (!document.getElementById('ytplayer')) {
				let div = document.createElement('div');
				div.id = 'ytplayer';
				document.getElementById('ytplayer-container-profile-popup')?.append(div);
			}

			if (window.YT && window.YT.Player) {
				init();
			} else if (document.getElementById('yt_iframe_api')) {
				let callback = window.onYouTubeIframeAPIReady;
				window.onYouTubeIframeAPIReady = () => {
					if (callback) callback();
					init();
				}
			} else {
				let tag = document.createElement('script');
				tag.src = 'https://www.youtube.com/iframe_api';
				tag.id = 'yt_iframe_api';
				let firstScriptTag = document.getElementsByTagName('script')[0];
				firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
				window.onYouTubeIframeAPIReady = init;
			}
		});

		return () => {
		}
	}, [video.videoKey]);

  return (
    <Modal
      onClose={onClose}
      open={true}
      PaperProps={{
        sx: {
          width: 1,
          maxWidth: '700px !important',
        }
      }}
    >
      <Stack
        gap={theme.spacing(2)}
        sx={{
          width: 1,
          mt: -3,
        }}
      >
        <Stack
          gap={theme.spacing(.5)}
        >
          <Box
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '-webkit-line-clamp': '2',
              '-webkit-box-orient': 'vertical',
              maxHeight: '2.6em',
              lineHeight: `1.3 !important`,
              
              maxWidth: '80%',
              fontSize: theme.typography.h5,
              fontWeight: 600,
            }}
          >
            {video.title}
          </Box>

          <Text
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: theme.typography.subtitle2,
              color: theme.palette.grey[500],
              whiteSpace: 'nowrap',
            }}
          >
            {video.author}
          </Text>
        </Stack>

      <Box
				id={'ytplayer-container-profile-popup'}
				sx={{
					position: 'relative',
					flex: `1 1 100%`,
					minHeight: 0,

					'iframe': {
						width: `100% !important`,
						height: `100% !important`,
						aspectRatio: 16/9,
						borderRadius: theme.spacing(1),
						border: 'none',
					}
				}}
			/>

        <Stack
					direction={'row'}
					gap={2}
          justifyContent={'space-between'}
          sx={{
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
				>
          <Stack
            gap={.5}
            sx={{
              minWidth: 0,
            }}
          >
            <Stack
              direction={'row'}
              gap={3}
            >
              {/* <User
                name={'testAuthor'}
                photo={'https://i.ytimg.com/vi/9gidul7vqOw/default.jpg'}
              /> */}
              {/* <Stack
                direction={'row'}
                gap={1}
                sx={{
                  '.lang-flag__code': {
                    color: theme.palette.grey[500],
                  },
                }}
              >
                <LangFlag code={targetLang} showCode={true} />
                <LangFlag code={nativeLang} showCode={true} />
              </Stack> */}
            </Stack>

            {/* <Text
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: theme.typography.subtitle2,
                color: theme.palette.grey[500],
                whiteSpace: 'nowrap',
              }}
            >
              {listTitle}
            </Text> */}
          </Stack>

          <Button
            variant={'contained'}
            startIcon={
              <PlusIcon sx={{ width: theme.spacing(2.5), height: theme.spacing(2.5) }} />
            }
            onClick={() => onSave(0)}
          >
            Add to my playlist
          </Button>
        </Stack>


          <Stack
            gap={1}
          >
            {video.notes && video.notes.length &&
              video.notes
              .sort((a, b) => {
                return +(a.phraseNoteId > b.phraseNoteId)
              })
              .map((note) => {

              return (
                <Box
                  className={`
                    profile-popup-note
                    ${!!note.phraseText ? 'phrase' : ''}
                  `}
                  key={note.phraseNoteId}
                  sx={{
                    position: 'relative',
                    p: theme.spacing(1),
                    borderRadius: theme.spacing(.75),
                    border: `2px solid ${theme.palette.grey[100]}`,

                    '.phrase-note-html': {
                      color: theme.palette.grey[500],
                      cursor: 'pointer',
                      p: 0,
                      border: `0 none`,

                      'pre': {
                        background: alpha(theme.palette.grey[100] , .8),
                      },

                      'code': {
                        background: alpha(theme.palette.grey[100] , .8),
                      },
                    },
                  }}
                >
                  <Text
                    sx={{
                      fontSize: theme.typography.subtitle2,
                      fontWeight: 400,
                      lineHeight: 1.7,
                    }}
                  >{note.phraseText}</Text>
                  {!!note.noteText &&
                      <Box
                        sx={{

                          minHeight: theme.spacing(4),
                          cursor: 'pointer',

                          [`.profile-popup-note.phrase &`]: {
                            pt: theme.spacing(1),

                            '&:before': {
                              content: '""',
                              position: 'absolute',
                              mt: theme.spacing(-1),
                              left: theme.spacing(0),
                              right: theme.spacing(0),
                              borderTop: `1px solid ${theme.palette.grey[100]}`,
                            },
                          },
                      }}>
                        <PhraseNoteHTML text={note.noteText} />
                      </Box>
                  }
                  
                </Box>
              )
            })}
          </Stack>

  
        {/* <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!anchorEl}
          onClose={handleClose}
          sx={{
            '.MuiPaper-root': {
              width: theme.spacing(33),
              mt: theme.spacing(),
              boxShadow: theme.shadows[16],
            },

            '.MuiMenuItem-root': {
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        >
          {groups.map(group => {
            return <MenuItem
              onClick={() => onSelectGroup(group.id)}
            >
              {getUserGroupTitle(getState(), group)}
            </MenuItem>
          })}

          <Button
            variant={'contained'}
            sx={{
              ml: 2,
              mt: 1,
            }}
            onClick={() => onSelectGroup(0)}
          >
            Create new playlist
          </Button>

        </Menu> */}
      </Stack>
    </Modal>
  );
}