import { EUserLevel } from '../../../../store/general/types';


export interface IPlayListLevelSelectFields {
  value: EUserLevel;
}

export interface IPlayListLevelSelectEvents {
  onChange: (level: EUserLevel) => void
}

export interface IPlayListLevelSelectProps extends
  IPlayListLevelSelectFields,
  IPlayListLevelSelectEvents
{}

export const PlayListLevelTitles: Record<EUserLevel, string> = {
  [EUserLevel.UNKNOWN]: 'Undefined',
  [EUserLevel.BEGINNER]: 'Beginner',
  [EUserLevel.INTERMEDIATE]: 'Intermediate',
  [EUserLevel.ADVANCED]: 'Advanced'
}