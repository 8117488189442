import React from 'react';
import { IPhraseDetailsTabItemCheckButtonProps } from './types';
import { IconButton, Tooltip } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';


export const PhraseDetailsTabItemCheckButton: React.FC<IPhraseDetailsTabItemCheckButtonProps> = ({
  active,
    tooltip,
  onClick,
  children
}) => {

  const buttonStyle = {
    minWidth: theme.spacing(2.75),
    color: active ? theme.palette.primary.main : theme.palette.grey[400],
    px: 0,
    '&:before': {
      content: '""',
      position: 'absolute',
      insert: theme.spacing(-0.5)
    }
  }

  return (
    <Tooltip title={tooltip} arrow enterDelay={400} enterNextDelay={300} disableInteractive>
      <IconButton
        color={'primary'}
        sx={buttonStyle}
        onClick={onClick}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
}
