import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { IPromptsLibItemHOCOwnProps } from './types';
import { PromptsLibItemEvents } from './events';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PromptsListItem } from '../../PromptsListItem/components/PromptsListItem';
import { IPromptsListItemEvents, IPromptsListItemFields } from '../../PromptsListItem/components/types';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { getPhraseDetailsTabByTypeAndRelationId } from '../../../../../store/models/selectors';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';

const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState,
		{ id }: IPromptsLibItemHOCOwnProps
	): IPromptsListItemFields => {
		const prompt = PhraseDetailsTabsEditorPopupSelectors.getPromptLibById(state, id);
		const tab = getPhraseDetailsTabByTypeAndRelationId(state, EPhraseDetailsTabType.LIB_PROMPT, id);
		return {
			favorite: tab?.favorite || false,
			isSelected: PhraseDetailsSelectors.getActiveTab(state)?.id === id,
			text: prompt?.description || '',
			title: prompt?.title || '',
		}
	};
}

const mapDispatchToProps = (
	dispatch: any,
	{ id, onSelected }: IPromptsLibItemHOCOwnProps
): IPromptsListItemEvents => ({
	onClick: () => dispatch(PromptsLibItemEvents.onClick(id, onSelected)),
	onClickFavorite: () => dispatch(PromptsLibItemEvents.onClickFavorite(id)),
}
);

export const PromptsLibItemHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(PromptsListItem);

PromptsLibItemHOC.displayName = 'PromptsLibItemHOC';
