import { IProfileNoteInfoProps } from './types';
import { ProfileVideo } from '../../ProfileVideo/components/ProfileVideo';
import React from 'react';
import { Box, Stack, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import LangFlag from '../../../LangFlag/LangFlag';
import { TUserProfileGroupVideo } from '../../../../../../common/rest/user/userProfileRest';

export function ProfileNoteInfo({
	videos,
}: IProfileNoteInfoProps) {

	const className = 'profile-note-info';

	return (
		<Box
			sx={{
				position: 'relative',
				display: 'grid',
				gridTemplateColumns: `repeat(4, 1fr)`,
				gap: 2,

				[theme.breakpoints.down('md')]: {
					gridTemplateColumns: `repeat(3, 1fr)`,
				},

				[theme.breakpoints.down('sm')]: {
					gridTemplateColumns: `repeat(2, 1fr)`,
				}
			}}
		>
			{videos.map((video) => {
				const v: TUserProfileGroupVideo = {
					groupId: video.groupId,
					videoId: video.videoId,
					videoKey: video.videoKey,
					title: video.videoTitle,
					thumb: video.thumbUrl,
					thumbMedium: video.thumbMediumUrl,
					thumbStandard: video.thumbStandardUrl,
					author: video.videoAuthor,
					notes: video.notes,
					targetLangCode: video.targetLangCode,
  					nativeLangCode: video.nativeLangCode,
				}
				return (
					<>
						<ProfileVideo
							groupId={video.groupId}
							showPopup={true}
							video={v}
							key={v.videoKey}
						/>
					</>
				)
			})}
		</Box>
	)
}