import { AppThunk } from '../../../../../store/types';
import { ContentLibActions } from '../../../../../store/contentLib/actions';
import { EContentLibViewMode } from '../../../../../store/contentLib/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { MainLayoutEffects } from '../../../../../effects/mainLayoutEffects';

export class ContentLibraryHeaderEvents {

  public static onTextChange(text: string): AppThunk {
     return (
       dispatch,
       getState
     ) => {
       dispatch(ContentLibActions.setSearchFilter({text}));
     }
   }

  public static onChangeTargetLangCode(targetLangCode: string): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (ContentLibSelectors.getViewMode(getState()) === EContentLibViewMode.GROUP_VIDEO_LIST) {
        dispatch(ContentLibActions.setViewMode(EContentLibViewMode.VIDEO_LIST));
      }

      dispatch(ContentLibActions.setSearchFilter({targetLangCode}));
    }
  }

  public static onChangeNativeLangCode(nativeLangCode: string): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      if (ContentLibSelectors.getViewMode(getState()) === EContentLibViewMode.GROUP_VIDEO_LIST) {
        dispatch(ContentLibActions.setViewMode(EContentLibViewMode.VIDEO_LIST));
      }

      dispatch(ContentLibActions.setSearchFilter({nativeLangCode}));
    }
  }

  public static onChangeViewMode(viewMode: EContentLibViewMode): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibActions.setViewMode(viewMode));
      if (viewMode != EContentLibViewMode.GROUP_LIST) {
        dispatch(ContentLibActions.setGroupsSearchResult({ groups: [], loading: false }));
      }
    }
  }

  public static onBack(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(ContentLibActions.setGroupsSearchResult({ groups: [], loading: false }));
      history.back();
    }
  }

  public static onClickHelp (): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(MainLayoutEffects.showHelp());
    }
  }



}