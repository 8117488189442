import { IState } from '../../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabTranslationContainer } from '../components/PhraseDetailsTabTranslationContainer';
import {
  IPhraseDetailsTabTranslationContainerEvents,
  IPhraseDetailsTabTranslationContainerFields
} from '../components/types';
import { getAuthUser, getAuthUserTranslateLangs } from '../../../../../../store/general/selectors';
import { PhraseDetailsTabTranslationContainerEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabTranslationContainerFields => {
    return {
      translateLangs: getAuthUserTranslateLangs(state)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabTranslationContainerEvents => ({
    onSort: langCodes => dispatch(PhraseDetailsTabTranslationContainerEvents.onSort(langCodes))
  }
);

export const PhraseDetailsTabTranslationContainerHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabTranslationContainer);

PhraseDetailsTabTranslationContainerHOC.displayName = 'PhraseDetailsTabTranslationContainerHOC';