import { AppThunk } from '../../../../store/types';
import { PhraseListActions } from '../../../../store/phrase-list/actions';
import { UserSettingEffects } from '../../../../effects/userSettingEffects';
import { EPhraseListViewType } from '../../../../store/phrase-list/types';

export class PhrasePlaySettingsEvents {

  public static onPauseChange(value: number): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      UserSettingEffects.setPhraseListPauseDelay(value);
    }
  }

  public static onStartChange(value: number): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      UserSettingEffects.setPhraseListStartPlayOffset(value);
    }
  }

  public static onEndChange(value: number): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      UserSettingEffects.setPhraseListEndPlayOffset(value);
    }
  }

  public static onChangeViewType(
    value: EPhraseListViewType
  ): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(PhraseListActions.setPhraseListViewTypeAction(value));
    }
  }

}
