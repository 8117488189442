import { IState } from '../../../../../../store/types';
import { connect } from 'react-redux';
import { PhraseDetailsTabPromptForm } from '../../components/PhraseDetailsTabPromptForm';
import { IPhraseDetailsTabPromptFormEvents, IPhraseDetailsTabPromptFormFields } from '../../components/types';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { PhraseDetailsCustomPromptVars } from '../../../../../../effects/phrase-details/phraseDetailsCustomPromptVars';
import { PhraseDetailsSelectors } from '../../../../../../store/phrase-details/selectors';
import { PhraseDetailsTabLibPromptFormEvents } from './events';
import { PhraseContextEditorSelectors } from '../../../../../../store/phrase-context-editor/selectors';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IPhraseDetailsTabPromptFormFields => {
    const promptLib = PhraseDetailsTabsEditorPopupSelectors.getOpenPromptLib(state);

    return {
      id: promptLib?.id || 0,
      show: !!promptLib,
      showSaveConfirm: false,
      title: promptLib?.title || '',
      prompt: promptLib?.prompt || '',
      description: promptLib?.description || '',
      processing: PhraseDetailsTabsEditorPopupSelectors.isProcessing(state),
      vars: PhraseDetailsCustomPromptVars.getList(),
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IPhraseDetailsTabPromptFormEvents => ({
    onShow: () => dispatch(PhraseDetailsTabLibPromptFormEvents.onShow()),
    onSave: (title, prompt, description) => dispatch(PhraseDetailsTabLibPromptFormEvents.onSave(title, prompt, description)),
    onValidate: prompt => dispatch(PhraseDetailsTabLibPromptFormEvents.onValidate(prompt)),
    onPromptChange: prompt => dispatch(PhraseDetailsTabLibPromptFormEvents.onPromptChange(prompt)),
  }
);

export const PhraseDetailsTabLibPromptFormHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhraseDetailsTabPromptForm);

PhraseDetailsTabLibPromptFormHOC.displayName = 'PhraseDetailsTabLibPromptFormHOC';