import { createBrowserHistory } from 'history';

export class HistoryProrvider {

  private static history: any;

  public static getHistory() {
    if (!HistoryProrvider.history) {
      HistoryProrvider.history = createBrowserHistory();
    }
    return HistoryProrvider.history;
  }
}