import { ILanguageItem } from '../general/types';
import { getNativeLanguage, getTargetLanguage } from '../models/selectors';
import { IPhraseDetailsState } from '../phrase-details/types';
import { IState } from '../types';
import { getTargetCaptions } from '../videos/selectors';
import { EPhraseDetailsTabsEditorNavigationMode, EPhraseDetailsTabsEditorViewMode, TLibGptPrompt } from './types';

export class PhraseDetailsTabsEditorPopupSelectors {

  public static getViewMode(state: IState): EPhraseDetailsTabsEditorViewMode {
    return state.phraseDetailsTabsEditorPopup.viewMode;
  }

  public static getNavigationMode(state: IState): EPhraseDetailsTabsEditorNavigationMode {
    return state.phraseDetailsTabsEditorPopup.navigationMode;
  }

  public static getEditTabId(state: IState): number {
    return state.phraseDetailsTabsEditorPopup.editTabId;
  }

  public static isProcessing(state: IState): boolean {
    return state.phraseDetailsTabsEditorPopup.processing;
  }


  public static getPromptTestPrompt(state: IState): string {
    return state.phraseDetailsTabsEditorPopup.promptTest.prompt;
  }

  public static getPromptTestResult(state: IState): string {
    return state.phraseDetailsTabsEditorPopup.promptTest.result;
  }

  public static isPromptTestProcessing(state: IState): boolean {
    return state.phraseDetailsTabsEditorPopup.promptTest.processing;
  }

  public static getPromptLibs(state: IState): TLibGptPrompt[] | null {
    return state.phraseDetailsTabsEditorPopup.promptLibs;
  }

  public static getPromptLibById(state: IState, id: number): TLibGptPrompt | undefined {
    const libs = PhraseDetailsTabsEditorPopupSelectors.getPromptLibs(state);
    return libs ? libs.find(l => l.id === id) : undefined;
  }

  public static getOpenPromptLibId(state: IState): number {
    return state.phraseDetailsTabsEditorPopup.openPromptLibId;
  }

  public static getOpenPromptLib(state: IState): TLibGptPrompt | undefined {
    return PhraseDetailsTabsEditorPopupSelectors.getPromptLibById(state, PhraseDetailsTabsEditorPopupSelectors.getOpenPromptLibId(state) || 0);
  }

  public static isShowTutorial(state: IState): boolean {
    return state.phraseDetailsTabsEditorPopup.showTutorial;
  }

  public static isShowFavoritePromptTooltip(state: IState): boolean {
    return state.phraseDetailsTabsEditorPopup.showFavoritePromptTooltip;
  }

  public static getLastSaveTabId(state: IState) : number {
    return state.phraseDetailsTabsEditorPopup.lastSaveTabId;
  }

  public static getFirstCaptionAsPhrase(state: IState): Partial<IPhraseDetailsState> {
    const captions = getTargetCaptions(state);
      if (captions && captions.length) {
        let caption = captions[0];
        let context = caption.text || '';
        let words = context.split(' ');
        let text = '';
        words.forEach(word => {
          if (word.length > text.length) {
            text = word;
          }
        });
        return {
          context,
          text,
          fromLang: getTargetLanguage(state) as ILanguageItem,
          toLang: getNativeLanguage(state) as ILanguageItem,
        };
      }
    return {};
  }
}