import { IState } from '../types';
import { IPublicProfilesState } from './types';
import { TTeacherPhraseNoteGroupInfo, TUserProfileGroup } from '../../../common/rest/user/userProfileRest';

export class PublicProfileSelectors {

  public static getData(state: IState): IPublicProfilesState {
    return state.publicProfile;
  }

  public static getGroupById(state: IState, id: number): TUserProfileGroup | undefined {
    return this.getData(state).groups.find(g => g.id === id)
  }

  public static getNoteGroupById(state: IState, groupId: number): TTeacherPhraseNoteGroupInfo | undefined {
    return this.getData(state).notes.find(g => g.groupId == groupId);
  }
}
