import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { IProfileVideoProps } from './type';
import Stack from '@mui/material/Stack';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from '../../../../../../common/styles/theme';
import { ProfileVideoPopupHOC } from '../../ProfileVideoPopup/hocs/ProfileVideoPopupHOC';
import LangFlag from '../../../LangFlag/LangFlag';

export function ProfileVideo({
	groupId,
	showPopup,
	video,
	onClick
}: IProfileVideoProps) {

	const [isPopup, setPopup] = React.useState(false);
	const handleClick = () => {
		if (showPopup) {
			setPopup(true);
		}
		if (onClick)
			onClick();
	}
	
	const [isLongTitle, setLongTitle] = React.useState(false);
	const ref1 = React.useRef(null);
	const ref2 = React.useRef(null);

    React.useEffect(() => {
        if (!isLongTitle && ref1.current && ref2.current) {
			//@ts-ignore
			if (ref2.current.offsetHeight > ref1.current.offsetHeight) {
				setLongTitle(true);
			}
		}
    }, [video.title]);

	const titleElement = <Text
		lineHeight={`1.3 !important`}
		sx={{
			display: '-webkit-box',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'-webkit-line-clamp': '2',
			'-webkit-box-orient': 'vertical',
			maxHeight: '2.6em',

			fontSize: theme.typography.subtitle2,
			fontWeight: 600,
		}}
		ref={ref1}
	>
		<span
			ref={ref2}
		>
			{video.title}
		</span>
	</Text>

	return (<>
		<Stack
			sx={{
				flex: `1 1 100%`,
				minWidth: 0,
				cursor: 'pointer',
			}}
			gap={1}
			onClick={handleClick}
		>
			<Box
				sx={{
					aspectRatio: 16/9,
					backgroundPosition: '50% 50%',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					backgroundImage: `url(${video.thumbMedium})`,
					borderRadius: theme.spacing(1),
				}}
			/>

			<Stack
				gap={.25}
			>
				{isLongTitle
					? <Tooltip
						enterDelay={1000}
						title={video.title}
					>
						{titleElement}	
					</Tooltip>
					: titleElement
				}

				<Text
					sx={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						fontSize: theme.typography.subtitle2,
						color: theme.palette.grey[500],
						whiteSpace: 'nowrap',
					}}
				>
					{video.author}
				</Text>

				{video.targetLangCode &&
					<Stack
						direction={'row'}
						gap={1}
						sx={{
							height: theme.spacing(4),
							'.lang-flag__code': {
								color: theme.palette.grey[500],
							},
						}}
					>
						<LangFlag code={video.targetLangCode} showCode={true} />
						{video.nativeLangCode &&
							<LangFlag code={video.nativeLangCode} showCode={true} />
						}
					</Stack>
				}
			</Stack>
		</Stack>
		{isPopup &&
			<ProfileVideoPopupHOC
				groupId={groupId}
				video={video}
				onClose={() => setPopup(false)}
			/>
		}
	</>)
};