import { AppThunk } from '../../../../store/types';
import { PhraseContextEditorActions } from '../../../../store/phrase-context-editor/actions';
import { getSelectedTargetCaption } from '../../../../store/current-video/selectors';
import { findVideoPhraseById, getTargetCaptions } from '../../../../store/videos/selectors';
import { LangUtil } from '../../../../../common/utils/lang-util';
import { IPhraseSelectResult, PhraseSelectPreparator } from '../../../../effects/phrase/utils/phrase-select-preparator';
import { PhraseContextEditorSelectors } from '../../../../store/phrase-context-editor/selectors';
import { PhraseEffects } from '../../../../effects/phrase/PhraseEffects';
import { SelectionUtil } from '../../../../../common/utils/selection-util';
import { getOneTimeTooltipsStorage } from '../../../../../common/utils/local-storage/onetime-tooltips';
import { setOneTimeTooltipPhrasesEditAction } from '../../../../store/general/actions';
import { CaptionsSelectionPopupActions } from '../../../../store/captions-selection-popup/actions';
import { PhraseNoteTimeScaleEffects } from '../../../../effects/phrase/phraseNoteTimeScaleEffects';
import { CaptionsSelectionPopupSelectors } from '../../../../store/captions-selection-popup/selectors';
import { getMainViewMode } from '../../../../store/general/selectors';
import { EMainViewMode } from '../../../../store/general/types';

export class PhraseContextEditorEvents {

  public static onClose(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(PhraseContextEditorActions.deActivate());
    }
  }

  public static onSelectText(selection: Selection): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const state = getState();
      const phraseId = PhraseContextEditorSelectors.getPhraseId(state);
      const targetLanguage = getSelectedTargetCaption(state);

      let phrase;
      let wordPhrase;
      if (phraseId) { // saved phrase
        phrase = findVideoPhraseById(state, phraseId);
        wordPhrase = findVideoPhraseById(state, phrase?.wordPhraseId || 0);
      } else { // selected phrase
        wordPhrase = PhraseContextEditorSelectors.getPhraseText(state);
        phrase = PhraseContextEditorSelectors.getPhraseContext(state);
      }
      
      const wordSelectPhrases = wordPhrase ? [wordPhrase] : [];
      const contextPhrases = phrase ? [phrase] : [];

      const useCheckSpanWords = !LangUtil.isHieroglyphLang(targetLanguage?.code || '');
      const selResult = new PhraseSelectPreparator(selection, getTargetCaptions(state), wordSelectPhrases, contextPhrases, null, useCheckSpanWords).execute();
      let previewContextPhrase = null;
      if (wordPhrase && selResult) {
        previewContextPhrase = dispatch(PhraseEffects.savePreviewContextByWordPhrase(selResult, wordPhrase.id)) as any;
      }

      dispatch(PhraseContextEditorActions.setSelectResult(selResult));
      dispatch(PhraseContextEditorActions.setPreviewContextPhrase(previewContextPhrase));
      SelectionUtil.removeWindowSelection();
    }
  }

  public static onDeSelectText(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      dispatch(PhraseContextEditorActions.setSelectResult(null));
      dispatch(PhraseContextEditorActions.setPreviewContextPhrase(null));
    }
  }

  public static onSave(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const state = getState();
      let selectResult: IPhraseSelectResult | null = PhraseContextEditorSelectors.getSelectResult(state);
      if (!selectResult) {
        const context = PhraseContextEditorSelectors.getPhraseContext(state);
        if (context) {
          let captionsSelectResult = CaptionsSelectionPopupSelectors.getSelectResult(state);
          selectResult = {
            ...captionsSelectResult,
            text: context.fullPhrase || '',
            startCaptionIndex: context.startCaptionIndex,
            endCaptionIndex: context.endCaptionIndex,
            startPosition: context.startPosition,
            endPosition: context.endPosition,
            startTime: context.startTime,
            endTime: context.endTime,
          };
        }
      }
      if (selectResult) {        
        getOneTimeTooltipsStorage().setShowPhrasesEdit(false);
        dispatch(setOneTimeTooltipPhrasesEditAction(false));

        if (!PhraseContextEditorSelectors.getIsContext(state)) {
          dispatch(PhraseContextEditorActions.updatePhraseContextEditorAction({
            isContext: true,
          }));
        }

        let phraseId = PhraseContextEditorSelectors.getPhraseId(state);
        if (phraseId) { // saved phrase
          await dispatch(PhraseEffects.modifyContext(selectResult));
          PhraseNoteTimeScaleEffects.load();

          const previewContextPhrase = findVideoPhraseById(state, phraseId);
          const previewWordPhrase = findVideoPhraseById(state, previewContextPhrase?.wordPhraseId || 0);
          dispatch(PhraseEffects.deleteCurrentPreviewPhrase());
          const promptsMode = getMainViewMode(state) === EMainViewMode.PHRASE_DETAILS_TABS_EDITOR;
          PhraseEffects.savePreviewPhraseModifiedContext(selectResult, previewContextPhrase, previewWordPhrase, promptsMode);
          
        } else { // selected phrase
          const previewPhraseId = CaptionsSelectionPopupSelectors.getPreviewPhraseId(state);
          const previewContextPhrase = findVideoPhraseById(state, previewPhraseId);
          const previewWordPhrase = findVideoPhraseById(state, previewContextPhrase?.wordPhraseId);
          dispatch(PhraseEffects.deleteCurrentPreviewPhrase());
          const promptsMode = getMainViewMode(state) === EMainViewMode.PHRASE_DETAILS_TABS_EDITOR;
          const phraseId = PhraseEffects.savePreviewPhraseModifiedContext(selectResult, previewContextPhrase, previewWordPhrase, promptsMode);
          dispatch(CaptionsSelectionPopupActions.setPreviewPhraseId(phraseId as any));
          SelectionUtil.removeWindowSelection();
        }
      }
      dispatch(PhraseContextEditorActions.deActivate());
    }
  }


}