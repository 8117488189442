import { SyncStorage } from './local-storage-helpers';
import { getAuthUser } from '../../../spa/store/general/selectors';
import { getState } from '../../../spa/store';

export type TOneTimeTooltipsStorage = {
  showPhrasesEdit: boolean,
  showNativeLanguage: boolean,
  showNativeAvailable: boolean,
  showTutorialPopup: boolean,
  showTutorial: boolean,
}


export class OneTimeTooltipsStorage extends SyncStorage {

  default: TOneTimeTooltipsStorage;

  constructor(fold: TOneTimeTooltipsStorage) {
    super();
    this.default = fold;
  }

  private getStorageKey() {
    const user = getAuthUser(getState());
    return 'one-time-tooltips' + (user ? '-' + user.hashId : '');
  }

  public getData(): TOneTimeTooltipsStorage {
    return this.getItem(this.getStorageKey(), this.default);
  }

  public saveData(value: TOneTimeTooltipsStorage): TOneTimeTooltipsStorage {
    this.saveItem(this.getStorageKey(), value);
    return value;
  }

  public setShowPhrasesEdit(showPhrasesEdit: boolean) {
    const data: TOneTimeTooltipsStorage = {
      ...this.getData(),
      ...{showPhrasesEdit}
    }
    this.saveData(data);
  }

  public setShowNativeLanguage(showNativeLanguage: boolean) {
    const data: TOneTimeTooltipsStorage = {
      ...this.getData(),
      ...{showNativeLanguage}
    }
    this.saveData(data);
  }

  public setShow(value: Partial<TOneTimeTooltipsStorage>) {
    this.saveData({
      ...this.getData(),
      ...value,
    });
  }

}

const oneTimeTooltipsStorage = new OneTimeTooltipsStorage({
  showPhrasesEdit: true,
  showNativeLanguage: true,
  showNativeAvailable: true,
  showTutorialPopup: true,
  showTutorial: true,
});

export const getOneTimeTooltipsStorage = (): OneTimeTooltipsStorage => {
  return oneTimeTooltipsStorage;
}