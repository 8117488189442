import { EVocabularyPhraseType } from '../../../types/common';
import { getState } from '../../../store';
import { TTargetCaptionsPhraseInfo } from '../../../components/dashboard/CaptionsContainer/TargetCaptions/components/helper';

export enum ECaptionHighlightLayerType {
  NONE,
  BG,
  HIGHTLIGHT,
  WORD_SELECT,
  TRANSLATE_SELECT,
  SELECTED_WORD,
  SELECTED_CONTEXT
}

type TStrPos = {
  s: string;
  pos: number;
}

export type TCaptionHighlightLayer = {
  type: ECaptionHighlightLayerType,
  underscore: boolean,
  wordActive: boolean
}

export class CaptionHighlightLayers {

  public static getLayers(
    startTime: number,
    endTime: number,
    _captionText: string,
    index: number,
    phrasesInfo: TTargetCaptionsPhraseInfo[],
    filterSelectContext: boolean,
    wordStartSelIndex: number,
    wordEndSelIndex: number,
    selectedRow: boolean
  ): TCaptionHighlightLayer[] | null {

    const captionText = _captionText.replace(/[\n\r]/gm, ' ');
    if (!captionText || !captionText.length)
      return null;

    const map: TCaptionHighlightLayer[] = new Array(captionText.length);
    CaptionHighlightLayers.fillMap(map, 0, map.length, ECaptionHighlightLayerType.NONE, false);

    if (wordStartSelIndex >= 0 && wordEndSelIndex >= 0) {
      for (let i=wordStartSelIndex; i<wordEndSelIndex; i++) {
        map[i].wordActive = true;
      }
    }

    if (selectedRow) {
      CaptionHighlightLayers.fillMap(map, 0, map.length, ECaptionHighlightLayerType.SELECTED_WORD, false);
      return map;
    }

    if (!phrasesInfo || !phrasesInfo.length)
      return map;

    const defPhrases = phrasesInfo.filter(pi => pi.phrase.type === EVocabularyPhraseType.DEFAULT);
    const contextPhrases = phrasesInfo.filter(pi => pi.phrase.type === EVocabularyPhraseType.WORD_AND_CONTEXT_SELECTED || pi.phrase.type === EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED);
    const selWordsPhrases = phrasesInfo.filter(pi => pi.phrase.type === EVocabularyPhraseType.WORD_SELECTED);
    //const previewPhrases = phrasesInfo.filter(pi => pi.phrase.type === EVocabularyPhraseType.PREVIEW_WORD_SELECTED);
    const selectedWordPhrases = phrasesInfo.filter(pi => pi.selectedWord);

 //   const selectedContextPhrases = phrasesInfo.filter(pi => pi.selectedContext);
    const selectedContextPhrases = phrasesInfo.filter(pi =>
      pi.selectedContext &&
      pi.phrase?.type === EVocabularyPhraseType.PREVIEW_WORD_AND_CONTEXT_SELECTED &&
      pi.phrase?.selected
    );
    const translatePhrases = phrasesInfo.filter(pi => pi.phrase.translate);

    defPhrases.forEach(p => {
      CaptionHighlightLayers.fillMap(map, 0, map.length, ECaptionHighlightLayerType.BG, false);
    })
    defPhrases.forEach(pi => {
      const p = pi.phrase;
      const underscore = !!pi.note;
      const highlighted = p.highlighted.replace(/[\n\r]/gm, ' ');

      if (p.startTime === startTime && p.endTime === endTime) { // 1 line
        const ps = captionText.indexOf(highlighted);
        if (ps >= 0) {
          CaptionHighlightLayers.fillMap(map, ps, ps + highlighted.length, ECaptionHighlightLayerType.HIGHTLIGHT, underscore);
        }
      } else if (p.startTime < startTime && p.endTime > endTime) { // middle line
        CaptionHighlightLayers.fillMap(map, 0, map.length, ECaptionHighlightLayerType.HIGHTLIGHT, underscore);
      } else if (p.startTime >= startTime) { // first line
        const {pos} = CaptionHighlightLayers.findStrPosition(captionText, highlighted);
        if (pos >= 0) {
          CaptionHighlightLayers.fillMap(map, pos, map.length, ECaptionHighlightLayerType.HIGHTLIGHT, underscore);
        }
      } else if (p.endTime === endTime) { // last line
        const {pos, s} = CaptionHighlightLayers.findStrPosition(captionText, highlighted, true);
        if (pos >= 0) {
          CaptionHighlightLayers.fillMap(map, 0, pos + s.length, ECaptionHighlightLayerType.HIGHTLIGHT, underscore);
        }
      }
    })

    const _contextPhrases = filterSelectContext ?
      contextPhrases.filter(p => p.selectContext && p.phrase.contextModified) :
      contextPhrases;
    CaptionHighlightLayers.fillRowByType(_contextPhrases, map, ECaptionHighlightLayerType.HIGHTLIGHT, index);
    CaptionHighlightLayers.fillRowByType(selWordsPhrases, map, ECaptionHighlightLayerType.WORD_SELECT, index);
    CaptionHighlightLayers.fillRowByType(selectedContextPhrases, map, ECaptionHighlightLayerType.SELECTED_CONTEXT, index);
    CaptionHighlightLayers.fillRowByType(selectedWordPhrases, map, ECaptionHighlightLayerType.SELECTED_WORD, index);
    CaptionHighlightLayers.fillRowByType(translatePhrases, map, ECaptionHighlightLayerType.TRANSLATE_SELECT, index);

    return map;
  }

  private static fillRowByType(phrasesInfo: TTargetCaptionsPhraseInfo[], map: TCaptionHighlightLayer[], type: ECaptionHighlightLayerType, index: number) {
    const state = getState();
    phrasesInfo.forEach(pi => {
  //    const note = getVideoPhraseNoteByPhraseId(state, p.id);
      const p = pi.phrase;
      const underscore = !!pi.note;
      if (p.startCaptionIndex === index && p.endCaptionIndex === index) {
        CaptionHighlightLayers.fillMap(map, p.startPosition, p.endPosition, type, underscore);
      } else if (p.startCaptionIndex === index) {
        CaptionHighlightLayers.fillMap(map, p.startPosition, map.length, type, underscore);
      } else if (p.endCaptionIndex === index) {
        CaptionHighlightLayers.fillMap(map, 0, p.endPosition, type, underscore);
      } else if (p.startCaptionIndex < index && p.endCaptionIndex > index) {
        CaptionHighlightLayers.fillMap(map, 0, map.length, type, underscore);
      }
    })
  }

  private static fillMap(map: TCaptionHighlightLayer[], fromIndex: number, toIndex: number, type: ECaptionHighlightLayerType, underscore: boolean) {
    if (fromIndex < 0 || fromIndex >= map.length) return;
    if (toIndex > map.length)
      toIndex = map.length;
    for(let i=fromIndex; i<toIndex; i++) {
      if (!map[i]) {
        map[i] = {type, underscore, wordActive: false};
      } else {
        map[i].type = type;
        map[i].underscore = underscore;
      }
    }
  }

  private static findStrPosition(str: string, findStr: string, fromEnd: boolean = false): TStrPos {
    const words = findStr.split(' ');
    const maxAttempts = 3;
    for(let i=maxAttempts; i>=1; i--) {
      if (words.length >= i) {
        const s = fromEnd ?
          words.slice(words.length - i, words.length).join(' ') :
          words.slice(0, i).join(' ');
        const pos = str.indexOf(s);
        if (pos >= 0) {
          return {pos, s};
        }
      }
    }
    return {pos: -1, s: ''};
  }
}