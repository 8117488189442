export class GptUtils {

  public static formatResult(text: string): string {
    const result = text.replaceAll(/\n/gm, '<br>');
    return result.replaceAll(/\*\*([^\*]+)\*\*/gm, '<b>$1</b>');
  }

  public static clear(text: string) {
    return text.replaceAll(/\*\*([^\*]+)\*\*/gm, '$1');
  }
}