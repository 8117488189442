import { IState } from '../../../../../store/types';
import { IOneTimeTooltipSimpleEvents, IOneTimeTooltipSimpleFields } from '../../components/OneTimeTooltipSimple/types';
import { EOneTimeTooltipType, OneTimeTooltipTextProvider } from '../oneTimeTooltipTextProvider';
import { connect } from 'react-redux';
import { OneTimeTooltipSimple } from '../../components/OneTimeTooltipSimple/OneTimeTooltipSimple';
import { OneTimeTooltipNativeLanguageEvents } from './events';
import { isShowNativeLanguageTooltip } from '../../../../../store/general/selectors';
import { getNativeLanguage, getTargetLanguage } from '../../../../../store/models/selectors';
import { OneTimeTooltipNativeLanguageButtonConnected } from '../../components/OneTimeTooltipNativeLanguageButton/OneTimeTooltipNativeLanguageButtonConnected';


const mapStateToProps = (
	dispatch: any
) => {
	return (
		state: IState
	): IOneTimeTooltipSimpleFields => {
		const show = !0||isShowNativeLanguageTooltip(state);
		let text = OneTimeTooltipTextProvider.getText(EOneTimeTooltipType.NATIVE_LANGUAGE);
		const nativeLanguage = getNativeLanguage(state)?.name || '';
		const targetLanguage = getTargetLanguage(state)?.name || '';
		text = text.replace('$NativeLanguage', nativeLanguage);
		text = text.replace('$TargetLanguage', targetLanguage);
		return {
			buttonCommand: OneTimeTooltipNativeLanguageButtonConnected(),
			show,
			text,
		}
	};
}

const mapDispatchToProps = (
	dispatch: any
): IOneTimeTooltipSimpleEvents => ({
	onClickHelp: () => dispatch(OneTimeTooltipNativeLanguageEvents.onClickHelp()),
	onClose: () => dispatch(OneTimeTooltipNativeLanguageEvents.onClose()),
});

export const OneTimeTooltipNativeLanguageHOC = connect(
	mapStateToProps,
	mapDispatchToProps
)(OneTimeTooltipSimple);

OneTimeTooltipNativeLanguageHOC.displayName = 'OneTimeTooltipNativeLanguageHOC';