import { VideoTutorialsRest } from '../../common/rest/videoTutorials/videoTutorialsRest';
import { getDispatch } from '../store';
import { setVideoTutorialListAction } from '../store/models/actions';

export class VideoTutorialEffects {

  public static async loadList() {
    const dispatch = getDispatch();
    const list = await VideoTutorialsRest.list();
    dispatch(setVideoTutorialListAction(list));
  }
}