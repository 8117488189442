import * as React from 'react';
import { Checkbox as CheckComponent, CheckboxProps, styled } from '@mui/material';
import { theme } from '../../styles/theme';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  backgroundColor: '#fff',
  outline: `2px solid ${theme.palette.grey[200]}`,
  '.Mui-focusVisible &': {
    outline: `2px auto ${theme.palette.primary.main}`,
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#fafafa',
  },
  'input:disabled ~ &': {
    background: 'rgba(206,217,224,.5)',
  },
  'input:checked ~ &': {
    outline: `2px solid ${theme.palette.primary.main}`,
  },
}));

const CheckIconChecked = styled(CheckIcon)({
  backgroundColor: theme.palette.primary.main,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fillRule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.primary.main,
  },
});

export const Check = ({ ...props }: CheckboxProps) => {
  return (
    <CheckComponent
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color='default'
      checkedIcon={<CheckIconChecked />}
      icon={<CheckIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
};
