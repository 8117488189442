import { TContentLibLangsResult } from '../../../common/rest/contentLib/contentLibRest';

export const CONTENT_LIB_SET_GROUPS_SEARCH_RESULT_ACTION = 'CONTENT_LIB/CONTENT_LIB_SET_SEARCH_RESULT_ACTION';
export const CONTENT_LIB_SET_SEARCH_FILTER_ACTION = 'CONTENT_LIB/CONTENT_LIB_SET_SEARCH_FILTER_ACTION';
export const CONTENT_LIB_SET_VIEW_MODE_ACTION = 'CONTENT_LIB/CONTENT_LIB_SET_VIEW_MODE_ACTION';
export const CONTENT_LIB_SET_VIDEO_POPUP_ACTION = 'CONTENT_LIB/CONTENT_LIB_SET_VIDEO_POPUP_ACTION';
export const CONTENT_LIB_SET_SELECTED_GROUP_ACTION = 'CONTENT_LIB/CONTENT_LIB_SET_SELECTED_GROUP_ACTION';
export const CONTENT_LIB_SET_VIDEO_COPY_CONFIRM_ACTION = 'CONTENT_LIB/CONTENT_LIB_SET_VIDEO_COPY_CONFIRM_ACTION';
export const CONTENT_LIB_SET_GROUP_COPY_CONFIRM_ACTION = 'CONTENT_LIB/CONTENT_LIB_SET_GROUP_COPY_CONFIRM_ACTION';
export const CONTENT_LIB_SET_LANGS_ACTION = 'CONTENT_LIB/CONTENT_LIB_SET_LANGS_ACTION';

export enum EContentLibVideoLevel {
  A1 = 1, A2 = 2, B1 = 3, B2 = 4, C1 = 5, C2 = 6
}

export interface IContentLibVideo {
  id: number,
  url: string,
  langId: number,
  title: string,
  description: string,
  level: EContentLibVideoLevel,
  youtubeChannel: string,
  youtubeTitle: string,
  youtubeThumbUrl: string,
  youtubeThumbMediumUrl: string,
  youtubeThumbStandardUrl: string
}

export interface IContentLibGroup {
  id: number,
  title: string,
  description: string,
  targetLangId: number,
  nativeLangId: number,
  videos: IContentLibVideo[],
  videoCount: number,
  tags: string[]
}

export interface IContentLibSearchFilter {
  targetLangCode: string,
  nativeLangCode: string,
  text: string
}

export interface IContentLibGroupsSearchResult {
  groups: IContentLibGroup[] | null
  loading: boolean
}

export enum EContentLibViewMode {
  VIDEO_LIST, GROUP_LIST, GROUP_VIDEO_LIST
}

export interface IContentLibVideoPopup {
  groupId: number
  videoId: number,
  video?: IContentLibVideo
}

export interface IContentLibCopyVideoConfirm {
  show: boolean,
  videoId?: string,
  groupId?: number
}

export interface IContentLibCopyGroupConfirm {
  show: boolean,
  groupId?: number
}

export type TContentLibLangs = TContentLibLangsResult;

export interface IContentLibState {
  langs: TContentLibLangs,
  searchFilter: IContentLibSearchFilter,
  groupsSearchResult: IContentLibGroupsSearchResult,
  videoPopup: IContentLibVideoPopup,
  viewMode: EContentLibViewMode,
  selectedGroupId: number,
  copyVideoConfirm: IContentLibCopyVideoConfirm,
  copyGroupConfirm: IContentLibCopyGroupConfirm,
}

export interface IContentLibSetGroupsSearchResultAction {
  type: typeof CONTENT_LIB_SET_GROUPS_SEARCH_RESULT_ACTION;
  groupsSearchResult: Partial<IContentLibGroupsSearchResult>
}

export interface IContentLibSetSearchFilterAction {
  type: typeof CONTENT_LIB_SET_SEARCH_FILTER_ACTION;
  searchFilter: Partial<IContentLibSearchFilter>
}

export interface IContentLibSetViewModeAction {
  type: typeof CONTENT_LIB_SET_VIEW_MODE_ACTION;
  viewMode: EContentLibViewMode
}

export interface IContentLibSetVideoPopupAction {
  type: typeof CONTENT_LIB_SET_VIDEO_POPUP_ACTION;
  videoPopup: Partial<IContentLibVideoPopup>
}

export interface IContentLibSetVideoCopyConfirmAction {
  type: typeof CONTENT_LIB_SET_VIDEO_COPY_CONFIRM_ACTION;
  copyVideoConfirm: Partial<IContentLibCopyVideoConfirm>
}

export interface IContentLibSetGroupCopyConfirmAction {
  type: typeof CONTENT_LIB_SET_GROUP_COPY_CONFIRM_ACTION;
  copyGroupConfirm: Partial<IContentLibCopyGroupConfirm>
}

export interface IContentLibSetSelectedGroupIdAction {
  type: typeof CONTENT_LIB_SET_SELECTED_GROUP_ACTION;
  selectedGroupId: number
}

export interface IContentLibSetLangsAction {
  type: typeof CONTENT_LIB_SET_LANGS_ACTION;
  langs: TContentLibLangs
}


export type TContentLibActions =
  | IContentLibSetGroupsSearchResultAction
  | IContentLibSetSearchFilterAction
  | IContentLibSetViewModeAction
  | IContentLibSetVideoPopupAction
  | IContentLibSetVideoCopyConfirmAction
  | IContentLibSetGroupCopyConfirmAction
  | IContentLibSetSelectedGroupIdAction
  | IContentLibSetLangsAction