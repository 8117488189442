import React from 'react';
import {Modal} from '../../../../../common/components/Modal/Modal';
import {List, ListItem} from '@mui/material';
import {INewFeaturesPopupProps} from './types';
import {theme} from "../../../../../common/styles/theme";

export const NewFeaturesPopup: React.FC<INewFeaturesPopupProps> = ({
  isShow,
  onLoad,
  onClose
}) => {

  if (!isShow) return null;

  const [features, setFeatures] = React.useState<string[]>([]);

  React.useEffect(() => {
    onLoad().then((_features: string[]) => {
      setFeatures(_features);
    });
  }, []);

  return (
    <Modal
      onClose={onClose}
      open={!!(features && features.length)}
      title={'New features'}
    >
      <List
        sx={{
          width: theme.spacing(45.75),
          listStyleType: 'disc',
          p: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2.75)}`,
          m: `0 !important`
        }}
      >
        {
            features && features.map(feature => {
              return (
                  <ListItem
                      sx={{
                        display: 'list-item',
                        pl: theme.spacing(0.75),
                        textAlign: 'justify',
                        fontSize: theme.spacing(1.75)
                      }}
                  >
                    {feature}
                  </ListItem>
              )
            })
        }
      </List>
    </Modal>
  );
}
