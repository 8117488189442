import React from 'react';
import { IconButton, Stack } from '@mui/material';
import { theme } from '../../../../../../../common/styles/theme';
import { IPhraseDetailsTabTranslationLangProps } from './type';
import { Text } from '../../../../../../../common/components/Text/Text';
import { TrashIcon } from '../../../../../../../../icons/TrashIcon';
import { ConfirmPopup } from '../../../../ConfirmPopup/ConfirmPopup';
import { DragIcon } from '../../../../../../../../icons/DragIcon';
import { Draggable } from 'react-beautiful-dnd';

export const PhraseDetailsTabTranslationLang: React.FC<IPhraseDetailsTabTranslationLangProps> = ({
  index,
  langCode,
  langName,
  color,
  onDelete
}) => {

  const [editMenuAnchor, setEditMenuAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [showDeletePrompt, setShowDeletePrompt] = React.useState<boolean>(false);

  const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
    setEditMenuAnchor(e.currentTarget);
    setShowDeletePrompt(true);
  };

  const onConfirmDelete = () => {
    setShowDeletePrompt(false);
    onDelete();
  }

  const bodyStyle = {
      position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
      height: theme.spacing(5),
      px: theme.spacing(1.5),
      background: theme.palette.common.white,
      borderRadius: theme.spacing(1),
      border: `${theme.spacing(0.25)} solid ${theme.palette.grey[200]}`,
      '&:hover,&:focus,&:active': {
          borderColor: theme.palette.grey[300]
      },

      '&:hover .drag-icon': {
        opacity: 1,
      },
  }

  const textStyle = {
   flexGrow: 1,
    p: theme.spacing(0.5, 1, 0.5, 0),
    lineHeight: 1.2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    color
  };

  const dragStyle = {
    position: 'absolute',
    left: theme.spacing(-2.5),
    top: '50%',
    transform: `translateY(-50%)`,
    color: theme.palette.grey[400],
    opacity: 0,
    pointerEvents: 'none',
  };

  return (
      <Draggable
        key={langCode}
        draggableId={langCode}
        index={index}
      >
        {(provided) => (
          <Stack
            direction={'row'}
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            sx={bodyStyle}            
          >
            <Text
              fontWeight={600}
              noWrap
              variant={'subtitle2'}
              sx={textStyle}
            >
              {langName}
            </Text>

            <Stack
              direction={'row'}
              alignItems={'center'}
              gap={theme.spacing(0.5)}
            >

              <Stack
                direction='row'
                spacing={theme.spacing()}
                alignItems={'center'}
                sx={{
                  pr: theme.spacing(0)
                }}
              >

                <>
                  <IconButton
                    onClick={handleDelete}
                    color={'primary'}
                    sx={{
                      px: 0,
                      color: theme.palette.grey[400],
                      '.MuiSvgIcon-root': { width: theme.spacing(2), height: theme.spacing(2) },
                      '&:hover, &:focus': {
                        color: theme.palette.error.main,
                      }
                    }}
                  >
                    <TrashIcon />
                  </IconButton>

                  {showDeletePrompt && <ConfirmPopup
                    anchor={editMenuAnchor}
                    text={'Delete translation?'}
                    onConfirm={onConfirmDelete}
                    onClose={() => setShowDeletePrompt(false)}
                  />}
                </>


              </Stack>
            </Stack>
            <DragIcon
              className={'drag-icon'}
              sx={dragStyle}
            />
          </Stack>
        )}
      </Draggable>
  );

}
