import { API_URL } from '../../constants';
import { TAsyncThunk } from '../../../spa/store/types';
import { TUserGroup } from '../../../spa/store/models/types';
import { getRequest } from '../../utils/requestService';

const USER_GROUP_URL = API_URL + '/groups/group/';

export const fetchUserGroup =  (groupId: number): TAsyncThunk => (
  dispatch
): Promise<TUserGroup> => {
  return dispatch(getRequest(USER_GROUP_URL + groupId));
};