import React from 'react';
import { Box, Stack } from '@mui/material';
import { IPinExtensionPopupProps } from './types';
import { Text } from '../../../../../../common/components/Text/Text';
import { theme } from "../../../../../../common/styles/theme";
//@ts-ignore
import img from '../../../../../../../img/pin-langapp.gif';
import { Snackbar } from "../../../../../../common/components/Snackbar/Snackbar";
import { PinExtensionPopupStep } from "../PinExtentionPopupStep/PinExtensionPopupStep";
import { SquareAppIcon } from "../../../../../../../icons/SquareAppIcon";
import { SquarePinIcon } from "../../../../../../../icons/SquarePinIcon";

export const PinExtensionPopup: React.FC<IPinExtensionPopupProps> = ({
                                                               show,
                                                               onClose
}) => {
  const snackbarStyle = {
    top: `${theme.spacing(0.5)} !important`,
    right: `${theme.spacing(0.5)} !important`,
    m: `0 !important`
  };

  const imageStyle = {
    width: theme.spacing(42.5),
    height: theme.spacing(29.5),
    borderRadius: theme.spacing(0.75),
    background: `url(${img})`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(35),
      height: theme.spacing(24.25),
    }
  };

  const iconStyle = {
    width: theme.spacing(4),
    height: theme.spacing(4)
  };

  if (!show) return null;

  return (
    <Snackbar
      onClose={onClose}
      open={true}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      sx={snackbarStyle}
    >
      <Stack spacing={theme.spacing(2)} direction={'column'}>
        <Text
            fontWeight={600}
            variant={'subtitle2'}
            sx={{ color: theme.palette.common.white }}
        >
          How to Pin the Lang app extension
        </Text>
        <Stack spacing={theme.spacing(1.5)}>
          <PinExtensionPopupStep
              text={'1. Click on the extensions icon'}
              icon={<SquareAppIcon sx={iconStyle}/>}
          />
          <PinExtensionPopupStep
              text={'2. Click the pin icon'}
              icon={<SquarePinIcon sx={iconStyle}/>}
          />
          <Box sx={imageStyle}/>
        </Stack>
      </Stack>
    </Snackbar>
  );
}
