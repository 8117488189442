import React from "react";
import img01 from './imgs/img01.jpg';
import img02 from './imgs/img02.jpg';
import img03 from './imgs/img03.jpg';
import img04 from './imgs/img04.jpg';

export const LANDING_TEXT1 = 'Break the Intermediate Barrier';
export const LANDING_TEXT2 = 'Start Understanding French Native Speakers with LangMagic';
export const LANDING_TEXT3 = 'A set of powerful tools that enhances your integration with native materials to turn them into interactive lessons.';

export const LOGIN_FEATURES = [
    {
        title: `Use the power of ChatGPT for pattern learning`,
        imgUrl: img01
    },
    {
        title: 'Translate to multiple languages simultaneously',
        imgUrl: img02
    },
    {
        title: 'Save and repeat phrases with context',
        imgUrl: img03
    },
    {
        title: 'Pre-made playlists at your hand',
        imgUrl: img04,
    },
]
