import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { theme } from '../../../../../common/styles/theme';
import { Box, Stack } from '@mui/material';
import { CLASS_ONBOARDING_BLUR } from '../../Onboarding/constants';
import { Paper } from '../../../../../common/components/Paper/Paper';
import { IAudioProps } from './types';
import { TargetAudioCaptionsHOC } from '../../AudioCaptions/TargetCaptions/hocs/TargetAudioCaptionsHOC';
import { AudioCaptionsContainerHOC } from '../../AudioCaptions/CaptionsContainer/hocs/AudioCaptionsContainerHOC';
import { ResizeIcon } from '../../../../../../icons/ResizeIcon';
import { AudioPlayerManager, IAudioPlayerManagerListener } from '../../../../effects/audioPlayer/audioPlayerManager';

export const Audio: React.FC<IAudioProps> = ({
                                               activeGroupId,
                                               layoutType,
  audioUrl,
  onLoad,
  onUpdateAudioPlayer
}) => {
  let { audioId, groupId } = useParams();

  const isResized = React.useRef(false);

  const history = useHistory();

  const [windowWidth, setWindowWidth] = React.useState<number | null>(null);
  const [isMouseDown, setMouseDown] = React.useState(false);

  const resizeRef = React.useRef<HTMLElement | null>(null);
  const videoRef = React.useRef<HTMLElement | null>(null);
  const paddingTop = 24;
  const captionMinHeight = theme.spacing(40);
  const minHeight = theme.spacing(20);

  React.useEffect(() => {
    onUpdateAudioPlayer(audioElemRef.current);
  }, [audioUrl]);


  React.useEffect(() => {
    let pos: number;

    const onMouseUp = () => {
      document.body.style.cursor = 'unset';
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onResize);
      isResized.current = true;
      setMouseDown(false);
    };

    const onResize = (e: MouseEvent) => {
      const video = videoRef.current as HTMLElement;
      if (video.firstChild && video.firstChild === e.target) onMouseUp();
      if (pos && video) {
        const newHeight = e.y - paddingTop;
        pos = e.y;
        video.style.height = newHeight + 'px';
      }
    };

    const onMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      setMouseDown(true);
      document.body.style.cursor = 'ns-resize';
      pos = e.y;
      document.addEventListener('mousemove', onResize);
      document.addEventListener('mouseup', onMouseUp);
    };

    const resize = resizeRef.current as HTMLElement;
    if (resize) {
      resize.addEventListener('mousedown', onMouseDown);
    }

    return () => {
      if (resize) {
        resize.removeEventListener('mousedown', onMouseDown);
        document.removeEventListener('mouseup', onMouseUp);
      }
    }
  }, []);



/*  React.useEffect(() => {
    if (!playerInited) return;
    const onKeyUpHandler = (event: KeyboardEvent) => {
      onKeyUp(event);
    };
    const onKeyDownHandler = (event: KeyboardEvent) => {
      onKeyDown(event);
    };
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (activeGroupId) {
        onUnload(activeGroupId);
      }
    }

    document.addEventListener('keyup', onKeyUpHandler);
    document.addEventListener('keydown', onKeyDownHandler);
    window.addEventListener('beforeunload', onBeforeUnload);
    const timer = setInterval(checkPlayerFocus, 1000);
    return () => {
      document.removeEventListener('keyup', onKeyUpHandler);
      document.removeEventListener('keydown', onKeyDownHandler);
      window.removeEventListener('beforeunload', onBeforeUnload);
      clearInterval(timer);
    };
  }, [playerInited]);*/

  /*React.useEffect(() => {
    if (!activeGroupId && activeGroupId == groupId) {
      history.push('/');
    }
  }, [activeGroupId]);

  React.useEffect(() => {
    onUpdateGroupId(parseInt(groupId));
  }, [groupId]);

  React.useEffect(() => {
    onUpdateAudioId(parseInt(audioId));
  }, [audioId]);*/

  React.useEffect(() => {
    const gId = parseInt(groupId);
    const aId = parseInt(audioId);
    onLoad(aId, gId);
  }, [groupId, audioId])



  /*React.useEffect(() =>  {
    if (playerInited) {
      onLoad(videoId, parseInt(groupId) | 0, parseInt(phraseId) | 0, pause === '1');
    }
  }, [videoId, groupId, phraseId, playerInited]);*/

 /* React.useEffect(() => {
    return () => {
      if (activeGroupId) {
        onUnload(activeGroupId);
      }
    }
  }, [videoId, activeGroupId]);*/

  React.useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const initVideoHeight = () => {
    const video = videoRef.current as HTMLElement;
    if (video) {
      const newHeight = video.offsetWidth / 16 * 9;
      video.style.height = newHeight + 'px';
    }
  }

 /* React.useEffect(() => {
    if (!isResized.current) initVideoHeight();
  }, [playerInited, videoId, windowWidth]);*/

  React.useEffect(() => {
    initVideoHeight();
  }, [layoutType]);

  const audioElemRef = React.useRef<HTMLAudioElement>(null);

  /*React.useEffect(() => {
    const timer = () => {
      const tm = audioElemRef.current.currentTime;
    }

    const tm = setInterval(timer, 100);
    return () => {
      clearInterval(tm);
    }
  }, []);*/

  const resizeContainerStyle = {
    minHeight: theme.spacing(1),
    py: theme.spacing(1),
    width: 1,
    cursor: 'ns-resize',
    '&:hover': {
      '.MuiBox-root': {
        background: theme.palette.grey[400],
        opacity: 1
      }
    },
    '&:active': {
      '.MuiBox-root': {
        opacity: 1
      },
    }
  };

  const resizeStyle = {
    position: 'relative',
    height: theme.spacing(0.25),
    width: 1,
    borderRadius: theme.spacing(0.25),
    background: isMouseDown ? theme.palette.grey[400] : theme.palette.grey[200],
  };

  const handleStyle = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    translate: '-50% -50%',
    width: theme.spacing(2),
    height: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: `${theme.palette.secondary.main} !important`,
    border: `2px solid ${theme.palette.grey[400]}`,
    borderRadius: '50%',
    opacity: 0
  };

  const iconStyle = {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    color: theme.palette.grey[400]
  }

  return (
    <Stack
      className={`video-column
        ${CLASS_ONBOARDING_BLUR}
      `}
      sx={{
        flex: `1 1 100%`,
        minWidth: theme.spacing(70),

        '.columns-4 &': {
          flexBasis: '25%',
          minWidth: 0,
        },
        '.captions-2-columns.columns-4 &': {
          flexBasis: '50%',
        },
      }}
    >
      <Paper
        sx={{
          background: 'none',
        }}
      >
        <Box
          ref={videoRef}
          sx={{
            flex: `1 1 360px`,
            minHeight,
            maxHeight: `calc(100vh - ${captionMinHeight})`,
            pointerEvents: isMouseDown ? 'none' : 'all',
          }}
        >
          <Box
            sx={{
              width: 1,
              height: 1,
              borderRadius: theme.spacing(.75),
            }}
          >
            {audioUrl &&
              <audio ref={audioElemRef} src={audioUrl} controls>
              </audio>
            }
          </Box>

        </Box>
      </Paper>
      {

        <>
          <Box ref={resizeRef} sx={resizeContainerStyle}>
            <Box sx={resizeStyle}>
              <Box sx={handleStyle}>
                <ResizeIcon sx={iconStyle} />
              </Box>
            </Box>
          </Box>

          <AudioCaptionsContainerHOC />

        </>
      }
    </Stack>
  );
};