import React from 'react';

export const MOBILE_BREAKPOINT = 1200; // px

export const DeviceContext = React.createContext<IDeviceContext>(null);

export interface IDeviceContext {
    isMobile?: boolean;
    isTouch?: boolean;
    scrollWidth?: number;
};
