import { AppThunk } from '../../../../../store/types';
import { VideoCopyRest } from '../../../../../../common/rest/videoCopy/videoCopyRest';
import { isAuthorised } from '../../../../../store/general/selectors';
import { setCopyVideoOnLoginAction } from '../../../../../store/general/actions';
import { Navigator } from '../../../../../effects/navigator';
import { CopyVideoEffects } from '../../../../../effects/copyVideoEffects';
import { ProfileStorage } from '../../../../../../common/utils/local-storage/local-storage-profile';
import { PublicProfileSelectors } from '../../../../../store/public-profile/selector';

export class ProfileVideoPopupEvents {

  public static onSave(
    videoId: string,
    groupId: number,
    onClose: () => void,
  ): AppThunk {
    return async (
      dispatch,
      getState
    ) => {
      onClose();
      const { profile } = PublicProfileSelectors.getData(getState());
      ProfileStorage.setOpenTeacherAnnotations(profile?.userId || 0);

      if (!isAuthorised(getState())) {
        dispatch(setCopyVideoOnLoginAction({groupId, videoId}));
        Navigator.openDefault();
      } else {
        const {hash} = await VideoCopyRest.createCopyLink({
          videoId, groupId
        })
        await CopyVideoEffects.startCopy(hash, 0);
      }
    }
  }
}