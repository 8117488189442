import { IEvent, IEventData, IEventsProvider } from '../types';
import { AMPLITUDE_API_KEY } from '../../constants';
import { TAuthUser } from '../../../spa/store/general/types';
import * as amplitude from '@amplitude/analytics-browser';

export class AmplitudeEventsProvider implements IEventsProvider {

  constructor() {
    const ampOptions: any = {
      includeReferrer: true,
      includeGclid: true,
      includeUtm: true,
    };

    let deviceId = new URL(location.href).searchParams.get('amp_device_id')
    if (deviceId) {
        ampOptions.deviceIdFromUrlParam = true;
        ampOptions.deviceId = deviceId;
    }

    amplitude.init(AMPLITUDE_API_KEY, undefined, ampOptions);
  }

  setUser(user: TAuthUser): void {
    const identify = new amplitude.Identify();
    identify.set('user_id', user.hashId);
    identify.set('email', user.email);
    identify.set('name', user.name);
    identify.set('googleId', user.googleId);

    amplitude.identify(identify, {
      user_id: user.hashId,
      email: user.email,
      name: user.name,
      googleId: user.googleId
    });
  }

  trackEvent(event: IEvent, date: Date, data: IEventData): void {
   const eventName = event.subject + ': ' + event.action
   const { userId, ...options } = data;
    amplitude.track(eventName,
      {
        time: date.getTime(),
        ...options,
      }, {
      user_id: userId
    });
  }

}