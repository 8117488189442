import { PlayerManager } from '../playerManager';
import { ICaptionsItem, PlayerStates } from '../../../../types/common';
import { EPlayerControllerMode, PlayerController } from '../playerController';
import { getState } from '../../../../store';
import { getPlayerState } from '../../../../store/general/selectors';

export class PlayCaptionCommandHandler {

  public async playNext() {
      const playerManager = PlayerManager.getInstance();
      const captions: ICaptionsItem[] = playerManager.getTargetCaptions();
      let currentIndex = await playerManager.getActiveTargetCaptionIndex();
      currentIndex++;
      if (currentIndex >= captions.length) return;
      this.playCaption(currentIndex, captions, EPlayerControllerMode.PLAY_CAPTION);
  }

  public async playPrev() {
      const playerManager = PlayerManager.getInstance();
      const captions: ICaptionsItem[] = playerManager.getTargetCaptions();
      const currentIndex = await playerManager.getActiveTargetCaptionIndex();

      if (currentIndex < 0 || currentIndex >= captions.length) return;
      const index = currentIndex > 0 ? currentIndex - 1 : 0;

      this.playCaption(index, captions, EPlayerControllerMode.PLAY_CAPTION);
  }

  public async repeat() {
      const playerManager = PlayerManager.getInstance();
      const captions: ICaptionsItem[] = playerManager.getTargetCaptions();
      let currentIndex = await playerManager.getActiveTargetCaptionIndex();
      if (currentIndex < 0 || currentIndex >= captions.length) return;
      const playerState = getPlayerState(getState());
      if (playerState === PlayerStates.PAUSED && currentIndex > 0) {
        currentIndex--;
      }
      this.playCaption(currentIndex, captions, EPlayerControllerMode.PLAY_CAPTION);
  }

  public async resumePlay() {
    const playerManager = PlayerManager.getInstance();
    const captions: ICaptionsItem[] = playerManager.getTargetCaptions();
    const currentIndex = await playerManager.getActiveTargetCaptionIndex();
    if (currentIndex < 0 || currentIndex >= captions.length) return;
    await playerManager.startPlay();
    playerManager.setTimer(captions[currentIndex].endTime);
  }

  private playCaption(index: number, captions: ICaptionsItem[], mode: EPlayerControllerMode) {
    if (index >= 0 && index < captions.length) {
      const playerManager = PlayerManager.getInstance();
      playerManager.pausePlay();
      const caption = captions[index];
      const playerController = PlayerController.getInstance();
      if (mode === EPlayerControllerMode.PLAY_CAPTION) {
        playerController.playCaption(caption);
      } else {
        playerController.play(caption.startTime, index);
      }
    }
  }
}