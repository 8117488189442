import { getDispatch, getState } from '../store';
import {
  userTranslateLangAddAction,
  userTranslateLangDeleteAction,
  userTranslateLangsUpdateAction
} from '../store/general/actions';
import { UserTranslateLangRest } from '../../common/rest/userTranslateLang/userTranslateLangRest';
import { getAuthUserTranslateLangsMaxOrder } from '../store/general/selectors';
import { TAuthUserTranslateLang } from '../store/general/types';

export class UserTranslateLangEffects {

  public static async addLang(langCode: string) {
    const dispatch = getDispatch();
    const state = getState();
    const orderNum = getAuthUserTranslateLangsMaxOrder(state);
    await UserTranslateLangRest.save({
      langCode,
      orderNum
    });
    dispatch(userTranslateLangAddAction(langCode, orderNum));
  }

  public static async deleteLang(langCode: string) {
    const dispatch = getDispatch();
    await UserTranslateLangRest.remove(langCode);
    dispatch(userTranslateLangDeleteAction(langCode));
  }

  public static async sortLangs(langCodes: string[]) {
    const dispatch = getDispatch();
    const sortedLangs: TAuthUserTranslateLang[] = langCodes.map((code, orderNum) => {
      return {code, orderNum};
    })
    dispatch(userTranslateLangsUpdateAction(sortedLangs));
    const result = await UserTranslateLangRest.updateList(sortedLangs);
    dispatch(userTranslateLangsUpdateAction(result));
  }
}
