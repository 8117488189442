import { IPlayerApiProps } from './player-api-context';
import { appStore } from '../../store';
import { setPlayerRate, setPlayerState } from '../../store/general/actions';
import { PlayerFactory } from './player-factory';
import { PlayerStates } from '../../types/common';

const onStateChange = (event: any) => {
  YtUtils.stateChangeListeners.forEach(l => l.onStateChange(event.data));
  appStore.dispatch(setPlayerState(event.data));
};

const onPlaybackRateChange = (event: any) => {
  appStore.dispatch(setPlayerRate(event.data));
};

export interface IPlayerApiPropsImpl extends IPlayerApiProps {
  lastPlayTime: number,
}

export interface IPlayerStateChangeListener {
  onStateChange: (state: PlayerStates) => void
}

export class YtUtils {

  public static stateChangeListeners: IPlayerStateChangeListener[] = [];
  private static videoIdSeparator = '|';

  public static async configure(videoId: string): Promise<IPlayerApiProps> {
    return await PlayerFactory.create(YtUtils.checkVideoId(videoId), onStateChange, onPlaybackRateChange);
  }

  public static loadVideoById(videoId: string, playerApi: IPlayerApiProps, position: number): Promise<void> {
    const player = playerApi.player;
    player.loadVideoById(YtUtils.checkVideoId(videoId), position);
    return new Promise((resolve) => {
      const interval = setInterval(async () => {
        const playerState = await player.getPlayerState();
        if (playerState === PlayerStates.PLAYING || playerState === PlayerStates.UNSTARTED) {
          clearInterval(interval);
          resolve();
        }
      }, 50);
    });
  }

  private static checkVideoId(videoId: string): string {
    const list = videoId.split(YtUtils.videoIdSeparator);
    return list[0];
  }
}