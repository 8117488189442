import { AppThunk } from '../../../../../store/types';
import { History } from 'history';
import { AuthManager } from '../../../../../../common/utils/auth-manager';
import { LoginUserEffects } from '../../../../../effects/loginUserEffects';
import { Navigator } from '../../../../../effects/navigator';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';

export class InstallLoginEvents {

  public static onDidMount(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      EventsRouter.trackEvent(Events.SIGN_IN_LOADED);
    }
  }

  public static onLogin(): AppThunk {
    return async(
      dispatch,
      getState
    ): Promise<boolean> => {
      EventsRouter.trackEvent(Events.SIGN_IN_CLICKED);
      try {
        const result = await AuthManager.login();
        if (!result || !result.authUser)
          return false;
        await LoginUserEffects.prepareBeforeShowSelectLangs(result.authUser);
        Navigator.openInstallSelectLangs();
        return true;
      } catch (e) {
        console.log('err', e);
        return false;
      }
    }
  }


}