import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsTabsEditorPopupActions } from '../../../../../store/phrase-details-tabs-editor-popup/actions';
import { EPhraseDetailsTabsEditorViewMode } from '../../../../../store/phrase-details-tabs-editor-popup/types';
import { PhraseDetailsTabEffects } from '../../../../../effects/phraseDetailsTabEffects';

export class PhraseDetailsTabsEditorContainerEvents {

  public static onCreateNew(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(PhraseDetailsTabsEditorPopupActions.setEditTabId(0));
      dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.CREATE));
    }
  }

  public static onAddFromLib(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      dispatch(PhraseDetailsTabsEditorPopupActions.setViewMode(EPhraseDetailsTabsEditorViewMode.LIBS));
    }
  }

  public static onReset(): AppThunk {
    return async(
      dispatch,
      getState
    ): Promise<any> => {
      return PhraseDetailsTabEffects.resetToDefault();
    }
  }
}