import { IconButton, Modal as ModalComponent, ModalProps, PaperProps, Stack, alpha, styled } from "@mui/material";
import { Paper } from "../Paper/Paper";
import React from 'react';
import { theme } from "../../styles/theme";
import { CloseIcon } from "../../../../icons/CloseIcon";
import { Text } from "../Text/Text";

const MUIModal = styled(ModalComponent)<ModalProps>(({ theme }) => ({
    position: 'absolute' as 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

type TCloseReason = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick';

interface IModalProps extends ModalProps {
    titleButton?: JSX.Element;
    isCloseButton?: boolean;
    isMobile?: boolean;
    onClose: (reason: TCloseReason) => void;
    PaperProps?: PaperProps;
}

export const Modal = ({
    isCloseButton = true,
    isMobile,
    open,
    onClose,
    children,
    PaperProps = {},
    title,
    titleButton,
    sx,
    ...props
}: IModalProps) => {
    const className = 'modal';

    return (
        <MUIModal open={open} onClose={onClose}
            sx={{
                '& > .MuiPaper-root': {
                    maxHeight: '90vh',
                    minWidth: theme.spacing(40),
                    maxWidth: '100%',

                    '& > .MuiStack-root': {
                        p: theme.spacing(3),
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        maxHeight: '90vh',
                    },

                    '.mobile & > .MuiStack-root': {
                        p: theme.spacing(2),
                    },
                },

                [theme.breakpoints.down('xs')]: isMobile
                    ? {
                        alignItems: 'flex-end',
                        
                        '& > .MuiPaper-root': {
                            minWidth: '100%',
                        }      
                    }
                    : null,

                ...sx,
            }}
            {...props}
        >
            <Paper
                {...PaperProps}
                sx={{
                    position: 'relative',
                    borderRadius: theme.spacing(1.25),
                    overflow: 'hidden',
                    ...PaperProps.sx,
                }}
            >
                {isCloseButton &&
                    <IconButton
                        className={`${className}__close`}
                        onClick={() => onClose('closeButtonClick')}
                        color={'primary'}
                        sx={{
                            position: 'absolute',
                            zIndex: 100,
                            top: theme.spacing(),
                            right: theme.spacing(),
                            color: theme.palette.grey[400],
                            borderRadius: '50%',
                            background: alpha(theme.palette.common.white, .5),

                            '&:hover, &:active, &:focus': {
                                color: theme.palette.grey[500],
                            },
                            '.MuiSvgIcon-root': { width: theme.spacing(3), height: theme.spacing(3) }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                }
                <Stack gap={theme.spacing(2)}>
                    <Text variant='h6' fontWeight={600} sx={{ maxWidth: `80%` }}>
                        {title}
                        {titleButton}
                    </Text>
                    {children}
                </Stack>
            </Paper>
        </MUIModal>
    );
}
