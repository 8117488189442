import { IState } from '../../../../../../store/types';
import { connect } from 'react-redux';
import { IVideoTutorialTooltipEvents, IVideoTutorialTooltipFields } from '../../components/types';
import { VideoTutorialTooltip } from '../../components/VideoTutorialTooltip';
import { VideoTutorialTooltipEvents } from './events';
import { PhraseDetailsTabsEditorPopupSelectors } from '../../../../../../store/phrase-details-tabs-editor-popup/selectors';
import { getVideoTutorialByType } from '../../../../../../store/models/selectors';
import { EVideoTutorialType } from '../../../../../../store/models/types';

const mapStateToProps = (
  dispatch: any,
) => {
  return (
    state: IState
  ): IVideoTutorialTooltipFields => {
    const videoTutorial = getVideoTutorialByType(state, EVideoTutorialType.PROMPTS);
    return {
      show: PhraseDetailsTabsEditorPopupSelectors.isShowTutorial(state),
      videoId: videoTutorial?.videoId || '',
      title: videoTutorial?.title || '',
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IVideoTutorialTooltipEvents => ({
    onClose: () => dispatch(VideoTutorialTooltipEvents.onClose())
  }
);

export const VideoTutorialTooltipPromptsHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoTutorialTooltip);

VideoTutorialTooltipPromptsHOC.displayName = 'VideoTutorialTooltipPromptsHOC';