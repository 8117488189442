import React from 'react';
import { IPhraseChatProps } from './types';
import { Button, Stack, SxProps } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';
import { PhraseChatItemHOC } from '../../PhraseChatItem/hocs/PhraseChatItemHOC';
import { DisketteIcon } from '../../../../../../../icons/DisketteIcon';

export const PhraseChat: React.FC<IPhraseChatProps> = ({
	chat,
	onCopyNote,
}) => {
	const {isMobile} = React.useContext(DeviceContext);

	return (
		<Stack
			gap={theme.spacing(1)}
			sx={{
				position: 'relative',
				width: 1,
				pl: theme.spacing(3),
				pb: theme.spacing(2),
			}}
		>
			{chat && chat.length &&
				chat.map(i => {
					return <PhraseChatItemHOC
						key={'key' + i.id + 'type' + i.type}
						id={i.id}
						type={i.type}
					/>
				})
			}

			<Stack
				flexDirection={'row'}
				justifyContent={'flex-end'}
				sx={{
					position: 'sticky',
					bottom: 0,
					ml: theme.spacing(-2),
					p: theme.spacing(1),
					background: theme.palette.common.white,
				}}
			>
				<Button
					variant={'contained'}
					sx={{
						height: theme.spacing(4),
						p: `0 ${theme.spacing(1.25)}`,
					}}
					startIcon={<DisketteIcon />}
					onClick={onCopyNote}
				>
					Save to notes
				</Button>
			</Stack>
			
		</Stack>
	);

}

