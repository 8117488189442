import {
  IPhraseContextEditorActiveAction,
  IPhraseContextEditorDeActiveAction, IPhraseContextEditorDeletePhraseAction,
  IPhraseContextEditorSetPhraseIdAction, IPhraseContextEditorSetPreviewContextPhraseAction,
  IPhraseContextEditorSetSelectResultAction,
  IPhraseContextEditorSetShowAction,
  IPhraseContextEditorState,
  IUpdatePhraseContextEditorAction, PHRASE_CONTEXT_EDITOR_DELETE_PHRASE_ACTION,
  SET_PHRASE_CONTEXT_EDITOR_ACTIVATE_ACTION,
  SET_PHRASE_CONTEXT_EDITOR_DEACTIVATE_ACTION,
  SET_PHRASE_CONTEXT_EDITOR_PHRASE_ID_ACTION,
  SET_PHRASE_CONTEXT_EDITOR_PREVIEW_CONTEXT_PHRASE_ACTION,
  SET_PHRASE_CONTEXT_EDITOR_SELECT_RESULT_ACTION,
  SET_PHRASE_CONTEXT_EDITOR_SHOW_ACTION,
  UPDATE_PHRASE_CONTEXT_EDITOR
} from './types';
import { IPhraseSelectResult } from '../../effects/phrase/utils/phrase-select-preparator';
import { IVocabularyPhrase } from '../../types/common';

export class PhraseContextEditorActions {

  public static setShow(show: boolean): IPhraseContextEditorSetShowAction {
    return {
      type: SET_PHRASE_CONTEXT_EDITOR_SHOW_ACTION,
      show
    }
  }

  public static setPhraseId(phraseId: number): IPhraseContextEditorSetPhraseIdAction {
    return {
      type: SET_PHRASE_CONTEXT_EDITOR_PHRASE_ID_ACTION,
      phraseId
    }
  }

  public static activate(phraseId: number): IPhraseContextEditorActiveAction {
    return {
      type: SET_PHRASE_CONTEXT_EDITOR_ACTIVATE_ACTION,
      phraseId
    }
  }

  public static deActivate(): IPhraseContextEditorDeActiveAction {
    return {
      type: SET_PHRASE_CONTEXT_EDITOR_DEACTIVATE_ACTION
    }
  }

  public static setSelectResult(selectResult: IPhraseSelectResult | null): IPhraseContextEditorSetSelectResultAction {
    return {
      type: SET_PHRASE_CONTEXT_EDITOR_SELECT_RESULT_ACTION,
      selectResult
    }
  }

  public static setPreviewContextPhrase(previewContextPhrase: IVocabularyPhrase | null): IPhraseContextEditorSetPreviewContextPhraseAction {
    return {
      type: SET_PHRASE_CONTEXT_EDITOR_PREVIEW_CONTEXT_PHRASE_ACTION,
      previewContextPhrase
    }
  }

  public static updatePhraseContextEditorAction(
    value: Partial<IPhraseContextEditorState>
  ): IUpdatePhraseContextEditorAction {
    return {
      type: UPDATE_PHRASE_CONTEXT_EDITOR,
      value
    }
  }

  public static deletePhrase(phraseId: number): IPhraseContextEditorDeletePhraseAction {
    return {
      type: PHRASE_CONTEXT_EDITOR_DELETE_PHRASE_ACTION,
      phraseId
    }
  }




}