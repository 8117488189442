import { IAvailableCaptionsItem } from '../../../spa/store/current-video/types';
import { postRequest } from '../../utils/requestService';
import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { TVideoInfo } from '../../../spa/store/models/types';
import { ICaptionsItem } from '../../../spa/types/common';

export type TVideoMetaLangsResponse = {
  success: boolean,
  langs?: IAvailableCaptionsItem[],
  errorMessage? :string
}

export type TVideoInfoResponse = {
  videoInfo: TVideoInfo,
  captions: Record<string, ICaptionsItem[]>
}

export class VideoMetaLangsRest {

  private static VIDEO_LANGS_URL = API_URL + '/videoMeta/langs/';
  private static VIDEO_INFO_URL = API_URL + '/videoMeta/info/';

  public static async getVideoMeta(videoUrl: string): Promise<TVideoMetaLangsResponse> {
    const dispatch = getDispatch();
    return dispatch(postRequest(VideoMetaLangsRest.VIDEO_LANGS_URL, {videoUrl}));
  }

  public static async getVideoInfo(videoKey: string, groupId: number): Promise<TVideoInfoResponse> {
    const dispatch = getDispatch();
    return dispatch(postRequest(VideoMetaLangsRest.VIDEO_INFO_URL, {videoKey, groupId}));
  }

}

