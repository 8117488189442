import { AppThunk } from '../../../../store/types';
import { StatLogManager } from '../../../../../common/stats/statLogManager';

export class VideoWatchEvents {

  public static onTime(sec: number): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      StatLogManager.logVideoWatch(sec);
    }
  }

}