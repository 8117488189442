import { IState } from '../../../../../store/types';
import { IInstallLoginEvents, IInstallLoginFields } from '../../components/types';
import { connect } from 'react-redux';
import { InstallLogin } from '../../components/InstallLogin';
import { DefaultLoginEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IInstallLoginFields => {
    return {}
  };
}

const mapDispatchToProps = (
  dispatch: any
): IInstallLoginEvents => ({
    onDidMount: () => dispatch(DefaultLoginEvents.onDidMount()),
    onLogin: (targetLang, nativeLang) =>
      dispatch(DefaultLoginEvents.onLogin(targetLang, nativeLang))
  }
);

export const DefaultLoginHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallLogin);

DefaultLoginHOC.displayName = 'DefaultLoginHOC';