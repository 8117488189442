import React from 'react';
import { Box, Stack } from '@mui/material';
import { theme } from '../../../../../../common/styles/theme';
import { SxProps } from '@mui/system';
import { INativeCaptionsItemProps } from './types';
import { HtmlUtils } from '../../../../../../common/utils/html-utils';


let marginHeight: number = -1;

const getMarginHeight = (elem: HTMLDivElement): number => {
  if (marginHeight >= 0) {
    return marginHeight;
  }
  marginHeight = HtmlUtils.getElementMaginsHeight(elem);
  return marginHeight;
}

export const NativeCaptionsItem: React.FC<INativeCaptionsItemProps> = ({
    index,
    isActive,
    startTime,
    endTime,
    text,
  onSetHeight,
  onMouseDown,
  onMouseUp,
  onMouseEnter
}) => {

  const rootElemRef = React.useRef<HTMLDivElement>(null);

    let textEl;

    const elStyle = {
        position: 'relative',
		zIndex: 100,
        fontSize: theme.typography.subtitle2,
        lineHeight: theme.spacing(3.25)
    };

    let boxStyle: SxProps = {
        position: 'relative',
        pr: theme.spacing(1.5),
        pb: theme.spacing(0.25),
        mb: theme.spacing(1),
    };

    let playStyle = {
		position: 'absolute',
		zIndex: 0,
		left: theme.spacing(-2.75),
		right: theme.spacing(.25),
		top: 0,
		bottom: 0,
        background: `${theme.palette.primary[100]}`,
		borderRadius: theme.spacing(2),
	};

    textEl = (
        <Box
            sx={elStyle}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseEnter={onMouseEnter}
            data-start={startTime}
            data-end={endTime}
            dangerouslySetInnerHTML={{ __html: text || '' }}
        />
    );

  if (onSetHeight && rootElemRef.current) {
    const height = rootElemRef.current.offsetHeight;
    if (height) {
      const margin = getMarginHeight(rootElemRef.current);
      onSetHeight(height + margin);
    }
  }
 
    return (
        <Stack
          direction={'row'}
          sx={boxStyle}
          ref={rootElemRef}>
            {isActive &&
                <Box sx={playStyle} />
            }
            {textEl}
        </Stack>
    );
};
