import { TPhraseDetailsTab } from '../../../../../store/models/types';
import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsTabEffects } from '../../../../../effects/phraseDetailsTabEffects';

export class PhraseDetailsTabListContainerEvents {

  public static onSort(tabs: TPhraseDetailsTab[]): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      PhraseDetailsTabEffects.updateTabsOrder(tabs);
    }
  }
}