import React from 'react';
import { IPlaylistsMobileProps } from './types';
import { VideoLibraryHOC } from '../../VideoGroups/VideoLibrary/hocs/VideoLibraryHOC';

export const PlaylistsMobile: React.FC<IPlaylistsMobileProps> = ({
  isShow,
}) => {
  if (!isShow) return null;

  return (
    <VideoLibraryHOC />
  );
};

export default PlaylistsMobile;
