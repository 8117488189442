import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { Statisitcs } from '../components/Statisitcs';
import { IStatisitcsEvents, IStatisitcsFields } from '../components/types';
import { StatisitcsEvents } from './events';
import { getMainViewMode } from '../../../../../store/general/selectors';
import { EMainViewMode } from '../../../../../store/general/types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
  ): IStatisitcsFields => {
    const show = getMainViewMode(state) === EMainViewMode.STATISTICS;
    return {
      show
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IStatisitcsEvents => ({
  onLoad: () => dispatch(StatisitcsEvents.onLoadStat())
});

export const StatisitcsHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(Statisitcs);

StatisitcsHOC.displayName = 'StatisitcsHOC';