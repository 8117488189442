import React from 'react';
import { IPromptsListProps } from './types';
import { CircularProgress, Stack, SxProps } from '@mui/material';
import { PromptsListItemHOC } from '../../PromptsListItem/hocs/PromptsListItemHOC';
import { theme } from '../../../../../../common/styles/theme';
import { PromptsLibItemHOC } from '../../PromptsLibItem/hocs/PromptsLibItemHOC';
import { EPhraseDetailsTabType, TPhraseDetailsTab } from '../../../../../store/models/types';
import { Text } from '../../../../../../common/components/Text/Text';

export function PromptsList ({
	lib,
	loading,
	tabs,
  	onLoad,
	onSelected,
}: IPromptsListProps) {

	const groupStyle: SxProps = {
		mt: theme.spacing(-1),
		mb: theme.spacing(1),
		fontSize: theme.typography.subtitle2,
		color: theme.palette.grey[400],
	};

	const [customItems, setCustomItems] = React.useState<TPhraseDetailsTab[]>([]);
	const [defaultItems, setDefaultItems] = React.useState<TPhraseDetailsTab[]>([]);
	React.useEffect(() => {
		let customItems: TPhraseDetailsTab[] = [];
		let defaultItems: TPhraseDetailsTab[] = [];

		if (tabs && tabs.length) {
			tabs.forEach(item => {
				if (item.type === EPhraseDetailsTabType.CUSTOM_PROMPT) {
					customItems.push(item);
				} else {
					defaultItems.push(item);
				}
			});
		}

		setCustomItems(customItems);
		setDefaultItems(defaultItems);
	}, [tabs]);

	React.useEffect(() => {
		onLoad();
	}, []);

	return (
		<Stack
			gap={theme.spacing(1)}
		>
			{!!customItems && !!customItems.length &&
				<>
					<Text
						sx={groupStyle}
					>
						My prompts
					</Text>
					{customItems.map((tab) => (
						<PromptsListItemHOC
							id={tab.id}
							key={tab.id}
						/>
					))}
					<Text
						sx={{
							...groupStyle,
							mt: theme.spacing(1),
						}}
					>
						All prompts
					</Text>
				</>
			}
			{!!defaultItems && !!defaultItems.length && defaultItems.map((tab) => (
				<PromptsListItemHOC
					id={tab.id}
					key={tab.id}
					onSelected={onSelected}
				/>
			))}
			{loading &&
				<Stack
					alignItems={'center'}
					justifyContent={'center'}
					sx={{ mt: theme.spacing(3) }}
				>
					<CircularProgress
						size={theme.spacing(3)}
					/>
				</Stack>
			}
			{!!lib && !!lib.length && lib.map((li, index) => (
				<PromptsLibItemHOC
					id={li.id}
					key={li.id}
					onSelected={onSelected}
				/>
			))}
		</Stack>
	);
}
