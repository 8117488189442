export class UserLangsTranslationColors {

  private static DEFAULT_COLOR: string = 'black';

  private static COLORS_MAP: Record<string, string> = {
    'en': '#2A8EF8',
    'zh': '#E74BA3',
    'es': '#67B757',
    'ru': '#DD524C',
    'de': '#FFB047',
    'pt': '#e311e1',
    'fr': '#11C8E3',
    'it': '#1b9355',
    'ar': '#ff4949',
    'ja': '#a84200',
    'ptbr': '#0030a8',
  }

  public static getColor(langCode: string) {
    return UserLangsTranslationColors.COLORS_MAP[langCode] || UserLangsTranslationColors.DEFAULT_COLOR;
  }
}

