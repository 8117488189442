import { AppThunk } from '../../../../../store/types';
import { StaticsFilterUtils } from '../components/filterUtils';
import { StatLogManager } from '../../../../../../common/stats/statLogManager';
import { TStatsResponse } from '../../../../../../common/rest/stats/statRest';
import { TStatsResultList } from '../components/types';


export class StatisitcsEvents {

  public static onLoadStat(): AppThunk {
    return (
      dispatch
    ): Promise<TStatsResultList> => {
      const dateRanges = StaticsFilterUtils.getDateRangeByFilters();
      return StatLogManager.getStat(dateRanges).then((ret: TStatsResponse) => {
        return ret.resultList
      })
    }
  }

}