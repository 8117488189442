import * as React from 'react';
import { Stack } from '@mui/material';
import { IEmptyCaptionsProps } from './types';
import { Text } from '../../../../../common/components/Text/Text';

export const EmptyCaptions: React.FC<IEmptyCaptionsProps> = ({
  targetLang,
  nativeLang
}) => {

  return (

      <Stack
        sx={{
          width: '100%',
          height: '100%',
        }}
        direction='row'
      >
        <Text variant='h6' fontWeight={600} marginTop={'10px'} marginLeft={'10px'} marginRight={'10px'}>
          This play list is set to display {targetLang}/{nativeLang} subtitles.<br/>
          There are not subtitles available in this video for those languages. Please select another video.
        </Text>
      </Stack>
  )
};
