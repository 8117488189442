import { EDefaultGptPromptType, EPhraseDetailsTabType } from '../../../store/models/types';
import { PhraseDetailsGptchatService } from './phrase-details-gptchat-service';
import { getDispatch, getState } from '../../../store';
import { PhraseDetailsActions } from '../../../store/phrase-details/actions';
import { PhraseDetailsSelectors } from '../../../store/phrase-details/selectors';
import { getDefaultGptPrompt, getPhraseDetailsTabByType, getUserGptPromptById } from '../../../store/models/selectors';


export class PhraseDetailsUsageService extends PhraseDetailsGptchatService {

  constructor(text: string, context: string, targetLangCode: string, nativeLangCode: string) {
    super(text, context, targetLangCode, nativeLangCode);
  }

  protected saveHash(hash: string): void {
    getDispatch()(PhraseDetailsActions.setUsageHash(hash));
  }

  protected getHash(): string {
    return PhraseDetailsSelectors.getUsageHash(getState());
  }

  protected async getPromptText(): Promise<string> {
    const state = getState();
    const tab = getPhraseDetailsTabByType(state, EPhraseDetailsTabType.USAGE);
    let prompt;
    if (tab && tab.relationId) {
      prompt = getUserGptPromptById(state, tab.relationId);
    }
    if (!prompt) {
      prompt = getDefaultGptPrompt(state, EDefaultGptPromptType.USAGE);
    }
    return prompt?.prompt || '';
  }

}