import React from 'react';
import { Stack } from '@mui/material';
import { IPhraseDetailsTabsLibContainerProps } from './types';
import { PhraseDetailsTabLibHOC } from '../../PhraseDetailsTabLib/hocs/PhraseDetailsTabLibHOC';
import { PhraseDetailsTabLibPromptFormHOC } from '../../PhraseDetailsTabPromptForm/hocs/lib/PhraseDetailsTabLibPromptFormHOC';
import { theme } from '../../../../../../common/styles/theme';
import { Text } from '../../../../../../common/components/Text/Text';
import { PhraseDetailsTabHeaderHOC } from '../../PhraseDetailsTabHeader/hocs/PhraseDetailsTabHeaderHOC';
import { Paper } from '../../../../../../common/components/Paper/Paper';


export const PhraseDetailsTabsLibContainer: React.FC<IPhraseDetailsTabsLibContainerProps> = ({
  onLoad
}) => {

  React.useEffect(() => {
    onLoad();
  }, []);

  const paperStyle = {
    position: 'sticky',
    top: 0,
    minWidth: theme.spacing(50),
    maxWidth: theme.spacing(50),
    maxHeight: `calc(100vh - ${theme.spacing(4)})`,
    p: theme.spacing(2),
  };

  return (
    <>
      <Paper sx={paperStyle}>
        <PhraseDetailsTabHeaderHOC />

        <Text
          fontWeight={600}
          variant={'h5'}
          sx={{
            mb: theme.spacing(1),
          }}
        >
          Prompt Library
        </Text>

        <PhraseDetailsTabLibHOC />
      </Paper>

      
      <PhraseDetailsTabLibPromptFormHOC />
      
    </>
  );

}
