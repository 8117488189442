export interface IPhraseDetailsPopupMenuFields {
  show: boolean,
  anchorEl?: HTMLElement
}

export interface IPhraseDetailsPopupMenuEvents {
  onCopy: () => void;
  onClose: () => void;
}

export interface IPhraseDetailsPopupMenuProps extends
  IPhraseDetailsPopupMenuFields,
  IPhraseDetailsPopupMenuEvents
{}

export const PHRASE_DETAILS_POPUP_MENU_CLASS_NAME = 'phraseDetailsPopupMenu';