import { TStatsDateRangeResponse } from '../../../../../../common/rest/stats/statRest';

export type TStatsResultList = TStatsDateRangeResponse[]

export interface IStatisitcsFields {
  show: boolean;
}

export interface IStatisitcsEvents {
  onLoad: () => Promise<TStatsResultList>
}

export interface IStatisitcsProps extends
  IStatisitcsFields,
  IStatisitcsEvents
{}

export interface IDateRange {
  id: string,
  from: Date,
  to: Date
}

export enum EStatDateRangeId {
  YESTERDAY = 'yesterday',
  LAST_10_DAYS = 'last-10-days',
  LAST_MONTH = 'last-month'
}

export const statDateRangeTitles: Record<EStatDateRangeId, string> = {
  [EStatDateRangeId.YESTERDAY]: 'Yesterday',
  [EStatDateRangeId.LAST_10_DAYS]: 'Last 10 days',
  [EStatDateRangeId.LAST_MONTH]: 'Last month'
}

export interface IStatisitcsItemFields {
  title: string;
  dataKey: string;
  resultList: TStatsResultList;
}

export interface IStatisitcsItemProps extends
  IStatisitcsItemFields
{}