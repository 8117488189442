import React from 'react';
import {
	CardMedia,
	Stack
} from '@mui/material';
import { theme } from '../../../../common/styles/theme';
import { IVideoCardProps } from './types';
import { Text } from '../../../../common/components/Text/Text';
import LangFlag from '../LangFlag/LangFlag';

export const VideoCard: React.FC<IVideoCardProps> = ({
	author,
	img,
	langCodes,
	title,
}) => {

	return (
		<Stack
			direction={'row'}
			alignItems={'flex-start'}
			gap={theme.spacing(2)}
		>
			<CardMedia
				component='img'
				image={img}
				alt={title}
				sx={{
					flex: `0 0 ${theme.spacing(15)}`,
					maxWidth: theme.spacing(15),
					aspectRatio: '16/9',
					borderRadius: theme.spacing(.75),
				}}
			/>
			<Stack spacing={theme.spacing(.5)}>
				<Text
					sx={{
						display: '-webkit-box',
						WebkitLineClamp: 2,
						WebkitBoxOrient: 'vertical',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						fontSize: theme.typography.subtitle2,
						fontWeight: 500,
					}}
				>
					{title}
				</Text>
				<Text
					noWrap
					sx={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						color: theme.palette.grey[400],
						fontSize: theme.typography.fontSize,
					}}
				>
					{author}
				</Text>
				{!!langCodes && !!langCodes.length &&
					<Stack
						direction={'row'}
						flexWrap={'wrap'}
						gap={theme.spacing(1)}
						sx={{
							'.MuiStack-root': {
								height: theme.spacing(2),
							}
						}}
					>
						{langCodes.map(langCode => (
							<LangFlag
								code={langCode}
								showCode={true}
								key={langCode}
							/>
						))}
					</Stack>
				}
			</Stack>
		</Stack>
	);
}
