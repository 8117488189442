import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsActions } from '../../../../../store/phrase-details/actions';
import { getAuthUser } from '../../../../../store/general/selectors';
import { PhraseDetailsTranslateManager } from '../../../../../effects/phrase-details/translate/phraseDetailsTranslateManager';
import { IPhrasesDetailTranslateLangResult } from '../../../../../store/phrase-details/types';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { PhraseNoteEffects } from '../../../../../effects/phraseNoteEffects';
import { EventsRouter } from '../../../../../../common/events/eventsRouter';
import { Events } from '../../../../../../common/events/types';
import { StatLogManager } from '../../../../../../common/stats/statLogManager';
import { ErrorEffects } from '../../../../../effects/errorEffects';
import { PhraseContextEditorSelectors } from '../../../../../store/phrase-context-editor/selectors';
import { UserLangsTranslationColors } from '../../../PhraseDetailsTabsEditor/PhraseDetailsTabTranslation/helpers/userLangsTranslationColors';

export class PhraseTranslationEvents {

  public static onLoad(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const state = getState();
      const user = getAuthUser(state);
      if (user) {
        EventsRouter.trackEvent(Events.TRANSLATION_LOADED);
        dispatch(PhraseDetailsActions.setTranslateResult(null, true, false));
        try {
          const isTranslateContext = PhraseContextEditorSelectors.getIsTranslateContext(getState());
          const text = isTranslateContext
            ? PhraseContextEditorSelectors.getPhraseContext(getState())?.fullPhrase || ''
            : PhraseDetailsSelectors.getSelectText(state);

          const results: IPhrasesDetailTranslateLangResult[] =
            await PhraseDetailsTranslateManager.translateLangsForUser(user, text || '');
          
          const translations = isTranslateContext
            ? [
              ...(PhraseDetailsSelectors.getTranslateResultText(state) || []).filter(i => {
                return !i.isContext;
              }),
              ...results.map(i => {
                return {
                  ...i,
                  isContext: true,
                }
              })
            ]
            : results;

          dispatch(PhraseDetailsActions.setTranslateResult(translations, false, true));
          StatLogManager.logTranslation();
        } catch(e) {
          dispatch(PhraseDetailsActions.setTranslateResult([], false, true));
          ErrorEffects.logError(e);
        }
      }
    }
  }

  public static onRefresh(toLang: string): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const state = getState();
      const user = getAuthUser(state);
      if (user) {
        const text = PhraseDetailsSelectors.getSelectText(state);
        const result = await PhraseDetailsTranslateManager.translateLang(text, toLang);
        dispatch(PhraseDetailsActions.setTranslateLangResult(result));
      }
    }
  }

  public static onCopyNote(): AppThunk {
    return async(
      dispatch,
      getState
    ) => {
      const text = PhraseDetailsSelectors.getTranslateResultText(getState())
        ?.filter(r => r.success)
        .map(r => {
          let color = UserLangsTranslationColors.getColor(r.langCode as string);
          return `<p style="color:${color}">${r.result}</span>`;
        })
        .join('') + '<p> </p>'; // new line without color
      PhraseNoteEffects.runCopyToNoteText(text);
    }
  }





}