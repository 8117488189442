import { API_URL } from '../../constants';
import { postRequest } from '../../utils/requestService';
import { TAsyncThunk } from '../../../spa/store/types';
import { TVideoRest } from '../../../spa/store/models/types';
import { IYoutubeVideoInfo } from '../../../spa/types/common';

const VIDEO_ADD_URL = API_URL + '/video/add';

export const createGroupVideo = (
  videoId: string,
  groupId: number,
  videoInfo: IYoutubeVideoInfo,
  langTargetAutoGenCode: string,
  nativeLangCode: string,
  targetLangCode: string,
  langs: string
): TAsyncThunk => (
  dispatch
): Promise<TVideoRest> => {
  const body = { videoId, groupId, langTargetAutoGenCode,
    title: videoInfo.title,
    author: videoInfo.channelTitle,
    thumbnail: videoInfo.thumbnailUrl,
    thumbnailMedium: videoInfo.thumbnailMediumUrl,
    thumbnailStandard: videoInfo.thumbnailStandardUrl,
    nativeLangCode, targetLangCode, langs };
  return dispatch(postRequest(VIDEO_ADD_URL, body));
};
