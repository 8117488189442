import { getDispatch } from '../../../spa/store';
import { postRequest } from '../../utils/requestService';
import { API_URL } from '../../constants';
import { TAuthUserTranslateLang } from '../../../spa/store/general/types';

export type TUserTranslateLangSaveBody = {
  langCode: string,
  orderNum: number
}

export class UserTranslateLangRest {

  private static SAVE_URL = API_URL + '/userTranslateLang/save';
  private static REMOVE_URL = API_URL + '/userTranslateLang/remove';
  private static UPDATE_LIST_URL = API_URL + '/userTranslateLang/updateList';

  public static async save(body: TUserTranslateLangSaveBody): Promise<any> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(UserTranslateLangRest.SAVE_URL, body));
  }

  public static async remove(langCode: string): Promise<any> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(UserTranslateLangRest.REMOVE_URL, {langCode}));
  }

  public static async updateList(langs: TAuthUserTranslateLang[]): Promise<TAuthUserTranslateLang[]> {
    const dispatch = getDispatch();
    return await dispatch(postRequest(UserTranslateLangRest.UPDATE_LIST_URL, {langs}));
  }
}
