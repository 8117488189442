import React from 'react';
import { IVideoWatchProps } from './types';

export const VideoWatch: React.FC<IVideoWatchProps> = ({
  play,
  onTime
}) => {

  const [startWatchTime, setStartWatchTime] = React.useState<number>(0);
  const [totalTime, setTotalTime] = React.useState<number>(0);

  const totalTimeRef = React.useRef<number>(totalTime);
  totalTimeRef.current = totalTime;

  const startWatchTimeRef = React.useRef<number>(startWatchTime);
  startWatchTimeRef.current = startWatchTime;


  React.useEffect(() => {
    if (play) {
      setStartWatchTime(new Date().getTime())
    } else {
      checkTotalTime(startWatchTime, totalTime, true);
    }
  }, [play]);


  const checkTotalTime = (startWatchTime: number, totalTime: number, reset: boolean) => {
    if (startWatchTime > 0) {
      const time = new Date().getTime() - startWatchTime;
      if (time > 0) {
        setTotalTime(totalTime + time);
      }
      setStartWatchTime(reset ? 0 : new Date().getTime());
    }
  }

  React.useEffect(() => {
    setInterval(() => {
      checkTotalTime(startWatchTimeRef.current, totalTimeRef.current, false);
    },  10 * 1000);

    setInterval(() => {
      if (totalTimeRef.current > 0) {
        onTime(Math.round(totalTimeRef.current / 1000));
        setTotalTime(0);
        if (startWatchTime > 0) {
          setStartWatchTime(new Date().getTime());
        }
      }
    }, 60 * 1000);
  }, []);


  return null;
}
