import { AppThunk } from '../../../../../store/types';
import { getOneTimeTooltipsStorage } from '../../../../../../common/utils/local-storage/onetime-tooltips';
import { setOneTimeTooltipAction } from '../../../../../store/general/actions';

export class OneTimeTooltipNativeAvailableEvents {

  public static onClose(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      getOneTimeTooltipsStorage().setShow({ showNativeAvailable: false });
      dispatch(setOneTimeTooltipAction({ showNativeAvailableTooltip: false }));
    }
  }

}