import { AppThunk } from '../../store/types';
import { PhraseDetailsTabsEditorPopupActions } from '../../store/phrase-details-tabs-editor-popup/actions';

export class PhraseDetailsTabsEditorPopupEffects {

  public static closePhraseDetailsTabsEditorPopup(): AppThunk {
    return (
      dispatch
    ) => {
      dispatch(PhraseDetailsTabsEditorPopupActions.setShowFavoritePromptTooltip(false));
      dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestPrompt(''));
      dispatch(PhraseDetailsTabsEditorPopupActions.setPromptTestResult(''));
      dispatch(PhraseDetailsTabsEditorPopupActions.setOpenPromptLibId(0));
      dispatch(PhraseDetailsTabsEditorPopupActions.setEditTabId(0));
    }
  }

}