import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { CardMedia, ListItem, ListItemButton, ListItemSecondaryAction, Stack } from '@mui/material';
import { Text } from '../../../../../../common/components/Text/Text';
import { IVideoLibraryGroupItemProps } from './types';
import { LangFlag } from '../../../LangFlag/LangFlag';
import { ConfirmPopup } from '../../../ConfirmPopup/ConfirmPopup';
import { TrashIcon } from '../../../../../../../icons/TrashIcon';
import { IconMenuItem } from '../../../../../../common/components/IconMenuItem/IconMenuItem';
import { LinkIcon } from '../../../../../../../icons/LinkIcon';
import { ButtonMenu } from '../../../../../../common/components/ButtonMenu/ButtonMenu';
import { DeviceContext } from '../../../../../../common/contexts/DeviceContext';

export const VideoLibraryGroupItem: React.FC<IVideoLibraryGroupItemProps> = ({
  isOpened,
  title,
  thumb,
  author,
  langCodes,
  flashVideo,
  showActionMenu,
  onClick,
  onDelete,
  onGetCopyLink
}) => {

  const { isTouch } = React.useContext(DeviceContext);

  const [show, setShow] = React.useState(isTouch);
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [className, setClassName] = React.useState<string>('');
  const itemRef = React.useRef<HTMLElement | null>(null);
  const [menuBtnAnchor, setMenuBtnAnchor] = React.useState(false);
  const menuBtnRef = React.useRef<HTMLButtonElement | null>(null);

  const handleDelete = () => {
    setAnchor(null);
    onDelete();
  };

  const onMouseOver = () => {
    if (isTouch) return;
    setShow(true);
  };

  const onMouseLeave = () => {
    if (isTouch) return;
    setShow(false);
  };

  const handleVideoClick = () => {
    onClick();
  }

  const handleAnimationEnd = () => {
    if (itemRef.current) {
      itemRef.current.style.setProperty('background-color', '');
    }
  }

  React.useEffect(() => {
    setClassName(flashVideo ? 'animate__animated animate__flash' : '');
    if (flashVideo && itemRef.current) {
      itemRef.current.addEventListener('animationend', handleAnimationEnd, {once: true});
    }
  }, [flashVideo]);

  const imageStyle = {
    minWidth: theme.spacing(14.25),
    maxWidth: theme.spacing(14.25),
    height: theme.spacing(9),
    borderRadius: theme.spacing(0.75),
  };

  const handleMenuButton = () => {
    setMenuBtnAnchor(true);
  }
  const handleCloseMenu = () => {
    setMenuBtnAnchor(false);
  }

  const handleConfirmDelete = () => {
    setMenuBtnAnchor(false);
    setAnchor(menuBtnRef.current);
  }

  const handeGetCopyLink = () => {
    setMenuBtnAnchor(false);
    onGetCopyLink();
  }
  return (
    <ListItem
      className={'video-library-item'}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      sx={{ p: 0 }}
    >
      <ListItemButton
        onClick={handleVideoClick}
        sx={{
          p: theme.spacing(),
          borderRadius: theme.spacing(0.75),
          bgcolor: isOpened ? `${theme.palette.primary[100]} !important` : ''
        }}
      >
        <Stack
          ref={itemRef}
          className={className}
          direction='row'
          spacing={theme.spacing(2)}
          sx={{ alignItems: 'center', pr: theme.spacing(4), overflow: 'hidden' }}
        >
          <CardMedia
            component='img'
            height={theme.spacing(9.25)}
            image={thumb}
            alt={title}
            sx={imageStyle}
          />
          <Stack
              spacing={'3px'}
              sx={{
                maxWidth: `calc(100% - ${theme.spacing(15.75)})`, // - img width + gap
                height: theme.spacing(9) // image height
              }}
          >
            <Text
              fontWeight={600}
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden'
              }}
            >
              {title}
            </Text>
            <Text
              fontWeight={600}
              noWrap
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: theme.palette.grey[400]
              }}
            >
              {author}
            </Text>
            <Stack
              direction='row'
              sx={{ height: theme.spacing(2) }}
              spacing={theme.spacing()}
            >
              { langCodes.map((langCode) => (
                <LangFlag code={langCode} showCode={true} key={langCode}/>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </ListItemButton>

      {showActionMenu &&
      <>
      <ListItemSecondaryAction
        onMouseOver={onMouseOver}
        sx={{ right: 0 }}
      >
        <ButtonMenu
            buttonProps={{
              ref: menuBtnRef,
              sx: {
                opacity: show ? 1 : 0,
              }
            }}
            menuProps={{
              id: 'account-menu',
              keepMounted: true,
              open: !!menuBtnAnchor,
            }}
            onOpen={handleMenuButton}
            onClose={handleCloseMenu}
        >
          <IconMenuItem
            title={'Copy public link'}
            onClick={handeGetCopyLink}
          >
            <LinkIcon />
          </IconMenuItem>
          <IconMenuItem
            title={'Delete'}
            onClick={handleConfirmDelete}
          >
            <TrashIcon />
          </IconMenuItem>
        </ButtonMenu>

      </ListItemSecondaryAction>

      <ConfirmPopup
        anchor={anchor}
        text={'Delete video?'}
        onConfirm={handleDelete}
        onClose={() => setAnchor(null)}
        />
        </>
      }

    </ListItem>
  );
};
