import { connect } from 'react-redux';
import { TextWithSpeaker } from '../components/TextWithSpeaker';
import { ITextWithSpeakerEvents, ITextWithSpeakerFields } from '../components/types';
import { TextWithSpeakerEvents } from './events';
import { IState } from '../../../../spa/store/types';
import { ITextWithSpeakerHOCProps } from './types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    props: ITextWithSpeakerHOCProps,
  ): ITextWithSpeakerFields => {
    const { text, boxSx, textSx } = props;
    return {
      text, boxSx, textSx
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  props: ITextWithSpeakerHOCProps,
): ITextWithSpeakerEvents => {
  const { lang, text } = props;
  return {
    onSpeak: (onStop) => dispatch(TextWithSpeakerEvents.onSpeak(text, lang, onStop)),
    onStopSpeak: () => dispatch(TextWithSpeakerEvents.onStopSpeak()),
  }
};

export const TextWithSpeakerHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(TextWithSpeaker);

TextWithSpeakerHOC.displayName = 'TextWithSpeakerHOC';
