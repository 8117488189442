import React from 'react';
import { Snackbar } from '../../../../../common/components/Snackbar/Snackbar';
import { theme } from '../../../../../common/styles/theme';
import { ISnackbarPanelProps } from './types';
import { Text } from '../../../../../common/components/Text/Text';


export const SnackbarPanel: React.FC<ISnackbarPanelProps> = ({
  show,
  text,
  hideTimeSec,
  onClose
}) => {

  if (!show) return null;

  React.useEffect(() => {
    if (show && hideTimeSec > 0) {
      setTimeout(() => {
        onClose();
      }, hideTimeSec * 1000)
    }
  }, [show])

  return (
    <Snackbar
      open={true}
      hideClose={hideTimeSec > 0}
      onClose={onClose}
      sx={{
        width: theme.spacing(37.5),

        [theme.breakpoints.down('xs')]: {
          width: `calc(100% - ${theme.spacing(2)})`,
        },
      }}
    >
      <Text
        sx={{ color: theme.palette.common.white, pr: theme.spacing(3) }}
        variant={'subtitle2'}
        dangerouslySetInnerHTML={{ __html: text }}
      />

    </Snackbar>
  );
}
