import { AppThunk } from '../../../../../store/types';
import { getOneTimeTooltipsStorage } from '../../../../../../common/utils/local-storage/onetime-tooltips';
import { setOneTimeTooltipPhrasesEditAction, setVideoTutorialModeAction } from '../../../../../store/general/actions';
import { EVideoTutorialType } from '../../../../../store/models/types';

export class OneTimeTooltipPhrasesEditEvents {

  public static onClose(): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      getOneTimeTooltipsStorage().setShowPhrasesEdit(false);
      dispatch(setOneTimeTooltipPhrasesEditAction(false));
    }
  }

  public static onClickHelp(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(setVideoTutorialModeAction(EVideoTutorialType.PHRASES));
		}
	}

}