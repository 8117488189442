import { AppThunk } from '../../../../../store/types';
import { PhraseDetailsPromptLibEffects } from '../../../../../effects/phrase-details/phraseDetailsPromptLibEffects';

export class PromptsListEvents {

	public static onLoad(): AppThunk {
		return async (
			dispatch,
			getState
		) => {
			PhraseDetailsPromptLibEffects.loadPromptLibs();
		}
	}

}


