import React from 'react';
import { IInstallLoginProps } from './types';
import { Button, Stack, Typography } from '@mui/material';
import { Text } from '../../../../../common/components/Text/Text';
import { theme } from '../../../../../common/styles/theme';
import { GoogleIcon } from '../../../../../../icons/GoogleIcon';
import { LoginFeature } from './LoginFeature';
import { LOGIN_FEATURES } from './constants';
import background from './imgs/background.svg';
import { LogoLabel } from '../../../../../../icons/LogoLabel';

export const InstallLogin: React.FC<IInstallLoginProps> = ({
  onDidMount,
  onLogin,
}) => {

  const [loginError, setLoginError] = React.useState<string | null>(null);

  const [targetLang, setTargetLang] = React.useState<string | null>(null);
  const [nativeLang, setNativeLang] = React.useState<string | null>(null);

  const onLoginClick = async () => {
    const success = await onLogin(targetLang, nativeLang);
    setLoginError(success ? null : 'Login error');
  }

  const googleButtonStyle = {
    height: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
    bgcolor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: '2px solid #fafafa',
    borderRadius: '56px',
    transition: 'opacity 0.15s ease',
    opacity: 1,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      opacity: 0.7,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
    },
    '.MuiButton-startIcon': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 1,
      minWidth: theme.spacing(7),
      m: 0,
    },
  };

  const errorStyle = {
    position: 'absolute',
    bottom: theme.spacing(-5),
    color: theme.palette.error.main,
    p: theme.spacing(1.5),
  };

  const containerStyle = {
    flexGrow: 1,
    p: theme.spacing(6),
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(8),
    bgcolor: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(4),
      p: theme.spacing(3),
    }
  };

  const contentStyle = {
    alignItems: 'center',
    gap: theme.spacing(10),
    zIndex: 10,
    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(6),
    }
  };

  const loginStyle = {
    position: 'relative',
    alignItems: 'center',
    gap: theme.spacing(5)
  };

  const textStyle = {
    alignItems: 'center',
    gap: theme.spacing(4)
  };

  const titleStyle = {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.h2,
      fontWeight: 700
    }
  };

  const subtitleStyle = {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    lineHeight: 1.6,
  };

  const featuresStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 1fr)`,
    width: '100%',
    maxWidth: '1280px',
    gap: theme.spacing(4),
    
    '.login-feature__img img': {
      borderRadius: theme.spacing(1.5),
    },

    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: `repeat(2, 1fr)`,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  };

  const bgStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    minHeight: '100vh',
    objectFit: 'cover',
    objectPosition: 'center',
  };

  React.useEffect(() => {
    if (onDidMount) onDidMount();
    const urlParams = new URLSearchParams(window.location.search);
    setTargetLang(urlParams.get('targetlangauge'));
    setNativeLang(urlParams.get('nativelanguage'));
  }, [])

  return (
        <Stack sx={containerStyle}>
          <Stack component={'img'} src={background} sx={bgStyle} />
          <Stack sx={contentStyle}>
            <LogoLabel
              sx={{
                width: theme.spacing(30),
                height: 'auto',
              }}
            />
            <Stack sx={loginStyle}>
              <Stack sx={textStyle}>
                <Text fontWeight={700} variant={'h1'} sx={titleStyle}>Learn any language with YouTube & ChatGPT. <br />Magically!</Text>
                <Text variant={'h5'} sx={subtitleStyle}>
                  Unlock the potential of immersive language learning by combining <br />
                  YouTube's endless content with the power of ChatGPT
                </Text>
              </Stack>
              <Button
                  variant={'contained'}
                  sx={googleButtonStyle}
                  onClick={onLoginClick}
                  startIcon={<GoogleIcon />}
              >
                <Typography fontWeight={600} variant={'h6'} sx={{ width: 1 }}>Sign in with Google</Typography>
              </Button>
              {loginError &&
                  <Text fontWeight={600} sx={errorStyle}>
                    {loginError}
                  </Text>
              }

            </Stack>
          </Stack>

          <Stack direction={'row'} sx={featuresStyle}>
            {LOGIN_FEATURES.map(feature => (
                <LoginFeature key={feature.imgUrl} title={feature.title} imgUrl={feature.imgUrl} icon={feature.icon} />
            ))}
          </Stack>
        </Stack>
  );
}
