import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { InstallLogin } from '../../components/InstallLogin';
import { IInstallLoginEvents, IInstallLoginFields } from '../../components/types';
import { InstallLoginEvents } from './events';


const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IInstallLoginFields => {
    return {}
  };
}

const mapDispatchToProps = (
  dispatch: any
): IInstallLoginEvents => ({
    onDidMount: () => dispatch(InstallLoginEvents.onDidMount()),
    onLogin: (targetLang, nativeLang) => dispatch(InstallLoginEvents.onLogin())
  }
);

export const InstallLoginHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallLogin);

InstallLoginHOC.displayName = 'InstallLoginHOC';