import {
  EShortcutsListenerContainer,
  getShortcutsListenerContainer,
  showShortcutsListenerContainer
} from '../shortcutsListener';
import { EKeyEventContainer, ESystemHotKeys, IShortcutHandler } from './types';
import { CaptionsShortcutHandler } from './handlers/captionsShortcutHandler';
import { PhrasesShortcutHandler } from './handlers/phrasesShortcutHandler';

export class ShortcutManager {

  private static captionsHandler: IShortcutHandler = new CaptionsShortcutHandler();
  private static phrasesHandler: IShortcutHandler = new PhrasesShortcutHandler();

  private static disableTargetInputs = ['input', 'textarea'];

  public static keyDown(event: KeyboardEvent) {
    if (!ShortcutManager.checkHandleHotKey(event)) return;

    const keyCode = event.code as ESystemHotKeys;
    if (ESystemHotKeys.SPACE === keyCode) {
      event.preventDefault();
    }
  }

  public static keyUp(event: KeyboardEvent) {
    if (!ShortcutManager.checkHandleHotKey(event)) return;

    const keyCode = event.code as ESystemHotKeys;
    const systemHotKeys = Object.values(ESystemHotKeys);
    if (systemHotKeys.includes(keyCode)) {
      showShortcutsListenerContainer();
    }
    ShortcutManager.getHandler().handle(keyCode);
  }

  private static checkHandleHotKey(event: KeyboardEvent): boolean {
    const element = event.target as HTMLElement;
    const isInput = ShortcutManager.disableTargetInputs.includes(element.tagName.toLowerCase());
    if (isInput) return false;

    const isContenteditable = element.hasAttribute('contenteditable');
    if (isContenteditable) return false;

    const dataNohotkey = element.dataset?.nohotkey;
    if (dataNohotkey) return false;
    
    return true;
  }

  private static getHandler(): IShortcutHandler {
    return getShortcutsListenerContainer() === EShortcutsListenerContainer.PHRASES ? ShortcutManager.phrasesHandler : ShortcutManager.captionsHandler;
  }
}


