import { IState } from '../../../../../store/types';
import { getMainLayoutType, getMainViewMode } from '../../../../../store/general/selectors';
import { connect } from 'react-redux';
import { TopBarPanel } from '../components/ToolbarPanel';
import { IToolbarPanelEvents, IToolbarPanelFields } from '../components/types';
import { TopBarPanelEvents } from './events';
import { ITopBarPanelHOCProps } from './types';
import { getCurrentMovieKey } from '../../../../../store/current-video/selectors';
import { PhraseDetailsSelectors } from '../../../../../store/phrase-details/selectors';
import { EPhraseDetailsTabType } from '../../../../../store/models/types';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState,
    { isWide }: ITopBarPanelHOCProps,
  ): IToolbarPanelFields => {
    return {
      isCanHidePanel: !!getCurrentMovieKey(state),
      isWide,
      mainLayoutType: getMainLayoutType(state),
      mainViewMode: getMainViewMode(state),
      phraseDetailsTabType: PhraseDetailsSelectors.getActiveTab(state)?.type || EPhraseDetailsTabType.TRANSLATION,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IToolbarPanelEvents => ({
    onClickBack: (isMobile) => dispatch(TopBarPanelEvents.onClickBack(isMobile)),
    onClickLogo: () => dispatch(TopBarPanelEvents.onClickLogo()),
    onLeaveFeedback: () => dispatch(TopBarPanelEvents.onLeaveFeedback()),
    onHideLeftPanelToggle: () => dispatch(TopBarPanelEvents.onHideLeftPanelToggle()),
    onLayoutToggle: () => dispatch(TopBarPanelEvents.onLayoutToggle()),
    onClickHelp: () => dispatch(TopBarPanelEvents.onClickHelp()),
  }
);

export const TopBarPanelHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBarPanel);

TopBarPanelHOC.displayName = 'TopBarPanelHOC';