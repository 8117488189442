import { AppThunk } from '../../../../../store/types';
import {
	setOneTimeTooltipNativeLanguageAction,
	setVideoTutorialModeAction
} from '../../../../../store/general/actions';
import { getOneTimeTooltipsStorage } from '../../../../../../common/utils/local-storage/onetime-tooltips';
import { EVideoTutorialType } from '../../../../../store/models/types';
import { UserSettingEffects } from '../../../../../effects/userSettingEffects';

export class OneTimeTooltipNativeLanguageEvents {

	public static onClose(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			UserSettingEffects.setShowNativeCaptions(false);

			getOneTimeTooltipsStorage().setShowNativeLanguage(false);
			dispatch(setOneTimeTooltipNativeLanguageAction(false));
		}
	}

	public static onClickClose(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			UserSettingEffects.setShowNativeCaptions(false);
			dispatch(setOneTimeTooltipNativeLanguageAction(false));
		}
	}

	public static onClickHelp(): AppThunk {
		return (
			dispatch,
			getState
		) => {
			dispatch(setVideoTutorialModeAction(EVideoTutorialType.NATIVE_LANGUAGE));
		}
	}

}