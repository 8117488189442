import { TPublicProfile } from '../general/types';
import { TTeacherPhraseNoteGroupInfo, TUserProfileGroup } from '../../../common/rest/user/userProfileRest';

export const PUBLIC_PROFILE_SET_ACTION = 'PUBLIC_PROFILE/PUBLIC_PROFILE_SET_ACTION';
export const PUBLIC_PROFILE_CLEAR_ACTION = 'PROFILE_LIST/PUBLIC_PROFILE_CLEAR_ACTION';

export interface IPublicProfileSetAction {
  type: typeof PUBLIC_PROFILE_SET_ACTION;
  data: Partial<IPublicProfilesState>
}

export interface IPublicProfileClearAction {
  type: typeof PUBLIC_PROFILE_CLEAR_ACTION;
}

export interface IPublicProfilesState {
  loading: boolean,
  profile?: TPublicProfile,
  groups: TUserProfileGroup[],
  notes: TTeacherPhraseNoteGroupInfo[]
}

export type TPublicProfileActions =
  | IPublicProfileSetAction
  | IPublicProfileClearAction