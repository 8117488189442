import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { MenuItem, Select } from "@mui/material";

export const VideoLibraryHeaderEmptyList: React.FC = () => {

  const selectRef = React.useRef<HTMLElement | null>(null);

  const handleClose = () => {
    if (selectRef.current) {
      const selectList = selectRef.current?.querySelector('.MuiSelect-select') as HTMLElement;
      if (selectList) setTimeout(() => selectList.blur(), 0);
    };
  }

  return (
    <Select
      ref={selectRef}
      value={''}
      onClose={handleClose}
      variant={'filled'}
      sx={{
        flex: `1 1 100%`,
      }}
      displayEmpty
    >
      <MenuItem value={''}>You have no playlists yet</MenuItem>
    </Select>
  );
}
