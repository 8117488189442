import { getAuthUser } from '../../../spa/store/general/selectors';
import { getState } from '../../../spa/store';
import { SyncStorage } from './local-storage-helpers';

export type TUserStateStorage = {
  libraryGroupId?: number;
  groupId?: number;
  videoId?: string;
  phraseId?: number;
}

export class UserStateStorage extends SyncStorage {

  default: TUserStateStorage;

  constructor(fold: TUserStateStorage) {
    super();
    this.default = fold;
  }

  private getStorageKey() {
    const user = getAuthUser(getState());
    return 'user-state' + (user ? '-' + user.hashId : '');
  }

  getData(): TUserStateStorage {
    return this.getItem(this.getStorageKey(), this.default);
  }
  saveData(value: TUserStateStorage): TUserStateStorage {
    this.saveItem(this.getStorageKey(), value);
    return value;
  }

  public setLibraryGroupId(libraryGroupId: number) {
    const data: TUserStateStorage = {
      ...this.getData(),
      ...{libraryGroupId}
    }
    this.saveData(data);
  }

  public setVideoInfo(videoId: string, groupId: number, phraseId: number) {
    const data: TUserStateStorage = {
      ...this.getData(),
      ...{videoId, groupId, phraseId, videoPosition: 0}
    }
    this.saveData(data);
  }

  public clear() {
    this.saveData({});
  }

}

const userStateStorage = new UserStateStorage({});
export const getUserStateStorage = (): UserStateStorage => {
  return userStateStorage;
}