import { ICaptionsItem } from '../../../../types/common';
import { PlayerManager } from '../playerManager';
import { getTargetCaptions } from '../../../../store/videos/selectors';
import { getState } from '../../../../store';
import { EPlayerControllerMode, PlayerController } from '../playerController';
import { getAutoPause } from '../../../../store/general/selectors';
import { Pause } from '../../../../types/pause-constants';

export class PlayCaptionHandler {

  private startCaptionIndex: number;

  public async play(caption: ICaptionsItem) {
    const playerController = PlayerController.getInstance();
    const playerManager = PlayerManager.getInstance()
    playerController.reset();

    await playerManager.pausePlay();

    const captions = getTargetCaptions(getState());
    this.startCaptionIndex = this.getCaptionIndex(captions, caption);
    playerController.setMode(EPlayerControllerMode.PLAY_CAPTION);
    await playerManager.startPlay(caption.startTime);
  }

  public onTargetIndexChange(index: number): void {
    if (this.startCaptionIndex === index) return;
    const pause = getAutoPause(getState());
    if (pause === Pause.NoPauseValue) return;
    PlayerManager.getInstance().pausePlay();
    if (pause !== Pause.FullStopValue) {
      PlayerController.getInstance().startWaitTimer(pause * 1000);
    }
  }

  private getCaptionIndex(captions: ICaptionsItem[], caption: ICaptionsItem) {
    return captions.findIndex(c => c.startTime === caption.startTime);
  }

}