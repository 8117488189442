import {API_URL} from "../../constants";
import {TAsyncThunk} from "../../../spa/store/types";
import {getRequest} from "../../utils/requestService";
import {TVideo} from "../../../spa/store/models/types";


const URL = API_URL + '/phrases/exist';

export interface IRestFetchUserPhrasesExist extends TVideo {
    exist: boolean;
}

export const fetchUserPhrasesExist = (): TAsyncThunk => async(
    dispatch
): Promise<boolean> => {
    const result: IRestFetchUserPhrasesExist = await dispatch(getRequest(URL));
    return result && result.exist;
};
