import React from 'react';
import { ILandingProps } from './types';
import { Button, Stack, Typography } from '@mui/material';
import { Text } from '../../../../../common/components/Text/Text';
import { theme } from '../../../../../common/styles/theme';
import { GoogleIcon } from '../../../../../../icons/GoogleIcon';
import { LANDING_TEXT1, LANDING_TEXT2, LANDING_TEXT3, LOGIN_FEATURES } from './constants';
import background from '../../Login/components/imgs/background.svg';
import { LogoLabel } from '../../../../../../icons/LogoLabel';
import { LoginFeature } from '../../Login/components/LoginFeature';

export const Landing: React.FC<ILandingProps> = ({
	onDidMount,
	onLogin,
}) => {

	const [loginError, setLoginError] = React.useState<string | null>(null);

	const [targetLang, setTargetLang] = React.useState<string | null>(null);
	const [nativeLang, setNativeLang] = React.useState<string | null>(null);
	const [path, setPath] = React.useState<string | null>(null);

	const onLoginClick = async () => {
		const success = await onLogin(targetLang, nativeLang, path);
		setLoginError(success ? null : 'Login error');
	}

	React.useEffect(() => {
		if (onDidMount) onDidMount();
		const urlParams = new URLSearchParams(window.location.search);
		setTargetLang(urlParams.get('targetlangauge'));
		setNativeLang(urlParams.get('nativelanguage'));
		setPath(window.location.pathname);
	}, []);

	return (
		<Stack sx={{
			flexGrow: 1,
			p: theme.spacing(6),
			alignItems: 'center',
			justifyContent: 'center',
			gap: theme.spacing(8),
			bgcolor: theme.palette.common.white,

			[theme.breakpoints.down('md')]: {
				p: theme.spacing(3),
			}
		}}>
			<Stack
				component={'img'}
				src={background}
				sx={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100vw',
					minHeight: '100vh',
					objectFit: 'cover',
					objectPosition: 'center',
				}}
			/>
			<Stack sx={{
				alignItems: 'center',
				gap: theme.spacing(10),
				zIndex: 10,
				[theme.breakpoints.down('md')]: {
					gap: theme.spacing(6),
				}
			}}>
				<LogoLabel
					sx={{
						width: theme.spacing(30),
						height: 'auto',
					}}
				/>
				<Stack sx={{
					position: 'relative',
					alignItems: 'center',
					gap: theme.spacing(5)
				}}>
					<Stack
						alignItems={'center'}
					>
						<Text
							sx={{
								textAlign: 'center',
								fontSize: theme.typography.h1,
								fontWeight: 700,

								[theme.breakpoints.down('md')]: {
									fontSize: theme.typography.h2,
									fontWeight: 700,
								}
							}}
						>
							{LANDING_TEXT1}
						</Text>
						<Text
							sx={{
								mt: 1,
								textAlign: 'center',
								fontSize: theme.typography.h2,
								fontWeight: 400,

								[theme.breakpoints.down('md')]: {
									fontSize: theme.typography.h3,
								}
							}}
						>
							{LANDING_TEXT2}
						</Text>
						<Text
							sx={{
								mt: 5,
								maxWidth: theme.spacing(80),
								textAlign: 'center',
								fontSize: theme.typography.h5,
								color: theme.palette.text.secondary,
								lineHeight: 1.6,
							}}
						>
							{LANDING_TEXT3}
						</Text>
					</Stack>
					<Button
						variant={'contained'}
						sx={{
							height: theme.spacing(8),
							paddingLeft: theme.spacing(2),
							paddingRight: theme.spacing(4),
							bgcolor: theme.palette.common.white,
							color: theme.palette.common.black,
							border: '2px solid #fafafa',
							borderRadius: '56px',
							transition: 'opacity 0.15s ease',
							opacity: 1,
							boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
							'&:hover': {
								backgroundColor: theme.palette.common.white,
								opacity: 0.7,
								boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
							},
							'.MuiButton-startIcon': {
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: 1,
								minWidth: theme.spacing(7),
								m: 0,
							},
						}}
						onClick={onLoginClick}
						startIcon={<GoogleIcon />}
					>
						<Typography fontWeight={600} variant={'h6'} sx={{ width: 1 }}>Sign in with Google</Typography>
					</Button>
					{loginError &&
						<Text
							fontWeight={600}
							sx={{
								position: 'absolute',
								bottom: theme.spacing(-5),
								color: theme.palette.error.main,
								p: theme.spacing(1.5),
							}}
						>
							{loginError}
						</Text>
					}

				</Stack>
			</Stack>

			<Stack
				direction={'row'}
				sx={{
					display: 'grid',
					gridTemplateColumns: `repeat(4, 1fr)`,
					width: '100%',
					maxWidth: '1280px',
					gap: theme.spacing(4),
					
					'.login-feature__img img': {
						borderRadius: theme.spacing(1.5),
					},

					[theme.breakpoints.down('lg')]: {
						gridTemplateColumns: `repeat(2, 1fr)`,
					},
					[theme.breakpoints.down('sm')]: {
						gridTemplateColumns: '1fr',
					},
				}}
			>
				{LOGIN_FEATURES.map(feature => (
					<LoginFeature
						key={feature.imgUrl}
						title={feature.title}
						imgUrl={feature.imgUrl}
					/>
				))}
			</Stack>
		</Stack>
	);
}
