import { connect } from 'react-redux';
import { VideoLeftColumn } from '../components/VideoLeftColumn';
import { IVideoLeftColumnEvents } from '../components/types';
import { VideoEvents } from './events';

const mapDispatchToProps = (
  dispatch: any
): IVideoLeftColumnEvents => ({
    onInit: () => dispatch(VideoEvents.onInit()),
  }
);

export const VideoLeftColumnHOC = connect(
  null,
  mapDispatchToProps
)(VideoLeftColumn);

VideoLeftColumnHOC.displayName = 'VideoLeftColumnHOC';
