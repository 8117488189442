import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { IInstallSelectLangsEvents, IInstallSelectLangsFields, userLevelItems } from '../components/types';
import { InstallSelectLangs } from '../components/InstallSelectLangs';
import { InstallSelectLangsEvents } from './events';
import { getNativeLanguage, getTargetLanguage, getUserRoles } from '../../../../store/models/selectors';

const getDefaultLangCode = () => {
  let lang = navigator.language || '';
  const ps = lang.indexOf('-');
  if (ps > 0) {
    lang = lang.substr(0, ps);
  }
  return lang;
}

const mapStateToProps = (
  dispatch: any
) => {
  const defaultNativeCode = getDefaultLangCode();
  return (
    state: IState
  ): IInstallSelectLangsFields => {
    let nativeLang = getNativeLanguage(state);
    const targetLang = getTargetLanguage(state);
    return {
      nativeCode: nativeLang ? nativeLang.code : defaultNativeCode || '',
      targetCode: targetLang ? targetLang.code : '',
      userRoles: getUserRoles(state),
      userLevelItems,
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IInstallSelectLangsEvents => ({
    onSave: (nativeLangCode, targetLangCode, roles, level) =>
      dispatch(InstallSelectLangsEvents.onSave(nativeLangCode, targetLangCode, roles, level)),
    onLoad: () => dispatch(InstallSelectLangsEvents.onLoad())
  }
);

export const InstallSelectLangsHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallSelectLangs);

InstallSelectLangsHOC.displayName = 'InstallSelectLangsHOC';