import { IState } from '../../../../../store/types';
import { ContentLibSelectors } from '../../../../../store/contentLib/selectors';
import { connect } from 'react-redux';
import { ContentLibVideoPopup } from '../components/ContentLibVideoPopup';
import { IContentLibVideoPopupEvents, IContentLibVideoPopupFields } from '../components/types';
import { ContentLibVideoPopupEvents } from './events';
import { findLanguageById, getUserGroupsByLangs } from '../../../../../store/models/selectors';
import { isAuthorised } from '../../../../../store/general/selectors';

const mapStateToProps = (
) => {
  return (
    state: IState
  ): IContentLibVideoPopupFields => {
    let {groupId, videoId, video} = ContentLibSelectors.getVideoPopup(state);
    if (!video || videoId !== video.id) {
      video = ContentLibSelectors.findVideo(state, groupId, videoId);
    }
    //const video = ContentLibSelectors.findVideo(state, groupId, videoId);
    const group = ContentLibSelectors.findGroupById(state, groupId);
    const targetLangCode = findLanguageById(state, group?.targetLangId || 0)?.code || '';
    const nativeLangCode = findLanguageById(state, group?.nativeLangId || 0)?.code || '';
    const groups = getUserGroupsByLangs(state, targetLangCode, nativeLangCode);
    const thumbUrl = video?.youtubeThumbStandardUrl || video?.youtubeThumbUrl || '';
    return {
      isOpen: !!video,
      title: video?.title || '',
      listTitle: group?.title || '',
      channel:  video?.youtubeChannel || '',
      thumbUrl,
      groups,
      targetLang: targetLangCode,
      nativeLang: nativeLangCode,
      url: video?.url || '',
      canSave: isAuthorised(state)
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IContentLibVideoPopupEvents => ({
    onClose: () => dispatch(ContentLibVideoPopupEvents.onClose()),
    onSave: targetGroupId => dispatch(ContentLibVideoPopupEvents.onSave(targetGroupId)),
  }
);

export const ContentLibVideoPopupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentLibVideoPopup);

ContentLibVideoPopupHOC.displayName = 'ContentLibVideoPopupHOC';