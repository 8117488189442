import React from 'react';
import { theme } from '../../../../../../common/styles/theme';
import { PlusIcon } from '../../../../../../../icons/PlusIcon';
import { IAddVideoButtonProps } from './types';
import { Button } from "@mui/material";
import { CLASS_ONBOARDING_HIDE } from '../../../Onboarding/constants';

export const AddVideoButton: React.FC<IAddVideoButtonProps> = ({
  show,
  onClick
}) => {

  if (!show) return null;

  return (
    <Button
      className={CLASS_ONBOARDING_HIDE}
      variant={'contained'}
      startIcon={<PlusIcon sx={{ width: theme.spacing(3), height: theme.spacing(3) }} />}
      onClick={onClick}
    >
      Add Video
    </Button>
  );
};
