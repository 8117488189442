import { AppThunk } from '../../../../../../store/types';
import { UserTranslateLangEffects } from '../../../../../../effects/userTranslateLangEffects';

export class PhraseDetailsTabTranslationLangEvents {

  public static onDelete(langCode: string): AppThunk {
    return (
      dispatch,
      getState
    ) => {
      UserTranslateLangEffects.deleteLang(langCode);
    }
  }

}