import { TUserTranslation } from '../store/models/types';
import { UserTranslationRest } from '../../common/rest/userTranslation/userTranslationRest';
import { IPhraseSelectResult } from './phrase/utils/phrase-select-preparator';
import { getCurrentMovieKey } from '../store/current-video/selectors';
import { getActiveGroupId } from '../store/models/selectors';
import { getTargetCaptions } from '../store/videos/selectors';
import { ICaptionsItem } from '../types/common';
import { getState } from '../store';
import { getAppSettings } from '../store/general/selectors';

export class UserTranslateEffects {

  public static async save(selectResult: IPhraseSelectResult): Promise<any> {
    const state = getState();

    if (!getAppSettings(state).saveUserTranslations) return;

    const videoKey = getCurrentMovieKey(state);
    const userGroupId = getActiveGroupId(state);
    const targetCaption = getTargetCaptions(state);
    const captions: ICaptionsItem[] = [];
    for(let i=selectResult.startCaptionIndex; i<=selectResult.endCaptionIndex; i++) {
      if (i < targetCaption.length) {
        captions.push(targetCaption[i]);
      }
    }
    const fullText = captions.map(c => c.text).join('\n');

    if (selectResult && videoKey && userGroupId && captions.length) {
      const userTranslation: TUserTranslation = {
        text: selectResult.text,
        fullText,
        startTime: selectResult.startTime,
        endTime: selectResult.endTime,
        startPosition: selectResult.startPosition,
        endPosition: selectResult.endPosition,
        startCaptionIndex: selectResult.startCaptionIndex,
        endCaptionIndex: selectResult.endCaptionIndex,
        videoKey,
        userGroupId
      };
      return UserTranslationRest.save(userTranslation);
    }
  }
}