const LANG_CODE_TITLES: Record<string, string> = {
  ptbr: 'PT(BR)'
}

export const getLangCodeTitle = (code: string): string => {
  return LANG_CODE_TITLES[code] || code;
}

export const LANGUAGE_TO_COUNTRY_CODE: Record<string, string> = {
  ab: 'GE', // Abkhazian -> Georgia
  aa: 'ET', // Afar -> Ethiopia
  af: 'ZA', // Afrikaans -> South Africa
  ak: 'GH', // Akan -> Ghana
  sq: 'AL', // Albanian -> Albania
  am: 'ET', // Amharic -> Ethiopia
  ar: 'SA', // Arabic -> Saudi Arabia
  an: 'ES', // Aragonese -> Spain
  hy: 'AM', // Armenian -> Armenia
  as: 'IN', // Assamese -> India
  av: 'RU', // Avaric -> Russian Federation
  ae: 'undefined',
  ay: 'BO', // Aymara -> Bolivia
  az: 'AZ', // Azerbaijani -> Azerbaijan
  bm: 'ML', // Bambara -> Mali
  ba: 'RU', // Bashkir -> Russian Federation
  eu: 'ES', // Basque -> Spain
  be: 'BY', // Belarusian -> Belarus
  bn: 'BD', // Bengali -> Bangladesh
  bi: 'VU', // Bislama -> Vanuatu
  bs: 'BA', // Bosnian -> Bosnia and Herzegovina
  br: 'FR', // Breton -> France
  bg: 'BG', // Bulgarian -> Bulgaria
  my: 'MM', // Burmese -> Myanmar
  ca: 'ES', // Catalan -> Spain
  ch: 'GU', // Chamorro -> Guam
  ce: 'RU', // Chechen -> Russian Federation
  ny: 'MW', // Chichewa -> Malawi
  zh: 'CN', // Chinese -> China
  cu: 'undefined',
  cv: 'RU', // Chuvash -> Russian Federation
  kw: 'GB', // Cornish -> United Kingdom
  co: 'FR', // Corsican -> France
  cr: 'CA', // Cree -> Canada
  hr: 'HR', // Croatian -> Croatia
  cs: 'CZ', // Czech -> Czechia
  da: 'DK', // Danish -> Denmark
  dv: 'MV', // Divehi -> Maldives
  nl: 'NL', // Dutch -> Netherlands
  dz: 'BT', // Dzongkha -> Bhutan
  en: 'GB', // English -> United Kingdom
  eo: 'undefined',
  et: 'EE', // Estonian -> Estonia
  ee: 'GH', // Ewe -> Ghana
  fo: 'FO', // Faroese -> Faroe Islands
  fj: 'FJ', // Fijian -> Fiji
  fi: 'FI', // Finnish -> Finland
  fr: 'FR', // French -> France
  fy: 'NL', // Western Frisian -> Netherlands
  ff: 'SN', // Fulah -> Senegal
  gd: 'GB', // Gaelic -> United Kingdom
  gl: 'ES', // Galician -> Spain
  lg: 'UG', // Ganda -> Uganda
  ka: 'GE', // Georgian -> Georgia
  de: 'DE', // German -> Germany
  el: 'GR', // Greek -> Greece
  kl: 'GL', // Kalaallisut -> Greenland
  gn: 'PY', // Guarani -> Paraguay
  gu: 'IN', // Gujarati -> India
  ht: 'HT', // Haitian -> Haiti
  ha: 'NG', // Hausa -> Nigeria
  he: 'IL', // Hebrew -> Israel
  hz: 'undefined',
  hi: 'IN', // Hindi -> India
  ho: 'undefined',
  hu: 'HU', // Hungarian -> Hungary
  is: 'IS', // Icelandic -> Iceland
  io: 'undefined',
  ig: 'NG', // Igbo -> Nigeria
  id: 'ID', // Indonesian -> Indonesia
  ia: 'undefined',
  ie: 'undefined',
  iu: 'CA', // Inuktitut -> Canada
  ik: 'undefined',
  ga: 'IE', // Irish -> Ireland
  it: 'IT', // Italian -> Italy
  ja: 'JP', // Japanese -> Japan
  jv: 'ID', // Javanese -> Indonesia
  kn: 'IN', // Kannada -> India
  kr: 'NE', // Kanuri -> Niger
  ks: 'IN', // Kashmiri -> India
  kk: 'KZ', // Kazakh -> Kazakhstan
  km: 'KH', // Central Khmer -> Cambodia
  ki: 'KE', // Kikuyu -> Kenya
  rw: 'RW', // Kinyarwanda -> Rwanda
  ky: 'KG', // Kirghiz -> Kyrgyzstan
  kv: 'RU', // Komi -> Russian Federation
  kg: 'CD', // Kongo -> Congo, Democratic Republic of
  ko: 'KR', // Korean -> Korea, Republic of
  kj: 'undefined',
  ku: 'undefined',
  lo: 'LA', // Lao -> Lao People's Democratic Republic
  la: 'undefined',
  lv: 'LV', // Latvian -> Latvia
  li: 'NL', // Limburgan -> Netherlands
  ln: 'CG', // Lingala -> Congo
  lt: 'LT', // Lithuanian -> Lithuania
  lu: 'CD', // Luba-Katanga -> Congo, Democratic Republic of
  lb: 'LU', // Luxembourgish -> Luxembourg
  mk: 'MK', // Macedonian -> North Macedonia
  mg: 'MG', // Malagasy -> Madagascar
  ms: 'MY', // Malay -> Malaysia
  ml: 'IN', // Malayalam -> India
  mt: 'MT', // Maltese -> Malta
  gv: 'GB', // Manx -> United Kingdom
  mi: 'NZ', // Maori -> New Zealand
  mr: 'IN', // Marathi -> India
  mh: 'MH', // Marshallese -> Marshall Islands
  mn: 'MN', // Mongolian -> Mongolia
  na: 'NR', // Nauru -> Nauru
  nv: 'US', // Navajo -> United States
  nd: 'ZW', // North Ndebele -> Zimbabwe
  nr: 'ZA', // South Ndebele -> South Africa
  ng: 'NA', // Ndonga -> Namibia
  ne: 'NP', // Nepali -> Nepal
  no: 'NO', // Norwegian -> Norway
  nb: 'NO', // Norwegian Bokmål -> Norway
  nn: 'NO', // Norwegian Nynorsk -> Norway
  ii: 'CN', // Sichuan Yi -> China
  oc: 'FR', // Occitan -> France
  oj: 'CA', // Ojibwa -> Canada
  or: 'IN', // Oriya -> India
  om: 'ET', // Oromo -> Ethiopia
  os: 'GE', // Ossetian -> Georgia
  pi: 'undefined',
  ps: 'AF', // Pashto -> Afghanistan
  fa: 'IR', // Persian -> Iran
  pl: 'PL', // Polish -> Poland
  pt: 'PT', // Portuguese -> Portugal
  ptbr: 'PT', // Portuguese -> Portugal
  pa: 'IN', // Punjabi -> India
  qu: 'PE', // Quechua -> Peru
  ro: 'RO', // Romanian -> Romania
  rm: 'CH', // Romansh -> Switzerland
  rn: 'BI', // Rundi -> Burundi
  ru: 'RU', // Russian -> Russian Federation
  se: 'NO', // Northern Sami -> Norway
  sm: 'WS', // Samoan -> Samoa
  sg: 'CF', // Sango -> Central African Republic
  sa: 'IN', // Sanskrit -> India
  sc: 'IT', // Sardinian -> Italy
  sr: 'RS', // Serbian -> Serbia
  sn: 'ZW', // Shona -> Zimbabwe
  sd: 'PK', // Sindhi -> Pakistan
  si: 'LK', // Sinhala -> Sri Lanka
  sk: 'SK', // Slovak -> Slovakia
  sl: 'SI', // Slovenian -> Slovenia
  so: 'SO', // Somali -> Somalia
  st: 'LS', // Southern Sotho -> Lesotho
  es: 'ES', // Spanish -> Spain
  su: 'ID', // Sundanese -> Indonesia
  sw: 'TZ', // Swahili -> Tanzania
  ss: 'SZ', // Swati -> Eswatini
  sv: 'SE', // Swedish -> Sweden
  tl: 'PH', // Tagalog -> Philippines
  ty: 'PF', // Tahitian -> French Polynesia
  tg: 'TJ', // Tajik -> Tajikistan
  ta: 'IN', // Tamil -> India
  tt: 'RU', // Tatar -> Russian Federation
  te: 'IN', // Telugu -> India
  th: 'TH', // Thai -> Thailand
  bo: 'CN', // Tibetan -> China
  ti: 'ET', // Tigrinya -> Ethiopia
  to: 'TO', // Tonga -> Tonga
  ts: 'ZA', // Tsonga -> South Africa
  tn: 'BW', // Tswana -> Botswana
  tr: 'TR', // Turkish -> Türkiye
  tk: 'TM', // Turkmen -> Turkmenistan
  tw: 'GH', // Twi -> Ghana
  ug: 'CN', // Uighur -> China
  uk: 'UA', // Ukrainian -> Ukraine
  ur: 'PK', // Urdu -> Pakistan
  uz: 'UZ', // Uzbek -> Uzbekistan
  ve: 'ZA', // Venda -> South Africa
  vi: 'VN', // Vietnamese -> Vietnam
  vo: 'undefined',
  wa: 'BE', // Walloon -> Belgium
  cy: 'GB', // Welsh -> United Kingdom
  wo: 'SN', // Wolof -> Senegal
  xh: 'ZA', // Xhosa -> South Africa
  yi: 'undefined',
  yo: 'NG', // Yoruba -> Nigeria
  za: 'CN', // Zhuang -> China
  zu: 'ZA' // Zulu -> South Africa
};
