import { IState } from '../../../../store/types';
import { getActiveGroupId, getAudioById } from '../../../../store/models/selectors';
import { getMainLayoutType } from '../../../../store/general/selectors';
import { connect } from 'react-redux';
import { Audio } from '../components/Audio';
import { IAudioEvents, IAudioFields } from '../components/types';
import { AudioEvents } from './events';
import { getCurrentAudioId } from '../../../../store/current-video/selectors';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IAudioFields => {
    const audioId = getCurrentAudioId(state);
    const audio = audioId ? getAudioById(state, audioId) : null;
    return {
      activeGroupId: getActiveGroupId(state) || 0,
      layoutType: getMainLayoutType(state),
      audioUrl: audio?.url
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IAudioEvents => ({
    onInit: () => dispatch(AudioEvents.onInit()),
    onLoad: (audioId, groupId) => dispatch(AudioEvents.onLoad(audioId, groupId)),
    onUpdateAudioPlayer: player => dispatch(AudioEvents.onUpdateAudioPlayer(player))
  }
);

export const AudioHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(Audio);

AudioHOC.displayName = 'AudioHOC';
