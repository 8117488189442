import * as React from 'react';
import { ICaptionsProps } from '../../../CaptionsContainer/Captions/components/types';
import {
  CLASS_ONBOARDING_ACTIVE,
  CLASS_ONBOARDING_NOEVENT,
  CLASS_ONBOARDING_TRIGGER
} from '../../../Onboarding/constants';
import { PlayerStates } from '../../../../../types/common';
import { Paper } from '../../../../../../common/components/Paper/Paper';
import { theme } from '../../../../../../common/styles/theme';
import { EmptyCaptions } from '../../../CaptionsContainer/EmptyCaptions/EmptyCaptions';
import { TextSelectionPopupHOC } from '../../../TextSelectionPopup/hocs/TextSelectionPopupHOC';
import { Box, IconButton, Stack } from '@mui/material';
import { SettingsIcon } from '../../../../../../../icons/SettingsIcon';
import { OneTimeTooltipNativeAvailableHOC } from '../../../OneTimeTooltip/hocs/OneTimeTooltipNativeAvailableHOC/OneTimeTooltipNativeAvailableHOC';
import { PlaybackSettingsHOC } from '../../../CaptionsContainer/PlaybackSettings/hocs/PlaybackSettingsHOC';
import { TargetAudioCaptionsHOC } from '../../TargetCaptions/hocs/TargetAudioCaptionsHOC';


export const AudioCaptionsContainer: React.FC<ICaptionsProps> = ({
                                                     playerState,
                                                     videoId,
                                                     groupId,
                                                     targetCaptions,
                                                     nativeCaptions,
                                                     targetLang,
                                                     nativeLang,
                                                     showNativeCaptions,
                                                     captionsEmpty,
                                                     groupNativeLangName,
                                                     groupTargetLangName,
                                                     onKeyDown,
                                                     onVideoChange,
                                                     onTargetCaptionsChange,
                                                     onNativeCaptionsChange
                                                   }) => {

  const ref = React.useRef<any>(null);
  const targetCaptionsContainer = React.useRef<HTMLDivElement | null>(null);
  const nativeCaptionsContainer = React.useRef<HTMLDivElement | null>(null);
  const [settingsButtonAnchor, setSettingsButtonAnchor] = React.useState<null | HTMLElement>(null);

  const [targetActiveCaptionIndex, setTargetActiveCaptionIndex] = React.useState<number>(0);
  const [targetTopScrolling, setTargetTopScrolling] = React.useState<boolean>(false);

  const targetMouseLeaveCaptionTimerRef = React.useRef<ReturnType<typeof setTimeout> | number>(0);


  const targetActiveCaptionIndexRef = React.useRef<number>(0);
  targetActiveCaptionIndexRef.current = targetActiveCaptionIndex;

  const handleKeyDown = () => {
    onKeyDown();
  }

  const scrollTaregetCaptionsTo = (captionElement: HTMLElement) => {
    if (document.body.classList.contains(CLASS_ONBOARDING_ACTIVE)) return;

    const containerElement = targetCaptionsContainer.current;
    if (containerElement) {
      setTargetTopScrolling(true);
      const offset = Math.round(containerElement.offsetHeight / 4);
      const scrollValue = captionElement.offsetTop - offset;
      containerElement.scrollTo({
        top: scrollValue,
        behavior: "smooth",
      })
      setTimeout(() => {
        setTargetTopScrolling(false);
      }, 1000)
    }
  }

  const calcItemTop = (heightList: number[], index: number): number => {
    let result = 0;
    for(let i=0; i<index; i++) {
      result += heightList[i];
    }
    return result;
  }


  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const getActiveCaptionItemElement = (): HTMLElement => {
    return document.getElementById('caption-item-' + targetActiveCaptionIndex) as HTMLElement;
  }

  React.useEffect(() => {
    if (PlayerStates.PLAYING === playerState) {
      const containerElement = targetCaptionsContainer.current;
      if (containerElement) {
        //const captionElement = containerElement.children[targetActiveCaptionIndex] as HTMLElement;
        const captionElement = getActiveCaptionItemElement();
        if (captionElement) {
          if (captionElement.offsetTop < containerElement.scrollTop) {
            scrollTaregetCaptionsTo(captionElement);
          }
        }
      }
    }
  }, [playerState]);

  React.useEffect(() => {
    onVideoChange();
  }, [videoId, groupId]);

  React.useEffect(() => {
    onTargetCaptionsChange();
  }, [targetCaptions]);

  React.useEffect(() => {
    onNativeCaptionsChange();
  }, [nativeCaptions]);



  const resetTargetMouseLeaveCaptionTimer = () => {
    if (targetMouseLeaveCaptionTimerRef.current) {
      clearTimeout(targetMouseLeaveCaptionTimerRef.current);
    }
    targetMouseLeaveCaptionTimerRef.current = 0;
  }

  const runTargetMouseLeaveCaptionTimer = () => {
    targetMouseLeaveCaptionTimerRef.current = setTimeout(() => {
      targetMouseLeaveCaptionTimerRef.current = 0;
    }, 1000);
  }

  const onTargetCaptionMouseEnter = (index: number) => {
    resetTargetMouseLeaveCaptionTimer();
  }
  const onTargetCaptionMouseLeave = (index: number) => {
    runTargetMouseLeaveCaptionTimer();
  }

  /*const onTargetCaptionScrolling = (scroll: boolean) => {
    if (!targetTopScrolling && !scroll) {
      onTargetCaptionsScroll();
    }
  }*/

  const handleSettingsClick = (e: React.MouseEvent<HTMLElement>) => {
    setSettingsButtonAnchor(e.currentTarget);
  }

  const handleSettingsClose = () => {
    setSettingsButtonAnchor(null);
  }

  const onboardingHighlight = () => {
    let selected = ref.current && ref.current.querySelector('.selected-onboarding') as HTMLElement;
    if (ref.current && ref.current.querySelector('.highlighted-span') ||
      !document.querySelector('.onboarding-hint--step2')
    ) {
      if (selected) selected.remove();
    } else {
      const letters = !selected && ref.current && ref.current.querySelectorAll('.caption-item__letters') as HTMLElement[];
      if (selected || letters && letters.length) {
        if (!selected) {
          selected = document.createElement('span');
          selected.classList.add('selected-onboarding');
          let i = letters.length > 1 ? 1 : 0;
          letters[i].append(selected);
        }
        setTimeout(onboardingHighlight, 1000);
      }
    }
  }

  React.useEffect(() => {
    setTimeout(onboardingHighlight, 2000);
  }, [targetCaptions, videoId, groupId]);

  return (
    <Paper
      className={`shortcuts-listener shortcuts-listener--captions`}
  sx={{
    position: 'relative',
      display: 'flex',
      flex: '1 1 100%',
      minHeight: theme.spacing(18),

      '&.shortcuts-listener--active': {
      ':after': {
        content: '""',
          position: 'absolute',
          inset: '-6px',
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: '10px',
      }
    }
  }}
  ref={ref}
  >
  {captionsEmpty &&
  <EmptyCaptions targetLang={groupTargetLangName} nativeLang={groupNativeLangName} />
}

  {!captionsEmpty &&
  <>
    <TextSelectionPopupHOC/>

  <IconButton
    className={`${CLASS_ONBOARDING_NOEVENT} playback-settings-button`}
    color={'primary'}
    sx={{
    position: 'absolute',
      zIndex: 1000,
      right: theme.spacing(1),
      top: theme.spacing(1),
      background: theme.palette.common.white,
      borderRadius: '50%',
  }}
    onClick={handleSettingsClick}
    >
    <SettingsIcon/>
    </IconButton>

    <OneTimeTooltipNativeAvailableHOC />

    {settingsButtonAnchor &&
    <PlaybackSettingsHOC
      anchor={settingsButtonAnchor}
    onClose={handleSettingsClose}
    />
  }

    <Stack
      sx={{
    width: '100%',
      height: '100%',
  }}
    direction='row'
    >


    <TargetAudioCaptionsHOC
      elementRef={targetCaptionsContainer}
    onChangeActiveIndex={()=>{}}
    onCaptionMouseEnter={onTargetCaptionMouseEnter}
    onCaptionMouseLeave={onTargetCaptionMouseLeave}
    />






      <Box
      className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step2`}
      sx={{
      position: 'absolute',
        pointerEvents: 'none',
        left: 0,
        right: 0,
        top: 0,
        height: theme.spacing(20),
    }}
      />

      <Box
      className={`${CLASS_ONBOARDING_TRIGGER} ${CLASS_ONBOARDING_TRIGGER}--step2-highlight`}
      sx={{
      position: 'absolute',
        pointerEvents: 'none',
        left: theme.spacing(-.5),
        right: theme.spacing(-.5),
        top: theme.spacing(-.5),
        bottom: theme.spacing(-.5),
        borderRadius: theme.spacing(1),
    }}
      />

      </Stack>
      </>
    }



    </Paper>
  )

};
