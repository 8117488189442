import { API_URL } from '../../constants';
import { TAsyncThunk } from '../../../spa/store/types';
import { getRequest } from '../../utils/requestService';

const GET_NEW_FEATURES_URL = API_URL + '/newFeatures/';

export const getNewFeatures = (version: string): TAsyncThunk => (
  dispatch
): Promise<string[]> => {
  return dispatch(getRequest(GET_NEW_FEATURES_URL + version));
};
