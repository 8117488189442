import { IState } from '../../../../../store/types';
import { connect } from 'react-redux';
import { VideoLibraryGroup } from '../components/VideoLibraryGroup';
import { IVideoLibraryGroupEvents, IVideoLibraryGroupFields } from '../components/types';
import { IVideoLibraryGroupHOCOwnProps } from './types';
import { getUserGroupById } from '../../../../../store/models/selectors';
import { getCurrentMovieKey } from '../../../../../store/current-video/selectors';

const mapStateToProps = (
  dispatch: any,
  ownProps: IVideoLibraryGroupHOCOwnProps
) => {
  return (
    state: IState,
    {groupId}: IVideoLibraryGroupHOCOwnProps
  ): IVideoLibraryGroupFields => {
    const group = getUserGroupById(state, groupId);
    return {
      videoId: getCurrentMovieKey(state),
      groupId: group?.id || 0,
      videos: group?.videos || [],
      audios: group?.audios || []
    }
  };
}

const mapDispatchToProps = (
  dispatch: any,
  ownProps: IVideoLibraryGroupHOCOwnProps
): IVideoLibraryGroupEvents => ({
  }
);

export const VideoLibraryGroupHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoLibraryGroup);

VideoLibraryGroupHOC.displayName = 'VideoLibraryGroupHOC';