import { TTextModel } from './textPartHelperEffect';
import { EHighlightedType } from '../HighLightedText';

export class TextPartUtil {

  public static getTextParts = (text: string, highLighted: string, highlightedStartPos: number): TTextModel[] => {

    const _text = text
      .replace(/\n\r/gm, ' ')
      .replace(/[\n\r]/gm, ' ')
      .replace(/ +/gm, ' ').trim()
    const _highLighted = highLighted
      .replace(/\n\r/gm, ' ')
      .replace(/[\n\r]/gm, ' ')
      .replace(/ +/gm, ' ').trim();
    const ps = _text.indexOf(_highLighted, highlightedStartPos);
//    const ps = highlightedStartPos;
    if (ps < 0) {
      return [{ text: _text, type: EHighlightedType.Normal }]
    }
    const result: TTextModel[] = [];
    result.push( {text: _text.substr(0, ps), type: EHighlightedType.Normal});
    result.push( {text: _text.substr(ps, _highLighted.length), type: EHighlightedType.Highlighted} );
    result.push( {text: _text.substr(ps + _highLighted.length), type: EHighlightedType.Normal} );
    return result;
  }
}