import { ESystemHotKeys, IShortcutHandler } from '../types';
import { getDispatch, getState } from '../../../store';
import { PlayerController } from '../../player/manager/playerController';
import { PhraseListSelectors } from '../../../store/phrase-list/selectors';
import { PhraseListActions } from '../../../store/phrase-list/actions';
import { UserSettingEffects } from '../../userSettingEffects';

export class PhrasesShortcutHandler implements IShortcutHandler {

  public handle(keyCode: ESystemHotKeys): void {
    const dispatch = getDispatch();

    switch (keyCode) {
      case ESystemHotKeys.SPACE:
        PlayerController.getInstance().togglePlayPause();
        break;

      case ESystemHotKeys.Q:
        const pause = PhraseListSelectors.getPauseDelay(getState());
        UserSettingEffects.setPhraseListPauseDelay(pause ? 0 : 1);
        break;


      case ESystemHotKeys.S:
      case ESystemHotKeys.ArrowDown:
        PlayerController.getInstance().repeatPhrase();
        break;

      case ESystemHotKeys.A:
      case ESystemHotKeys.ArrowLeft:
        PlayerController.getInstance().playPrevPhrase();
        break;

      case ESystemHotKeys.D:
      case ESystemHotKeys.ArrowRight:
        PlayerController.getInstance().playNextPhrase();
        break;
    }

  }

}