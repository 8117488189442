import React from 'react';
import { IInstallSelectLangsProps } from './types';
import { theme } from '../../../../../common/styles/theme';
import { Button, FormGroup, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Text } from '../../../../../common/components/Text/Text';
import { EN_AUTO_GEN_LANG_CODE, EN_LANG_CODE, TLanguageCode } from '../../../../store/models/types';
import { LogoLabel } from '../../../../../../icons/LogoLabel';
import background from '../../Login/components/imgs/background.svg';
import { LangSelectHOC } from '../../../dashboard/LangSelect/hocs/LangSelectHOC';
import { InstallUserRoleCheckbox } from './InstallUserRoleCheckbox';
import { EUserLevel } from '../../../../store/general/types';

export const InstallSelectLangs: React.FC<IInstallSelectLangsProps> = ({
  nativeCode,
  targetCode,
  userRoles,
  userLevelItems,
  onLoad,
  onSave,
}) => {
  const [nativeLangCode, setNativeLangCode] = React.useState<string>('');
  const [targetLangCode, setTargetLangCode] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [saveProgress, setSaveProgress] = React.useState<boolean>(false);
  const [level, setLevel] = React.useState<EUserLevel>(EUserLevel.BEGINNER);

  React.useEffect(() => {
    onLoad();
  }, []);

  React.useEffect(() => {
    setNativeLangCode(nativeCode);
    setTargetLangCode(targetCode);
  }, [nativeCode, targetCode]);

  React.useEffect(() => {
    if (!nativeLangCode || !targetLangCode) return;
    if (isEqualLangCodes(nativeLangCode, targetLangCode)) {
      setError('Choose different languages');
    } else {
      setError('');
    }
  }, [nativeLangCode, targetLangCode]);

  const isEqualLangCodes = (code1: TLanguageCode, code2: TLanguageCode) => {
    return (
      code1 === code2 ||
      (code1 === EN_AUTO_GEN_LANG_CODE && code2 === EN_LANG_CODE) ||
      (code1 === EN_LANG_CODE && code2 === EN_AUTO_GEN_LANG_CODE)
    );
  };

  const handleSave = async () => {
    if (!nativeLangCode || !targetLangCode || error) return;
    setSaveProgress(true);
    await onSave(nativeLangCode, targetLangCode, checkedRoles, level);
  };

  const saveBtnTitle = saveProgress ? 'Please wait...' : 'Get started';

  const containerStyle = {
    position: 'relative',
    width: 1,
    flex: `1 1 100%`,
    bgcolor: theme.palette.secondary.main,
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
  };

  const popupStyle = {
    m: theme.spacing(2),
    p: theme.spacing(6),
    maxWidth: '600px',
    bgcolor: theme.palette.common.white,
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[2],

    [theme.breakpoints.down('sm')]: {
      p: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      p: theme.spacing(4),
      m: 0,
      borderRadius: 0,
    },

    '.MuiFormControl-root': {
      m: 0,
    },
  };

  const titleStyle = {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
  };

  const rolesStyle = {
    gap: theme.spacing(0.5),
    ml: theme.spacing(1.5),
  };

  const [checkedRoles, setCheckedRoles] = React.useState<number[]>([]);

/*  const roleItemStyle = (isChecked: boolean) => ({
    backgroundColor: '#fff',
    p: theme.spacing(0.25),
    pr: theme.spacing(1.5),
    border: `2px solid ${isChecked ? theme.palette.primary.main : theme.palette.grey[200]}`,
    borderRadius: theme.spacing(1),
    userSelect: 'none',
    span: {
      fontSize: '14px',
    },
  });*/

  const onRoleChanged = (id: number) => {
    let roles;
    const idx = checkedRoles.indexOf(id);
    if (idx > -1) {
      roles = [...checkedRoles.filter(item => item !== id)];
    } else {
      roles = [...checkedRoles, id];
    }
    setCheckedRoles(roles);
  }

  const handleLevelChange = ({ target }: SelectChangeEvent) => {
    setLevel(Number(target.value));
  }

  return (
    <Stack sx={containerStyle} alignItems={'center'} justifyContent={'center'}>
      <Stack gap={theme.spacing(3)} sx={popupStyle}>
        <Stack
          spacing={theme.spacing(2)}
          alignItems={'left'}
          sx={{
            mb: theme.spacing(2),
          }}
        >
          <Stack direction={'row'} sx={titleStyle}>
            <Text variant={'h3'} fontWeight={700} fontSize={26} noWrap>
              Welcome to&nbsp;
            </Text>
            <LogoLabel
              sx={{
                height: theme.spacing(4),
                width: 'auto',
                mt: '1px',
              }}
            />
          </Stack>
        </Stack>
        {!saveProgress && (
          <>
            <LangSelectHOC
              label={'I want to learn'}
              onChange={setTargetLangCode}
              langCode={targetLangCode}
              highlightWithDefVideos={true}
            />
            <LangSelectHOC
              label={'My native language is'}
              onChange={setNativeLangCode}
              langCode={nativeLangCode}
            />

            <Select
              value={''+level}
              onChange={handleLevelChange}
              variant={'filled'}
              fullWidth
              displayEmpty
            >
              {
                userLevelItems.map((level) => {
                  return (
                    <MenuItem
                      key={level.id}
                      value={String(level.id)}
                    >
                      {level.title}
                    </MenuItem>
                  );
                })
              }
            </Select>

            <Stack gap={1.5}>
              <Text variant={'subtitle2'}>
                Select what better describes you, so we can tune our app to your needs:
              </Text>
              <FormGroup row sx={rolesStyle}>
                {userRoles.map(role => {
                  return (
                  <InstallUserRoleCheckbox
                    key={role.id}
                    id={role.id}
                    title={role.name}
                    checked={checkedRoles.includes(role.id)}
                    onChange={()=>onRoleChanged(role.id)}
                  />)
                })}
              </FormGroup>
            </Stack>
          </>
        )}
        {error && <Text sx={{ color: theme.palette.error.main }}>{error}</Text>}

        <Button
          disabled={!nativeLangCode || !targetLangCode || !!error || saveProgress}
          onClick={handleSave}
          variant={'contained'}
          fullWidth
          sx={{
            height: theme.spacing(6),
            fontSize: 16,
          }}
        >
          {saveBtnTitle}
        </Button>
      </Stack>
    </Stack>
  );
};
