import { API_URL } from '../../constants';
import { TAsyncThunk } from '../../../spa/store/types';
import { postRequest } from '../../utils/requestService';

const VIDEO_UPDATE_POSITION_URL = API_URL + '/video/update-position';

export const updateVideoPosition = (
  videoKey: string,
  userGroupId: number,
  position: number
): TAsyncThunk => async(
  dispatch
) => {
  position = Math.round(position);
  const body = { videoKey, userGroupId, position };
  return await dispatch(postRequest(VIDEO_UPDATE_POSITION_URL, body));
};