import { IState } from '../../../../../store/types';
import { TUserGroup, TVideo } from '../../../../../store/models/types';
import { getUserGroupLangKnown, getUserGroupLangToLearn } from '../../../../../store/models/selectors';
import { LangUtil } from '../../../../../../common/utils/lang-util';

export class VideoLibraryGroupItemHelpers {

  public static getSelectedVideoLangsByGroup(state: IState, group: TUserGroup, video: TVideo): string[] {
    const nativeLang = getUserGroupLangKnown(state, group);
    const targetLang = getUserGroupLangToLearn(state, group);
    const nativeLangCode = LangUtil.checkLangCode(nativeLang?.code);
    const targetLangCode = LangUtil.checkLangCode(targetLang?.code);
    let groupLangCodes: string[] = [targetLangCode, nativeLangCode];
    if (!video.info.langsChecked)
      return groupLangCodes;
    const result: string[] = [];
    groupLangCodes.forEach(code => {
      if (code && video.info.langList && video.info.langList.includes(code)) {
        result.push(code);
      }
    })
    return result;
  }

  public static getSelectedVideoLangs(state: IState, group: TUserGroup, langList: string[]): string[] {
    const nativeLang = getUserGroupLangKnown(state, group);
    const targetLang = getUserGroupLangToLearn(state, group);
    const nativeLangCode = LangUtil.checkLangCode(nativeLang?.code);
    const targetLangCode = LangUtil.checkLangCode(targetLang?.code);
    let groupLangCodes: string[] = [targetLangCode, nativeLangCode];
    const result: string[] = [];
    groupLangCodes.forEach(code => {
      if (code && langList && langList.includes(code)) {
        result.push(code);
      }
    })
    return result;
  }
}