import { ILanguageItem } from '../../../store/general/types';
import { TApiResponse } from './phrase-details-service';
import { LangUtil } from '../../../../common/utils/lang-util';

export class PhraseDetailsBase {

  protected checkLangCode(langCode: string): string {
    return LangUtil.checkLangCode(langCode);
  }

  protected checkLangName(name: string): string {
    return LangUtil.checkLangName(name);
  }

  public async getFromApi(text: string, fromLang: ILanguageItem, toLang: ILanguageItem, hash: string): Promise<TApiResponse> {
    return { hash: '', result: ''};
  }
}