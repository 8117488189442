import { IState } from '../../../../store/types';
import { connect } from 'react-redux';
import { InstallExtensionWarning } from '../components/InstallExtensionWarning';
import { IInstallExtensionWarningEvents, IInstallExtensionWarningFields } from '../components/types';
import { InstallExtensionWarningEvents } from './events';

const mapStateToProps = (
  dispatch: any
) => {
  return (
    state: IState
  ): IInstallExtensionWarningFields => {
    return {
    }
  };
}

const mapDispatchToProps = (
  dispatch: any
): IInstallExtensionWarningEvents => ({
    onCheckInstalled: () => dispatch(InstallExtensionWarningEvents.onCheckInstalled()),
    onInstall: () => dispatch(InstallExtensionWarningEvents.onInstall()),
  }
);

export const InstallExtensionWarningHOC = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallExtensionWarning);

InstallExtensionWarningHOC.displayName = 'InstallExtensionWarningHOC';
