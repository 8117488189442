import { IVocabularyPhrase } from '../../../../types/common';
import { PlayPhraseBaseHandler } from './playPhraseBaseHandler';
import { getDispatch } from '../../../../store';
import { PlayerManager } from '../playerManager';
import { PhraseListActions } from '../../../../store/phrase-list/actions';
import { EPlayerControllerMode, PlayerController } from '../playerController';
import { EPhraseListPlayMode } from '../../../../store/phrase-list/types';

export class PlayPhraseHandler extends PlayPhraseBaseHandler {

  public async play(phrase: IVocabularyPhrase) {

    const playerController = PlayerController.getInstance();
    const playerManager = PlayerManager.getInstance();
    playerController.reset();
    await playerManager.pausePlay();

    playerController.setMode(EPlayerControllerMode.PLAY_PHRASE);

    const dispatch = getDispatch();
    dispatch(PhraseListActions.setPlayMode(EPhraseListPlayMode.PLAY));

    this.startPhrase(phrase);
  }

}