import * as React from 'react';
import { Button, TextField, Stack } from '@mui/material';
import { ILoginAsItemProps } from './types';

export const LoginAsItem: React.FC<ILoginAsItemProps> = ({
  onClick
}) => {

  const [email, setEmail] = React.useState<string>('');

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }

  const handleClick = () => {
    onClick(email);
  }

  return (
    <>
      <TextField
        placeholder={'Email'}
        variant={'filled'}
        fullWidth
        autoFocus
        onChange={onEmailChange}
        value={email}
        multiline={false}
      />
      <Button
        variant={'contained'}
        onClick={handleClick}>
        Login as
      </Button>
    </>
  );
};
