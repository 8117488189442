import { EExtMessageType, ExtMessageSender } from '../../common/utils/ext-message-sender';
import { setExtensionInstalledAction } from '../store/general/actions';
import { getDispatch } from '../store';

export class ExtensionInstallChecker {

  public static async check(): Promise<boolean> {
    const dispatch = getDispatch();
    let result;
    try {
      result = await ExtMessageSender.send({ type: EExtMessageType.PING});
    } catch(e) {
      result = false;
    }
    dispatch(setExtensionInstalledAction(result));
    return result;
  }

}