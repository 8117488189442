import { PhraseDetailsBase } from './phrase-details-base';
import { GptChatRest } from '../../../../common/rest/gptChat/gptChatRest';
import { PhraseDetailsCache } from './phrase-details-cache';
import { PhraseDetailsCustomPromptVars } from '../phraseDetailsCustomPromptVars';
import { LangUtil } from '../../../../common/utils/lang-util';
import { getAllVideoPhrasesWithFilterOrphans, getTargetCaptions } from '../../../store/videos/selectors';
import { getState } from '../../../store';
import { EVocabularyPhraseType, ICaptionsItem } from '../../../types/common';

const md5 = require('md5');

export abstract class PhraseDetailsGptchatService extends PhraseDetailsBase {



  protected text: string;
  protected context: string;
  protected targetLangCode: string;
  protected nativeLangCode: string;

  private paramValues: Record<string, string> = null;

  constructor(text: string, context: string, targetLangCode: string, nativeLangCode: string) {
    super();
    this.text = text;
    this.context = context;
    this.targetLangCode = this.checkLangCode(targetLangCode);
    this.nativeLangCode = this.checkLangCode(nativeLangCode);
  }

  public async load(useCache: boolean = true): Promise<string | undefined> {

    const prompt = await this.getPreparedPrompt();

    if (!prompt) {
      return '';
    }
    let result;
    if (useCache) {
      result = PhraseDetailsCache.get([prompt, this.constructor.name]);
    }
    if (result) {
      return result;
    }

    const hash = md5(prompt);
    result = await this.callApi(prompt, hash);

    if (result) {
      result = result.trim();
      PhraseDetailsCache.put([prompt, this.constructor.name], result);
    }
    return result;
  }

  public async getPreparedPrompt(): Promise<string> {
    const params = this.getPromptParams();
    let promptText = await this.getPromptText();

    if (!promptText) {
      return '';
    }

    PhraseDetailsCustomPromptVars.getListWithNameLenSort(true).forEach(v => {
      promptText = promptText.replaceAll(v.name, v.originalName)
    })

    const paramNames = PhraseDetailsCustomPromptVars.getVarsInPrompt(promptText);
    paramNames.forEach(paramName => {
      const value = this.getPromptParam(paramName);
      promptText = promptText.replaceAll('{'+paramName+'}', value);
    })
   /* const result = promptText.matchAll(PhraseDetailsGptchatService.PARAM_RE);
    for (const item of result) {
      const paramName = item[1]?.trim();
      if (paramName) {
        const value = this.getPromptParam(paramName);
        promptText = promptText.replaceAll('{'+paramName+'}', `<span class="prompt-param_${paramName}">${value}</span>`);
      }
    }*/
    return promptText;
  }

  public static preparePrompt(
    params: {
      text: string,
      context: string,
      targetLang: string,
      nativeLang: string,
    },
    prompt: string,
  ): string {
    if (!prompt) return '';

    PhraseDetailsCustomPromptVars.getList(true).forEach(v => {
      prompt = prompt.replaceAll(v.name, v.originalName)
    })

    const result = prompt.matchAll(PhraseDetailsGptchatService.PARAM_RE);
    for (const item of result) {
      const paramName = item[1]?.trim();
      if (paramName && params.hasOwnProperty(paramName)) {
        const value = params[paramName];
        prompt = prompt.replaceAll('{'+paramName+'}', value);
      }
    }
    return prompt;
  }

  private async callApi(prompt: string, hash: string): Promise<string | undefined> {
    if (this.useHashCallApi()) {
      this.saveHash(hash);
    }
    const resp = await GptChatRest.exec({ prompt, hash });
    hash = this.getHash();
    if (this.useHashCallApi() && hash !== resp.hash) {
      return undefined;
    }
    return resp.result;
  }

  protected useHashCallApi(): boolean {
    return true;
  }

  protected getPromptParam(param: string): string {
    if (!this.paramValues) {
      this.paramValues = this.getPromptParams()
    }
    if (this.paramValues[param]) {
      return this.paramValues[param];
    }
    if (PhraseDetailsCustomPromptVars.ENTIRE_SCRIPT_PARAM === param) {
      return this.getEntiryScript();
    }
    if (PhraseDetailsCustomPromptVars.PHRASE_LIST_PARAM === param) {
      return this.getPhraseList();
    }
    return '';
  }

  private getPromptParams(): Record<string, string> {
    const targetLang = LangUtil.getLangNameByCode(this.targetLangCode);
    const nativeLang = LangUtil.getLangNameByCode(this.nativeLangCode);
    return {
      targetLang,
      nativeLang,
      text: this.text,
      context: this.context
    }
  }

  private getEntiryScript(): string {
    return getTargetCaptions(getState()).map((caption: ICaptionsItem) => caption.text).join(' ');
  }

  private getPhraseList() : string {
    return getAllVideoPhrasesWithFilterOrphans(getState()).
      filter(p => p.type === EVocabularyPhraseType.WORD_SELECTED).
      map(p => p.highlighted).join(', ');
  }

  protected abstract getPromptText(): Promise<string>;

  protected abstract saveHash(hash: string): void;

  protected abstract getHash(): string;

}