import { API_URL } from '../../constants';
import { getDispatch } from '../../../spa/store';
import { getRequest } from '../../utils/requestService';
import { TVideoTutorial } from '../../../spa/store/models/types';

export class VideoTutorialsRest {

  private static LIST_URL = API_URL + '/videoTutorial/list';

  public static async list(): Promise<TVideoTutorial[]> {
    const dispatch = getDispatch();
    return await dispatch(getRequest(VideoTutorialsRest.LIST_URL));
  }

}
